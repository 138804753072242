import React from 'react';
import { WorkingIconWithText, IconText, OnlineIcon, OfflineIcon, WorkingIcon } from '../styles';
import { chooseContractorIconWithBottomTextColor } from '../utils/colorChoice';
import { opacityColorForBackgroundHex } from '../../../../styles/colors';



const Icon = ({ hasOrder, online, bgColor }: { hasOrder: boolean, online: boolean, bgColor:string }) => {
  const style = { width: '100%', color: opacityColorForBackgroundHex({ bgHex: bgColor }) }
  if (hasOrder)
    return <WorkingIcon fontSize='large' style={style} />
  if (online)
    return <OnlineIcon fontSize='large' style={style} />
  return <OfflineIcon fontSize='large' style={style} />
}

interface ContratorOnlineIconProps {
  hasOrder: boolean;
  online: boolean;
}

const ContractorOnlineIcon = ({ hasOrder, online }: ContratorOnlineIconProps) => {

  const color = chooseContractorIconWithBottomTextColor(hasOrder, online)

  const chooseText = (hasOrder: boolean, online: boolean) => {
    if (hasOrder) return 'ONLINE ‧ ALOCADO'
    if (online) return 'ONLINE ‧ DISPONÍVEL'
    return 'OFFLINE'
  }

  return (
    <WorkingIconWithText color={color}>
      <Icon hasOrder={hasOrder} online={online} bgColor={color} />
      <IconText bgColorHex={color}>{chooseText(hasOrder, online)}</IconText>
    </WorkingIconWithText>
  );
};

export default ContractorOnlineIcon