import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { ImageViewer } from '~/components';
import { Title, Data, DataButton } from './styles';

export interface InfoProps {
  title: string;
  data?: string;
}

export function Info(props: InfoProps) {
  const [imageUrl, setImageUrl] = useState<string>();

  return (
    <>
      <Grid
        container
        item
        direction='column'
        justify='flex-start'
        alignContent='flex-start'
        alignItems='center'
        xs
        spacing={1}
      >
        <Grid item>
          <Title> {props.title}</Title>
        </Grid>
        <Grid item>
          <DataButton onClick={() => setImageUrl(props.data)}>
            <Data src={props.data} />
          </DataButton>
        </Grid>
      </Grid>

      <ImageViewer imageUrl={imageUrl} onClose={() => setImageUrl(undefined)} />
    </>
  );
}

export default Info;
