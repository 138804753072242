import styled, { css } from 'styled-components';

import Colors from '~/styles/colors';

interface CardSelectedProps {
  selected?: boolean;
  last?: boolean;
}

export const Container = styled.div<CardSelectedProps>`
  display: flex;
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  ${({ last }) =>
    !last &&
    css`
      margin-right: 8px;
    `}
  ${({ selected }) => (selected ? 'background-color: #50C6DF;' : '')}
`;

export const Title = styled.p<CardSelectedProps>`
  line-height: 1.5;
  ${({ selected }) => (selected ? `color: ${Colors.white};` : '')}
  font-size: 14px;
`;

export const Subtitle = styled.p<CardSelectedProps>`
  line-height: 1.5;
  font-weight: bold;
  font-size: 14px;
  ${({ selected }) =>
    selected &&
    css`
      color: ${Colors.white};
      font-weight: bold;
    `}
`;
