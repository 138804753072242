const messages = {
  'message-default-error': 'Ocorreu um erro, tente novamente!',
  'message.verification-code': 'Enviamos um código de verificação para seu e-mail',
  'message-confirmation-code-mandatory': 'Código de Confirmação não informado!',
  'message-confirmation-code-valid': 'Código validado com sucesso!',
  'message-load-order-details': 'Ocorreu um erro ao carregar detalhes ordem!\nTente novamente.',
  'message-send-order-confirmation': 'Ocorreu um erro ao enviar a confirmação da ordem!\nTente novamente.',
  'message-create-order': 'Ocorreu um erro ao criar a ordem!\nTente novamente.',
  'message-cancel-order': 'Ocorreu um erro ao cancelar a ordem!\nTente novamente.',
  'message-additional-information':
    'Sua solicitação não poderá ser realizada, por favor entre em contato com a empresa',
  'message.account-type.mandatory': 'Tipo de conta obrigatório',
  'message.address.city.length': 'Tamanho da cidade inválido',
  'message.address.city.mandatory': 'Cidade obrigatória',
  'message.address.complement.length': 'Tamanho do endereço inválido',
  'message.address.country.length': 'Tamanho do país inválido',
  'message.address.country.mandatory': 'País obrigatório',
  'message.address.neighborhood.length': 'Tamanho do bairro obrigatório',
  'message.address.neighborhood.mandatory': 'Bairro obrigatório',
  'message.address.number.length': 'Tamanho do número inválido',
  'message.address.number.mandatory': 'Número inválido',
  'message.address.state.length': 'Tamanho do estado inválido',
  'message.address.state.mandatory': 'Estado obrigatório',
  'message.address.street.length': 'Tamanho da rua inválido',
  'message.address.street.mandatory': 'Rua é obrigatório',
  'message.address.zipCode.invalid.format': 'CEP inválido',
  'message.address.zipCode.mandatory': 'CEP é obrigatório',
  'message.allocation.contractor.id.mandatory': 'ID do prestador obrigatório',
  'message.allocation.order.id.mandatory': 'ID da ordem é obrigatório',
  'message.answer.format-length': 'Tamanho da resposta inválido',
  'message.association-name.format-length': 'Tamanho do nome da empresa inválido',
  'message.association.address.mandatory': 'Endereço obrigatório',
  'message.association.charge-excess.mandatory': 'Excesso de carga obrigatório',
  'message.association.cnpj.duplicated': 'CNPJ duplicado',
  'message.association.cnpj.length': 'Tamanho do CNPJ inválido',
  'message.association.cnpj.mandatory': 'CNPJ obrigatório',
  'message.association.code.duplicate': 'Código duplicado',
  'message.association.code.length': 'Tamanho do código inválido',
  'message.association.code.mandatory': 'Código obrigatório',
  'message.association.company-name.length': 'Tamanho da companhia inválido',
  'message.association.company-name.mandatory': 'Companhia obrigatório',
  'message.association.description.length': 'Tamanho da descrição inválido',
  'message.association.description.mandatory': 'Descrição obrigatório',
  'message.association.diff-source-destination.mandatory': 'Diferença de origem e destino obrigatório',
  'message.association.email.invalid-format': 'Formato do e-mail inválido',
  'message.association.not.found': 'Empresa não encontrada',
  'message.association.phone-number.invalid-format': 'Formato do telefone inválido',
  'message.association.trading-name.length': 'Tamanho do nome comercial inválido',
  'message.benefits.association.id.mandatory': 'ID do benefício inválido',
  'message.benefits.association.service.code.duplicate': 'Código serviço do benefício duplicado',
  'message.benefits.code.length': 'Tamanho do código inválido',
  'message.benefits.code.mandatory': 'Código obrigatório',
  'message.benefits.coverageKm.length': 'Tamanho da cobertura inválido',
  'message.benefits.coverageKm.mandatory': 'Cobertura obrigatório',
  'message.benefits.description.length': 'Tamanho da descrição inválido',
  'message.benefits.description.mandatory': 'Descrição obrigatório',
  'message.benefits.list.error': 'Erro na lista de benefício',
  'message.benefits.period.length': 'Tamanho do período inválido',
  'message.benefits.period.mandatory': 'Período obrigatório',
  'message.benefits.quantity.length': 'Tamanho da quantidade inválido',
  'message.benefits.quantity.mandatory': 'Quantidade obrigatório',
  'message.benefits.service.id.mandatory': 'ID do serviço obrigatório',
  'message.benefits.update.error': 'Erro na atualização do benefício',
  'message.billing-address.city.mandatory': 'Cidade obrigatório',
  'message.billing-address.mandatory': 'Endereço obrigatório',
  'message.billing-address.neighborhood.mandatory': 'Bairro obrigatório',
  'message.billing-address.number.mandatory': 'Número obrigatório',
  'message.billing-address.state.mandatory': 'Estado obrigatório',
  'message.billing-address.street.mandatory': 'Endereço obrigatório',
  'message.billing-address.zip-code.mandatory': 'CEP obrigatório',
  'message.calculation.association.id.mandatory': 'ID da empresa obrigatório ',
  'message.calculation.customer.vehicle.social.security.conflict': 'O veículo não pertence ao cliente',
  'message.calculation.displace.mandatory': 'Deslocamento obrigatório',
  'message.calculation.point.mandatory': 'Ponto obrigatório',
  'message.calculation.pricing.association.notfound': 'Valor não encontrado',
  'message.calculation.service.id.mandatory': 'ID do serviço obrigatório',
  'message.calculation.social.security.mandatory': 'Documento obrigatório',
  'message.calculation.timezone.mandatory': 'Fuso horário obrigatório',
  'message.calculation.vehicle.id.mandatory': 'ID do veículo obrigatório',
  'message.checking.type.mandatory': 'Tipo obrigatório',
  'message.client.maplink.error': 'Erro no Maplink',
  'message.client.zoop.error.timeout': 'Tempo expirou (Zoop)',
  'message.cnh.dispatchedDate.mandatory': 'Data de expedição obrigatória',
  'message.cnh.number.length.invalid': 'Tamanho do número inválido',
  'message.cnh.number.mandatory': 'Número obrigatório',
  'message.coordinate.latitude.mandatory': 'Latitude obrigatória',
  'message.coordinate.longitude.mandatory': 'Longitude obrigatória',
  'message.customer.batch.familyname.length': 'Tamanho do sobrenome inválido',
  'message.customer.batch.familyname.mandatory': 'Sobrenome obrigatório',
  'message.customer.batch.givenname.length': 'Tamanho do nome inválido',
  'message.customer.batch.givenname.mandatory': 'Nome obrigatório',
  'message.customer.batch.username.length': 'Tamanho do usuário inválido',
  'message.customer.batch.username.mandatory': 'Usuário obrigatório',
  'message.customer.username.already.exists': 'Usuário já existe',
  'message.customer.vehicle.benefits.not.associated': 'Veículo não associado',
  'message.customer.vehicle.error': 'Erro no veículo',
  'message.database.duplicated-key': 'Chave duplicada no banco de dados',
  'message.device.customer-id.length': 'Tamanho do ID cliente inválido',
  'message.device.customer-id.mandatory': 'ID do cliente é obrigatório',
  'message.device.notfound': 'Dispositivo não encontrado',
  'message.device.token.length': 'Tamanho do token inválido',
  'message.device.token.mandatory': 'Token é obrigatório',
  'message.document.type.mandatory': 'Tipo do documento obrigatório',
  'message.email.unique': 'E-mail já existe',
  'message.file.presign.error': 'Erro no arquivo',
  'message.file.upload.error': 'Erro no upload',
  'message.fipe-api.integration-error': 'Erro na integração com a FIPE',
  'message.identity.customer.client.error': 'Erro na identificação do cliente',
  'message.integration.fipe-api.error': 'Erro na integração com a FIPE',
  'message.integration.gateway.connection.refused': 'Conexão recusada',
  'message.order.calculation.invalid': 'Cálculo inválido',
  'message.order.calculation.notfound': 'Cálculo não encontrado',
  'message.order.checking.notfound': 'Verificação não encontrada',
  'message.order.deallocation.not-same-contractor': 'Desalocação não é do mesmo prestador',
  'message.order.id.mandatory': 'ID da ordem é obrigatória',
  'message.order.message.canceled': 'Ordem cancelada',
  'message.order.message.deallocated': 'Ordem desalocada',
  'message.order.message.executing': 'Ordem em execução',
  'message.order.message.finished': 'Ordem concluída',
  'message.order.message.on-going': 'Ordem em andamento',
  'message.order.message.payment-refused': 'Pagamento recusado',
  'message.order.state-violation': 'Violação do estado',
  'message.order.subject.canceled': 'Ordem cancelada',
  'message.order.subject.deallocated': 'Ordem desalocada',
  'message.order.subject.executing': 'Ordem em execução',
  'message.order.subject.finished': 'Ordem concluída',
  'message.order.subject.on-going': 'Ordem em andamento',
  'message.order.subject.payment-refused': 'Pagamento recusado',
  'message.orders.association-code.mandatory': 'Código da empresa obrigatório',
  'message.orders.association-id.mandatory': 'ID da empresa obrigatório',
  'message.orders.association-name.mandatory': 'Nome da empresa obrigatório',
  'message.orders.coordinates.destination.mandatory': 'Destino obrigatório',
  'message.orders.coordinates.displace.mandatory': 'Deslocamento obrigatório',
  'message.orders.coordinates.latitude.format-length': 'Tamanho da latitude inválido',
  'message.orders.coordinates.latitude.format': 'Formato da latitude inválido',
  'message.orders.coordinates.latitude.mandatory': 'Latitude obrigatória',
  'message.orders.coordinates.longitude.format-length': 'Tamanho da longitude inválido',
  'message.orders.coordinates.longitude.format': 'Formato da longitude inválido',
  'message.orders.coordinates.longitude.mandatory': 'Longitude obrigatório',
  'message.orders.coordinates.mandatory': 'Coordenadas obrigatório',
  'message.orders.coordinates.ordination.format-min': 'Tamanho mínimo inválido',
  'message.orders.coordinates.ordination.mandatory': 'Ordenação obrigatório',
  'message.orders.coordinates.source.mandatory': 'Fonte obrigatório',
  'message.orders.cost.amount.format-length': 'Tamanho do quantida inválida',
  'message.orders.cost.amount.format-max': 'Tamanho máximo do quantida inválida',
  'message.orders.cost.amount.format-min': 'Tamanho mínimo do quantida inválida',
  'message.orders.cost.amount.mandatory': 'Quantidade obrigatória',
  'message.orders.cost.benefits-quota-exceeded.mandatory': 'Quantidade de benefícios obrigatória',
  'message.orders.cost.discount.format-length': 'Tamanho do desconto inválido',
  'message.orders.cost.discount.format-max': 'Tamanho máximo do desconto inválido',
  'message.orders.cost.discount.format-min': 'Tamanho mínimo do desconto inválido',
  'message.orders.cost.discount.mandatory': 'Desconto obrigatório',
  'message.orders.cost.displace.mandatory': 'Deslocamento obrigatório',
  'message.orders.cost.has-benefits.mandatory': 'Tem benefício obrigatório',
  'message.orders.cost.id.mandatory': 'ID do custo obrigatório',
  'message.orders.cost.mandatory': 'Custo obrigatório',
  'message.orders.cost.payer.mandatory': 'Pagador obrigatório',
  'message.orders.cost.surcharge.format-length': 'Tamanho da sobretaxa inválido',
  'message.orders.cost.surcharge.format-max': 'Tamanho máximo da sobretaxa inválido',
  'message.orders.cost.surcharge.format-min': 'Tamanho mínimo da sobretaxa inválido',
  'message.orders.cost.surcharge.mandatory': 'Sobretaxa obrigatório',
  'message.orders.cost.total.format-length': 'Tamanho do total inválido',
  'message.orders.cost.total.format-max': 'Tamanho máximo do total inválido',
  'message.orders.cost.total.format-min': 'Tamanho mínimo do total inválido',
  'message.orders.cost.total.mandatory': 'Total obrigatório',
  'message.orders.cost.type.mandatory': 'Tipo obrigatório',
  'message.orders.cost.value.format-length': 'Tamanho do valor inválido',
  'message.orders.cost.value.format-max': 'Tamanho máximo do valor inválido',
  'message.orders.cost.value.format-min': 'Tamanho mínimo do valor inválido',
  'message.orders.cost.value.mandatory': 'Valor obrigatório',
  'message.orders.customer.mandatory': 'Cliente obrigatório',
  'message.orders.orders.vehicle.model-year.format-length': 'Tamanho do ano inválido',
  'message.orders.payment.mandatory': 'Pagamento obrigatório',
  'message.orders.payment.type.mandatory': 'Tipo do pagamento obrigatório',
  'message.orders.service-type.mandatory': 'Tipo do serviço obrigatório',
  'message.orders.time-zone.mandatory': 'Fuso horário obrigatório',
  'message.orders.vehicle-color.mandatory': 'Cor do veículo obrigatória',
  'message.orders.vehicle-manufacture.mandatory': 'Marca do veículo obrigatória',
  'message.orders.vehicle-model.mandatory': 'Modelo do veículo obrigatório',
  'message.orders.vehicle-status.mandatory': 'Situação do veículo obrigatória',
  'message.orders.vehicle-type.mandatory': 'Tipo do veículo obrigatório',
  'message.orders.vehicle.city.format-length': 'Tamanho da cidade inválido',
  'message.orders.vehicle.color.format-length': 'Tamanho da cor inválido',
  'message.orders.vehicle.license-plate.format-length': 'Tamanho da placa inválido',
  'message.orders.vehicle.mandatory': 'Veículo obrigatório',
  'message.orders.vehicle.manufacture.format-length': 'Tamanho da montadora inválido',
  'message.orders.vehicle.model.format-length': 'Tamanho do modelo inválido',
  'message.orders.vehicle.state.format-length': 'Tamanho da situação inválido',
  'message.orders.vehicle.status.format-length': 'Tamanho do estado inválido',
  'message.orders.vehicle.type.format-length': 'Tamanho do tipo do veículo inválido',
  'message.organization.address.mandatory': 'Endereço obrigatório',
  'message.organization.alreadyexists': 'Organização já existe',
  'message.organization.company-name.lenght': 'Tamanho do nome da companhia inválido',
  'message.organization.company-name.mandatory': 'Companhia obrigatória',
  'message.organization.contractor.alreadyexists': 'Prestador já existe',
  'message.organization.contractor.identity.mandatory': 'Identidade obrigatória',
  'message.organization.contractor.organization.mandatory': 'Organização obrigatória',
  'message.organization.contractor.organization.notfound': 'Organização não encontrada',
  'message.organization.email.invalid': 'E-mail inválido',
  'message.organization.email.lenght': 'Tamanho do e-mail inválido',
  'message.organization.email.mandatory': 'E-mail obrigatório',
  'message.organization.identity.lenght': 'Tamanho da identidade inválido',
  'message.organization.notfound': 'Organização não encontrada',
  'message.organization.social-identity.duplicated': 'Documento da empresa duplicado',
  'message.organization.social-identity.lenght': 'Tamanho do documento inválido',
  'message.organization.social-identity.mandatory': 'Documento obrigatório',
  'message.path.mandatory': 'Caminho obrigatório',
  'message.payment.notfound': 'Pagamento não encontrado',
  'message.payment.order.id.mandatory': 'ID da ordem obrigatório',
  'message.payment.reason.length': 'Tamanho da razão inválido',
  'message.payment.reason.mandatory': 'Razão obrigatório',
  'message.payment.token.mandatory': 'Token obrigatório',
  'message.period.data.error': 'Erro no período',
  'message.point.latitude.mandatory': 'Latitude obrigatória',
  'message.point.longitude.mandatory': 'Longitude obrigatória',
  'message.pricing.association.duplicate': 'Empresa duplicada',
  'message.pricing.code.mandatory': 'Código obrigatório',
  'message.pricing.price.format': 'Formato do preço inválido',
  'message.pricing.price.mandatory': 'Preço obrigatório',
  'message.pricing.price.minValue': 'Preço mínimo inválido',
  'message.pricingAssociation.association.mandatory': 'Empresa obrigatória',
  'message.pricingAssociation.exceeded.mandatory': 'Excedido obrigatório',
  'message.pricingAssociation.prices.mandatory': 'Preço obrigatório',
  'message.pricingAssociation.serviceCategory.mandatory': 'Categoria obrigatória',
  'message.problem.calculation-mode.mandatory': 'Modo de cálculo obrigatório',
  'message.problem.points.lenght': 'Tamanho dos pontos inválido',
  'message.problem.points.mandatory': 'Pontos obrigatório',
  'message.problem.start-date.mandatory': 'Data início obrigatório',
  'message.question.format-length': 'Tamanho da mensagem inválido',
  'message.quiz.active.mandatory': 'Questionário obrigatório',
  'message.quiz.answer.mandatory': 'Resposta obrigatória',
  'message.quiz.association.code.constraint.duplicate': 'Empresa duplicada',
  'message.quiz.association.mandatory': 'Empresa obrigatória',
  'message.quiz.code.mandatory': 'Código obrigatório',
  'message.quiz.form.mandatory': 'Formulário obrigatório',
  'message.quiz.question.mandatory': 'Questão obrigatória',
  'message.quiz.service.mandatory': 'Serviço obrigatório',
  'message.quiz.serviceId.mandatory': 'ID do serviço obrigatório',
  'message.request.conversion.error': 'Erro na conversão da requisição',
  'message.rg.dispatchedDate.mandatory': 'Data de expedição obrigatória',
  'message.rg.number.length.invalid': 'Tamanho do número inválido',
  'message.rg.number.mandatory': 'Número obrigatório',
  'message.sign-up.username.error': 'Erro na criação do usuário',
  'message.toll-problem.legs.mandatory': 'Deslocamento obrigatório',
  'message.toll-problem.source.mandatory': 'Fonte obrigatória',
  'message.toll.leg.points.mandatory': 'Pontos obrigatório',
  'message.toll.leg.vehicle-type.mandatory': 'Tipo do veículo obrigatório',
  'message.trip.identity.mandatory': 'Identidade obrigatória',
  'message.trip.job.notfound': 'Trabalho não encontrado',
  'message.trip.problem.mandatory': 'Problema obrigatório',
  'message.user.created-at.mandatory': 'Data de criação obrigatória',
  'message.user.email.invalid': 'E-mail inválido',
  'message.user.email.mandatory': 'E-mail obrigatório',
  'message.user.id.mandatory': 'ID do usuário obrigatório',
  'message.user.phone-number.length': 'Tamanho do telefone inválido',
  'message.user.phone-number.mandatory': 'Telefone obrigatório',
  'message.user.token.length': 'Tamanho do token inválido',
  'message.user.token.mandatory': 'Token obrigatório',
  'message.user.username.length': 'Tamanho do usuário inválido',
  'message.user.username.mandatory': 'Usuário obrigatório',
  'message.value.mandatory': 'Valor obrigatório',
  'message.vehicle.contractor.city.length': 'Tamanho da cidade inválido',
  'message.vehicle.contractor.city.mandatory': 'Cidade obrigatória',
  'message.vehicle.contractor.color.length': 'Tamanho da cor inválido',
  'message.vehicle.contractor.color.mandatory': 'Cor obrigatória',
  'message.vehicle.contractor.licenseplate.length': 'Tamanho da placa inválido',
  'message.vehicle.contractor.licenseplate.mandatory': 'Placa obrigatória',
  'message.vehicle.contractor.manufacture.length': 'Tamanho do marca inválido',
  'message.vehicle.contractor.manufacture.mandatory': 'Marca obrigatória',
  'message.vehicle.contractor.model.length': 'Tamanho do modelo é inválido',
  'message.vehicle.contractor.model.mandatory': 'Modelo obrigatório',
  'message.vehicle.contractor.notfound': 'Prestador não encontrado',
  'message.vehicle.contractor.organization.id.mandatory': 'ID da Organização é obrigatório',
  'message.vehicle.contractor.state.length': 'O tamanho do estado é inválido',
  'message.vehicle.contractor.state.mandatory': 'O estado é obrigatório',
  'message.vehicle.contractor.type.id.mandatory': 'O tipo é obrigatório',
  'message.vehicle.contractor.unique': 'O veículo não é único',
  'message.vehicle.contractor.vin.length': 'Tamanho do chassi inválido',
  'message.vehicle.contractor.vin.mandatory': 'Chassi obrigatório',
  'message.vehicle.customer.association.id.mandatory': 'ID da empresa é obrigatório',
  'message.vehicle.customer.city.length': 'Tamanho da cidade inválido',
  'message.vehicle.customer.city.mandatory': 'Cidade obrigatória',
  'message.vehicle.customer.color.length': 'Tamanho da cor inválido',
  'message.vehicle.customer.color.mandatory': 'Cor obrigatória',
  'message.vehicle.customer.identity.length': 'Tamanho da identidade inválido',
  'message.vehicle.customer.licenseplate.length': 'Tamanho da placa inválido',
  'message.vehicle.customer.licenseplate.mandatory': 'Placa é obrigatória',
  'message.vehicle.customer.licenseplate.or.vin.mandatory': 'Placa ou chassi são obrigatórios',
  'message.vehicle.customer.manufacture.length': 'Tamanho da marca inválido',
  'message.vehicle.customer.manufacture.mandatory': 'Marca obrigatória',
  'message.vehicle.customer.manufacturer.length': 'Tamanho da marca inválido',
  'message.vehicle.customer.manufacturer.mandatory': 'Marca obrigatória',
  'message.vehicle.customer.model.length': 'Tamanho do modelo inválido',
  'message.vehicle.customer.model.mandatory': 'Modelo obrigatório',
  'message.vehicle.customer.notfound': 'Cliente não encontrado',
  'message.vehicle.customer.social.security.length': 'Tamanho do documento inválido',
  'message.vehicle.customer.social.security.mandatory': 'Documento obrigatório',
  'message.vehicle.customer.state.length': 'Tamanho do estado inválido',
  'message.vehicle.customer.state.mandatory': 'Estado obrigatório',
  'message.vehicle.customer.status.error': 'Erro no estado',
  'message.vehicle.customer.status.length': 'Tamanho do estado inválido',
  'message.vehicle.customer.status.mandatory': 'Estado obrigatório',
  'message.vehicle.customer.type.id.mandatory': 'ID do tipo obrigatório',
  'message.vehicle.customer.type.invalid': 'Tipo inválido',
  'message.vehicle.customer.type.length': 'Tamanho do tipo inválido',
  'message.vehicle.customer.type.mandatory': 'Tipo obrigatório',
  'message.vehicle.customer.update.unable': 'Atualização não permitida',
  'message.vehicle.customer.vin.length': 'Tamanho do chassi inválido',
  'message.vehicle.customer.vin.mandatory': 'Chassi obrigatório',
  'message.bank.mandatory': 'Banco obrigatório',
  'message.bankCode.mandatory': 'Código do banco obrigatório',
  'message.branch.mandatory': 'Agência obrigatória',
  'message.cnpj.invalid': 'CNPJ inválido',
  'message.cnpj.mandatory': 'CNPJ obrigatório',
  'message.confirmation-code.expired': 'Código de confirmação expirado',
  'message.confirmation-code.mismatch': 'Código de confirmação inválido',
  'message.cpf.invalid': 'CPF inválido',
  'message.cpf.mandatory': 'CPF obrigatório',
  'message.current-account.mandatory': 'Conta corrente obrigatório',
  'message.customer.phone-number.already.exists': 'Telefone já existe',
  'message.document.notfound': 'Documento não encontrado',
  'message.document.validation.error': 'Erro na validação do documento',
  'message.file.convert.error': 'Erro ao converter o arquivo',
  'message.integration.connection.refused': 'Integração foi recusada',
  'message.login.not-confirmed': 'Login não confirmado',
  'message.login.unauthorized': 'Usuário ou senha inválido',
  'message.malformed-request': 'Requisição mal formatada',
  'message.order.notfound': 'Ordem não encontrada',
  'message.parser.error': 'Erro ao converter',
  'message.password.weak': 'Senha inválida',
  'message.person-type.mandatory': 'Tipo de pessoa obrigatório',
  'message.recipient.mandatory': 'Recebedor obrigatório',
  'message.reset-password.code.expired': 'Código de reset da senha expirou',
  'message.sign-in.wrong-username-password': 'Usuário ou senha inválida',
  'message.sign-up.email-phone-constraint': 'E-mail e telefone obrigatório',
  'message.sign-up.email.duplicity': 'E-mail já existe',
  'message.sign-up.social-account.already.logged.in': 'Login social já existe',
  'message.sign-up.username.duplicity': 'Usuário já existe',
  'message.token.expired': 'Token expirado',
  'message.type.mandatory': 'Tipo obrigatório',
  'message.user.not-found': 'Usuário não encontrado',
  'message.orders.time-zone.format-length': 'Tamanho do fuso horário inválido',
  'message.orders.association-code.format-length': 'Tamanho do código da empresa inválido',
  'message.orders.customer.format-length': 'Tamanho do documento inválido',
  'message.orders.payment.token.format-length': 'Tamanho do token pagamento inválido',
  'message.orders.payment.type.format-length': 'Tamanho do tipo de pagamento inválido',
  'message.service_regions.unavailable': 'Fora da área de atuação da Picktow.',
  'message.calculation.source.mandatory': 'Origem obrigatória',
  'message.contractor.evaluation.social-security.mandatory': 'Documento obrigatório',
  'message.contractor.evaluation.order-id.mandatory': 'ID da ordem obrigatório',
  'message.old-password.length': 'Tamanho da senha antiga inválido',
  'message.orders.in-progress': 'Já existe uma ordem em execução',
  'message.app.destination.mandatory': 'Destino é obrigatório',
  'message.app.origin.mandatory': 'Origem é obrigatória',
  'message.app.invalid.password':
    'Nova Senha inválida!\n\nDeve conter 8 caracteres, sendo.\nLetras maiúsculas,\nletras minúsculas e números.',
  'message.app.password.not-matched': 'Senhas não são iguais!',
  'message.app.password.new.mandatory': 'Nova Senha obrigatória!',
  'message.app.password.old.mandatory': 'Senha Atual obrigatória!',
  'message.app.confirmation-code.mandatory': 'Código de Confirmação obrigatório!',
  'message.app.usename.mandatory': 'CPF / CNPJ obrigatório!',
  'message.app.password.mandatory': 'Senha é obrigatória!',
  'message.contractor.cnh.mandatory': 'CNH é obrigatório!',
  'message.contractor.rg.mandatory': 'RG é obrigatório!',
};

export default messages;
