import { Content, StyledModal, ButtonContainer, StyledButton, TextContainer } from './styles';
import { Backdrop, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { addNoteByOrderId } from '~/services/api';
import {
  onSaveManual,
  onSaveAllocating,
  onSaveCanceled,
  onSaveInExecution,
  onSaveOngoing,
  onSavePayment,
  onSaveFinished,
} from '~/store/ducks/orderNote';
import { useDispatch } from 'react-redux';

interface NoteModalProps {
  opportunityNotes: Array<string>;
  opportunityId?: string;
  opportunityStatus?: string;
  visible: boolean;
  onClose: () => void;
}

export const AddNoteModal: React.FC<NoteModalProps> = ({
  visible,
  onClose,
  opportunityId,
  opportunityStatus,
  opportunityNotes,
}) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const selectDispatchByStatus = () => {
    switch (opportunityStatus) {
      case 'MANUAL_ALLOCATION':
        dispatch(onSaveManual());
        break;
      case 'ALLOCATING':
        dispatch(onSaveAllocating());
        break;
      case 'ON_GOING':
        dispatch(onSaveOngoing());
        break;
      case 'IN_EXECUTION':
        dispatch(onSaveInExecution());
        break;
      case 'CANCELED':
        dispatch(onSaveCanceled());
        break;
      case 'FINISHED':
        dispatch(onSaveFinished());
        break;
      case 'PAYMENT_PENDING' || 'PAYMENT_APPROVED' || 'PAYMENT_REFUSED':
        dispatch(onSavePayment());
        break;
    }
  };

  const handleSubmit = () => {
    if (value.trim().length > 0) {
      opportunityNotes.push(value)
      addNoteByOrderId(opportunityId, JSON.stringify(opportunityNotes))
        .then(resp => {
          if (resp.status === 200) selectDispatchByStatus();
          setValue('');
          onClose();
        })
        .catch(err => {
          console.error(err);
          onClose();
          setValue('');
        });
    } else {
      onClose();
      setValue('');
    }
  };

  const handleOnClose = () => {
    setValue('');
    onClose();
  };

  return (
    <StyledModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={visible}
      onClose={handleOnClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Content>
        <TextContainer>
          <TextField
            style={{ width: 470 }}
            id='standard-multiline-flexible'
            label='Observação'
            multiline
            value={value}
            onChange={event => setValue(event.target.value)}
          />
        </TextContainer>
        <ButtonContainer>
          <StyledButton type='text' filled label='Cancelar' onClick={() => handleOnClose()} />
          <StyledButton type='text' filled label='Enviar' onClick={() => handleSubmit()} />
        </ButtonContainer>
      </Content>
    </StyledModal>
  );
};
