import { useEffect, useState } from 'react';
import { PaymentDetails } from '~/types';
import { getPaymentDetailsApi } from '../api';

export function usePaymentDetails(orderId?: string, paymentType?: string) {
  const [details, setDetails] = useState<PaymentDetails>();

  useEffect(() => {
    const getPaymentDetails = async () => {
      if (!orderId || paymentType !== 'PIX') {
        setDetails(undefined);
        return;
      }

      try {
        const { data } = await getPaymentDetailsApi(orderId);
        setDetails(JSON.parse(data.details));
      } catch {
        return;
      }
    };

    getPaymentDetails();
  }, [orderId, paymentType]);

  return details;
}

export default usePaymentDetails;
