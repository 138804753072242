import React, { useState, useEffect } from 'react';
import { Grid, FormControl, TextField } from '@material-ui/core';
import { MTableEditField } from 'material-table';

import Snackbar, { SeverityType } from '~/components/Snackbar';
import InputComponent from '~/components/InputComponent';
import { isNumeric } from '~/util/validate';
import {
  loadAssociations,
  createBenefits,
  deleteBenefits,
  changeBenefits,
  loadBenefits,
  loadServices,
  loadPeriods,
} from '~/services/api';

import { Form } from './styles';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { AssociationsResponse, BenefitResponse, ServicesResponse } from '~/types';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { Title } from '~/components/Title';
import { Table } from '~/components';

export default function CoveragePlans() {
  const [errorsField, setErrorsField] = useState({
    code: false,
    description: false,
    quantity: false,
    coverage_km: false,
    period: false,
  });
  const [content, setContent] = useState<BenefitResponse[]>([]);
  const [periods, setPeriods] = useState({});
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [association, setAssociation] = useState<AssociationsResponse | null>(null);
  const [services, setServices] = useState<ServicesResponse[]>([]);
  const [service, setService] = useState<ServicesResponse | null>(null);
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [messageSnack, setMessageSnack] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: returnResponse } = await loadPeriods();

        let periods = {};
        returnResponse.periodos.forEach(item => {
          periods = { ...periods, [item]: item };
        });

        setPeriods(periods);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data } = await loadAssociations();
        setAssociations(data || []);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: returnResponse } = await loadServices();
        setServices(returnResponse || []);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!association || !service) {
        return;
      }

      setLoading(true);
      try {
        const { data } = await loadBenefits(association.id, service.id);
        setContent(data || []);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [association, service]);

  const handleSubmit = event => {
    event.preventDefault();

    return false;
  };

  const validateFields = newData => {
    setErrorsField({
      code: false,
      description: false,
      quantity: false,
      coverage_km: false,
      period: false,
    });
    let error = false;

    if (!newData.code.trim()) {
      setErrorsField(prevState => ({
        ...prevState,
        code: true,
      }));
      error = true;
    }

    if (!newData.description.trim()) {
      setErrorsField(prevState => ({
        ...prevState,
        description: true,
      }));
      error = true;
    }

    if (!isNumeric(newData.quantity)) {
      setErrorsField(prevState => ({
        ...prevState,
        quantity: true,
      }));
      error = true;
    }

    if (!isNumeric(newData.coverage_km)) {
      setErrorsField(prevState => ({
        ...prevState,
        coverage_km: true,
      }));
      error = true;
    }

    if (!newData.period.trim()) {
      setErrorsField(prevState => ({
        ...prevState,
        period: true,
      }));
      error = true;
    }

    return !error;
  };

  const saveNewPlan = async ({ code, description, quantity, coverage_km, period }) => {
    setLoading(true);
    if (!association) {
      setSeverity('warning');
      setMessageSnack('Selecione uma empresa.');
      setOpenSnackbar(true);
      setLoading(false);
      return undefined;
    }

    if (!service) {
      setSeverity('warning');
      setMessageSnack('Selecione um serviço.');
      setOpenSnackbar(true);
      setLoading(false);
      return undefined;
    }

    try {
      const { headers } = await createBenefits({
        association_id: association.id,
        service_id: service.id,
        code,
        description,
        quantity,
        coverage_km,
        period,
      });

      return String(headers.location).split('/').pop();
    } catch (err) {
      const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
      setSeverity('warning');
      setMessageSnack(applicationErrorMessage || apiError);
      setOpenSnackbar(true);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const changePlan = async ({ id }, { code, description, quantity, coverage_km, period }) => {
    setLoading(true);
    try {
      await changeBenefits(id, {
        association_id: association?.id,
        service_id: service?.id,
        code,
        description,
        quantity,
        coverage_km,
        period,
      });

      return true;
    } catch (err) {
      const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
      setSeverity('warning');
      setMessageSnack(applicationErrorMessage || apiError);
      setOpenSnackbar(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deletePlan = async ({ id }) => {
    try {
      setLoading(true);
      await deleteBenefits(id);

      return true;
    } catch (err) {
      const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
      setSeverity('warning');
      setMessageSnack(applicationErrorMessage || apiError);
      setOpenSnackbar(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title>Planos de cobertura</Title>

      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={6} sm={5} md={4} lg={3}>
            <FormControl style={{ width: '100%' }}>
              <CustomAutocomplete
                id='combo-box-associations'
                options={associations}
                getOptionLabel={item => item.code}
                renderInput={params => <TextField {...params} label='Selecione uma empresa' />}
                onChange={(_, value) => {
                  setAssociation(value);
                }}
                value={association ?? null}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={3}>
            <FormControl style={{ width: '100%' }}>
              <CustomAutocomplete
                id='combo-box-services'
                options={services}
                getOptionLabel={item => item.description}
                renderInput={params => <TextField {...params} label='Selecione o serviço' />}
                onChange={(_, value) => {
                  setService(value);
                }}
                value={service ?? null}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Form>

      <Table
        isLoading={loading}
        data={content}
        columns={{
          id: { title: 'ID', hidden: true },
          code: {
            title: 'Código',
          },
          description: {
            title: 'Descrição',
          },
          quantity: {
            title: 'Quantidade',
            type: 'numeric',
          },
          coverage_km: {
            title: 'km cobertura',
            type: 'numeric',
          },
          period: {
            title: 'Cota',
            lookup: periods,
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              association?.id && service?.id ? 'Sem planos cadastrados' : 'Selecione a Empresa e o Serviço',
            editRow: {
              deleteText: 'Deseja excluir o plano?',
            },
          },
        }}
        options={{
          addRowPosition: 'first',
          showTitle: false,
          pageSize: 10,
          paging: false,
          draggable: false,
        }}
        components={{
          EditField: props => {
            const {
              value,
              columnDef: { field },
            } = props;
            return field === 'period' ? (
              <MTableEditField {...props} error={errorsField[field]} />
            ) : (
              <InputComponent
                type='text'
                value={value}
                onChange={e => props.onChange(e.target.value)}
                error={errorsField[field]}
                autoFocus={errorsField[field]}
              />
            );
          },
        }}
        editable={{
          onRowAdd: async newData => {
            if (validateFields(newData)) {
              const idPlan = await saveNewPlan(newData);
              if (idPlan) {
                setContent(prevState => [...prevState, { ...newData, id: idPlan }]);
                return Promise.resolve();
              }
            }

            return Promise.reject();
          },
          onRowUpdate: async (newData, oldData) => {
            if (validateFields(newData)) {
              if (oldData) {
                const response = await changePlan(oldData, newData);
                if (response) {
                  setContent(prevState => {
                    const list = prevState.map(item => {
                      if (item === oldData) {
                        return newData;
                      }
                      return item;
                    });
                    return list;
                  });
                  return true;
                }
              }
            }
            return Promise.reject();
          },
          onRowDelete: async oldData => {
            const response = await deletePlan(oldData);
            if (response) {
              setContent(prevState => {
                const list = prevState.filter(item => item !== oldData);
                return list;
              });
              return true;
            }
            return Promise.reject();
          },
        }}
      />

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />
    </>
  );
}
