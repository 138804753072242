import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OrderBadgeType, setOrderBadgeValue } from '~/store/ducks/ordersBadge';

export const useOrdersBadge = (orderBadge: OrderBadgeType, quantity: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOrderBadgeValue(orderBadge, quantity));
  }, [dispatch, orderBadge, quantity]);
};
