export enum CustomerBenefitType {
  ON_REQUEST = '@customer/ON_REQUEST',
  ON_SUCESS = '@customer/ON_SUCESS',
  ON_RESET = '@customer/ON_RESET',
  ON_FAILURE = '@customer/ON_FAILURE',
  CLEAR_BENEFITS = '@customer/CLEAR_BENEFITS',
}

export const requestBenefit = (
  associationId: string,
  serviceId: string,
  socialSecurity: string,
  vehicleId: string,
  vin: string,
) => {
  return {
    type: CustomerBenefitType.ON_REQUEST,
    payload: { associationId, serviceId, socialSecurity, vehicleId, vin },
  };
};

export const resetBenefit = () => {
  return {
    type: CustomerBenefitType.ON_RESET,
  };
};

export const clearBenefit = () => {
  return {
    type: CustomerBenefitType.CLEAR_BENEFITS,
  };
};

export interface BenefitState {
  loading: boolean;
  associationId: string;
  serviceId: string;
  socialSecurity: string;
  vehicleId: string;
  vin: string;
  vehicleBenefits: [];
}

const INITIAL_STATE: BenefitState = {
  loading: false,
  associationId: '',
  serviceId: '',
  socialSecurity: '',
  vehicleId: '',
  vehicleBenefits: [],
  vin: '',
};

export default function customerBenefit(state = INITIAL_STATE, action: any = {}) {
  switch (action.type) {
    case CustomerBenefitType.ON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CustomerBenefitType.ON_SUCESS:
      return {
        ...state,
        vehicleBenefits: [...state.vehicleBenefits, action.payload.vehicleBenefits],
        associationId: action.payload.associationId,
        serviceId: action.payload.serviceId,
        socialSecurity: action.payload.socialSecurity,
        vehicleId: action.payload.vehicleId,
        loading: false,
      };
    case CustomerBenefitType.ON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CustomerBenefitType.ON_RESET:
      return INITIAL_STATE;
    case CustomerBenefitType.CLEAR_BENEFITS:
      return {
        ...state,
        vehicleBenefits: [],
      };
    default:
      return state;
  }
}
