import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';

import { Container, Header } from './styles';
import Timeline from './components/Timeline';
import { Title } from '~/components/Title';

import useOrderHistories from '~/services/hooks/useOrderHistories';
import Loading from '~/components/Loading';

const OpportunityTimeline = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const history = useHistory();

  const { loading, histories } = useOrderHistories(orderId);

  return (
    <Container>
      <Header>
        <Button style={{ marginRight: 20 }}>
          <ArrowBack fontSize='large' onClick={() => history.goBack()} />
        </Button>

        <Title>Timeline</Title>
      </Header>

      {loading ? <Loading /> : <Timeline histories={histories} />}
    </Container>
  );
};

export default OpportunityTimeline;
