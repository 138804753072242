import React from 'react';
import styled, { css } from 'styled-components';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

interface CustomAutocompleteProps {
  width?: number | string;
}

function CustomFunctionAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & CustomAutocompleteProps) {
  return <Autocomplete {...props} />;
}

export const CustomAutocomplete = styled(CustomFunctionAutocomplete).attrs({
  clearText: 'Limpar',
  noOptionsText: 'Nenhum valor encontrado',
  openText: 'Abrir',
  closeText: 'Fechar',
})<CustomAutocompleteProps>`
  ${({ width }) => {
    if (!width) {
      return '';
    }

    if (typeof width !== 'number') {
      return css`
        width: ${width};
      `;
    }

    return css`
      width: ${width}px;
    `;
  }}
` as typeof CustomFunctionAutocomplete;
