import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 15px;
`;

export const DivAlert = styled.div`
  display: flex;
  justify-content: center;
`;
