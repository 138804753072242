import React from 'react';
import Viewer from 'react-viewer';
import ViewerProps from 'react-viewer/lib/ViewerProps';
import { getImageFileApi } from '~/services/api';

export interface ImageViewerProps extends Omit<ViewerProps, 'images' | 'visible'> {
  imageUrl?: string;
}

export function ImageViewer(props: ImageViewerProps) {
  const download = (e?: string) => {
    if (!e) {
      return;
    }

    getImageFileApi(e)
      .then(response => {
        const extension = e.match(/\.\w{3,4}($|\?)/);
        if (extension) {
          const onlyExtension = extension[0].replace('?', '');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `image.${onlyExtension}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        fetch(e /* .replace('http', 'https') */, {
          method: 'GET',
        })
          .then(response => {
            response.arrayBuffer().then(function (buffer) {
              const extension = e.match(/\.\w{3,4}($|\?)/);
              if (extension) {
                const onlyExtension = extension[0].replace('?', '');
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `image.${onlyExtension}`); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            });
          })
          .catch(err => {
            console.log(err);
          });
      });
  };

  return (
    <Viewer
      visible={!!props.imageUrl}
      images={[{ src: props.imageUrl ?? '', downloadUrl: props.imageUrl }]}
      onMaskClick={props.onClose}
      noNavbar
      customToolbar={toolbar => {
        const downloadButton = document.querySelector('div.react-viewer-footer > div > ul > li:nth-child(8)');

        if (downloadButton && !!props.imageUrl) {
          const newDownloadButton = downloadButton.cloneNode(true);

          newDownloadButton.addEventListener('click', e => {
            e.preventDefault();
            download(props.imageUrl);
          });
          downloadButton?.replaceWith(newDownloadButton);
        }

        return toolbar.filter(t => !['prev', 'next'].includes(t.key));
      }}
      zIndex={10000}
      showTotal={false}
      downloadable
      downloadInNewWindow
      {...props}
    />
  );
}

export default ImageViewer;
