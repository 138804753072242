import styled from 'styled-components';

export const Form = styled.form`
  display: grid;
`;

export const ConfigList = styled.div`
  display: flex;
  flex-direction: column;

  .rst__node {
    display: flex;

    .rst__lineBlock {
      flex: 0 0 auto;
    }

    .rst__nodeContent {
      left: auto !important;
      top: auto !important;
      position: relative !important;
      flex: 0 1 auto;
      width: 100%;
    }
  }

  .rst__rowTitle {
    font-weight: normal;
  }

  .rst__rowLabel {
    flex: 1;
  }

  .MuiInputBase-root {
    width: 100%;
  }
  .MuiButton-sizeSmall {
    height: 25px;
  }

  .MuiTypography-root {
    font-size: 10px;
  }
`;
