import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import { Panel, PanelTitle } from '../styles';

interface InfoPanelProps {
  title: string;
  style?: CSSProperties;
}
const InfoPanel: React.FC<InfoPanelProps> = ({ title, children, style }) => (
  <Panel style={style}>
    <PanelTitle>{title}</PanelTitle>
    {children}
  </Panel>
);

export default InfoPanel