import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControl, Grid, Select, Tooltip, Typography, Button as ButtonMUI } from '@material-ui/core';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import firebase from '~/config/firebase';

import { formatCellPhone, removeFormat } from '~/util/format';

import { useNewOrder } from '~/services/hooks/useNewOrder';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';

import Colors from '~/styles/colors';

import Button from '~/components/Button';
import Title from '../../components/Title';
import { ModalConfirm } from '~/components/ModalConfirm';
import Modal from '~/components/ModalNewVehicle';
import LocationSearchInput from '../../components/LocationSearchInput';
import InputComponent from '~/components/InputComponent';
import HighlightedButton from '../../components/HighlightedButton';
import ChangeAddressButton from '../../components/ChangeAddressButton';
import { Pin, Container } from './styles';

import {
  AddressComponent,
  Coords,
  CoordsOrder,
  ErrorResponse,
  MapCalculatorCostParameters,
  MapCalculatorRequest,
  MapCalculatorResponse,
  OrderRequest,
  OrderResponse,
  PaymentStatus,
} from '~/types';
import BackButton from '../../components/BackButton';
import ChoiceModal, { Choice } from '~/components/ChoiceModal/index';
import { EmojiPeople, HistoryOutlined, TrackChanges } from '@material-ui/icons';
import { needClear } from '~/store/ducks/order';
import InformationOrder from '../../components/InformationOrder';
import { getPeriod, ModalOpportunities } from '../OpportunityAdditionalInformation/components/ModalOpportunities';
import { Divider } from '../../styles';
import { GridContainer } from './components/GridContainer';
import Loading from '~/components/Loading';

enum CalcStatus {
  'NEW',
  'CALCULATING',
  'CALCULATED',
  'ERROR',
  'SEARCH',
}

enum OrderStatus {
  'PAYMENT_PENDING',
  'PAYMENT_APPROVED',
  'PAYMENT_REFUSED',
  'ALLOCATING',
  'ON_GOING',
}

interface LatLng {
  latitude: number;
  longitude: number;
}

interface MapLinkResponse {
  solution?: {
    id: string;
    clientId: string;
    legs: Array<{
      points: Array<{
        latitude: number;
        longitude: number;
      }>;
    }>;
    totalDistance?: number;
    totalSpeedProfilePenalty?: number;
  };
}

interface Points {
  lat: number;
  lng: number;
}

type ValueType = {
  [key: string]: {
    value: number;
  };
};

const db = firebase.firestore();

const OpportunityMap = () => {
  const [modalCancel, setModalCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState<Coords>();
  const [destination, setDestination] = useState<Coords>();
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState<JSX.Element>();
  const [calcStatus, setCalcStatus] = useState<CalcStatus>();
  const [forcePickTow, setForcePickTow] = useState(false);
  const [mapLinkID, setMapLinkID] = useState<string>();
  const [currentLatLng, setCurrentLatLng] = useState<LatLng>();
  const [map, setMap] = useState<any>();
  const [points, setPoints] = useState<Points[]>();
  const [dataCalculator, setDataCalculator] = useState<MapCalculatorResponse>();
  const [valueOverDistance, setValueOverDistance] = useState<MapCalculatorCostParameters>();
  const [timeZone, setTimeZone] = useState<string>();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [mapLinkData, setMapLinkData] = useState<MapLinkResponse>();
  const [paymentType, setPaymentType] = useState<string>();
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>();
  const [paymentSelected, setPaymentSelected] = useState<string>();
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<string>();
  const [confirmationType, setConfirmationType] = useState<string>();
  const [openModalPhoneNumber, setOpenModalPhoneNumber] = useState(false);
  const [addressOrigin, setAddressOrigin] = useState<AddressComponent>();
  const [addressDestination, setAddressDestination] = useState<AddressComponent>();
  const [paymentError, setPaymentError] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [positionCenter, setPositionCenter] = useState<{ lat: number; lng: number }>();
  const [zoomPosition, setZoomPosition] = useState(16);
  const [pixCode, setPixCode] = useState('');
  const [secureUrl, setSecureUrl] = useState('');
  const [openModalEnableAutoAllocation, setOpenModalEnableAutoAllocation] = useState(false);
  const [enableAutoAllocation, setEnableAutoAllocation] = useState(false);
  const [autoAllocationSetup, setAutoAllocationSetup] = useState(false);
  const [modalOpportunitiesOpen, setModalOpportunitiesOpen] = useState(false);

  const refPhone = useRef(null);
  const refAllocation = useRef(false);
  const refContactPhoneNumber = useRef<string | undefined>(undefined);

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    validateRegionsService,
    getTimeZoneByGoogle,
    maplinkTrip,
    mapCalculator,
    payments,
    createOrder,
  } = useNewOrder();
  const { addToast } = useToast();

  const {
    service,
    vehicle,
    customerId,
    separateService,
    uuid,
    quizAnswers,
    account_statement: associationBalance,
    customerName,
    benefit,
    customerPhone,
  } = useSelector(state => ({
    ...state.order,
    ...state.associationBalance,
  }));

  const period = getPeriod(benefit.period);

  const flightPath = useMemo(() => {
    const google = window.google;

    return new google.maps.Polyline({
      strokeColor: Colors.strokeColor,
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const {
        coords: { latitude, longitude },
      } = position;
      setCurrentLatLng({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    if (!points) {
      flightPath.setMap(null);
      return;
    }

    const flightPathCoordinates: google.maps.LatLngLiteral[] = points;

    flightPath.setPath(flightPathCoordinates);

    flightPath.setMap(map);
  }, [flightPath, map, points]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  const calculator = useCallback(
    async (points, mapLinkData?: MapLinkResponse) => {
      setCalcStatus(CalcStatus.CALCULATING);
      try {
        const timeZoneId = origin && (await getTimeZoneByGoogle(origin));

        let data: MapCalculatorRequest = {
          time_zone_id: timeZoneId || 'America/Sao_Paulo',
          displace: service?.displace,
          service_id: service?.id,
          association_id: vehicle?.association_id,
          vehicle_id: vehicle?.id,
          social_security: customerId,
          has_picktow: forcePickTow || separateService,
          points,
          source: addressOrigin?.city,
        };

        if (service?.displace) {
          data = {
            ...data,
            total_distance: mapLinkData?.solution?.totalDistance,
            total_speed_profile_penalty: mapLinkData?.solution?.totalSpeedProfilePenalty,
            destination: addressDestination?.city,
          };
        }

        const responseCalculator = await mapCalculator(data);

        setDataCalculator(responseCalculator.data);

        if (responseCalculator.data.charge_additional_fee) {
          setMessage(<Typography>Um valor excedente será cobrado!</Typography>);
          setOpenModal(true);
        }

        if (service?.displace) {
          const parameters = responseCalculator.data.cost_parameters;
          const filterCostParameters = parameters.filter(f => f.type === 'OVER_DISTANCE' && f.payer === 'CUSTOMER');
          setValueOverDistance(filterCostParameters[0]);
        }

        setTimeZone(timeZoneId);
        setCalcStatus(CalcStatus.CALCULATED);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao realizar o calculo');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      addressDestination,
      addressOrigin,
      customerId,
      forcePickTow,
      getTimeZoneByGoogle,
      mapCalculator,
      origin,
      separateService,
      service,
      vehicle,
    ],
  );

  const confirmLocations = useCallback(async () => {
    if (!origin || !origin.latitude || !origin.longitude) {
      addToast({ title: 'Validação', type: 'error', description: 'Origem é obrigatória!' });
      return;
    }

    if (service?.displace && (!destination || !destination.latitude || !destination.longitude)) {
      addToast({ title: 'Validação', type: 'error', description: 'Destino é obrigatório!' });
      return;
    }

    setLoading(true);
    const regionValid = origin && (await validateRegionsService(origin));

    if (!regionValid) {
      setMessage(
        <>
          <Typography>Não podemos atender sua solicitação</Typography>
          <Typography>Fora da área de atuação da Picktow.</Typography>
        </>,
      );
      setLoading(false);
      setOpenModal(true);
      return;
    }

    if (!service?.displace) {
      calculator([origin]);
      return;
    }

    try {
      setLoading(true);

      const response = await maplinkTrip({
        problem: {
          calculationMode: 'THE_FASTEST',
          points: [
            {
              ...origin,
              siteId: 'source',
            },
            {
              ...destination,
              siteId: 'destination',
            },
          ],
          useRealSpeeds: true,
          startDate: 0,
        },
        identity: uuid,
      });

      const location = response.headers?.location;

      if (location) {
        const linkId = location.split('/').pop();
        setMapLinkID(linkId);
      }

      setCalcStatus(CalcStatus.NEW);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao realizar a rota!');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });

      setLoading(false);
    }
  }, [addToast, calculator, destination, maplinkTrip, origin, service, uuid, validateRegionsService]);

  useEffect(() => {
    db.collection('users')
      .doc(uuid)
      .onSnapshot(snapshot => {
        if (!snapshot) {
          return;
        }

        const data = snapshot.data() as MapLinkResponse | undefined;
        setMapLinkData(data);

        if (
          service?.displace &&
          loading &&
          !!mapLinkID &&
          mapLinkID === data?.solution?.id &&
          calcStatus === CalcStatus.NEW
        ) {
          setPoints(data?.solution?.legs[0]?.points.map(item => ({ lat: item.latitude, lng: item.longitude })));
          calculator(data?.solution?.legs[0]?.points, data);
        }
      });
  }, [calcStatus, calculator, loading, mapLinkID, service, uuid]);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    db.collection('orders')
      .doc(orderId)
      .onSnapshot(snapshot => {
        if (!snapshot) {
          return;
        }

        const orderDocument = snapshot.data() as OrderResponse | undefined;

        if (orderDocument) {
          if (orderDocument.status === 'PAYMENT_REFUSED' && !(paymentMethodSelected === 'PIX')) {
            setPaymentError('Sua solicitação não pode ser atendida pela empresa, deseja solicitar particular?');
            db.collection('orders').doc(orderId).delete();
          } else if (orderDocument.status === 'PAYMENT_APPROVED' || orderDocument.status === 'ALLOCATING') {
            db.collection('users')
              .doc(uuid)
              .delete()
              .then(() => {
                dispatch(needClear());
                history.push('/opportunity-manager#ALLOCATING');
              })
              .catch(() => {
                addToast({
                  title: 'Erro Inesperado',
                  type: 'error',
                  description: 'Erro ao excluir oportunidade do Firebase!',
                });
              });
          }
        }
      });
  }, [addToast, dispatch, history, orderId, paymentMethodSelected, uuid]);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    // get Pix Code
    db.collection('payments')
      .doc(orderId)
      .onSnapshot(snapshot => {
        if (!snapshot) {
          return;
        }

        const orderPayment = snapshot?.data() as PaymentStatus | undefined;
        if (orderPayment?.gatewayDetails?.pixText) {
          setPixCode(orderPayment.gatewayDetails.pixText);
        }

        if (orderPayment?.gatewayDetails?.secureUrl) {
          setSecureUrl(orderPayment.gatewayDetails.secureUrl);
        }
      });
  }, [addToast, history, orderId, uuid]);

  const handleOrigin = useCallback((latLng: google.maps.LatLngLiteral | undefined) => {
    if (!latLng) {
      return;
    }

    setOrigin({ latitude: latLng.lat, longitude: latLng.lng });
  }, []);

  const handleDestination = useCallback((latLng: google.maps.LatLngLiteral | undefined) => {
    if (!latLng) {
      return;
    }

    setDestination({ latitude: latLng.lat, longitude: latLng.lng });
  }, []);

  const handleOnChange = useCallback(() => {
    setPoints(undefined);
  }, []);

  const statusMessage = useCallback(() => {
    switch (orderStatus) {
      case OrderStatus.PAYMENT_PENDING:
        return 'Aguardando pagamento...';
      case OrderStatus.PAYMENT_APPROVED:
        return 'Pagamento aprovado...';
      case OrderStatus.PAYMENT_REFUSED:
        return 'Pagamento recusado...';
      case OrderStatus.ALLOCATING:
        return 'Procurando prestador de serviço...';
      case OrderStatus.ON_GOING:
        return 'Prestador de serviço indo até você...';
      default:
        return '';
    }
  }, [orderStatus]);

  const showPixCode = useCallback(() => {
    return paymentMethodSelected === 'PIX' && pixCode === ''
      ? 'Gerando código Pix, aguarde'
      : paymentMethodSelected === 'PIX' && (
          <>
            <Typography>Código Pix: {pixCode}</Typography>
            <CopyToClipboard
              text={pixCode}
              onCopy={() =>
                addToast({
                  title: 'Sucesso!',
                  type: 'success',
                  description: 'O código Pix foi copiado com sucesso!',
                })
              }
            >
              <Button
                type='button'
                label='Copiar código'
                loading={loading}
                filled
                style={{ width: 200, marginRight: 30 }}
              />
            </CopyToClipboard>
          </>
        );
  }, [addToast, loading, paymentMethodSelected, pixCode]);

  const showSecureCode = useCallback(() => {
    return (
      paymentMethodSelected === 'PIX' &&
      secureUrl !== '' && (
        <>
          <Divider height={30} />
          <Typography>Fatura: {secureUrl}</Typography>
          <CopyToClipboard
            text={secureUrl}
            onCopy={() =>
              addToast({
                title: 'Sucesso!',
                type: 'success',
                description: 'A fatura foi copiado com sucesso!',
              })
            }
          >
            <Button
              type='button'
              label='Copiar link'
              loading={loading}
              filled
              style={{ width: 200, marginRight: 30 }}
            />
          </CopyToClipboard>
        </>
      )
    );
  }, [addToast, loading, paymentMethodSelected, secureUrl]);

  const paymentsType = useMemo((): [string, string][] | undefined => {
    if (!payments) {
      return undefined;
    }

    return Object.entries(
      payments
        .filter(payment => payment.payment_method !== 'VOUCHER')
        .reduce((acc: { [key: string]: string }, payment) => {
          let type: string;
          switch (payment.type) {
            case 'ONLINE':
              type = 'Pagamento pelo app';
              break;
            case 'OFFLINE':
              type = 'Pagamento no local';
              break;
            default:
              type = payment.type;
              break;
          }
          acc[payment.type] = type;
          return acc;
        }, {}),
    );
  }, [payments]);

  const handleModalPayment = useCallback((type: string) => {
    setPaymentType(type);

    setOpenModalPayment(true);
  }, []);

  const associationTotalValue: ValueType = useMemo(
    () =>
      dataCalculator?.cost_parameters
        .filter(
          item => !['CANCELLATION_FEE', 'COMMISSION_PERCENTAGE'].includes(item.type) && item.payer === 'ASSOCIATION',
        )
        .reduce((acc, item) => {
          const valueSum = item.value * item.multiplier * item.amount;

          if (acc[item.payer]) {
            acc[item.payer]['value'] = acc[item.payer]['value'] + valueSum;
          } else {
            acc[item.payer] = { value: valueSum };
          }
          return acc;
        }, {} as ValueType) || ({} as ValueType),
    [dataCalculator],
  );

  const confirmSolicitation = useCallback(
    async (type: string) => {
      const newContactPhoneNumber = contactPhoneNumber || refContactPhoneNumber.current;
      if (!newContactPhoneNumber || newContactPhoneNumber.length < 13) {
        setConfirmationType(type);
        setOpenModalPhoneNumber(true);
        return;
      }

      if (
        (!enableAutoAllocation && confirmationType === undefined) ||
        (!autoAllocationSetup && !openModalEnableAutoAllocation)
      ) {
        setOpenModalEnableAutoAllocation(true);
        return;
      }

      if (!origin || !origin.latitude || !origin.longitude) {
        addToast({ title: 'Validação', type: 'error', description: 'Origem é obrigatória!' });
        return;
      }

      if (service?.displace && (!destination || !destination.latitude || !destination.longitude)) {
        addToast({ title: 'Validação', type: 'error', description: 'Destino é obrigatório!' });
        return;
      }

      if ((associationTotalValue['ASSOCIATION']?.value || 0) > (associationBalance?.available_balance || 0)) {
        setPaymentError('Saldo insuficiente na empresa. deseja solicitar particular?');
        return;
      }

      setLoading(true);

      let coordinates: CoordsOrder[] = [
        {
          position: 'SOURCE',
          ordination: 0,
          latitude: origin.latitude,
          longitude: origin.longitude,
          address: addressOrigin?.fullAddress,
          neighborhood: addressOrigin?.neighborhood,
          city: addressOrigin?.city,
        },
      ];

      if (service?.displace) {
        coordinates = [
          ...coordinates,
          {
            position: 'DESTINATION',
            ordination: 1,
            latitude: destination?.latitude,
            longitude: destination?.longitude,
            address: addressDestination?.fullAddress,
            neighborhood: addressDestination?.neighborhood,
            city: addressDestination?.city,
          },
        ];
      }

      try {
        const quizWithPhone = [
          ...(quizAnswers.length > 0 ? quizAnswers : []),
          {
            question: 'Telefone de contato',
            answer: newContactPhoneNumber,
            block: false,
          },
          {
            question: 'Alocação manual',
            answer: enableAutoAllocation,
            block: false,
          },
        ];

        const data: OrderRequest = {
          from_backoffice: true,
          payment: {
            token: '3fa85f64-5717-4562-b3fc-2c963f66afa6', // id do payment (mockar)
            type,
          },
          association_name: vehicle?.association_trading_name,
          detached: separateService,
          customer_id: customerId,
          association_id: vehicle?.association_id,
          time_zone: timeZone,
          service_type: service?.description,
          cost: dataCalculator,
          vehicle: {
            type: vehicle?.vehicle_customer_type_description,
            model: vehicle?.model,
            model_year: vehicle?.model_year,
            color: vehicle?.color,
            manufacture: vehicle?.manufacture,
            status: vehicle?.status,
            vin: vehicle?.vin,
            license_plate: vehicle?.license_plate,
            state: vehicle?.state,
            city: vehicle?.city,
          },
          coordinates,
          association_code: vehicle?.association_code,
          service_id: service?.id,
          quiz_answers: JSON.stringify(quizWithPhone),
          disable_auto_allocation: refAllocation.current,
        };

        const response = await createOrder(data);
        const location = response.headers?.location;
        let locationOrderId: string;

        if (location) {
          locationOrderId = location.split('/').pop();
          setOrderId(locationOrderId);
          setOrderStatus(OrderStatus.PAYMENT_PENDING);
          addToast({ title: 'Sucesso', type: 'success', description: 'Oportunidade criada com sucesso!' });

          db.collection('orders')
            .doc(locationOrderId)
            .set(
              {
                vin: vehicle?.vin,
                license_plate: vehicle?.license_plate,
                service_id: service?.id,
                service_type: service?.description,
                vehicle_type: vehicle?.vehicle_customer_type_description,
                association_code: vehicle?.association_code,
                service_displace: service?.displace,
                latitude: origin.latitude,
                longitude: origin.longitude,
                association_id: vehicle?.association_id,
                vehicle_id: vehicle?.id,
                separate_service: separateService,
                cost: {
                  surcharge: dataCalculator?.surcharge,
                },
              },
              { merge: true },
            );
        }

        setCalcStatus(CalcStatus.SEARCH);
      } catch (error) {
        const errorResponse = error as ErrorResponse;

        if (errorResponse.response?.data?.messages[0] === 'message.payment.association.balance.insufficient') {
          setForcePickTow(true);
          confirmLocations();
          return;
        }

        const errorMessages = handleApiErrorResponse(errorResponse, 'Erro ao criar oportunidade!');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
        setContactPhoneNumber(undefined);
        setAutoAllocationSetup(false);
      }
    },
    [
      addToast,
      addressDestination,
      addressOrigin,
      associationBalance,
      associationTotalValue,
      autoAllocationSetup,
      confirmLocations,
      confirmationType,
      contactPhoneNumber,
      createOrder,
      customerId,
      dataCalculator,
      destination,
      enableAutoAllocation,
      openModalEnableAutoAllocation,
      origin,
      quizAnswers,
      separateService,
      service,
      timeZone,
      vehicle,
    ],
  );

  const handleSubmit = useCallback(() => {
    setOpenModalPayment(false);

    const payment = payments?.find(paymentItem => paymentItem.id === paymentSelected);

    if (!payment) {
      addToast({ title: 'Validação', type: 'error', description: 'Pagamento obrigatório!' });
      return;
    }

    confirmSolicitation(payment.payment_method);
  }, [addToast, confirmSolicitation, paymentSelected, payments]);

  const cancel = useCallback(() => {
    setCalcStatus(undefined);
    setMapLinkID(undefined);
    setOrigin(undefined);
    setDestination(undefined);
    setPoints(undefined);
  }, []);

  const handlePhoneNumber = useCallback(() => {
    const newContactPhoneNumber = contactPhoneNumber || customerPhone;
    if (!newContactPhoneNumber) {
      addToast({ title: 'Validação', type: 'error', description: 'Telefone para contato é obrigatório' });
      return;
    }

    if ((newContactPhoneNumber?.length || 0) < 13) {
      addToast({ title: 'Validação', type: 'error', description: 'Telefone inválido. (XX)XXXX-XXXX' });
      return;
    }

    setOpenModalPhoneNumber(false);
    refContactPhoneNumber.current = newContactPhoneNumber;

    if (!confirmationType) {
      addToast({ title: 'Erro Inesperado', type: 'error', description: 'Tipo de confirmação inválido!' });
      return;
    }

    confirmSolicitation(confirmationType);
  }, [addToast, confirmSolicitation, confirmationType, contactPhoneNumber, customerPhone]);

  const handleChooseAllocationType = useCallback(
    (mode: 'MANUAL' | 'AUTOMATIC') => {
      setOpenModalEnableAutoAllocation(false);
      setAutoAllocationSetup(true);

      setEnableAutoAllocation(mode !== 'AUTOMATIC');
      refAllocation.current = mode !== 'AUTOMATIC';

      if (!confirmationType) {
        addToast({ title: 'Erro Inesperado', type: 'error', description: 'Tipo de confirmação inválido!' });
        return;
      }
      confirmSolicitation(confirmationType);
    },
    [addToast, confirmSolicitation, confirmationType],
  );

  useEffect(() => {
    if (!payments) {
      return;
    }

    const paymentMethod = payments.filter(payment => payment.id === paymentSelected);

    setPaymentMethodSelected(paymentMethod[0]?.payment_method);
  }, [paymentSelected, payments]);

  const itemsPicker = useMemo(() => {
    if (!payments) {
      return;
    }

    const paymentsFiltered = payments.filter(
      payment => payment.type === paymentType && payment.payment_method !== 'VOUCHER',
    );

    if (paymentsFiltered.length > 0 && !paymentSelected) {
      setPaymentSelected(paymentsFiltered[0].id);
    }

    return (
      <FormControl style={{ width: '100%' }}>
        <Select
          native
          value={paymentSelected}
          onChange={e => {
            setPaymentSelected(String(e.target.value));
          }}
        >
          <option hidden value=''>
            {' '}
          </option>
          {paymentsFiltered.map(payment => (
            <option key={payment.id} value={payment.id}>
              {payment.description}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }, [paymentSelected, paymentType, payments]);

  const payersValue: ValueType = useMemo(
    () =>
      dataCalculator?.cost_parameters
        .filter(item => !['CANCELLATION_FEE', 'COMMISSION_PERCENTAGE'].includes(item.type))
        .reduce((acc, item) => {
          const valueSum = item.value * item.multiplier * item.amount;

          if (acc[item.payer]) {
            acc[item.payer]['value'] = acc[item.payer]['value'] + valueSum;
          } else {
            acc[item.payer] = { value: valueSum };
          }
          return acc;
        }, {} as ValueType) || ({} as ValueType),
    [dataCalculator],
  );

  const showValues = useMemo(() => {
    if (!payersValue['ASSOCIATION'] && !payersValue['CUSTOMER']) return null;

    return (
      <Grid item xs={4}>
        <Container>
          <Typography style={{ textDecoration: 'underline' }}>Valor a ser pago</Typography>

          {payersValue['ASSOCIATION'] && (
            <Typography>
              {vehicle?.association_code} - {`R$ ${payersValue['ASSOCIATION'].value.toFixed(2).replace('.', ',')}`}
            </Typography>
          )}

          {payersValue['CUSTOMER'] && (
            <Typography>
              {customerName} - {`R$ ${payersValue['CUSTOMER'].value.toFixed(2).replace('.', ',')}`}
            </Typography>
          )}
        </Container>
      </Grid>
    );
  }, [customerName, payersValue, vehicle]);

  useEffect(() => {
    if (!origin || !destination) {
      return;
    }

    const latitudes = [origin.latitude, destination.latitude];
    const longitudes = [origin.longitude, destination.longitude];

    const bounds = {
      ne: {
        lat: Math.min(...latitudes),
        lng: Math.max(...longitudes),
      },
      sw: {
        lat: Math.max(...latitudes),
        lng: Math.min(...longitudes),
      },
    };

    const size = {
      width: 640, // Map width in pixels
      height: 380, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size);

    setPositionCenter(center);
    setZoomPosition(zoom);
  }, [destination, origin]);

  const reasonsMessages = () => {
    const messages: string[] = [];

    if (dataCalculator?.benefits_quota_exceeded) {
      messages.push(
        'A cota foi consumida do pacote de benefício. Estamos a disposição caso queira solicitar particular.',
      );
    }

    if (dataCalculator?.has_benefits && dataCalculator?.outside_limit_exceeded) {
      messages.push('O beneficio permite que o destino seja cidade domicilio ou da cidade mais próxima da origem.');
    }

    if ((associationTotalValue['ASSOCIATION']?.value || 0) > (associationBalance?.available_balance || 0)) {
      messages.push('Saldo insuficiente na empresa.');
    }

    if (messages.length === 0) return null;

    return (
      <Grid item xs={4}>
        <Container>
          <Typography style={{ textDecoration: 'underline' }}>
            {messages.length > 0 ? (messages.length === 1 ? 'Mensagem' : 'Mensagens') : ''}
          </Typography>
          {messages.map((message, index) => (
            <Typography key={`${index}`}>{message}</Typography>
          ))}
        </Container>
      </Grid>
    );
  };

  // useEffect(() => {
  //   setContactPhoneNumber(customerPhone);
  // }, [customerPhone]);

  return (
    <>
      <Title>Endereço</Title>

      <InformationOrder />

      {(!calcStatus || calcStatus === CalcStatus.ERROR) && (
        <>
          <LocationSearchInput
            label='De onde?'
            setLatLng={handleOrigin}
            fnSetAddress={setAddressOrigin}
            fnOnChange={handleOnChange}
          />

          {service?.displace && (
            <LocationSearchInput
              label='Para onde?'
              setLatLng={handleDestination}
              fnSetAddress={setAddressDestination}
              fnOnChange={handleOnChange}
            />
          )}
        </>
      )}

      {currentLatLng?.latitude && currentLatLng?.longitude && (
        <div style={{ height: '50vh', width: '100%' }} id='map'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? '' }}
            defaultCenter={{
              lat: currentLatLng.latitude,
              lng: currentLatLng.longitude,
            }}
            defaultZoom={16}
            center={positionCenter}
            zoom={zoomPosition}
            onGoogleApiLoaded={({ map }) => {
              setMap(map);
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            {origin && <Pin lat={origin.latitude} lng={origin.longitude} />}
            {destination && <Pin lat={destination.latitude} lng={destination.longitude} />}
          </GoogleMapReact>
        </div>
      )}

      {(!calcStatus || calcStatus === CalcStatus.ERROR) && (
        <Grid container spacing={1}>
          <Grid container item xs={3} md={12} spacing={3}>
            <Grid item xs={3} md={10}>
              <BackButton loading={loading} onClick={handleGoBack} />

              <Button
                type='button'
                label='Cancelar'
                loading={loading}
                filled
                style={{ width: 200, marginRight: 30 }}
                onClick={handleCancel}
              />

              <HighlightedButton label='Confirmar Locais' loading={loading} onClick={confirmLocations} />
            </Grid>

            <Grid item container md={2} direction='column' alignItems='flex-end' justify='center'>
              <Tooltip title='Histórico de solicitações' arrow>
                <ButtonMUI
                  aria-label='Histórico de solicitações'
                  onClick={() => {
                    setModalOpportunitiesOpen(true);
                  }}
                >
                  <HistoryOutlined style={{ fontSize: 40 }} />
                </ButtonMUI>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}

      {(calcStatus === CalcStatus.NEW ||
        calcStatus === CalcStatus.CALCULATING ||
        calcStatus === CalcStatus.CALCULATED ||
        calcStatus === CalcStatus.SEARCH) && (
        <div>
          {calcStatus === CalcStatus.SEARCH ? (
            <div>
              <Loading />
              <Typography>{statusMessage()}</Typography>
              <Typography>{showPixCode()}</Typography>
              <Typography>{showSecureCode()}</Typography>
            </div>
          ) : (
            calcStatus !== CalcStatus.NEW &&
            calcStatus !== CalcStatus.CALCULATING && (
              <>
                {dataCalculator?.has_benefits &&
                !dataCalculator?.benefits_quota_exceeded &&
                !dataCalculator.outside_limit_exceeded ? (
                  <>
                    <Typography>
                      {service?.displace
                        ? `Sua rota é de ${((mapLinkData?.solution?.totalDistance ?? 0) / 1000).toFixed(0)} km${
                            dataCalculator?.surcharge
                              ? `, porém excedeu em ${
                                  valueOverDistance?.amount?.toFixed(0) ?? 0
                                } km o benefício contratado`
                              : ''
                          }`
                        : 'Solicitação aprovada'}
                    </Typography>

                    {dataCalculator?.surcharge ? (
                      <>
                        <GridContainer>
                          {showValues}

                          {reasonsMessages()}
                        </GridContainer>

                        <Grid container spacing={1}>
                          <Grid container item xs={3} md={12} spacing={3}>
                            <Grid item xs={3} md={10}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <ChangeAddressButton loading={loading} onClick={cancel} />

                                {paymentsType?.map((payment, index) => (
                                  <React.Fragment key={index}>
                                    <HighlightedButton
                                      loading={loading}
                                      label={payment[1]}
                                      onClick={() => handleModalPayment(payment[0])}
                                    />
                                    <Divider width={30} />
                                  </React.Fragment>
                                ))}
                              </div>
                            </Grid>

                            <Grid item container md={2} direction='column' alignItems='flex-end' justify='center'>
                              <Tooltip title='Histórico de solicitações' arrow>
                                <ButtonMUI
                                  aria-label='Histórico de solicitações'
                                  onClick={() => {
                                    setModalOpportunitiesOpen(true);
                                  }}
                                >
                                  <HistoryOutlined style={{ fontSize: 40 }} />
                                </ButtonMUI>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <GridContainer>
                          {showValues}

                          {reasonsMessages()}
                        </GridContainer>

                        <Grid container spacing={1}>
                          <Grid container item xs={3} md={12} spacing={3}>
                            <Grid item xs={3} md={10}>
                              <ChangeAddressButton loading={loading} onClick={cancel} />

                              <HighlightedButton
                                loading={loading}
                                label='Solicitar Serviço'
                                onClick={() => confirmSolicitation('VOUCHER')}
                              />
                            </Grid>

                            <Grid item container md={2} direction='column' alignItems='flex-end' justify='center'>
                              <Tooltip title='Histórico de solicitações' arrow>
                                <ButtonMUI
                                  aria-label='Histórico de solicitações'
                                  onClick={() => {
                                    setModalOpportunitiesOpen(true);
                                  }}
                                >
                                  <HistoryOutlined style={{ fontSize: 40 }} />
                                </ButtonMUI>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography>
                      {service?.displace
                        ? `Sua rota é de ${((mapLinkData?.solution?.totalDistance ?? 0) / 1000).toFixed(0)} km`
                        : 'Solicitação aprovada, abaixo o valor para ser executado'}
                    </Typography>

                    <GridContainer>
                      {/* Encontramos sua rota, abaixo o valor para executar o serviço */}
                      {showValues}

                      {reasonsMessages()}
                    </GridContainer>

                    <Grid container spacing={1}>
                      <Grid container item xs={3} md={12} spacing={3}>
                        <Grid item xs={3} md={10}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ChangeAddressButton loading={loading} onClick={cancel} />

                            {paymentsType?.map((payment, index) => (
                              <React.Fragment key={index}>
                                <HighlightedButton
                                  key={index}
                                  loading={loading}
                                  label={payment[1]}
                                  onClick={() => handleModalPayment(payment[0])}
                                />
                                <Divider width={30} />
                              </React.Fragment>
                            ))}
                          </div>
                        </Grid>

                        <Grid item container md={2} direction='column' alignItems='flex-end' justify='center'>
                          <Tooltip title='Histórico de solicitações' arrow>
                            <ButtonMUI
                              aria-label='Histórico de solicitações'
                              onClick={() => {
                                setModalOpportunitiesOpen(true);
                              }}
                            >
                              <HistoryOutlined style={{ fontSize: 40 }} />
                            </ButtonMUI>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )
          )}
        </div>
      )}

      <ModalConfirm
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
        title='Tem certeza de que deseja cancelar essa oportunidade?'
      />

      <Modal onConfirm={() => setOpenModal(false)} open={openModal}>
        {message}
      </Modal>

      <Modal
        question
        onConfirm={handleSubmit}
        onCancel={() => {
          setOpenModalPayment(false);
          setAutoAllocationSetup(false);
        }}
        open={openModalPayment}
        buttonPositive='Confirmar'
      >
        <div>{itemsPicker}</div>
      </Modal>

      <Modal
        question
        onConfirm={handlePhoneNumber}
        onCancel={() => {
          setOpenModalPhoneNumber(false);
          setAutoAllocationSetup(false);
          setContactPhoneNumber(undefined);
        }}
        open={openModalPhoneNumber}
        buttonPositive='Confirmar'
      >
        <div>
          <Typography>Informe um telefone, para o prestador de serviço entrar em contato</Typography>
          <InputComponent
            type='text'
            onChange={e => setContactPhoneNumber(formatCellPhone(removeFormat(e.target.value)))}
            value={contactPhoneNumber || customerPhone}
            ref={refPhone}
            inputProps={{ maxLength: 14 }}
          />
        </div>
      </Modal>

      <ChoiceModal
        open={openModalEnableAutoAllocation}
        onRequestClose={() => {
          setOpenModalEnableAutoAllocation(false);
          setContactPhoneNumber(undefined);
        }}
      >
        <Typography>
          Enviar esta solicitação para <strong>alocação automática</strong> ou <strong>alocação manual</strong>?
        </Typography>
        <Choice onClick={() => handleChooseAllocationType('AUTOMATIC')} loading={loading}>
          <>
            <TrackChanges />
            Automática
          </>
        </Choice>
        <Choice onClick={() => handleChooseAllocationType('MANUAL')} loading={loading}>
          <>
            <EmojiPeople />
            Manual
          </>
        </Choice>
      </ChoiceModal>

      <Modal
        open={!!paymentError}
        onConfirm={() => {
          setForcePickTow(true);
          setPaymentError(undefined);
          confirmLocations();
        }}
        onCancel={() => {
          setPaymentError(undefined);
        }}
        question
      >
        <div>
          <Typography>{paymentError}</Typography>
        </div>
      </Modal>

      <ModalOpportunities
        open={modalOpportunitiesOpen}
        closeModal={() => setModalOpportunitiesOpen(false)}
        vin={vehicle?.vin}
        serviceDescription={service?.description}
        period={period}
      />
    </>
  );
};

export default OpportunityMap;
