import React, { useEffect } from 'react';
import { DialogProps, Dialog, DialogContent, useMediaQuery, useTheme, DialogActions, Button } from '@material-ui/core';
import { MTableToolbar } from 'material-table';

import { OpportunitiesResponse, AllocationContractorsResponse } from '~/types';
import { Table } from '~/components';

import { TableStyle } from './styles';
import { useAllocationContractors } from '~/services/hooks';
import { formatPhone } from '~/util/format';

export interface ContractorPickerProps extends Omit<DialogProps, 'open'> {
  opportunity?: OpportunitiesResponse[number];
  onPick: (contractor: AllocationContractorsResponse[number]) => void;
}

export function ContractorPicker({ opportunity, onPick, ...props }: ContractorPickerProps) {
  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { load, clear, data, loading } = useAllocationContractors();

  useEffect(() => {
    if (opportunity) {
      load(opportunity.service_id, opportunity.customer_vehicle_type);
    } else {
      clear();
    }
  }, [clear, load, opportunity]);

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} {...props} open={!!opportunity}>
      {/* <DialogTitle >{ opportunity?.given_name }</DialogTitle> */}
      <DialogContent style={{ padding: 0 }}>
        <TableStyle>
          <Table
            columns={{
              role: 'Tipo',
              name: 'Nome',
              company_name: 'Empresa',
              phone_number: {
                title: 'Telefone',
                render: rowData => formatPhone(rowData.phone_number),
              },
            }}
            data={data}
            onRowClick={(event, rowData) => {
              onPick(rowData!);
            }}
            components={{
              Toolbar: props => (
                <div
                  style={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              showTitle: false,
              headerStyle: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
              },
              searchFieldStyle: {
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                outlineColor: theme.palette.secondary.main,
              },
            }}
            style={{
              overflow: 'hidden',
            }}
            customOptions={{
              removeShadow: true,
            }}
            isLoading={loading}
          />
        </TableStyle>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant='contained'
          color='primary'
          onClick={e => {
            props.onClose && props.onClose(e, 'escapeKeyDown');
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContractorPicker;
