import React, { useCallback, useEffect, useState } from 'react';

import InputComponent from '~/components/InputComponent';
import Snackbar, { SeverityType } from '~/components/Snackbar';
import { createContractorAllocatedApi, deleteContractorAllocatedApi, getContractorsAllocatedApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useContractors } from '~/services/hooks';
import { useToast } from '~/services/hooks/toast';
import { formatCPF, onlyNumbers } from '~/util/format';
import { validCPF } from '~/util/validate';
import Loading from '~/components/Loading';
import { Title } from '~/components/Title';
import { Table } from '~/components';

interface Props {
  isOpen: boolean;
}

interface Contractors {
  id: string;
  name: string;
}

export const ContractorRedisModal = ({ isOpen }: Props) => {
  const [contractorsAllocated, setContractorsAllocated] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [contractorsAllocatedData, setContractorsAllocatedData] = useState<Contractors[]>([]);
  const [errorsField, setErrorsField] = useState({
    id: false,
  });
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [messageSnack, setMessageSnack] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { addToast } = useToast();
  const { contractors, loading: loadingContractors } = useContractors();

  const validateFields = (contractor: { id: string }) => {
    let error = false;

    setErrorsField({
      id: false,
    });

    if (!contractor.id.trim()) {
      setSeverity('warning');
      setMessageSnack('Informe o CPF.');
      setOpenSnackbar(true);
      // setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        id: true,
      }));
      error = true;
    }

    if (!validCPF(contractor.id.trim())) {
      setSeverity('warning');
      setMessageSnack('Informe um CPF válido.');
      setOpenSnackbar(true);
      // setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        id: true,
      }));
      error = true;
    }

    return !error;
  };

  const getContractorsAllocated = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getContractorsAllocatedApi();
      setContractorsAllocated(data);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultas os prestadores');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getContractorsAllocated();
  }, [addToast, getContractorsAllocated, isOpen]);

  useEffect(() => {
    if (contractors.length === 0) {
      return;
    }

    const newContractorsAllocatedData = contractorsAllocated.map(contractorAllocated => {
      const contractor = contractors.find(contract => contract.id === contractorAllocated);
      return {
        id: contractorAllocated,
        name: contractor?.name || 'Não encontrado',
      };
    });

    setContractorsAllocatedData(newContractorsAllocatedData);
  }, [contractors, contractorsAllocated]);

  if (loading || loadingContractors) {
    return <Loading />;
  }

  return (
    <>
      <Title>Prestadores alocados</Title>

      <Table
        isLoading={loading || loadingContractors}
        data={contractorsAllocatedData}
        columns={{
          id: {
            title: 'CPF',
            render: data => formatCPF(data.id),
          },
          name: {
            title: 'Nome',
            editable: 'never',
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Não há prestadores alocados',
            editRow: {
              deleteText: 'Deseja excluir a alocação?',
            },
          },
        }}
        options={{
          addRowPosition: 'first',
          showTitle: false,
          pageSize: 10,
          paging: false,
          draggable: false,
        }}
        components={{
          EditField: props => {
            const {
              value,
              columnDef: { field },
            } = props;
            return (
              <InputComponent
                type='text'
                value={formatCPF(value)}
                onChange={e => props.onChange(onlyNumbers(e.target.value))}
                error={errorsField[field]}
                autoFocus={errorsField[field]}
                inputProps={{
                  maxLength: 11,
                }}
              />
            );
          },
        }}
        editable={{
          onRowAdd: async newData => {
            if (validateFields(newData)) {
              await createContractorAllocatedApi(newData.id);
              await getContractorsAllocated();
              return Promise.resolve();
            }

            return Promise.reject();
          },
          onRowDelete: async oldData => {
            await deleteContractorAllocatedApi(oldData.id);
            await getContractorsAllocated();
            return Promise.resolve();
          },
        }}
      />

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />
    </>
  );
};
