import React from 'react';
import { ContractorsLocation } from '../../index';
import { IconContentGrid } from '../styles';
import { formatCNPJ, formatCPF } from '~/util/format';
import NameValueItem from './NameValueItem';
import ContractorOnlineIcon from './ContratorOnlineIcon';
import { CopyableNameValueItem } from './NameValueItem';
import { WhatsappLinkWithTooltip } from './WhatsappLink';

interface ContractorInfoSectionProps {
  contractor: ContractorsLocation;
  contractorData: any;
}

function ContractorInfoSection({ contractor, contractorData }: ContractorInfoSectionProps) {
  const contractorFullName = `${contractorData.contractor?.given_name} ${contractorData.contractor?.family_name}`;

  return (
    <IconContentGrid>
      <ContractorOnlineIcon hasOrder={contractor.has_order} online={contractor.online} />

      <section>
        <CopyableNameValueItem title='Nome' loading={contractorData.loading} textGenerator={() => contractorFullName}>
          {!contractorData.contractor && <em>Indisponível</em>}
          {contractorData.contractor?.given_name} {contractorData.contractor?.family_name}
        </CopyableNameValueItem>

        <CopyableNameValueItem textGenerator={() => contractor.id} title={contractor.id.length <= 11 ? 'CPF' : 'CNPJ'}>
          {contractor.id.length <= 11 ? formatCPF(contractor.id) : formatCNPJ(contractor.id)}
        </CopyableNameValueItem>

        <NameValueItem title={'Contato'} loading={contractorData.loading}>
          {contractorData.contractor ? (
            <WhatsappLinkWithTooltip phone={contractorData.contractor?.phone_number} />
          ) : (
            <em>Indisponível</em>
          )}
        </NameValueItem>
      </section>
    </IconContentGrid>
  );
}

export default ContractorInfoSection;
