import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Done, KeyboardArrowUp } from '@material-ui/icons';
import ScrollToTop from 'react-scroll-up';

import { VerticalTimeline, VerticalTimelineElementFinish, ContentType, Type, MapButton, UpBox } from './style';
import VerticalTimelineItem from '../VerticalTimelineItem';

import Colors from '~/styles/colors';

import { OrderHistoryItem, StatusPTBR } from '~/types';

interface TimeLineProps {
  histories?: OrderHistoryItem[];
}

const Timeline = ({ histories }: TimeLineProps) =>
  histories && histories.length > 0 ? (
    <>
      <VerticalTimeline>
        {histories?.map(orderHistory => (
          <VerticalTimelineItem key={`${orderHistory.title}-${orderHistory.id}`} order={orderHistory}>
            <ContentType>
              <Type>Tipo: {orderHistory.type}</Type>

              {orderHistory.typeContent && <MapButton>{orderHistory.typeContent}</MapButton>}
            </ContentType>

            <h4>Situação: {StatusPTBR[orderHistory.title] ?? orderHistory.title}</h4>

            {orderHistory.content}
          </VerticalTimelineItem>
        ))}

        <VerticalTimelineElementFinish icon={<Done />} />
      </VerticalTimeline>

      <ScrollToTop showUnder={160}>
        <Tooltip title='Voltar ao Topo' arrow>
          <UpBox>
            <KeyboardArrowUp style={{ color: Colors.white }} />
          </UpBox>
        </Tooltip>
      </ScrollToTop>
    </>
  ) : (
    <h1 style={{ textAlign: 'center', color: Colors.darkBlue }}>Sem histórico</h1>
  );

export default Timeline;
