import styled from 'styled-components';
import Colors from '~/styles/colors';

export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.primary};

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Div = styled.div`
  width: 100%;
  max-width: 360px;
  text-align: center;

  form {
    width: 360px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 300px;
  margin-bottom: 10px;
`;
