import styled from 'styled-components';
import Colors from '~/styles/colors';

export const TableStyle = styled.div`
  & .MuiInput-underline:before {
    border-color: ${Colors.blue};
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${Colors.blue};
  }
`;
