import { useState, useEffect, useCallback } from 'react';
import { getAllBanksApi } from '../api';
import { BanksResponse } from '~/types';

export function useBanks() {
  const [banks, setBanks] = useState<BanksResponse>();
  const [loading, setLoading] = useState(false);

  const load = useCallback(() => {
    setLoading(true);
    getAllBanksApi()
      .then(response => response.data as BanksResponse)
      .then(setBanks)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return {
    banks,
    loading,
  };
}
