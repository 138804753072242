import { useEffect, useState } from 'react';
import { OrderDocumentsResponse } from '~/types';
import { loadOrderDocuments } from '../api';

export function useOrderDocuments(orderId?: string, update?: Date) {
  const [documents, setDocuments] = useState<OrderDocumentsResponse>([]);

  useEffect(() => {
    if (orderId) {
      loadOrderDocuments(orderId)
        .then(response => response.data)
        .then(setDocuments);
    } else {
      setDocuments([]);
    }
  }, [orderId, update]);

  return documents;
}

export default useOrderDocuments;
