import React, { useRef } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Info, NoteInfo } from './components';
import { Container, Content, Header, FullWidthFlex, IconWrapper, OptionalChildrenWrapper } from './styles';
import CopyableToClipboard from '~/components/Copyable';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTypography-h5': {
      fontWeight: 'bold',
    },
  },
}));

type DataItem = [
  string, // title
  string?, // data
  boolean?, // break
];
export interface CardProps {
  title: string;
  children?: any;
  data?: DataItem[];
  copyable?: boolean;
  buttonHeader?: any;
  optionalChildren?: any;
  annotation?: any;
}

interface ConditionalWrapperProps {
  condition?: boolean;
  wrapper: any;
  children: any;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children);

export function Card({ title, data, copyable, children, buttonHeader, optionalChildren, annotation }: CardProps) {
  const classes = useStyles();
  const contentRef = useRef(null);

  const Items = ({ children, data, copyableData }: { children: any[]; data?: DataItem[]; copyableData: boolean }) => (
    <Content>
      {children}
      {data &&
        data.map((d, index) => (
          <>
            <Info key={`${index}-${d[0]}-${d}`} title={d[0]} data={d[1]} copyable={copyableData} />
            {d[2] && <Division />}
          </>
        ))}
    </Content>
  );

  const getText = ref => {
    if (ref && ref.current) {
      return (String(ref.current.innerText) || 'Sem texto')
        .split('\n')
        .filter(el => el !== '')
        .flatMap((_, i, a) => (i % 2 ? [] : [a.slice(i, i + 2)]))
        .map(([name, value]) => `${name} ${value}`)
        .join('\n');
    }
    return '';
  };

  const getAnnotations = data => {
    if (data) {
      return data.map(item => `${item.field}: ${item.data}`).join('\n');
    }
    return '';
  };

  return (
    <Container className={classes.root}>
      <FullWidthFlex>
        <Header title={title} action={buttonHeader} />

        {buttonHeader ? null : (
          <FullWidthFlex style={{ justifyContent: 'flex-end' }}>
            {children && children?.filter(child => child?.type).filter(child => child?.type.name === Action.name)}
          </FullWidthFlex>
        )}
      </FullWidthFlex>

      <div ref={contentRef}>
        <ConditionalWrapper
          condition={copyable}
          wrapper={children => (
            <CopyableToClipboard textGenerator={() => (annotation ? getAnnotations(annotation) : getText(contentRef))}>
              {children}
            </CopyableToClipboard>
          )}
        >
          <Items data={data} copyableData={!copyable}>
            <OptionalChildrenWrapper>{optionalChildren}</OptionalChildrenWrapper>
            {children &&
              children
                ?.filter(child => child?.type)
                .filter(child => child?.type.name === Item.name || child?.type.name === Division.name)}
          </Items>
        </ConditionalWrapper>
      </div>
    </Container>
  );
}

export interface ItemProps {
  field?: string;
  content?: string | number;
  children?: string | number;
  code?: boolean;
  copyable?: boolean;
  hasWhiteSpace?: boolean;
}

export function Item({ field = '', content, children, code, copyable, hasWhiteSpace }: ItemProps) {
  return (
    <Info
      title={field}
      data={content?.toString() || children?.toString()}
      code={code}
      copyable={copyable}
      hasWhiteSpace={hasWhiteSpace}
    />
  );
}

export function NoteItem({ field = '', content, code, copyable }: any) {
  return (
    <NoteInfo
      title={field}
      data={content.split('\n').map((i, index) => (
        <p key={`${index}`}>{i}</p>
      ))}
      code={code}
      copyable={copyable}
    />
  );
}

export function Division() {
  return <hr style={{ marginBottom: 24 }} />;
}

export function Action({ tootipText, children }: { tootipText: string; children: any }) {
  return (
    <div style={{ display: 'flex' }}>
      <Tooltip arrow placement='top' title={tootipText} style={{ height: '100%' }}>
        <IconWrapper>{children}</IconWrapper>
      </Tooltip>
    </div>
  );
}
