import { useCallback, useEffect, useState } from 'react';
import { IBGECity, IBGEState } from '~/types';

import { getCitiesApi, getStatesApi } from '../api';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

type Props = {
  stateId?: string;
  isCalled?: boolean;
};

export function useIBGE({ stateId, isCalled = true }: Props) {
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [states, setStates] = useState<IBGEState[]>([]);
  const [cities, setCities] = useState<IBGECity[]>([]);

  const { addToast } = useToast();

  const getStates = useCallback(async () => {
    try {
      if (!isCalled) {
        return;
      }

      setLoadingStates(true);
      const { data } = await getStatesApi();
      setStates(data);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar estados');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoadingStates(false);
    }
  }, [addToast, isCalled]);

  const getCities = useCallback(
    async (statesId?: string) => {
      try {
        if (!statesId) {
          return;
        }

        setLoadingCities(true);
        const { data } = await getCitiesApi(statesId);
        const citiesSanitized = (data as IBGECity[]).map(city => ({ ...city, name: city.name.toUpperCase() }));
        setCities(citiesSanitized);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar cidades');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingCities(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getCities(stateId);
  }, [getCities, stateId]);

  return {
    loadingStates,
    loadingCities,
    states,
    cities,
  };
}
