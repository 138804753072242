import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadVehicleBenefitQuota, loadVehicleBenefits } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { setOrderBenefit } from '~/store/ducks/order';
import { BenefitResponse, BenefitsQuotaResponse } from '~/types';

export function useInformationOrder() {
  const [benefit, setBenefit] = useState<BenefitResponse>();
  const [loadingBenefit, setLoadingBenefit] = useState(false);
  const [quota, setQuota] = useState<BenefitsQuotaResponse>();
  const [loadingQuota, setLoadingQuota] = useState(false);

  const order = useSelector(state => state.order);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    const getVehicleBenefits = async () => {
      if (!order.vehicle?.association_id || !order.service?.id || !order.customerId || !order.vehicle?.id) {
        return;
      }

      try {
        setLoadingBenefit(true);
        const { data } = await loadVehicleBenefits(
          order.vehicle.association_id,
          order.service.id,
          order.customerId,
          order.vehicle.id,
        );

        setBenefit(data);
        dispatch(setOrderBenefit(data));
      } catch (error) {
        const errorResponse = error as AxiosError;
        if (errorResponse?.response?.status === 404) {
          return;
        }

        dispatch(setOrderBenefit({}));
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o benefício');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingBenefit(false);
      }
    };

    getVehicleBenefits();
  }, [addToast, dispatch, order.customerId, order.service, order.vehicle]);

  useEffect(() => {
    const getBenefitQuota = async () => {
      if (!benefit?.created_at || !order.customerId || !benefit.quantity || !order.service?.id || !order.vehicle?.vin) {
        return;
      }

      try {
        setLoadingQuota(true);
        const { data } = await loadVehicleBenefitQuota(order.vehicle.id, benefit.id);
        setQuota(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a quota');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingQuota(false);
      }
    };

    getBenefitQuota();
  }, [addToast, benefit, order.customerId, order.service, order.vehicle]);

  return { benefit, quota, loadingBenefit, loadingQuota };
}
