import React, { useEffect, useState } from 'react';
import { Column } from 'material-table';
import { Refresh } from '@material-ui/icons';

import { useOpportunities } from '~/services/hooks';
import { OpportunityStatusRequest, OpportunitiesResponse, PaymentTypePTBR } from '~/types';
import { Table } from '~/components';
import { formatCNPJ, formatCPF } from '~/util/format';
import Details from '../Details';
import exportCustomCSV from '~/util/exportCustomCSV';
import ActionMenu from '../ActionMenu';
import CancelReasonItem from './components/CancelReasonRowItem/index';
import { useOrdersBadge } from '~/services/hooks/useOrdersBadge';

export interface PaymentTableProps {
  open: boolean;
}

// Pagamento
export function PaymentTable(props: PaymentTableProps) {
  const { loading, data, invalidateQuery } = useOpportunities([
    OpportunityStatusRequest.PAYMENT_APPROVED,
    OpportunityStatusRequest.PAYMENT_PENDING,
    OpportunityStatusRequest.PAYMENT_REFUSED,
  ]);
  const [opportunityDetails, setOpportunityDetails] = useState<OpportunitiesResponse[number]>();
  const generateItemBackgroundColor = rowData => {
    switch (rowData.status) {
      case OpportunityStatusRequest.PAYMENT_APPROVED:
        return '#89e166';
      case OpportunityStatusRequest.PAYMENT_PENDING:
        return '#66d6e1';
      case OpportunityStatusRequest.PAYMENT_REFUSED:
        return '#e2846e';
    }
  };

  const statusLookup = {
    PAYMENT_APPROVED: 'APROVADO',
    PAYMENT_PENDING: 'PENDENTE',
    PAYMENT_REFUSED: 'NEGADO',
  };

  useEffect(() => {
    if (opportunityDetails) {
      const updatedOpportunity = data.filter(opp => opp.id === opportunityDetails.id);
      setOpportunityDetails(updatedOpportunity[0]);
    }
  }, [data, opportunityDetails]);

  useOrdersBadge('payment', data.length);

  useEffect(() => {
    if (props.open) {
      invalidateQuery();
    }
  }, [invalidateQuery, props.open]);

  if (!props.open) {
    return null;
  }

  return (
    <>
      <Table
        columns={{
          actions: {
            filtering: false,
            export: false,
            render: rowData => <ActionMenu id='payment-cancel' data={rowData} onCancel={invalidateQuery} />,
          },
          number: {
            title: 'ID do pedido',
            cellStyle: (data, rowData) => {
              return {
                background: generateItemBackgroundColor(rowData),
                fontWeight: 'bold',
                fontFamily: 'Courier, "Lucida Console", monospace;',
                color: 'rgba(0, 0, 0,.7)',
              };
            },
          },
          status: {
            title: 'Status',
            lookup: statusLookup,
          },
          issued_by: {
            title: 'Origem',
            hidden: true,
            export: true,
            render: rowData => rowData?.issued_by || 'DESCONHECIDO',
          },
          cancel_reason: {
            title: 'Motivo',
            render: rowData => <CancelReasonItem data={rowData} />,
            customExport: rowData => rowData.cancel_reason,
            export: true,
          },
          service_description: {
            title: 'Tipo de Serviço',
            lookup: (() => data.map(d => d.service_description).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
          },
          payment_type: {
            title: 'Forma de Pgto.',
            lookup: (() =>
              data
                .map(item => item.payment_type)
                .reduce((acc, item) => ({ ...acc, [item]: PaymentTypePTBR[item] }), {}))(),
            render: rowData => PaymentTypePTBR[rowData.payment_type] ?? rowData.payment_type,
            export: false,
            exportOnlyCSV: true,
          },
          customer_id: {
            title: 'CPF/CNPJ',
            render: rowData =>
              rowData.customer_id.length > 11 ? formatCNPJ(rowData.customer_id) : formatCPF(rowData.customer_id),
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          customer_name: {
            title: 'Cliente',
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          customer_phone_number: {
            title: 'Telefone do Cliente',
            exportOnlyCSV: true,
            export: false,
          },
          association_name: {
            title: 'Empresa',
            lookup: (() => data.map(d => d.association_name).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          created_at_formatted: 'Data/Hora de inicio',
          updated_at_formatted: {
            title: 'Data/Hora do aceite do pedido',
            defaultSort: 'asc',
          },
          license_plate_or_vin: 'Placa do veículo ou Chassi',
          vehicle_manufacture: {
            title: 'Marca',
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          vehicle_model: {
            title: 'Modelo do veículo',
            hidden: true,
            export: true,
          },

          source_latlng: {
            title: 'LatLng Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          source_address: {
            title: 'Endereço Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_latlng: {
            title: 'LatLng Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_address: {
            title: 'Endereço Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          total: {
            title: 'Total',
            hidden: true,
            export: true,
          },
        }}
        data={data}
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: 'Atualizar',
            isFreeAction: true,
            onClick: invalidateQuery,
          },
        ]}
        onRowClick={(event, rowData) => {
          if (rowData) {
            setOpportunityDetails(rowData);
          }
        }}
        options={{
          showTitle: false,
          exportButton: true,
          exportAllData: true,
          filtering: true,
          exportCsv: (allColumns: Column<OpportunitiesResponse[number]>[], allData: OpportunitiesResponse) => {
            exportCustomCSV({
              csvColumns: allColumns,
              csvContent: allData,
              filenameOutput: 'oportunidades_aceitas.csv',
            });
          },
        }}
        customOptions={{
          noLineBreakInHeader: true,
        }}
        isLoading={loading}
        title='Oportunidades aceitas'
      />

      <Details opportunity={opportunityDetails} onRequestClose={() => setOpportunityDetails(undefined)} />
    </>
  );
}

export default PaymentTable;
