import { TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import Loading from '~/components/Loading';
import { Divider, TitleModal } from '~/pages/NewOpportunity/styles';

import useServices from '~/services/hooks/useServices';
import { BenefitResponse, ServicesResponse } from '~/types';
import { SelectedBenefits } from '../VehicleRegistration';
import { Container } from './styles';

interface ServiceProps {
  associationId: string;
  selectedBenefits: SelectedBenefits;
  setSelectedBenefits: (value: SelectedBenefits) => void;
  initialData?: BenefitResponse[];
}

export function BenefitsRegistration({
  associationId,
  selectedBenefits,
  setSelectedBenefits,
  initialData,
}: ServiceProps) {
  const [benefits, setBenefits] = useState<(BenefitResponse[] | undefined)[]>([]);
  const [loading, setLoading] = useState(false);

  const { services, loadingServices, getBenefits, loadingBenefits } = useServices();

  useEffect(() => {
    const getAllBenefits = async () => {
      if (!services) {
        return;
      }

      setLoading(true);

      const promises = services.map(service => getBenefits(associationId, service.id));
      const result = await Promise.all(promises);

      setBenefits(result);
      setLoading(false);
    };

    getAllBenefits();
  }, [associationId, getBenefits, services]);

  const getBenefitOfInitialData = useCallback(
    (serviceId: string) =>
      initialData
        ?.map(
          item =>
            (benefits
              .map(benefit => benefit?.filter(b => b.service_id === item.service_id && b.id === item.id))
              .filter(benefit => (benefit?.length || 0) > 0)[0] || [])[0],
        )
        .filter(item => item?.service_id === serviceId)[0],
    [benefits, initialData],
  );

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const initialBenefits = initialData
      .map(item => getBenefitOfInitialData(item.service_id))
      .reduce((acc, curr) => {
        if (!curr) {
          return acc;
        }

        acc[curr.service_id] = curr.id;

        return acc;
      }, {} as SelectedBenefits);
    setSelectedBenefits(initialBenefits);
  }, [getBenefitOfInitialData, initialData, setSelectedBenefits]);

  const getBenefitsBySelected = useCallback(
    (serviceIdParam: string) => {
      const ret = Object.entries(selectedBenefits)
        .map(
          ([serviceId, benefitId]) =>
            benefits
              .map(benefit => benefit?.filter(b => b.service_id === serviceId && b.id === benefitId)[0])
              .filter(benefit => benefit)[0],
        )
        .filter(benefit => benefit?.service_id === serviceIdParam)[0];

      return ret;
    },
    [benefits, selectedBenefits],
  );

  const handleClick = (serviceId: string, value: string) => {
    const hasService = selectedBenefits[serviceId];

    if (hasService === value || value === '') {
      const newSelectedValue = { ...selectedBenefits };
      delete newSelectedValue[serviceId];
      setSelectedBenefits(newSelectedValue);
    } else {
      const newSelectedValue = { ...selectedBenefits, [serviceId]: value };
      setSelectedBenefits(newSelectedValue);
    }
  };

  const filterServicesWithBenefits = (services: ServicesResponse) =>
    benefits.findIndex(
      benefitsItem => (benefitsItem?.findIndex(benefitItem => benefitItem.service_id === services.id) || 0) > -1,
    ) > -1;

  if (loadingServices || loadingBenefits || loading) {
    return <Loading />;
  }

  return (
    <Container>
      <TitleModal>Selecione os benefícios (opcionais)</TitleModal>
      <Divider height={15} />

      {services?.filter(filterServicesWithBenefits)?.map(item => (
        <div key={item.id} style={{ marginBottom: 16 }}>
          <CustomAutocomplete
            id={`combo-box-${item.description}`}
            options={
              benefits.filter(benefitsItem => benefitsItem?.[0]?.service_id === item.id)[0] || ([] as BenefitResponse[])
            }
            getOptionLabel={benefitsItem => benefitsItem.description}
            renderInput={params => <TextField {...params} label={item.description} />}
            onChange={(_, value) => {
              handleClick(item.id, value?.id || '');
            }}
            width={400}
            value={getBenefitsBySelected(item.id)}
          />
        </div>
      ))}
    </Container>
  );
}
