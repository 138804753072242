import React from 'react'
import { Dialog, useTheme, useMediaQuery, DialogActions, Button, DialogContent, ButtonProps } from '@material-ui/core';

export interface ChoiceModalProps {
  children: any
  open: boolean
  onRequestClose?: () => void
}

/**
 * Choice modal is a kind of modal where the user is presented with options to choose.
 * This differs from the usual yes/no or ok/cancel modals. In this case we want to avoid
 * the "automatic" behavior of clicking the rightmost button.
 * 
 * @example
 * <ChoiceModal open={true} onRequestClose={() => {}}>
 *     <p>{Text to show on the top of the modal}</p>
 *     <p>{Text will also show on the top of the modal}</p>
 *     <Choice onClick={()=>{}>{'Option 1'}</Choice>
 *     <Choice onClick={()=>{}>{'Option 2'}</Choice>
 * </ChoiceModal>
 */
const ChoiceModal = ({ children, open, onRequestClose, ...rest }: ChoiceModalProps) => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      maxWidth='xl'
      fullScreen={fullscreen}
      disableScrollLock={true}
      open={open}
      onClose={onRequestClose}
      {...rest}
    >
      <DialogContent>{children.filter(child => child?.type.name !== Choice?.name)}</DialogContent>

      <DialogActions style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: '.3em' }}>
        {children.filter(child => child?.type.name === Choice?.name)}
      </DialogActions>
    </Dialog>
  );
}

export interface ChoiceProps {
  onClick: () => void
  children: any
  props?: ButtonProps
  loading?: boolean
}

export const Choice = ({ onClick, children, loading, props }: ChoiceProps) => (
  <Button
    autoFocus
    variant='contained'
    color='secondary'
    style={{ textTransform: 'none' }}
    onClick={onClick}
    onDoubleClick={onClick}
    disabled={loading}
    {...props}
  >
    {children}
  </Button>
)

export default ChoiceModal

