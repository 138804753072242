import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import { CustomRoles } from '~/types';

export interface RouterWrapperProps extends RouteProps {
  isPrivate?: boolean;
  component: (props: any) => ReactElement;
  roles?: CustomRoles[];
}

export function RouterWrapper({
  component: Component,
  isPrivate,
  roles = [CustomRoles.BACKOFFICE],
  ...props
}: RouterWrapperProps) {
  const {
    authorization: { authenticated },
    user: { 'custom:role': role },
  } = useSelector(state => state.auth);

  if ((!authenticated && isPrivate) || (authenticated && isPrivate && !roles?.includes(role) && props.path !== '/')) {
    return <Redirect to='/' />;
  }

  if (authenticated && !isPrivate) {
    return <Redirect to='/dashboard' />;
  }

  const Layout = authenticated ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...props}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default RouterWrapper;
