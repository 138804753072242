import React, { useCallback, useLayoutEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Add, ArrowBack, Delete, Edit } from '@material-ui/icons';

import { ContractorsResponse, VehiclesResponseItem } from '~/types';

import { Form, Header } from './styles';
import { useVehicles } from '~/services/hooks';

import { Table, ModalDeleteVehicle } from '~/components';
import { Title } from '~/components/Title';
import { VehicleDetailsDialog } from '../../components/VehicleDetailsDialog';

export interface VehiclesParams {
  contractorId: string;
}

export interface VehiclesLocationState {
  contractor?: ContractorsResponse;
}

export type VehiclesProps = RouteChildrenProps<VehiclesParams, VehiclesLocationState>;

export function Vehicles(props: VehiclesProps) {
  const contractorId = props.match?.params.contractorId;
  const contractor = props.location.state?.contractor ?? ({} as ContractorsResponse);

  const { vehicles, loading, deleteVehicle, getVehicles } = useVehicles(contractorId);

  const [modalDeleteVehicle, setModalDeleteVehicle] = useState(false);
  const [vehicleId, setVehicleId] = useState<string | undefined>(undefined);
  const [isNewVehicle, setIsNewVehicle] = useState(false);
  const [vehicleItem, setVehicleItem] = useState<VehiclesResponseItem>();

  const handleConfirmDeleteVehicle = useCallback(async () => {
    await deleteVehicle(vehicleId);

    setModalDeleteVehicle(false);
  }, [vehicleId, deleteVehicle]);

  const handleNewVehicle = () => {
    setIsNewVehicle(true);
  };

  const handleCloseModal = () => {
    setIsNewVehicle(false);
    setVehicleItem(undefined);
    if (contractorId) {
      getVehicles(contractorId);
    }
  };

  useLayoutEffect(() => {
    if (!contractor.id) {
      props.history.push('/');
    }
  }, [contractor.id, props.history]);

  return (
    <>
      <Header>
        <Button style={{ marginRight: 20 }}>
          <ArrowBack fontSize='large' onClick={props.history.goBack} />
        </Button>
        <Title>Veículos</Title>
      </Header>

      <Form>
        <Tooltip title='Adicionar veículo' arrow>
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleNewVehicle}>
            <Add />
          </IconButton>
        </Tooltip>
      </Form>

      <Table
        title={contractor?.name || contractorId}
        columns={{
          vehicle_contractor_type_description: 'Tipo',
          license_plate: 'Placa',
          manufacture: 'Marca',
          model: 'Modelo',
          color: 'Cor',
          state: 'UF',
          city: 'Cidade',
          status: 'Situação',
        }}
        data={vehicles}
        actions={[
          () => ({
            icon: () => <Delete />,
            tooltip: 'Excluir',
            onClick: (_, data) => {
              let dataItem: VehiclesResponseItem;

              if (Array.isArray(data)) {
                dataItem = data[0];
              } else {
                dataItem = data;
              }

              setVehicleId(dataItem.id);
              setModalDeleteVehicle(true);
            },
          }),
          () => ({
            icon: () => <Edit />,
            tooltip: 'Editar',
            onClick: (_, data) => {
              let dataItem: VehiclesResponseItem;

              if (Array.isArray(data)) {
                dataItem = data[0];
              } else {
                dataItem = data;
              }

              setVehicleItem(dataItem);
            },
          }),
        ]}
        localization={{
          header: {
            actions: 'Ação',
          },
          body: {
            emptyDataSourceMessage: 'Sem veículos',
          },
          pagination: {
            labelRowsSelect: 'veículos',
          },
        }}
        options={{
          exportButton: true,
          exportFileName: `Veículos-${contractorId}`,
        }}
        isLoading={loading}
      />

      <VehicleDetailsDialog
        vehicleItem={vehicleItem}
        contractor={contractor}
        onClose={handleCloseModal}
        newVehicle={isNewVehicle}
      />

      <ModalDeleteVehicle
        onConfirm={handleConfirmDeleteVehicle}
        onCancel={() => {
          setModalDeleteVehicle(false);
        }}
        open={modalDeleteVehicle}
      />
    </>
  );
}

export default Vehicles;
