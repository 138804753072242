import { useState, useEffect, useCallback } from 'react';
import { loadAllocationContractors } from '../api';
import { CancelableAxiosPromise, AllocationContractorsResponse } from '~/types';
import Axios from 'axios';

export function useAllocationContractors() {
  const [data, setData] = useState<AllocationContractorsResponse>([]);
  const [loading, setLoading] = useState(false);

  const [serviceId, setServiceId] = useState<string>();
  const [customerVehicleType, setCustomerVehicleType] = useState<string>();

  const [lastRequest, setLastRequest] = useState<CancelableAxiosPromise<AllocationContractorsResponse>>();

  useEffect(() => {
    if (loading && serviceId && customerVehicleType) {
      const request = loadAllocationContractors(serviceId, customerVehicleType) as CancelableAxiosPromise<
        AllocationContractorsResponse
      >;

      setLastRequest(request);

      request
        .then(response => response.data)
        .then(setData)
        .then(
          () => setLoading(false),
          e => !Axios.isCancel(e) && setLoading(false),
        );
    } else {
      if (lastRequest) lastRequest.cancel();
      if (loading) setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerVehicleType, loading, serviceId]);

  const load = useCallback((service_id: string, customer_vehicle_type: string) => {
    setLoading(false);
    setServiceId(service_id);
    setCustomerVehicleType(customer_vehicle_type);
    setLoading(true);
  }, []);

  const refresh = useCallback(() => {
    setLoading(false);
    setLoading(true);
  }, []);

  const clear = useCallback(() => {
    setLoading(false);
    setServiceId(undefined);
    setCustomerVehicleType(undefined);
    setData([]);
  }, []);

  return {
    data,
    loading,
    load,
    refresh,
    clear,
  };
}

export default useAllocationContractors;
