import { useEffect, useState } from 'react';

import { loadVehicleServicesApi } from '../api';

import { VehicleServicesResponse, VehiclesResponse } from '../../types';

export function useVehiclesServices(vehiclesData: VehiclesResponse) {
  const [vehiclesServices, setVehiclesServices] = useState<VehicleServicesResponse>([]);
  const [loadingVehiclesServices, setLoadingVehiclesServices] = useState(false);

  useEffect(() => {
    setLoadingVehiclesServices(true);

    const promises = vehiclesData.map(vehicle => vehicle.id).map(id => loadVehicleServicesApi(id));

    Promise.all(promises)
      .then(responses => responses.map(response => response.data as VehicleServicesResponse))
      .then(responses => responses.flat())
      .then(setVehiclesServices)
      .finally(() => {
        setLoadingVehiclesServices(false);
      });
  }, [vehiclesData]);

  return { vehiclesServices, loadingVehiclesServices };
}

export default useVehiclesServices;
