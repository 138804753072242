import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Button from '~/components/Button';
import InputComponent from '~/components/InputComponent';
import Modal from '~/components/Modal';
import { setOrderPhone } from '~/store/ducks/order';
import { formatCellPhone, removeFormat } from '~/util/format';

type Props = {
  openModalPhone: boolean;
  setOpenModalPhone: (value: boolean) => void;
};

export function ModalPhone({ openModalPhone, setOpenModalPhone }: Props) {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const { customerPhone } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const handleSavePhone = () => {
    dispatch(setOrderPhone(phoneNumber));
    setOpenModalPhone(false);
  };

  useEffect(() => {
    setPhoneNumber(customerPhone);
  }, [customerPhone]);

  return (
    <Modal
      open={openModalPhone}
      buttons={[
        <Button
          key='cancel'
          type='button'
          filled
          label='Cancelar'
          onClick={() => {
            setOpenModalPhone(false);
          }}
        />,
        <Button key='save' type='button' filled label='Salvar' onClick={handleSavePhone} />,
      ]}
    >
      <Typography>Informe um telefone, para o prestador de serviço entrar em contato</Typography>
      <InputComponent
        type='text'
        autoFocus
        value={phoneNumber}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPhoneNumber(formatCellPhone(removeFormat(event.target.value)));
        }}
        inputProps={{ maxLength: 14 }}
      />
    </Modal>
  );
}
