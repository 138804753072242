import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

import { getIconService } from '~/util/getIcon';

import useServices from '~/services/hooks/useServices';
import { useToast } from '~/services/hooks/toast';

import { setOrderService } from '~/store/ducks/order';

import Title from '../../components/Title';
import Button from '~/components/Button';
import { ModalConfirm } from '~/components/ModalConfirm';
import HighlightedButton from '../../components/HighlightedButton';
import BackButton from '../../components/BackButton';

import { ServicesResponse } from '~/types';
import InformationOrder from '../../components/InformationOrder';

const OpportunityServices = () => {
  const [service, setService] = useState<ServicesResponse>();
  const [modalCancel, setModalCancel] = useState(false);

  const { services, loadingServices } = useServices();
  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();

  const { customerId, service: serviceState } = useSelector(state => state.order);

  useEffect(() => {
    setService(serviceState);
  }, [serviceState]);

  useEffect(() => {
    if (!customerId) {
      history.push('/new-opportunity');
    }
  }, [customerId, history]);

  const handleListItemClick = useCallback((service: ServicesResponse) => {
    setService(service);
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!service?.id) {
        addToast({ title: 'Validação', type: 'error', description: 'Tipo do serviço obrigatório!' });
        return;
      }

      dispatch(setOrderService(service));
      history.push('/new-opportunity/vehicles');
    },
    [addToast, dispatch, history, service],
  );

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  return (
    <>
      <Title>Tipo de serviço</Title>

      <InformationOrder type='SERVICE' />

      <form onSubmit={handleSubmit}>
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <List component='nav' aria-label='main mailbox folders'>
              {services?.map(item => (
                <ListItem
                  key={item.id}
                  button
                  selected={service?.id === item.id}
                  onClick={() => handleListItemClick(item)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item.description}
                      src={getIconService(item.description)}
                      style={{ width: 48, height: 48 }}
                    />
                  </ListItemAvatar>

                  <ListItemText primary={item.description} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <BackButton loading={loadingServices} onClick={() => history.goBack()} />

          <Button
            type='button'
            label='Cancelar'
            loading={loadingServices}
            filled
            style={{ width: 200, marginRight: 30 }}
            onClick={handleCancel}
          />

          <HighlightedButton loading={loadingServices} />
        </Grid>
      </form>

      <ModalConfirm
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
        title='Tem certeza de que deseja cancelar essa oportunidade?'
      />
    </>
  );
};

export default OpportunityServices;
