import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { v4 as uuidV4 } from 'uuid';

import { formatCPF_CNPJ, formatLicensePlate } from '~/util/format';
import { useToast } from '~/services/hooks/toast';
import { useCustomer } from '~/services/hooks';
import { clearOrder, setOrderInitial } from '~/store/ducks/order';
import Title from './components/Title';
import HighlightedButton from './components/HighlightedButton';
import { ContainerModal, Divider, TitleModal } from './styles';
import { createNewUserApi } from '~/services/api';
import Modal from '~/components/ModalNewVehicle';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { CustomerResponse } from '~/types';

export interface NewUser {
  given_name: string;
  given_name_error?: string;
  family_name: string;
  family_name_error?: string;
}

const NewOpportunity = () => {
  const [cpfCNPJ, setCpfCNPJ] = useState('');
  const [newUser, setNewUser] = useState<NewUser>({} as NewUser);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [licensePlate, setLicensePlate] = useState('');
  const [customers, setCustomers] = useState<CustomerResponse[]>();
  const [modalCustomersOpen, setModalCustomersOpen] = useState(false);

  const { customerId } = useSelector(state => state.order);

  const { addToast } = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, clear, loading, getCustomerByLicensePlate } = useCustomer(cpfCNPJ);

  useEffect(() => {
    setCpfCNPJ(customerId || '');
  }, [customerId]);

  const handleClearOrder = () => {
    dispatch(clearOrder());
  };

  const handleCreateNewUser = async () => {
    const errors: Pick<NewUser, 'given_name_error' | 'family_name_error'> = {};

    if (!newUser.given_name) {
      errors.given_name_error = 'Nome é obrigatório';
    }

    if (!newUser.family_name) {
      errors.family_name_error = 'Sobrenome é obrigatório';
    }

    setNewUser({ ...newUser, ...errors });

    if (Object.keys(errors).length !== 0) {
      return;
    }
    setLoadingCreateUser(true);

    try {
      await createNewUserApi({ ...newUser, username: cpfCNPJ, locale: 'pt-BR', zoneinfo: 'America/Sao_Paulo' });
      setLoadingCreateUser(false);
      setModalIsOpen(false);
      dispatch(clearOrder());
      dispatch(
        setOrderInitial({
          customerId: cpfCNPJ,
          uuid: uuidV4(),
          customerName: `${newUser.given_name} ${newUser.family_name}`,
        }),
      );
      history.push('/new-opportunity/services');
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao criar o usuário');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
      setLoadingCreateUser(false);
    }
  };

  const handleCancelModal = () => {
    setNewUser({} as NewUser);
    setModalIsOpen(false);
  };

  const handleCancelModalLicensePlate = () => {
    setModalCustomersOpen(false);
  };

  const handleCustomerSelected = (customerId: string) => {
    setCpfCNPJ(customerId);
    setModalCustomersOpen(false);
  };

  const hasCustomer = useMemo(() => !!cpfCNPJ && (cpfCNPJ.length === 11 || cpfCNPJ.length === 14), [cpfCNPJ]);

  const canRegisterNewUser = hasCustomer && !loading && !data?.name;

  useEffect(() => {
    if (!hasCustomer) {
      clear();
    }
  }, [clear, cpfCNPJ, hasCustomer]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!cpfCNPJ) {
        addToast({ title: 'Validação', type: 'error', description: 'CPF ou CNPJ obrigatório!' });
        return;
      }

      if (canRegisterNewUser) {
        setModalIsOpen(true);
        return;
      }

      dispatch(setOrderInitial({ customerId: cpfCNPJ, uuid: uuidV4(), customerName: data?.name }));
      history.push('/new-opportunity/services');
    },
    [addToast, canRegisterNewUser, cpfCNPJ, data, dispatch, history],
  );

  useEffect(() => {
    const getCustomerData = async () => {
      const data = await getCustomerByLicensePlate(licensePlate);

      setCustomers(data);

      if (!data || data.length === 0) {
        return;
      }

      if (data.length > 1) {
        setModalCustomersOpen(true);
        return;
      }

      setCpfCNPJ(data[0].id);
    };

    getCustomerData();
  }, [getCustomerByLicensePlate, licensePlate]);

  return (
    <>
      <Title>Nova Oportunidade</Title>

      <form onSubmit={handleSubmit}>
        <Card style={{ display: 'flex', flexDirection: 'row' }}>
          <CardContent>
            <TextField
              id='cpf-CNPJ-field'
              label='CPF ou CNPJ'
              value={formatCPF_CNPJ(cpfCNPJ)}
              onChange={({ target: { value } }) => setCpfCNPJ(value.split(/\D+/).join('').slice(0, 14))}
              InputProps={{
                endAdornment: customerId ? (
                  <InputAdornment position='end'>
                    <IconButton aria-label='clear user' onClick={handleClearOrder}>
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography style={{ marginTop: 16 }}>
              {hasCustomer && !loading ? data?.name ?? 'Cliente não encontrado!' : ''}
            </Typography>
          </CardContent>

          <CardContent>
            <TextField
              id='license-plate-field'
              label='Placa'
              value={licensePlate}
              onChange={({ target: { value } }) => setLicensePlate(formatLicensePlate(value))}
              inputProps={{ maxLength: 7 }}
            />
            <Typography style={{ marginTop: 16 }}>
              {!loading && customers ? (customers.length > 0 ? '' : 'Placa não encontrada!') : ''}
            </Typography>
          </CardContent>
        </Card>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <HighlightedButton loading={loading} label={canRegisterNewUser ? 'Cadastrar' : undefined} />
        </Grid>
      </form>

      <Modal
        onConfirm={handleCreateNewUser}
        open={modalIsOpen}
        buttonPositive='Cadastrar e avançar'
        haveCustomButton
        buttonCustom='Cancelar'
        onCustom={handleCancelModal}
        loadingConfirmButton={loadingCreateUser}
        disabledCustomButton={loadingCreateUser}
      >
        <ContainerModal>
          <TitleModal>Novo usuário</TitleModal>
          <Divider height={15} />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField id='cpf-CNPJ-newUser-field' label='CPF ou CNPJ' value={formatCPF_CNPJ(cpfCNPJ)} disabled />

            <TextField
              id='giveName-newUser-field'
              label='Nome'
              value={newUser.given_name}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, given_name: String(value).toUpperCase(), given_name_error: '' })
              }
              error={!!newUser.given_name_error}
              helperText={newUser.given_name_error}
            />

            <TextField
              id='lastName-newUser-field'
              label='Sobrenome'
              value={newUser.family_name}
              onChange={({ target: { value } }) =>
                setNewUser({ ...newUser, family_name: String(value).toUpperCase(), family_name_error: '' })
              }
              error={!!newUser.family_name_error}
              helperText={newUser.family_name_error}
            />
          </div>
        </ContainerModal>
      </Modal>

      <Modal
        onConfirm={handleCancelModalLicensePlate}
        open={modalCustomersOpen}
        buttonPositive='Cancelar'
        loadingConfirmButton={loadingCreateUser}
        disabledCustomButton={loadingCreateUser}
        fullWidth
        maxWidth='md'
      >
        <TitleModal>Selecione um cliente</TitleModal>
        <Divider height={15} />

        <List component='nav' aria-label='main mailbox folders'>
          {customers?.map(customer => (
            <ListItem key={customer.id} button onClick={() => handleCustomerSelected(customer.id)}>
              <ListItemText primary={`${formatCPF_CNPJ(customer.id)} - ${customer.name}`} />
            </ListItem>
          ))}
        </List>
      </Modal>
    </>
  );
};

export default NewOpportunity;
