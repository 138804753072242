import React from 'react';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { AddNote } from './styles';

interface AddNoteButtonProps {
  onClick: () => void;
}

export const AddNoteButton: React.FC<AddNoteButtonProps> = ({
  onClick
}) => {
  return (
    <AddNote aria-label='add note' color='primary' onClick={() => onClick()}>
      <BorderColorIcon style={{ fontSize: 30 }} />
    </AddNote>
  );
};
