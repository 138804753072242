import styled from 'styled-components';

export const Hoverable = styled.div`
    margin-top: .8em;
    margin-bottom: .8em;
    margin-left: .5em;
    margin-right: .5em;
    transition: height 1s ease-in;
    display: flex;
    width: 100%;
    justify-content:center;
`;

export const TextOverflow = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 15vw;
`;
