import { useCallback, useEffect, useState } from 'react';
import { loadAllocationCancelReasons } from '../api';
import { AllocationCancelReasonsResponse } from '~/types';

export function useAllocationCancelReasons() {
  const [data, setData] = useState<AllocationCancelReasonsResponse['cancel_reasons']>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      loadAllocationCancelReasons()
        .then(response => response.data as AllocationCancelReasonsResponse)
        .then(data => data.cancel_reasons)
        .then(setData)
        .finally(() => setLoading(false));
    }
  }, [loading]);

  const load = useCallback(() => {
    setLoading(true);
  }, []);

  return {
    data,
    loading,
    load,
  };
}

export default useAllocationCancelReasons;
