import React, { useState, useCallback, useRef } from 'react';
import XLSX from 'xlsx';
import { Grid, IconButton, Tooltip } from '@material-ui/core';

import { sendPushApi } from '~/services/api';
import Button from '~/components/Button';
import Inputfile from '~/components/Inputfile';
import { useToast } from '~/services/hooks/toast';
import { validCPF } from '~/util/validate';
import InputComponent from '~/components/InputComponent';
import { columnContentToOriginalValue } from '~/util/importFile';
import { Help } from '@material-ui/icons';

const readCsv = XLSX.read;
const sheetToJson = XLSX.utils.sheet_to_json;

interface CustomersTableProps {
  open: boolean;
}

export const CustomersTable = ({ open }: CustomersTableProps) => {
  const [contentFile, setContentFile] = useState('');
  const [fileName, setFileName] = useState('Selecione um arquivo');
  const [loading, setLoading] = useState(false);

  const [errorFile, setErrorFile] = useState('');

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToast();

  const cleanInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  const handleFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setErrorFile('');

      const reader = new FileReader();

      reader.onload = async event => {
        const text = event.target?.result?.toString() ?? '';

        setContentFile(text);
        cleanInput();
      };

      if (e.target.files && e.target.files[0]) {
        reader.readAsText(e.target.files[0]);
        setFileName(e.target.files[0].name);
      } else {
        setFileName('Selecione um arquivo');
        setContentFile('');
        cleanInput();
      }
    },
    [cleanInput],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    if (!contentFile) {
      setLoading(false);
      return addToast({ title: 'Arquivo CSV', type: 'error', description: 'Arquivo não carregado ou vazio' });
    }

    const workbook = columnContentToOriginalValue(readCsv(contentFile, { type: 'binary' }), 'NO_COLUMN');

    const sheetAsJson = sheetToJson<{ __rowNum__: number; CPF: number }>(workbook.Sheets.Sheet1)
      .map(({ __rowNum__, CPF }) => {
        return {
          __rowNum__,
          CPF: String(CPF).padStart(11, '0'),
        };
      })
      .filter(item => validCPF(item.CPF));

    const messages: JSX.Element[] = [];
    if (sheetAsJson.length === 0) {
      messages.push(<p>CSV sem CPF válido</p>);
    }

    if (!title?.trim()) {
      messages.push(<p>Informe o título</p>);
    }

    if (!message?.trim()) {
      messages.push(<p>Informe a mensagem</p>);
    }

    if (messages.length > 0) {
      setLoading(false);
      return addToast({ title: 'Validações', type: 'info', description: messages });
    }

    await sendPushApi({
      destinations: sheetAsJson.map(customer => customer.CPF),
      type: 'CUSTOMER',
      body: message,
      title,
    });

    setFileName('Selecione um arquivo');
    setContentFile('');
    setLoading(false);
    cleanInput();
    setTitle('');
    setMessage('');

    addToast({ title: 'Notificação', type: 'success', description: 'Notificação enviada com sucesso!' });
  }, [addToast, cleanInput, contentFile, message, title]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_S3}/files/customer_example.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!open) {
    return null;
  }

  return (
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid item xs={11}>
        <Inputfile
          accept='.csv'
          id='csvImport'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFile(e)}
          fileName={fileName}
          label='CSV de clientes'
          error={!!errorFile}
          clearFile={() => {
            setFileName('Selecione um arquivo');
            setContentFile('');
            cleanInput();
          }}
          ref={inputRef}
        />
      </Grid>

      <Grid item xs={1} justify='flex-end' style={{ display: 'flex' }}>
        <Tooltip title='CSV Exemplo'>
          <IconButton onClick={handleDownload}>
            <Help />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <InputComponent
          type='text'
          autoFocus
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
          }}
          value={title}
          defaultValue={title}
          label='Título'
        />
      </Grid>

      <Grid item xs={12}>
        <InputComponent
          type='text'
          autoFocus
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMessage(event.target.value);
          }}
          value={message}
          defaultValue={message}
          label='Mensagem'
        />

        <Button filled label='Enviar' onClick={handleSubmit} loading={loading} />
      </Grid>
    </Grid>
  );
};
