import { TextField } from '@material-ui/core';
import React from 'react';

import { CustomAutocomplete } from '~/components/CustomAutocomplete';

interface AssociationAutocompleteProps {
  props: {
    columnDef: {
      lookup: { [key: string]: string };
    };
    value: string;
    onChange: (value: string) => void;
  };
}

export const AssociationAutocomplete = ({ props }: AssociationAutocompleteProps) => {
  const associationsProps = Object.entries(props.columnDef.lookup) as [string, string][];
  const associationCurrent = associationsProps.find(([key]) => key === props.value);

  return (
    <CustomAutocomplete
      id='combo-box-associations'
      options={associationsProps}
      getOptionLabel={([, value]) => value}
      renderInput={params => <TextField {...params} />}
      onChange={(_, newValue) => {
        if (!newValue) {
          return;
        }

        const [key] = newValue;
        props.onChange(key);
      }}
      defaultValue={associationCurrent}
    />
  );
};
