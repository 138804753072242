import { useEffect, useState } from 'react';
import { OrderCostsResponse } from '~/types';
import { loadOrderCosts } from '../api';

export function useOrderCosts(orderId?: string) {
  const [costs, setCosts] = useState<OrderCostsResponse>([]);

  useEffect(() => {
    if (orderId) {
      loadOrderCosts(orderId)
        .then(response => response.data)
        .then(setCosts);
    } else {
      setCosts([]);
    }
  }, [orderId]);

  return costs;
}

export default useOrderCosts;
