const colors = {
  blue: '#00DEF5',
  lightBlue: '#cdd8db',
  darkYellow: '#FEDB00',
  green: '#009C54',
  lightGrey: '#f3f3f3',
  grey: '#6a6c6e',
  white: '#fff',
  black: '#000000',
  red: '#f00',
  darkBlue: '#2e397b',
  picktowBlue: '#2f397c',
  done: '#10cc52',
  strokeColor: '#FF0000',
  backButton: '#497fe5',
  purple: '#7030A0',
  orange: '#FFC000',
  pureRed: '#fe0000',
  pureYellow: '#ffff02',
  pureLightBlue: '#00b0ee',
  pureGreen: '#02af52',
  darkGolden: '#B8860B',
};

/**
 * Calculates the contrast for a given color and returns the best tone that an inside element should have in
 * order to mantain a good contrast/readablity.
 *
 * @param red red color channel of the background
 * @param green green color channel of the background
 * @param blue blue color channel of the background
 * @param threshold the threshold for deciding if the inside element color should be darker or lighter
 * @returns the tone that the inside element shoud use, DARK or LIGHT
 */
export function getContrastForColor(red: number, green: number, blue: number, threshold = 130) {
  const colorBrightness = (red * 299 + green * 587 + blue * 114) / 1000;
  return colorBrightness > threshold ? 'DARK' : 'LIGHT';
}

/**
 * Calculates the contrast for a given color and returns the best tone that an inside element should have in
 * order to mantain a good contrast/readablity.
 *
 * @param hexColor the background color as a hexadecimal string
 * @param threshold the threshold for deciding if the inside element color should be darker or lighter
 * @returns the tone that the inside element shoud use, DARK or LIGHT
 */
export function getContrastForHexColor(hexColor: string, threshold = 130) {
  const rgbColor = hexToRgb(hexColor);
  return getContrastForColor(rgbColor[0], rgbColor[0], rgbColor[0], threshold);
}

interface OpacityColorForBackgroundHexConfig {
  bgHex: string;
  opacityDark?: number;
  opacityLight?: number;
  threshold?: number;
}

/**
 * Generates a RGBA string with a light or dark color depending on the contrast of the background color.
 * If the background is DARK, the returned color will be LIGHTER: rgba(255,255,255,opacityLight).
 * If the background is LIGH, the returned color will be DARKER: rgba(0,0,0,opacityDark).
 *
 * Use case: choose a text color that depends on the background color, being always easy to read by being
 * darker on light colors and lighter on dark colors.]
 *
 * @param bgHex the background color as a hexadecimal string
 * @param opacityDark Opacity for dark case
 * @param opacityLight Opacity for light case
 * @param threshold
 * @returns
 */
export function opacityColorForBackgroundHex({
  bgHex,
  opacityDark = 0.6,
  opacityLight = 0.8,
  threshold = 130,
}: OpacityColorForBackgroundHexConfig) {
  return getContrastForHexColor(bgHex, threshold) === 'DARK'
    ? rgbaString(0, 0, 0, opacityDark)
    : rgbaString(255, 255, 255, opacityLight);
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [255, 255, 255];
}

export function rgbaString(red = 0, green = 0, blue = 0, alpha = 0) {
  const fixBoundariesIfNeeded = (value: number) => Math.min(Math.max(value, 0), 255);
  const formatFloat = (value: number) => value.toFixed(2);
  const formatInt = (value: number) => fixBoundariesIfNeeded(Math.round(value));
  return `rgba(${formatInt(red)},${formatInt(green)},${formatInt(blue)},${formatFloat(alpha)})`;
}

const rgbaColors = {
  blue: (opacity: number) => rgbaString(...hexToRgb(Colors.blue), opacity), // '#00DEF5'
  lightBlue: (opacity: number) => rgbaString(...hexToRgb(Colors.lightBlue), opacity), // '#cdd8db'
  darkYellow: (opacity: number) => rgbaString(...hexToRgb(Colors.darkYellow), opacity), // '#FEDB00'
  green: (opacity: number) => rgbaString(...hexToRgb(Colors.green), opacity), // '#009C54'
  lightGrey: (opacity: number) => rgbaString(...hexToRgb(Colors.lightGrey), opacity), // '#f3f3f3'
  grey: (opacity: number) => rgbaString(...hexToRgb(Colors.grey), opacity), // '#6a6c6e'
  white: (opacity: number) => rgbaString(...hexToRgb(Colors.white), opacity), // '#fff'
  black: (opacity: number) => rgbaString(...hexToRgb(Colors.black), opacity), // '#000'
  red: (opacity: number) => rgbaString(...hexToRgb(Colors.red), opacity), // '#f00'
  darkBlue: (opacity: number) => rgbaString(...hexToRgb(Colors.darkBlue), opacity), // '#2e397b'
  done: (opacity: number) => rgbaString(...hexToRgb(Colors.done), opacity), // '#10cc52'
  strokeColor: (opacity: number) => rgbaString(...hexToRgb(Colors.strokeColor), opacity), // '#FF0000'
  backButton: (opacity: number) => rgbaString(...hexToRgb(Colors.backButton), opacity), // '#497fe5'
  picktowBlue: (opacity: number) => rgbaString(...hexToRgb(Colors.picktowBlue), opacity), // '#2f397c'
  purple: (opacity: number) => rgbaString(...hexToRgb(Colors.purple), opacity), // '#7030A0'
  orange: (opacity: number) => rgbaString(...hexToRgb(Colors.orange), opacity), // '#FFC000'
  darkGolden: (opacity: number) => rgbaString(...hexToRgb(Colors.darkGolden), opacity), // '#B8860B'
};

export const RgbaColors = {
  primary: rgbaColors.darkBlue,
  secondary: rgbaColors.blue,
  textBody: rgbaColors.lightGrey,
  textFeatured: rgbaColors.darkBlue,
  textHighlight: rgbaColors.darkYellow,
  error: rgbaColors.red,
  textLink: rgbaColors.blue,
  ...rgbaColors,
};

/**
 * Exports the application colors.
 *
 * @example
 * // Using HEX color
 * Colors.primary // Returns #00DEF5
 *
 * @example
 * // Using RGBA color
 * Colors.rgba.primary(.5) // Returns rgb(0,222,245,0.5)
 *
 */
const Colors = {
  primary: colors.darkBlue,
  secondary: colors.blue,
  textBody: colors.lightGrey,
  textFeatured: colors.darkBlue,
  textHighlight: colors.darkYellow,
  error: colors.red,
  textLink: colors.blue,
  rgba: { ...RgbaColors },
  ...colors,
};

export default Colors;
