import { useState, useEffect, useCallback } from 'react';
import { loadCancelReasons } from '../api';
import { CancellationReasonsResponse } from '~/types';

export function useCancellationReasons() {
  const [data, setData] = useState<CancellationReasonsResponse>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      loadCancelReasons()
        .then(response => response.data as CancellationReasonsResponse)
        .then(D => {
          const newD = D.filter(d => d.penalty_charge !== undefined);
          const meta = D.filter(d => d.penalty_charge === undefined);
          meta.forEach(d => newD.push({ ...d, penalty_charge: true }, { ...d, penalty_charge: false }));
          return newD;
        })
        .then(setData)
        .finally(() => setLoading(false));
    }
  }, [loading]);

  const load = useCallback(() => {
    setLoading(true);
  }, []);

  return {
    data,
    loading,
    load,
  };
}

export default useCancellationReasons;
