import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

export const ActionContainer = styled(Grid).attrs({ container: true, justify: 'flex-end' })`
  margin-top: 20px;
`;

export const ActionButton = styled(Button).attrs({ variant: 'contained' })<{ $success?: boolean; $danger?: boolean }>`
  background-color: ${props =>
    props.$success ? props.theme.palette.success.main : props.$danger ? props.theme.palette.danger.main : undefined};
  color: ${props => (props.$success || props.$danger ? 'white' : undefined)};

  margin-right: 10px;

  :first-child {
    margin-right: 20px;
  }

  :last-child {
    margin-right: 0px;
  }

  :hover {
    background-color: ${props =>
      props.$success
        ? props.theme.palette.success.light
        : props.$danger
        ? props.theme.palette.danger.light
        : undefined};
    color: ${props => (props.$success || props.$danger ? 'white' : undefined)};
  }

  & span {
    font-weight: bold;
  }
`;
