import { Backdrop } from '@material-ui/core';
import React from 'react';
import { ImageDropzone } from '../Dropzone';
import { StyledModal } from './styles';

interface DropzoneModalProps {
  type: string;
  opportunityId?: string;
  visible: boolean;
  onClose: () => void;
}

export const DropzoneModal: React.FC<DropzoneModalProps> = ({ visible, onClose, opportunityId, type }) => {
  return (
    <StyledModal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={visible}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ImageDropzone onClose={onClose} opportunityId={opportunityId ? opportunityId : ''} type={type} />
    </StyledModal>
  );
};
