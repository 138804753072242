import { Grid } from '@material-ui/core';
import React from 'react';
import { OrderDetailsResponse, OrderDetailsReason } from '~/types';
import { Info } from './components';
import { Info as InfoCard } from '../Card/components';
import { Container, Content, Header } from './styles';

export interface PhotosCardProps {
  title: string;
  data: [
    string, // title
    string?, // data
  ][];
  details: OrderDetailsResponse;
  type: string;
  button?: any;
}

export function PhotosCard(props: PhotosCardProps) {
  const reasonSkipPictures = props.details.filter(item => item.type === props.type);
  let reason: OrderDetailsReason = {} as OrderDetailsReason;

  if (reasonSkipPictures.length > 0) {
    reason = JSON.parse(reasonSkipPictures[0].value);
  }

  return (
    <Container>
      <Header title={props.title} action={props.button} />
      <Content>
        <Grid container spacing={3}>
          {props.data.length ? (
            props.data.map((d, index) => <Info key={`${index}`} title={d[0]} data={d[1]} />)
          ) : (
            <InfoCard title={reason.reason} />
          )}
        </Grid>
      </Content>
    </Container>
  );
}

export default PhotosCard;
