/**
 * This function generates a query-param-like string from an Object
 * - Null or undefined values are removed, e.g. {'param': undefined, 'param1': 'hello'} will generate `param1=hello`
 * - Lists are parsed as several params, e.g. {'param':['AAA', 'BBB', undfined, 'CCC']} will generate `param=aaa&param=bbb&param=ccc`
 * @param params A one-depth object that can contain arrays
 */
export function objectToQueryParams(params?: Object) {

  const isValid = (element?: any) => element !== undefined && element !== null
  const isObjectEmpty = (obj: Object) => Object.keys(obj).length === 0
  const isValidObject = (obj?: Object) => isValid(obj) && !isObjectEmpty(obj || {})

  const toParamString = (key: string, value: string | number) =>
    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  const toArrayParamString = (key: string, arr: any[]) => arr
		.filter(isValid)
    .map(element => toParamString(key, element))
    .join('&')

  if (isValidObject(params)) {
    return Object.entries(params ?? {})
    .filter(([_, value]) => isValid(value))
    .map(([key, value]) => Array.isArray(value) ?
        toArrayParamString(key, value) : toParamString(key, value)
    )
    .join('&')
  }
  return ''
}

/**
 * Given an addres and a query param Object, it parses the objet to query-params string
 * and appends it to the end of the address including a `?` charachter if needed.
 * @param address The base addres to have query param appended
 * @param queryParams A one-depth object that can contain arrays
 */
export function appendQueryParamsTo(address: string, queryParams?: Object) {
  const params = objectToQueryParams(queryParams)
  return `${address}${params && "?"}${params}`
}