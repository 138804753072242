import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { loginRequest } from '~/store/ducks/auth';

import Button from '~/components/Button';

import { validEmail } from '~/util/validate';

import { Input, Link } from './styles';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [secureText, setSecureText] = useState(true);
  const { loading, error } = useSelector(state => state.auth);

  useEffect(() => {
    if (error && error !== {}) {
      setUserError({ message: 'Usuário/senha inválido' });
      setPassError({ message: '' });
    }
  }, [error]);

  const dispatch = useDispatch();

  function handleSubmit(event) {
    event.preventDefault();
    setUserError(null);
    setPassError(null);

    if (!username.trim()) {
      return setUserError({ message: 'E-mail não informado!' });
    }

    if (!validEmail(username)) {
      return setUserError({ message: 'E-mail inválido' });
    }

    if (!password.trim()) {
      return setPassError({ message: 'Senha não informada!' });
    }
    dispatch(loginRequest(username.trim().toLowerCase(), password));
    return true;
  }

  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <Input
        name='username'
        label='E-mail'
        value={username}
        onChange={e => setUsername(e.target.value)}
        error={userError}
        autoFocus
      />
      <Input
        name='password'
        label='Senha'
        type={secureText ? 'password' : 'text'}
        value={password}
        onChange={e => setPassword(e.target.value)}
        error={passError}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => setSecureText(secure => !secure)}
              style={{ cursor: 'pointer', color: '#fff' }}
            >
              {secureText ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />

      <Button type='submit' filled label='Entrar' loading={loading} />

      <Link to='/forgot-password'>Esqueci minha senha</Link>
    </form>
  );
}
