import React from 'react';
import { Dialog, useTheme, useMediaQuery, DialogActions, Button, DialogProps, DialogTitle } from '@material-ui/core';

interface VehicleConfirmProps extends DialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmSendToManualAllocation: React.FC<VehicleConfirmProps> = ({
  onConfirm,
  onCancel,
  ...props
}: VehicleConfirmProps) => {
  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} onClose={_ => onCancel()} {...props}>
      <DialogTitle>Tem certeza de que deseja enviar essa oportunidade para Alocação Manual?</DialogTitle>

      <DialogActions>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onCancel()}
        >
          Cancelar
        </Button>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onConfirm()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSendToManualAllocation;
