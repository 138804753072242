import React, { useState, useEffect, useCallback } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { useCustomer } from '~/services/hooks';

import { formatCPF_CNPJ, formatLicensePlate } from '~/util/format';
import { DataTree, DataGroup, TextDataItem, DateDataItem, ModalDeleteVehicle } from '~/components';
import useServices from '~/services/hooks/useServices';
import { useBenefit } from '~/services/hooks/useBenefit';
import { BenefitResponse, CustomerResponse, CustomerVehicle } from '~/types';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircleOutline, Edit, HistoryOutlined } from '@material-ui/icons';

import {
  FipeModel,
  VehicleInitialData,
  VehicleRegistration,
  SelectedBenefits,
  VehicleStatus,
} from '../NewOpportunity/pages/OpportunityVehicles/components/VehicleRegistration';
import Modal from '~/components/ModalNewVehicle';
import {
  changeCustomerApi,
  changeVehicleApi,
  createCustomerVehicleApi,
  createCustomerVehicleBenefitsApi,
  disableCustomerApi,
  enableCustomerApi,
} from '~/services/api';
import { BenefitsRegistration } from '../NewOpportunity/pages/OpportunityVehicles/components/BenefitsRegistration';
import { clearBenefit } from '~/store/ducks/customerBenefit';
import { getKeyByValue } from '~/util/objectUtil';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { ContainerModal, Divider, TitleModal } from '../NewOpportunity/styles';
import { NewUser } from '../NewOpportunity';
import { ModalOpportunities } from '../NewOpportunity/pages/OpportunityAdditionalInformation/components/ModalOpportunities';
import Loading from '~/components/Loading';
import { Title } from '~/components/Title';

export interface CustomerManagerParams {}

export interface CustomerManagerLocationState {}

export type CustomerManagerProps = RouteChildrenProps<CustomerManagerParams, CustomerManagerLocationState>;

export type VehicleBenefit = {
  associationId: string;
  vehicleId: string;
};

interface ChangeUser extends NewUser {
  email?: string;
  email_error?: string;
  enabled?: boolean;
}

export function CustomerManager() {
  const [cpfCNPJ, setCpfCNPJ] = useState<string>('');
  const [openResetConfirmation, setOpenResetConfirmation] = useState(false);
  const [modalDeleteVehicle, setModalDeleteVehicle] = useState(false);
  const [vehicleId, setVehicleId] = useState<string>('');
  const [modalVehicleIsOpen, setModalVehicleIsOpen] = useState(false);
  const [fipeModel, setFipeModel] = useState<FipeModel>({} as FipeModel);
  const [selectedBenefits, setSelectedBenefits] = useState<SelectedBenefits>({});
  const [step, setStep] = useState(0);
  const [vehicleBenefits, setVehicleBenefits] = useState<VehicleBenefit>();
  const [vehicleInitialData, setVehicleInitialData] = useState<VehicleInitialData>();
  const [benefitsInitialData, setBenefitsInitialData] = useState<BenefitResponse[]>();
  const [modalChangeCustomerIsOpen, setModalChangeCustomerIsOpen] = useState(false);
  const [changeCustomer, setChangeCustomer] = useState<ChangeUser>({} as ChangeUser);
  const [loadingChangeCustomer, setLoadingChangeCustomer] = useState(false);
  const [modalOpportunitiesOpen, setModalOpportunitiesOpen] = useState(false);
  const [vinOpportunitiesFilter, setVinOpportunitiesFilter] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [customers, setCustomers] = useState<CustomerResponse[]>();
  const [modalCustomersOpen, setModalCustomersOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const customer = useCustomer(cpfCNPJ);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const getCustomerByLicensePlate = customer.getCustomerByLicensePlate;

  const { services } = useServices();
  useBenefit(services, customer.data?.vehicles);
  const benefits = useSelector(state => state.customerBenefit);

  const filterBenefitsByVehicleId = (id: string) => {
    return benefits.vehicleBenefits.filter(b => b.id === id).map(obj => obj.benefits);
  };

  const deleteCustomerVehicle = (vehicleId: string) => () => {
    setVehicleId(vehicleId);
    setModalDeleteVehicle(true);
  };

  const handleConfirmDeleteVehicle = useCallback(async () => {
    try {
      await customer.deleteCustomerVehicle(vehicleId);
    } finally {
      setModalDeleteVehicle(false);
      dispatch(clearBenefit());
    }
  }, [customer, vehicleId, dispatch]);

  const handleCancelModalVehicle = () => {
    setFipeModel({} as FipeModel);
    setSelectedBenefits({} as SelectedBenefits);
    setModalVehicleIsOpen(false);
  };

  const handleRegisterVehicle = async () => {
    const errors: FipeModel = {};

    if (!fipeModel.association?.code?.trim()) {
      errors.association_error = true;
    }

    if (!fipeModel.category?.description.trim()) {
      errors.category_error = true;
    }

    if (!fipeModel.manufacturer?.name.trim()) {
      errors.manufacturer_error = true;
    }

    if (!fipeModel.vehicle?.name.trim()) {
      errors.vehicle_error = true;
    }

    if (!fipeModel.model?.name.trim()) {
      errors.model_error = true;
    }

    if ((fipeModel.license_plate?.length || 0) < 7) {
      errors.license_plate_error = true;
    }

    if (!fipeModel.color?.trim()) {
      errors.color_error = true;
    }

    if (!fipeModel.city?.trim()) {
      errors.city_error = true;
    }

    if (!fipeModel.state?.trim()) {
      errors.state_error = true;
    }

    if (Object.keys(fipeModel.status || {}).length === 0 && vehicleInitialData) {
      errors.status_error = true;
    }

    setFipeModel({ ...fipeModel, ...errors });

    if (Object.keys(errors).length !== 0) {
      return;
    }

    const fipe_data = {
      model_id: fipeModel.vehicle!.id,
      manufacture_id: fipeModel.manufacturer!.id,
      model_year_id: fipeModel.model!.id,
    };

    const data = {
      association_id: fipeModel.association?.id,
      social_security: cpfCNPJ,
      vehicle_customer_type_id: fipeModel.category?.id,
      license_plate: fipeModel.license_plate,
      manufacture: fipeModel.manufacturer?.name.toUpperCase(),
      model: fipeModel.vehicle?.name.toUpperCase(),
      model_year: fipeModel.model?.name,
      color: fipeModel.color,
      vin: fipeModel.vin || `${cpfCNPJ}${fipeModel.license_plate?.replace(/-/g, '')}`,
      state: fipeModel.state?.toUpperCase(),
      city: fipeModel.city?.toUpperCase(),
      status: fipeModel.status
        ? getKeyByValue(VehicleStatus, fipeModel.status)
        : getKeyByValue(VehicleStatus, VehicleStatus.ACTIVE),
      fipe_data: JSON.stringify(fipe_data),
    };

    try {
      !vehicleInitialData
        ? await createCustomerVehicleApi(data)
        : await changeVehicleApi(vehicleInitialData.vehicleId, data);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao cadastrar o veículo');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setModalVehicleIsOpen(false);
      dispatch(clearBenefit());
      customer.refresh();
    }
  };

  const handleCreateBenefits = async () => {
    try {
      if (!vehicleBenefits?.vehicleId) {
        return;
      }

      await createCustomerVehicleBenefitsApi(vehicleBenefits?.vehicleId, Object.values(selectedBenefits));
      dispatch(clearBenefit());
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao criar o benefício');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setModalVehicleIsOpen(false);
      dispatch(clearBenefit());
      customer.refresh();
    }
  };

  const handleChangeVehicle = (vehicle: CustomerVehicle) => () => {
    setStep(0);
    setVehicleInitialData({
      vehicleId: vehicle.id,
      associationId: vehicle.association_id,
      categoryId: vehicle.vehicle_customer_type_id,
      manufacturerDescription: vehicle.manufacture,
      vehicleDescription: vehicle.model,
      modelDescription: vehicle.model_year,
      licensePlate: vehicle.license_plate,
      color: vehicle.color,
      vin: vehicle.vin,
      state: vehicle.state.toUpperCase(),
      city: vehicle.city.toUpperCase(),
      status: vehicle.status,
    });
    setModalVehicleIsOpen(true);
  };

  const handleChangeBenefits = (vehicle: CustomerVehicle) => () => {
    setStep(1);
    setSelectedBenefits({} as SelectedBenefits);
    const vehicleBenefits = filterBenefitsByVehicleId(vehicle.id);
    setBenefitsInitialData(vehicleBenefits);
    setVehicleBenefits({ associationId: vehicle.association_id, vehicleId: vehicle.id });
    setModalVehicleIsOpen(true);
  };

  const handleNewVehicle = () => {
    setStep(0);
    setVehicleInitialData(undefined);
    setFipeModel({} as FipeModel);
    setModalVehicleIsOpen(true);
  };

  const handleToggleModalChangeCustomer = () => {
    setChangeCustomer({
      family_name: customer.data?.family_name || '',
      given_name: customer.data?.given_name || '',
      email: customer.data?.email,
      enabled: customer.data?.enabled,
    });
    setModalChangeCustomerIsOpen(prev => !prev);
  };

  const handleToggleFlagCustomer = async () => {
    try {
      if (!customer.data) {
        return;
      }

      setLoadingChangeCustomer(true);
      if (changeCustomer.enabled) {
        await disableCustomerApi(customer.data.id);
        return;
      }
      await enableCustomerApi(customer.data.id);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao alterar o cliente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      customer.refresh();
      setLoadingChangeCustomer(false);
      setModalChangeCustomerIsOpen(prev => !prev);
    }
  };

  const handleChangeCustomer = async () => {
    setLoadingChangeCustomer(true);
    try {
      await changeCustomerApi({
        username: customer.data?.id,
        locale: customer.data?.locale,
        zoneinfo: customer.data?.zoneinfo,
        given_name: changeCustomer.given_name,
        family_name: changeCustomer.family_name,
        email: changeCustomer.email,
        enabled: changeCustomer.enabled,
      });
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao alterar o cliente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoadingChangeCustomer(false);
      setModalChangeCustomerIsOpen(false);
      customer.refresh();
    }
  };

  const handleOpenModalOpportunities = (vin: string) => () => {
    setVinOpportunitiesFilter(vin);
    setModalOpportunitiesOpen(true);
  };

  const handleCancelModalLicensePlate = () => {
    setModalCustomersOpen(false);
  };

  const handleCustomerSelected = (customerId: string) => {
    setCpfCNPJ(customerId);
    setModalCustomersOpen(false);
  };

  useEffect(() => {
    if (!cpfCNPJ || (cpfCNPJ.length !== 11 && cpfCNPJ.length !== 14)) {
      customer.clear();
    }
  }, [cpfCNPJ, customer]);

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        setLoading(true);
        const data = await getCustomerByLicensePlate(licensePlate);

        setCustomers(data);

        if (!data || data.length === 0) {
          return;
        }

        if (data.length > 1) {
          setModalCustomersOpen(true);
          return;
        }

        setCpfCNPJ(data[0].id);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar cliente pela placa');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    getCustomerData();
  }, [addToast, getCustomerByLicensePlate, licensePlate]);

  return (
    <>
      <Title marginBottom={20}>Gestão de Clientes</Title>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          id='cpf-CNPJ-field'
          label='CPF ou CNPJ'
          value={formatCPF_CNPJ(cpfCNPJ)}
          onChange={({ target: { value } }) => setCpfCNPJ(value.split(/\D+/).join('').slice(0, 14))}
        />
        <Divider width={16} />

        <TextField
          id='license-plate-field'
          label='Placa'
          value={licensePlate}
          onChange={({ target: { value } }) => setLicensePlate(formatLicensePlate(value))}
          inputProps={{ maxLength: 7 }}
        />
      </div>

      {(loading || customer.loading) && <Loading />}

      {customer.data && (
        <>
          <DataTree>
            <DataGroup
              title='Detalhes Pessoais'
              rightButton={
                <Button onClick={handleToggleModalChangeCustomer}>
                  <Edit />
                </Button>
              }
            >
              <TextDataItem
                title='Ativo'
                value={customer.data?.enabled === true ? 'Sim' : customer.data?.enabled === false ? 'Não' : ''}
              />
              <TextDataItem title='Nome' value={customer.data?.given_name} />
              <TextDataItem title='Sobrenome' value={customer.data?.family_name} />
              <TextDataItem title='CPF/CNPJ' value={formatCPF_CNPJ(customer.data?.id || '')} />
              <TextDataItem title='E-mail' value={customer.data?.email} />
            </DataGroup>

            <DataGroup
              title='Detalhes de Veículos'
              rightButton={
                <IconButton aria-label='novo veículo' onClick={handleNewVehicle}>
                  <AddCircleOutline />
                  <Typography style={{ marginLeft: 8 }}>Novo veículo</Typography>
                </IconButton>
              }
            >
              {customer.data ? (
                customer.data?.vehicles.map(v => (
                  <DataGroup
                    key={v.license_plate}
                    title={v.license_plate}
                    deletedFunction={deleteCustomerVehicle(v.id)}
                    rightButton={
                      <>
                        <Tooltip title='Histórico de solicitações' arrow>
                          <Button aria-label='Histórico de solicitações' onClick={handleOpenModalOpportunities(v.vin)}>
                            <HistoryOutlined />
                          </Button>
                        </Tooltip>

                        <Button onClick={handleChangeVehicle(v)}>
                          <Edit />
                        </Button>
                      </>
                    }
                  >
                    <DateDataItem title='Última Atualização' value={v.updated_at * 1000} />
                    <TextDataItem title='Empresa' value={v.association_trading_name} />
                    <TextDataItem title='Situação' value={VehicleStatus[v.status] || v.status} />
                    <TextDataItem title='Placa' value={v.license_plate} />
                    <TextDataItem title='Chassi' value={v.vin} />
                    <TextDataItem title='Tipo' value={v.vehicle_customer_type_description} />
                    <TextDataItem title='Cor' value={v.color} />
                    <TextDataItem title='Marca' value={v.manufacture} />
                    <TextDataItem title='Modelo' value={v.model} />
                    <TextDataItem title='Ano' value={v.model_year} />
                    <TextDataItem title='Estado' value={v.state} />
                    <TextDataItem title='Cidade' value={v.city} />
                    <DataGroup
                      open={false}
                      key={`${v.license_plate}Benefits`}
                      title='Benefícios'
                      rightButton={
                        <IconButton aria-label='alterar benefício' onClick={handleChangeBenefits(v)}>
                          <AddCircleOutline />
                          <Typography style={{ marginLeft: 8 }}>Alterar benefícios</Typography>
                        </IconButton>
                      }
                    >
                      {filterBenefitsByVehicleId(v.id).map(benefit => (
                        <DataGroup
                          variant={true}
                          open={false}
                          key={`${v.license_plate}Benefit${benefit.id}`}
                          title={benefit.description}
                        >
                          <TextDataItem title='Código' value={benefit.code} fontSize={14} variant={true} />
                          <TextDataItem title='Período' value={benefit.period} fontSize={14} variant={true} />
                          <TextDataItem
                            title='Área de Cobertura'
                            value={`${benefit.coverage_km?.toString()} km`}
                            fontSize={14}
                            variant={true}
                          />
                          <TextDataItem
                            title='Quantidade Fornecida'
                            value={benefit.quantity?.toString()}
                            fontSize={14}
                            variant={true}
                          />
                          <TextDataItem
                            title='Quantidade Consumida'
                            value={benefit.quota_expended?.toString()}
                            fontSize={14}
                            variant={true}
                          />
                        </DataGroup>
                      ))}
                    </DataGroup>
                  </DataGroup>
                ))
              ) : (
                <div />
              )}
            </DataGroup>
          </DataTree>

          <div style={{ marginTop: 30 }}>
            <Button
              variant='contained'
              style={{ marginRight: 30 }}
              onClick={() => {
                (customer.resetEmail() as Promise<void>)
                  .then(() => {
                    if (customer.data && !customer.data.vehicles.length) customer.erase();
                  })
                  .then(() => setOpenResetConfirmation(true));
              }}
            >
              REDEFINIR CONTA
            </Button>
          </div>

          <Dialog open={openResetConfirmation} onClose={() => setOpenResetConfirmation(false)}>
            <DialogTitle>Conta Redefinida com Sucesso!</DialogTitle>
            <DialogActions>
              <Button variant='contained' color='primary' onClick={() => setOpenResetConfirmation(false)}>
                Obrigado
              </Button>
            </DialogActions>
          </Dialog>

          <ModalDeleteVehicle
            onConfirm={handleConfirmDeleteVehicle}
            onCancel={() => {
              setModalDeleteVehicle(false);
            }}
            open={modalDeleteVehicle}
          />

          <Modal
            onConfirm={step === 0 ? handleRegisterVehicle : handleCreateBenefits}
            open={modalVehicleIsOpen}
            buttonPositive='Salvar'
            haveCustomButton
            buttonCustom='Cancelar'
            onCustom={handleCancelModalVehicle}
          >
            {step === 0 ? (
              <VehicleRegistration
                modalVehicleIsOpen={modalVehicleIsOpen}
                fipeModel={fipeModel}
                setFipeModel={setFipeModel}
                initialData={vehicleInitialData}
                title={vehicleInitialData ? 'Editar Veículo' : undefined}
                showStatus={vehicleInitialData ? true : false}
              />
            ) : (
              <BenefitsRegistration
                associationId={vehicleBenefits?.associationId || ''}
                selectedBenefits={selectedBenefits}
                setSelectedBenefits={setSelectedBenefits}
                initialData={benefitsInitialData}
              />
            )}
          </Modal>

          <Modal
            onConfirm={handleChangeCustomer}
            open={modalChangeCustomerIsOpen}
            buttonPositive='Alterar'
            haveCustomButton
            buttonCustom={changeCustomer.enabled ? 'Desativar' : 'Ativar'}
            onCustom={handleToggleFlagCustomer}
            onCancel={handleToggleModalChangeCustomer}
            loadingConfirmButton={loadingChangeCustomer}
            loadingCustomButton={loadingChangeCustomer}
            disabledCancelButton={loadingChangeCustomer}
            question
          >
            <ContainerModal>
              <TitleModal>Alterar o cliente</TitleModal>
              <Divider height={16} />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  id='cpf-CNPJ-changeUser-field'
                  label='CPF ou CNPJ'
                  value={formatCPF_CNPJ(customer.data.id)}
                  disabled
                />
                <Divider height={16} />

                <TextField
                  id='giveName-changeUser-field'
                  label='Nome'
                  value={changeCustomer.given_name}
                  onChange={({ target: { value } }) =>
                    setChangeCustomer({
                      ...changeCustomer,
                      given_name: String(value).toUpperCase(),
                      given_name_error: '',
                    })
                  }
                  error={!!changeCustomer.given_name_error}
                  helperText={changeCustomer.given_name_error}
                />

                <TextField
                  id='lastName-changeUser-field'
                  label='Sobrenome'
                  value={changeCustomer.family_name}
                  onChange={({ target: { value } }) =>
                    setChangeCustomer({
                      ...changeCustomer,
                      family_name: String(value).toUpperCase(),
                      family_name_error: '',
                    })
                  }
                  error={!!changeCustomer.family_name_error}
                  helperText={changeCustomer.family_name_error}
                />

                <TextField
                  id='email-changeUser-field'
                  label='E-mail'
                  value={changeCustomer.email}
                  onChange={({ target: { value } }) =>
                    setChangeCustomer({ ...changeCustomer, email: String(value).toLowerCase(), email_error: '' })
                  }
                  error={!!changeCustomer.family_name_error}
                  helperText={changeCustomer.family_name_error}
                />
              </div>
            </ContainerModal>
          </Modal>
        </>
      )}

      <ModalOpportunities
        open={modalOpportunitiesOpen}
        closeModal={() => setModalOpportunitiesOpen(false)}
        vin={vinOpportunitiesFilter}
      />

      <Modal
        onConfirm={handleCancelModalLicensePlate}
        open={modalCustomersOpen}
        buttonPositive='Cancelar'
        loadingConfirmButton={loadingChangeCustomer}
        disabledCustomButton={loadingChangeCustomer}
        fullWidth
        maxWidth='md'
      >
        <TitleModal>Selecione um cliente</TitleModal>
        <Divider height={15} />

        <List component='nav' aria-label='main mailbox folders'>
          {customers?.map(customer => (
            <ListItem key={customer.id} button onClick={() => handleCustomerSelected(customer.id)}>
              <ListItemText primary={`${formatCPF_CNPJ(customer.id)} - ${customer.name}`} />
            </ListItem>
          ))}
        </List>
      </Modal>
    </>
  );
}

export default CustomerManager;
