import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dropzone,
  Container,
  FilesContainer,
  Wrapper,
  ButtonContainer,
  FilesWrapper,
  StyledSelect,
  DropzoneButton,
} from './styles';
import { uploadFileToOrderAPI } from '~/services/api';
import { MenuItem } from '@material-ui/core';
import { onUpload } from '~/store/ducks/uploadPhoto';
import { useDispatch } from 'react-redux';

interface DropzoneProps {
  opportunityId: string;
  onClose: () => void;
  type: string;
}

export const ImageDropzone: React.FC<DropzoneProps> = ({ onClose, opportunityId, type }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFilesToUpload] = useState<any[]>([]);
  const [documentType, setDocumentType] = useState(type === 'CHECKOUT' ? 'CHECKOUT_IMG_FRONT' : 'CHECKIN_IMG_FRONT');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.jpg'] },
    maxFiles: 1,
  });

  const handleDocumentType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDocumentType(event.target.value as string);
  };

  const handlePhotoUpload = useCallback(() => {
    dispatch(onUpload());
  }, [dispatch]);

  const createImage = useCallback(async () => {
    setLoading(true);

    try {
      const photoData = new FormData();
      photoData.append('order_id', opportunityId);
      photoData.append('document_type', documentType);
      photoData.append('file', fileToUpload[0]);
      await uploadFileToOrderAPI(photoData);
      handlePhotoUpload();
    } catch (err) {
      console.error('Erro ao enviar as fotos');
    }
    setLoading(false);
  }, [opportunityId, documentType, fileToUpload, handlePhotoUpload]);

  const handleSubmit = () => {
    createImage();
    onClose();
  };

  useEffect(() => {
    setFilesToUpload(acceptedFiles);
  }, [acceptedFiles]);

  const files = acceptedFiles.map(file => <li key={file.name}>{file.name}</li>);

  return (
    <>
      <Wrapper>
        {type === 'CHECKOUT' ? (
          <StyledSelect value={documentType} onChange={handleDocumentType}>
            <MenuItem value='CHECKOUT_IMG_FRONT'>Frontal</MenuItem>
            <MenuItem value='CHECKOUT_IMG_BOTTOM'>Traseira</MenuItem>
            <MenuItem value='CHECKOUT_IMG_LEFT_SIDE'>Lateral Esquerda</MenuItem>
            <MenuItem value='CHECKOUT_IMG_RIGHT_SIDE'>Lateral Direita</MenuItem>
            <MenuItem value='CHECKOUT_IMG_WINCHED'>Veículo Guichado</MenuItem>
          </StyledSelect>
        ) : (
          <StyledSelect value={documentType} onChange={handleDocumentType}>
            <MenuItem value='CHECKIN_IMG_FRONT'>Frontal</MenuItem>
            <MenuItem value='CHECKIN_IMG_BOTTOM'>Traseira</MenuItem>
            <MenuItem value='CHECKIN_IMG_LEFT_SIDE'>Lateral Esquerda</MenuItem>
            <MenuItem value='CHECKIN_IMG_RIGHT_SIDE'>Lateral Direita</MenuItem>
            <MenuItem value='CHECKIN_IMG_WINCHED'>Veículo Guichado</MenuItem>
          </StyledSelect>
        )}
        <Container {...getRootProps()}>
          <Dropzone>
            <input {...getInputProps()} />
            {'Selecione o tipo da foto e insira aqui'}
          </Dropzone>
        </Container>
        <FilesWrapper>
          <FilesContainer>
            <ul>{files}</ul>
          </FilesContainer>
        </FilesWrapper>
        <ButtonContainer>
          <DropzoneButton type='text' filled label='Enviar' loding={loading} onClick={() => handleSubmit()} />
        </ButtonContainer>
      </Wrapper>
    </>
  );
};
