import { useState, useEffect } from 'react';
import { loadOrdersFromContractor } from '../api';
import { OpportunityStatusRequest, OpportunityResponse } from '../../types';

export function useOrdersFromContractor(contractorId?: string, statuses?: OpportunityStatusRequest[]) {
  const [orders, setOrders] = useState<OpportunityResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contractorId) {
      setLoading(true);
      loadOrdersFromContractor(contractorId, statuses)
        .then(response => response.data as OpportunityResponse[])
        .then(setOrders)
        .finally(() => setLoading(false));
    } else {
      setOrders([]);
    }
  }, [contractorId, statuses]);

  return {
    orders,
    loading,
    setOrders
  };
}
