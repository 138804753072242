import React, { useState, forwardRef } from 'react';
import { Slide, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from '@material-ui/core';

const TransitionLeft = forwardRef((props, ref) => <Slide {...props} ref={ref} direction='left' />);

const TransitionUp = forwardRef((props, ref) => <Slide {...props} ref={ref} direction='up' />);

const TransitionRight = forwardRef((props, ref) => <Slide {...props} ref={ref} direction='right' />);

const TransitionDown = forwardRef((props, ref) => <Slide {...props} ref={ref} direction='down' />);

export default function DialogComponent({
  open,
  onClose,
  title,
  message,
  cancelClick,
  confirmedClick,
  transition = 'up',
}) {
  const transitionOptions = {
    left: TransitionLeft,
    up: TransitionUp,
    right: TransitionRight,
    down: TransitionDown,
  };

  const [directionTransition] = useState(() => transitionOptions[transition]);

  return (
    <Dialog
      open={open}
      TransitionComponent={directionTransition}
      keepMounted
      onClose={onClose}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClick} color='primary'>
          Cancelar
        </Button>
        <Button onClick={confirmedClick} color='primary'>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
