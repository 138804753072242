import React from 'react';

import { Grid } from '@material-ui/core';
import { Title, Data, Code } from './styles';
import CopyableToClipboard from '~/components/Copyable/';

export interface InfoProps {
  title: string;
  data?: string;
  copyable?: boolean;
  code?: boolean;
  hasWhiteSpace?: boolean;
}

function Value({
  children,
  empty = false,
  hasWhiteSpace,
}: {
  children?: any;
  empty?: boolean;
  hasWhiteSpace?: boolean;
}) {
  if (empty) {
    return (
      <Data style={{ opacity: '.5' }} hasWhiteSpace={hasWhiteSpace}>
        <em>Sem dados</em>
      </Data>
    );
  }
  return <Data hasWhiteSpace={hasWhiteSpace}>{children}</Data>;
}

function CopyableValue({
  children,
  code,
  hasWhiteSpace,
}: {
  children?: string;
  code?: boolean;
  hasWhiteSpace?: boolean;
}) {
  return (
    <CopyableToClipboard textGenerator={() => children?.toString() ?? 'Sem dados'}>
      <Value empty={!children} hasWhiteSpace={hasWhiteSpace}>
        {code ? <Code>{children}</Code> : <span>{children}</span>}
      </Value>
    </CopyableToClipboard>
  );
}

export function Info({ title, data, copyable, code = false, hasWhiteSpace }: InfoProps) {
  return (
    <Grid
      container
      style={{ flex: 1 }}
      item
      direction='row'
      justify='flex-start'
      alignContent='flex-start'
      alignItems='center'
    >
      <Grid item>
        <Title>{title}</Title>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        {copyable && data ? (
          <CopyableValue code={code} hasWhiteSpace={hasWhiteSpace}>
            {data}
          </CopyableValue>
        ) : (
          <Value empty={!data} hasWhiteSpace={hasWhiteSpace}>
            {data}
          </Value>
        )}
      </Grid>
    </Grid>
  );
}

export function NoteInfo({ title, data }: InfoProps) {
  return (
    <Grid
      container
      style={{ flex: 1 }}
      item
      direction='row'
      justify='flex-start'
      alignContent='flex-start'
      alignItems='center'
    >
      <Grid item>
        <Title>{title}</Title>
      </Grid>
      <Grid item style={{ flexGrow: 1, fontSize: 20, padding: 10, textAlign: 'justify' }}>
        {data}
      </Grid>
    </Grid>
  );
}

export default Info;
