import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';

import { Title } from '~/components/Title';
import { OpportunityResponse } from '~/types';
import { Divider } from '~/components/Divider';
import firebase from '~/config/firebase';
import { createSurveyContractorApi, createSurveyCustomerApi, getOrderByIdApi } from '~/services/api';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Loading from '~/components/Loading';

interface Props {
  opportunity?: OpportunityResponse;
  closeModal: () => void;
}

const useStyles = makeStyles(() => ({
  starColor: {
    color: '#EFD26E',
  },
}));

const db = firebase.firestore();

export const SurveyModalContent = ({ opportunity, closeModal }: Props) => {
  const [customerRating, setCustomerRating] = useState(5);
  const [contractorRating, setContractorRating] = useState(5);
  const [hasContractorRating, setHasContractorRating] = useState(false);
  const [hasCustomerRating, setHasCustomerRating] = useState(false);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const { addToast } = useToast();

  const handleSurveyCustomer = async () => {
    if (!opportunity?.id) {
      return;
    }

    try {
      const request = {
        order_id: opportunity.id,
        social_security: opportunity.customer_id,
        feedback: 'backoffice',
        rating: customerRating,
      };

      const doc = await db.collection('orders').doc(opportunity.id).get();
      const docData = doc.data() as OpportunityResponse | undefined;

      if (docData) {
        await db.collection('orders').doc(opportunity.id).set({ contractor_survey: true }, { merge: true });
      }

      await createSurveyCustomerApi(request);
      setHasCustomerRating(true);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao avaliar o cliente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  const handleSurveyContractor = async () => {
    if (!opportunity?.id) {
      return;
    }

    try {
      const request = {
        order_id: opportunity.id,
        social_security: opportunity.customer_id,
        feedback: 'backoffice',
        rating: customerRating,
      };

      const doc = await db.collection('orders').doc(opportunity.id).get();
      const docData = doc.data() as OpportunityResponse | undefined;

      if (docData) {
        await db.collection('orders').doc(opportunity.id).set({ customer_survey: true }, { merge: true });
      }

      await createSurveyContractorApi(request);
      setHasContractorRating(true);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao avaliar o prestador');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  useEffect(() => {
    const getOrder = async () => {
      if (!opportunity?.id) {
        return;
      }

      try {
        const { data } = (await getOrderByIdApi(opportunity.id)) as { data?: OpportunityResponse };
        setLoading(false);
        setHasContractorRating(!!data?.contractor_survey);
        setHasCustomerRating(!!data?.customer_survey);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a oportunidade');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    };

    getOrder();
  }, [addToast, opportunity]);

  useEffect(() => {
    if (hasContractorRating && hasCustomerRating) {
      if (opportunity?.id) {
        db.collection('orders').doc(opportunity.id).delete();
      }
      closeModal();
    }
  }, [closeModal, hasContractorRating, hasCustomerRating, opportunity]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Title>{!hasContractorRating && !hasCustomerRating ? 'Avaliações' : 'Avaliação'}</Title>

      <Grid container>
        {!hasContractorRating && (
          <Grid item xs={12}>
            <Divider height={60} />
            <Typography>Prestador</Typography>

            {Array.from({ length: 5 }).map((_, index) => (
              <IconButton key={`${index}`} onClick={() => setCustomerRating(index + 1)}>
                {customerRating > index ? <Star className={classes.starColor} /> : <StarBorder />}
              </IconButton>
            ))}

            <Button autoFocus variant='contained' color='primary' onClick={handleSurveyContractor}>
              Avaliar
            </Button>
          </Grid>
        )}

        {!hasCustomerRating && (
          <Grid item xs={12}>
            <Divider height={60} />
            <Typography>Cliente</Typography>

            {Array.from({ length: 5 }).map((_, index) => (
              <IconButton key={`${index}`} onClick={() => setContractorRating(index + 1)}>
                {contractorRating > index ? <Star className={classes.starColor} /> : <StarBorder />}
              </IconButton>
            ))}

            <Button autoFocus variant='contained' color='primary' onClick={handleSurveyCustomer}>
              Avaliar
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
