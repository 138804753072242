import React from 'react';
import { ContractorsLocation } from '../../index';
import { IconText, IconWithBottomText, Chips, SelectedVehicleText, ServiceChip, IconContentGrid, VehiclePaper, VehicleListUl, VehicleLi, Hr } from '../styles';
import { CircularProgress } from '@material-ui/core';
import { AirportShuttle } from '@material-ui/icons';
import ServiceIcon from './ServiceIcon';
import { VehiclesResponse, VehicleServicesResponse, VehiclesResponseItem, VehicleServicesResponseItem } from '../../../../types';
import { chooseIconWithBottomTextColor } from '../utils/colorChoice';
import { opacityColorForBackgroundHex } from '../../../../styles/colors';
import pluralize from '../utils/pluralize';

interface VehicleInfoSectionProps {
  vehicleData: {
    vehicles: VehiclesResponse
    loading: boolean
  }
  iconStyle: {
    width: string
    height: string
    color: string
  }
  contractor: ContractorsLocation
  servicesData: {
    vehiclesServices: VehicleServicesResponse
    loadingVehiclesServices: boolean
  }
}

const isVehicleValid = (vehicle: VehiclesResponseItem) =>
  !vehicle.removed

const isVehicleSelected = (vehicle: VehiclesResponseItem, contractor: ContractorsLocation) =>
  vehicle.id === contractor.vehicle_id

const isServiceValid = (service: VehicleServicesResponseItem) =>
  !service.removed

const vehicleHasService = (vehicle: VehiclesResponseItem, service: VehicleServicesResponseItem) =>
  service.vehicle_contractor_id === vehicle.id

const compareVehicle = (
  contractor: ContractorsLocation,
  vehicle1: VehiclesResponseItem,
  vehicle2: VehiclesResponseItem) =>
  isVehicleSelected(vehicle1, contractor) ? -1 : 0

const ActiveVehicleText = () => <>
  <SelectedVehicleText>VEÍCULO ATIVO</SelectedVehicleText>
  <Hr />
</>

const SeparatorDot = () => <span>{' ‧ '}</span>


function VehicleInfoSection({ vehicleData, iconStyle, contractor, servicesData }: VehicleInfoSectionProps) {

  const vehicleAmount = vehicleData.vehicles.filter(vehicle => !vehicle.removed).length ?? 0

  const color = chooseIconWithBottomTextColor(vehicleData.vehicles.length > 0, false)

  return <IconContentGrid>
    <IconWithBottomText color={color}>
      <AirportShuttle style={{ ...iconStyle, color: opacityColorForBackgroundHex({ bgHex: color }) }} />
      <IconText bgColorHex={color}>
        {`${vehicleAmount} `}
        {pluralize('VEÍCULO', 'VEÍCULOS', vehicleAmount)}
      </IconText>
    </IconWithBottomText>

    {vehicleData.loading && <CircularProgress size='2em' />}

    <VehicleListUl>
      {vehicleData.vehicles
        .filter(isVehicleValid)
        .sort((vehicle1, vehicle2) => compareVehicle(contractor, vehicle1, vehicle2))
        .map((vehicle) =>

          <VehicleLi key={`vehicleChip#${vehicle.id}`}>
            <VehiclePaper selected={isVehicleSelected(vehicle, contractor)}>

              {isVehicleSelected(vehicle, contractor) && <ActiveVehicleText />}

              <strong>{vehicle.vehicle_contractor_type_description}</strong>

              <SeparatorDot />

              {`${vehicle.manufacture} ${vehicle.model}`}

              <Chips>
                {servicesData.loadingVehiclesServices && <CircularProgress size='2em' />}

                {
                  servicesData.vehiclesServices
                    .filter(isServiceValid)
                    .filter(service => vehicleHasService(vehicle, service))
                    .map(service =>
                      <ServiceChip
                        key={`ServiceChip#${service.id}.${vehicle.id}`}
                        active={service.vehicle_contractor_id === contractor.vehicle_id}
                        label={service.service_description}
                        icon={
                          <ServiceIcon
                            iconColor={'rgba(0,0,0,0.7)'}
                            service={service.service_description}
                            key={`ServiceChip.Icon#${service.id}.${vehicle.id}`}
                            style={{ backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '100%', marginLeft: '5px' }} />
                        }
                      //icon={<ServiceChipIcon iconName={service.service_description} key={`ServiceChip.Icon#${service.id}.${vehicle.id}`} />}
                      />)
                }
              </Chips>

            </VehiclePaper>
          </VehicleLi>
        )}
    </VehicleListUl>
  </IconContentGrid>;
}

export default VehicleInfoSection;