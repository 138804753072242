import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import { Image } from '@material-ui/icons';

import { ContractorIdentity, ContractorResponseEmpty, EmployeesResponse } from '~/types';
import {
  DetailsDialog,
  DataTree,
  DataGroup,
  TextDataItem,
  DetailsDialogProps,
  DateDataItem,
  ImagePicker,
  ImageViewer,
} from '~/components';
import { useContractor, useDocuments } from '~/services/hooks';
import { formatCPF, removeFormat, formatCellPhone, onlyNumbers } from '~/util/format';
import { validEmail, isNumeric, validCPF } from '~/util/validate';
import { changeContractorApi, updateEmployeeApi, createEmployeeApi } from '~/services/api';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Modal from '~/components/Modal';
import Loading from '~/components/Loading';

export interface EmployeeDetailsDialogProps extends Omit<DetailsDialogProps, 'open' | 'children'> {
  employee?: EmployeesResponse[number];
  organizationId?: string;
  newEmployee?: boolean;
  closeModal?: () => void;
}

// const INITIAL_VALUE: ContractorResponseEmpty = {
//   given_name: 'Adeilson',
//   family_name: 'Silva',
//   id: '02059263042',
//   email: 'adeilson.silva+02059263042@brainweb.com.br',
//   phone_number: '19991076121',
//   rg: { number: '1234564', dispatched_date: '2023-01-13' },
//   cnh: { number: '12345647', expiration_date: '2023-01-13' },
//   organization: {
//     company_name: 'empresa',
//     email: 'adeilson.silva+02059263042@brainweb.com.br',
//     social_identity: '02059263042',
//     address: { city: '1', country: '1', neighborhood: '1', number: '1', state: '1', street: '1', zip_code: '13040056' },
//   },
// };

export function EmployeeDetailsDialog({
  employee,
  organizationId,
  newEmployee,
  closeModal,
  ...props
}: EmployeeDetailsDialogProps) {
  const { contractor: details, loading, resetPhone, getContractor } = useContractor(employee?.username);
  const { updateSelf } = useDocuments(employee?.username);

  const [newData, setNewData] = useState<ContractorResponseEmpty>(details ?? ({} as ContractorResponseEmpty));
  const [editing, setEditing] = useState(false);
  const [openResetConfirmation, setOpenResetConfirmation] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [contractorData, setContractorData] = useState<ContractorIdentity>();
  const [loadingContractor, setLoadingContractor] = useState(true);

  const { addToast } = useToast();

  const handleSubmit = async () => {
    if (!newData.id) return;

    try {
      if (
        !(
          !newData.organization?.company_name ||
          !newData.organization?.company_name.length ||
          !newData.organization.email ||
          !validEmail(newData.organization.email) ||
          !newData.organization.social_identity ||
          !newData.organization?.address?.street ||
          !newData.organization.address.street.length ||
          !newData.organization.address.number ||
          !isNumeric(newData.organization.address.number) ||
          !newData.organization.address.neighborhood ||
          !newData.organization.address.neighborhood.length ||
          !newData.organization.address.city ||
          !newData.organization.address.city.length ||
          !newData.organization.address.state ||
          !newData.organization.address.state.length ||
          !newData.organization.address.country ||
          !newData.organization.address.country.length ||
          !newData.organization.address.zip_code ||
          newData.organization.address.zip_code.length !== 8 ||
          (newData.rg?.number.trim() &&
            (!newData.rg?.dispatched_date || !/\d{4}-\d{2}-\d{2}/.test(newData.rg.dispatched_date))) ||
          (newData.cnh?.number.trim() &&
            (!newData.cnh?.expiration_date || !/\d{4}-\d{2}-\d{2}/.test(newData.cnh.expiration_date)))
        )
      ) {
        const employeeDataChange = {
          ...newData,
          zone_info: 'America/Sao_Paulo',
          cpf: removeFormat(newData.id),
          phone_number: `+55${removeFormat(newData.phone_number?.replace('+55', ''))}`,
        };

        newEmployee
          ? await createEmployeeApi({ ...employeeDataChange, organization_id: organizationId })
          : await updateEmployeeApi(newData.id, employeeDataChange);

        const employeeData = {
          username: newData.id,
          email: newData.email,
          phone_number: `+55${removeFormat(newData.phone_number?.replace('+55', ''))}`,
          given_name: newData.given_name,
          family_name: newData.family_name,
          locale: 'pt-BR',
          zoneinfo: 'America/Sao_Paulo',
        };

        await changeContractorApi(employeeData);
      }
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao editar o funcionário');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setEditing(false);
    }
  };

  const getContractorData = useCallback(async () => {
    const data = await getContractor(employee?.username);
    setContractorData(data);
    setLoadingContractor(false);
  }, [employee, getContractor]);

  const sendUpdateSelf = async (file: File) => {
    const idContractor = details?.id || employee?.username;

    if (!idContractor) {
      return;
    }

    setLoadingContractor(true);

    await updateSelf(idContractor, file);
    await getContractorData();
  };

  const handleCancelEditing = () => {
    if (newEmployee && closeModal) {
      closeModal();
    }

    setEditing(false);
  };

  useEffect(() => {
    setNewData(details ?? ({} as ContractorResponseEmpty));
    // setNewData(INITIAL_VALUE);
    setEditing(!!newEmployee);
  }, [details, newEmployee]);

  useEffect(() => {
    getContractorData();
  }, [getContractorData]);

  return (
    <DetailsDialog
      open={!!employee || !!newEmployee}
      editable
      editing={editing}
      onRequestEdit={() => setEditing(true)}
      onRequestSave={handleSubmit}
      loading={loading}
      onCancelEditing={handleCancelEditing}
      resetable={!editing && newData && !newData.organization?.address?.zip_code && !newEmployee}
      onRequestReset={resetPhone}
      {...props}
    >
      {newData && (
        <>
          <DataTree>
            <DataGroup title='Detalhes Pessoais'>
              <TextDataItem
                title='Nome'
                value={newData.given_name}
                editing={editing}
                onChange={value => setNewData({ ...newData, given_name: value })}
              />

              <TextDataItem
                title='Sobrenome'
                value={newData.family_name}
                editing={editing}
                onChange={value => setNewData({ ...newData, family_name: value })}
              />

              <TextDataItem
                title='CPF'
                value={formatCPF(newData.id)}
                onChange={value => setNewData({ ...newData, id: onlyNumbers(removeFormat(value)) })}
                editing={editing && newEmployee}
                otherProps={{ inputProps: { maxLength: 14 } }}
                error={!newData.id || !validCPF(newData.id)}
              />

              <TextDataItem
                title='E-mail'
                value={newData.email}
                editing={editing}
                onChange={value => setNewData({ ...newData, email: value })}
              />

              <TextDataItem
                title='Telefone'
                value={formatCellPhone(removeFormat(newData.phone_number?.replace('+55', '')))}
                editing={editing}
                onChange={value => setNewData({ ...newData, phone_number: value })}
                otherProps={{ inputProps: { maxLength: 14 } }}
                error={!newData?.phone_number}
              />

              <Grid item xs={12} sm={6}>
                <Typography variant='h6' style={{ whiteSpace: 'normal' }}>
                  Foto
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => {
                    setImageUrl(contractorData?.picture);
                  }}
                  disabled={!contractorData?.picture}
                >
                  <Image color={!contractorData?.picture ? 'disabled' : undefined} />
                </Button>
                <ImagePicker onFile={sendUpdateSelf} />
              </Grid>

              <DataGroup title='RG'>
                <TextDataItem
                  title='Número'
                  value={newData.rg?.number}
                  onChange={number => setNewData({ ...newData, rg: { dispatched_date: '', ...newData.rg, number } })}
                  editing={editing}
                />

                <DateDataItem
                  title='Data de Expedição'
                  value={newData.rg?.dispatched_date}
                  onChange={dispatched_date =>
                    setNewData({ ...newData, rg: { number: '', ...newData.rg, dispatched_date } })
                  }
                  error={
                    !!newData.rg?.number &&
                    (!newData.rg?.dispatched_date || !/\d{4}-\d{2}-\d{2}/.test(newData.rg.dispatched_date))
                  }
                  editing={editing}
                />
              </DataGroup>

              <DataGroup title='CNH'>
                <TextDataItem
                  title='Número'
                  value={newData.cnh?.number}
                  onChange={number => setNewData({ ...newData, cnh: { expiration_date: '', ...newData.cnh, number } })}
                  editing={editing}
                />

                <DateDataItem
                  title='Data de Expiração'
                  value={newData.cnh?.expiration_date}
                  onChange={expiration_date =>
                    setNewData({ ...newData, cnh: { number: '', ...newData.cnh, expiration_date } })
                  }
                  error={
                    !!newData.cnh?.number &&
                    (!newData.cnh?.expiration_date || !/\d{4}-\d{2}-\d{2}/.test(newData.cnh.expiration_date))
                  }
                  editing={editing}
                />
              </DataGroup>
            </DataGroup>
          </DataTree>

          <Dialog open={openResetConfirmation} onClose={() => setOpenResetConfirmation(false)}>
            <DialogTitle>Conta Redefinida com Sucesso!</DialogTitle>
            <DialogActions>
              <Button variant='contained' color='primary' onClick={() => setOpenResetConfirmation(false)}>
                Obrigado
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <ImageViewer imageUrl={imageUrl?.replace('https', 'http')} onClose={() => setImageUrl(undefined)} />

      <Modal open={loadingContractor}>
        <Loading />
      </Modal>
    </DetailsDialog>
  );
}

export default EmployeeDetailsDialog;
