import styled from 'styled-components';
import Colors from '~/styles/colors';

const Title = styled.h1`
  font-size: 36px;
  color: ${Colors.primary};
  margin-bottom: 20px;
`;

export default Title;
