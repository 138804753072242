export enum UploadPhotoType {
    ON_UPLOAD = "@upload/ON_UPLOAD",
    UPLOAD_SUCESS = "@upload/UPLOAD_SUCESS",
  }

export const onUpload = () => {
  return {
    type: UploadPhotoType.ON_UPLOAD,
  };
};

export interface UploadState {
    loading: boolean;
    lastInsertion: Date | null;
  }
  
  const INITIAL_STATE: UploadState = {
    loading: false,
    lastInsertion: null,
  };
  
  export default function uploadPhotos(state = INITIAL_STATE, action: any = {}){
    switch (action.type) {
      case UploadPhotoType.ON_UPLOAD:
        return {
          ...state,
          loading: true,
          lastInsertion: new Date(),
        };
      case UploadPhotoType.UPLOAD_SUCESS:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };