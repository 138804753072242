import React, { InputHTMLAttributes, useRef } from 'react';
import { Button, ButtonProps, SvgIconProps } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

export interface ImagePickerProps extends ButtonProps {
  iconProps?: SvgIconProps;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onFile: (file: File) => void;
}

export function ImagePicker({ iconProps, inputProps, onFile, ...buttonProps }: ImagePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button {...buttonProps} onClick={() => inputRef.current?.click()}>
        <CloudUpload {...iconProps} />
      </Button>

      <input
        accept='image/*, image/png, .png, image/jpg, .jpg, image/jpeg, .jpeg'
        {...inputProps}
        ref={inputRef}
        type='file'
        style={{ display: 'none', ...inputProps?.style }}
        onChange={() => handleInputChange(onFile, inputRef.current?.files)}
      />
    </>
  );
}

export default ImagePicker;

function handleInputChange(onFile: (file: File) => void, files?: FileList | null, maxFileSize = 10000000 /*10MB*/) {
  if (files && files.length) {
    const file = files.item(0);
    if (file && file.size <= maxFileSize) {
      onFile(file);
    }
  }
}
