export type DateToStringParserVariation = '00/00/0000 00:00' | '0000-00-00';

export function dateToString(date?: Date | null, variation: DateToStringParserVariation = '00/00/0000 00:00') {
  if (!date) return undefined;

  if (variation === '0000-00-00')
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

  // default: '00/00/0000 00:00'
  return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${(
    '0' + date.getHours()
  ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
}
