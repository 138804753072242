import React, { forwardRef } from 'react';
import { Close } from '@material-ui/icons';

import { Container, DivFile, File, Label, CloseIcon } from './styles';

function Inputfile(
  { accept, name, id, onChange, fileName, label, error, clearFile, ...rest },
  ref
) {
  return (
    <Container>
      {label && <Label error={error}>{label}</Label>}

      <DivFile>
        <File
          name={name}
          type="file"
          accept={accept}
          onChange={onChange}
          id={id}
          ref={ref}
          {...rest}
        />
        <Label htmlFor={id} error={error}>
          {fileName}
        </Label>
        <CloseIcon onClick={clearFile}>
          <Close />
        </CloseIcon>
      </DivFile>
    </Container>
  );
}

export default forwardRef(Inputfile);
