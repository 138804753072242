import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

export const DivButtons = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
