import React from 'react';
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogActions,
  Button,
  DialogProps,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';

interface CancelConfirmProps extends DialogProps {
  question?: boolean;
  haveCustomButton?: boolean;
  buttonNegative?: string;
  buttonPositive?: string;
  buttonCustom?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onCustom?: () => void;
  disabledConfirmButton?: boolean;
  loadingConfirmButton?: boolean;
  disabledCustomButton?: boolean;
  loadingCustomButton?: boolean;
  disabledCancelButton?: boolean;
}

const Modal = ({
  onConfirm,
  onCancel,
  onCustom,
  question,
  haveCustomButton,
  buttonNegative,
  buttonPositive,
  buttonCustom,
  children,
  disabledConfirmButton,
  loadingConfirmButton,
  disabledCustomButton,
  loadingCustomButton,
  disabledCancelButton,
  ...props
}: CancelConfirmProps) => {
  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleConfirm = () => {
    if (loadingConfirmButton) {
      return;
    }

    onConfirm();
  };

  const handleCustom = () => {
    if (loadingCustomButton) {
      return;
    }

    onCustom && onCustom();
  };

  return (
    <Dialog
      maxWidth='xl'
      fullScreen={fullscreen}
      disableScrollLock={true}
      onClose={_ => onCancel && onCancel()}
      {...props}
    >
      <DialogContent>{children}</DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        {question && (
          <Button
            autoFocus
            variant='contained'
            color='secondary'
            style={{ textTransform: 'none' }}
            onClick={_ => onCancel && onCancel()}
            disabled={disabledCancelButton}
          >
            {buttonNegative ?? 'Cancelar'}
          </Button>
        )}
        {haveCustomButton && (
          <Button
            autoFocus
            variant='contained'
            color='secondary'
            style={{ textTransform: 'none' }}
            onClick={handleCustom}
            disabled={disabledCustomButton}
          >
            {loadingCustomButton ? <CircularProgress size={26} /> : buttonCustom ?? 'Não'}
          </Button>
        )}
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={handleConfirm}
          disabled={disabledConfirmButton}
        >
          {loadingConfirmButton ? <CircularProgress size={26} /> : buttonPositive ?? (question ? 'Sim' : 'OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
