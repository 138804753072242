import Colors from '~/styles/colors';

export const chooseIconWithBottomTextColor = (valid, enabled) => {
  if (enabled) return Colors.backButton
  if (valid) return Colors.darkBlue
  return Colors.grey
}

export const chooseContractorIconWithBottomTextColor = (isWorking, isOnline) => {
  if (isWorking) return Colors.darkBlue
  if (isOnline) return Colors.backButton
  return Colors.grey
}