import * as React from "react"

interface BatterySvgIconProps {
  color?: string;
  props?: any
}

export function BatterySvgIcon({ color = '#fff', props }: BatterySvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Camada_1"
      x={0}
      y={0}
      viewBox="-140 -140 1360 1360"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    > 
      <g
        id="prefix__Grupo_663_1_"
        transform="matrix(1.9348 0 0 1.9348 -931.966 -1979.857)"
      >
        <g
          id="prefix__Grupo_115_1_"
          transform="translate(265.691 805.293)"
          fill="#fff"
          fillOpacity={0}
        >
          <path
            id="prefix__Ret\xE2ngulo_614_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M236.14 307.8h518.95v49.81H236.14z"
          />
          <path
            id="prefix__Ret\xE2ngulo_615_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M236.14 677.9h518.95v49.81H236.14z"
          />
          <path
            id="prefix__Ret\xE2ngulo_616_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M270.96 357.61h449.3V677.9h-449.3z"
          />
          <path
            id="prefix__Ret\xE2ngulo_617_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            d="M327.45 258.03h61.98c7.04 0 12.75 5.71 12.75 12.75v37.04H314.7v-37.04c0-7.04 5.71-12.75 12.75-12.75z"
            fill="#fff"
            fillOpacity={0}
          />
          <path
            id="prefix__Ret\xE2ngulo_618_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            d="M601.79 258.03h61.98c7.04 0 12.75 5.71 12.75 12.75v37.04h-87.48v-37.04c0-7.04 5.71-12.75 12.75-12.75z"
            fill="#fff"
            fillOpacity={0}
          />
          <path
            id="prefix__Linha_50_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M366.3 383.09v71.77"
          />
          <path
            id="prefix__Linha_51_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M330.42 418.97h71.76"
          />
          <path
            id="prefix__Linha_52_1_"
            style={{fill: 'none', stroke: color, strokeWidth: 30.4753, strokeMiterlimit: 10}}
            fill="#fff"
            fillOpacity={0}
            d="M589.57 416.49h71.24"
          />
          <g
            id="prefix__Grupo_114_1_"
            transform="translate(37.256 28.512)"
            fill="#fff"
            fillOpacity={0}
          >
            <path
              id="prefix__Caminho_460_1_"
              d="M517.81 472.52l-54-.38 30.85-89.57-95.76 123.34 54.01.38-30.85 89.58z"
              fill="#fff"
              fillOpacity={0}
              stroke={color}
              strokeWidth={21.475}
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

