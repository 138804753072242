import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ImportContacts,
  AssignmentInd,
  ExitToApp,
  Person,
  PostAdd,
  AttachMoney,
  InfoOutlined,
  VpnKey,
  EmojiTransportation,
  AddCircleOutline,
  Map,
  Business,
  Assessment,
  Notifications,
} from '@material-ui/icons';
import { List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core';

import history from '~/services/history';

import { logout } from '~/store/ducks/auth';

import { Link, Button } from './styles';
import { CustomRoles } from '~/types';

export interface MenuProps {
  toggleDrawer?: (option: boolean) => void;
  menuIsOpen: boolean;
}

interface ItemProps extends Omit<MenuItemProps, 'handleLink' | 'menuIsOpen'> {
  roles?: CustomRoles[];
}

export function Menu({ toggleDrawer, menuIsOpen }: MenuProps) {
  const dispatch = useDispatch();
  const { 'custom:role': role } = useSelector(state => state.auth.user);

  function handleLink(url: string) {
    history.push(url);
    if (typeof toggleDrawer === 'function') {
      toggleDrawer(false);
    }
  }

  function logoutApp() {
    dispatch(logout());
  }

  function Item({ roles = [CustomRoles.BACKOFFICE], ...rest }: ItemProps) {
    if (!roles?.includes(role)) {
      return null;
    }

    return <MenuItem handleLink={handleLink} menuIsOpen={menuIsOpen} {...rest} />;
  }

  return (
    <>
      <List>
        <Item
          to='/dashboard'
          text='Dashboard'
          icon={<Assessment />}
          roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
        />

        <Item to='/import' text='Importar Usuários da Empresa' icon={<ImportContacts />} />

        <Item to='/table-prices' text='Tabela de Preços' icon={<AttachMoney />} />

        <Item to='/coverage-plans' text='Planos de Cobertura' icon={<PostAdd />} />

        <Item to='/additional-information' text='Informações Adicionais' icon={<InfoOutlined />} />

        <Item to='/contractor-manager' text='Gestão de Prestadores' icon={<Person />} />

        <Item to='/b2b-manager' text='Gestão B2B' icon={<Business />} />

        <Item
          to='/opportunity-manager'
          text='Gestão de Oportunidades'
          icon={<EmojiTransportation />}
          roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
        />

        <Item
          to='/map-opportunities?refresh_interval_ms=40000'
          text='Mapa de Oportunidades'
          icon={<Map />}
          roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
        />

        <Item to='/association-manager' text='Gestão de Empresas' icon={<AssignmentInd />} />

        <Item
          to='/customer-manager'
          text='Gestão de Clientes'
          icon={<Person />}
          roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
        />

        <Item
          to='/new-opportunity'
          text='Nova Oportunidade'
          icon={<AddCircleOutline />}
          roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
        />

        <Item to='/notifications' text='Notificações' icon={<Notifications />} />
      </List>

      <div style={{ flex: 1 }} />

      <List style={{ paddingBottom: 30 }}>
        <Item to='/change-password' text='Alterar Senha' icon={<VpnKey />} />

        <ListItem>
          <Button onClick={() => logoutApp()}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </Button>
        </ListItem>
      </List>
    </>
  );
}

export default Menu;

interface MenuItemProps {
  to: string;
  handleLink: (url: string) => void;
  text: string;
  icon: ReactNode;
  menuIsOpen: boolean;
}

function MenuItem(props: MenuItemProps) {
  return (
    <ListItem>
      <Link to={props.to} onClick={() => props.handleLink(props.to)}>
        <Tooltip title={props.text} placement='right' disableHoverListener={props.menuIsOpen}>
          <ListItemIcon>{props.icon}</ListItemIcon>
        </Tooltip>

        <ListItemText>{props.text}</ListItemText>
      </Link>
    </ListItem>
  );
}
