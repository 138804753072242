import styled from 'styled-components';

interface ContainerProps {
  topScroll: number;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: ${props => props.topScroll ?? 0}px;
  padding: 30px;
  overflow: hidden;
  z-index: 1500;
`;
