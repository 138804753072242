import React from 'react';

import Colors from '~/styles/colors';

import Button from '~/components/Button';

interface HighlightedButtonProps extends React.HTMLProps<HTMLButtonElement> {
  label?: string;
  loading: boolean;
}

const HighlightedButton = ({ loading, label, ...rest }: HighlightedButtonProps) => {
  return (
    <Button
      {...rest}
      label={label ?? 'Avançar'}
      loading={loading}
      backgroundColor={Colors.primary}
      color={Colors.white}
      style={{ width: 200 }}
    />
  );
};

export default HighlightedButton;
