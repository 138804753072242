import React from 'react';
import { Icon, IconButton } from '@material-ui/core';
import { ChevronRight, Edit } from '@material-ui/icons';

import { Container, Subtitle, Title } from './styles';

import CopyableToClipboard from '~/components/Copyable';
import { InformationOrderProps } from '../..';

export interface CardItemProps {
  title: string;
  subtitle: string | JSX.Element;
  clipboard?: string;
  last?: boolean;
  typeCard?: InformationOrderProps['type'];
  currentTypeCard?: InformationOrderProps['type'];
  edit?: () => void;
}

const Card = ({ title, subtitle, clipboard, last, typeCard, currentTypeCard, edit }: CardItemProps) => {
  const selected = typeCard === currentTypeCard && typeCard !== undefined;

  return (
    <Container selected={selected} last={last}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title selected={selected}>{title}</Title>
          {edit && (
            <IconButton onClick={edit}>
              <Edit style={{ fontSize: 18 }} />
            </IconButton>
          )}
        </div>

        {clipboard ? (
          <CopyableToClipboard textGenerator={() => clipboard ?? 'Sem dados'}>
            <Subtitle selected={selected}>{subtitle}</Subtitle>
          </CopyableToClipboard>
        ) : (
          <Subtitle selected={selected}>{subtitle}</Subtitle>
        )}
      </div>

      {!last && (
        <Icon>
          <ChevronRight />
        </Icon>
      )}
    </Container>
  );
};

export default Card;
