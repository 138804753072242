import React from 'react';
import { Dialog, useTheme, useMediaQuery, DialogActions, Button, DialogProps, DialogTitle } from '@material-ui/core';

interface CancelConfirmProps extends DialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
}

const ModalConfirm = ({ onConfirm, onCancel, title, ...props }: CancelConfirmProps) => {
  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} onClose={_ => onCancel()} {...props}>
      <DialogTitle>{title}</DialogTitle>

      <DialogActions>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onCancel()}
        >
          Cancelar
        </Button>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onConfirm()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ModalConfirm };
