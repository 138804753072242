import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import Colors from '~/styles/colors';

import InputComponent from '~/components/InputComponent';

export const Input = styled(InputComponent)`
  input {
    color: #fff;
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.white)};

    &:hover {
      border-bottom-color: ${Colors.white};
    }
  }
  & label {
    color: #fff;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.blue};
  }

  && .MuiInput-underline:before {
    border-bottom-color: ${Colors.white};
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid ${Colors.white};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-bottom-color: ${Colors.blue};
    }
    &.Mui-focused fieldset {
      border-bottom-color: ${Colors.blue};
    }
  }
`;

export const Link = styled(BaseLink)`
  color: ${Colors.white};
  margin-top: 15px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
