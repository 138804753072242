import styled, { css } from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Refresh, HelpRounded } from '@material-ui/icons';

import Colors, { opacityColorForBackgroundHex } from '~/styles/colors';

interface SimplePinProps {
  lat: number;
  lng: number;
  color: string;
}

export const SimplePin = styled(FaMapMarkerAlt).attrs({ size: 24 })<SimplePinProps>`
  position: absolute;
  top: -20px;
  transform-origin: 50% 50%;
  cursor: pointer;
  color: ${props => props.color};
`;

interface OrderPinProps {
  lat: number;
  lng: number;
  colorHex: string;
}

export const OrderPin = styled.code.attrs({ size: 24 })<OrderPinProps>`
  background-color: ${props => props.colorHex};
  position: absolute;
  top: -50px;
  transform-origin: 50% 50%;
  cursor: pointer;
  color: ${props => opacityColorForBackgroundHex({ bgHex: props.colorHex })};
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bolder;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: solid 10px ${props => props.colorHex};
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
    margin-left: -5px;
  }
`;

interface DividerProps {
  height: number;
}

export const Divider = styled.div<DividerProps>`
  height: ${props => (props.height ? props.height + 'px' : '0')};
`;

export const ContainerModal = styled.div`
  width: 75vw;
  min-height: 60vh;
  max-height: 70vh;
`;

export const TitleModal = styled.h1`
  font-size: 22px;
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 15px;
`;

export const Minitext = styled.p`
  font-size: 0.65em;
  color: #687787;
  background-color: #f2f1f0;
  text-align: end;

  & a {
    color: #687787;
    text-decoration: underline;
    cursor: hand;
  }
`;

type RequestStatus = 'IDLE' | 'SUCCESS' | 'ERROR';

const chooseIconColor = (status: RequestStatus) => {
  switch (status) {
    case 'SUCCESS':
      return Colors.green;
    case 'ERROR':
      return Colors.red;
    default:
      return Colors.darkBlue;
  }
};

const chooseBackgroundColor = (status: RequestStatus, spinning: boolean) => {
  switch (status) {
    case 'SUCCESS':
      return Colors.rgba.done(0.9);
    case 'ERROR':
      return Colors.rgba.strokeColor(0.5);
    default:
      return spinning ? Colors.rgba.blue(0.9) : Colors.rgba.blue(0.6);
  }
};

const iconStyle = css`
  border-radius: 100%;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  transition: all 0.5s ease-in-out;
  font-size: 1.4em;
  height: 1.4em;
  width: 1.4em;
`;

const spinningStyle = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  transition: all 0.5s ease-in-out;
  animation: spin 0.15s linear infinite;
`;

interface RefreshButtonProps {
  spin: any;
  status: RequestStatus;
}

export const RefreshButton = styled(Refresh).attrs((props: RefreshButtonProps) => ({ spin: props.spin }))<
  RefreshButtonProps
>`
  ${spinningStyle};
  ${iconStyle};

  animation-play-state: ${props => (props.spin ? `running` : `paused`)};
  color: ${props => chooseIconColor(props.status)};
  border: solid 2px ${props => chooseIconColor(props.status)};
  background-color: ${props => chooseBackgroundColor(props.status, props.spin)};
`;

export const InfoButton = styled(HelpRounded)`
  ${iconStyle};
  color: rgba(0, 0, 0, 0.7);
  border: solid 2px rgba(0, 0, 0, 0.5);
  background-color: ${Colors.rgba.done(0.6)};
`;

export const MapControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  height: 3.4em;
  background-color: ${Colors.darkBlue};
`;
