import { ContractorsResponse } from '~/types';

export const customFilterAndSearchContractors = (filter: string | string[], rowData: ContractorsResponse) => {
  if (typeof filter === 'string') {
    if (!filter.trim()) {
      return true;
    }

    const hasService =
      (rowData.services?.filter(service => service.description.toLowerCase().includes(filter.toLowerCase())).length ||
        0) > 0 || false;

    return hasService;
  }

  if (filter.length === 0) {
    return true;
  }

  const hasData = Array.from(filter).map(() => false);

  filter.forEach((term, index) => {
    if ((rowData.services?.filter(service => service.description.includes(term)).length || 0) > 0) {
      hasData[index] = true;
    }
  });

  return hasData.every(item => item);
};
