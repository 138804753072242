import XLSX from 'xlsx';

type SheetItemMapper = (key: any, value: any) => [string, any];

const getColumnWithValue = (workbook: XLSX.WorkBook, value: string | number) =>
  Object.entries(workbook.Sheets.Sheet1)
    .filter(([_, content]) => content.v === value)
    .map(([column]) => column.replace(/[0-9]/g, ''))?.[0];

const mapSheetItems = (sheet: XLSX.WorkSheet, mapper: SheetItemMapper) =>
  Object.fromEntries(Object.entries(sheet).map(([key, value]) => mapper(key, value)));

export const columnContentToOriginalValue = (workbook: XLSX.WorkBook, columnName: string) => {
  const columnPosition = getColumnWithValue(workbook, columnName);

  workbook.Sheets.Sheet1 = mapSheetItems(workbook.Sheets.Sheet1, (key, value) => {
    if (key.includes(columnPosition) && value.v !== columnName) {
      value.v = value.w;
    }
    return [key, value];
  });

  return workbook;
};
