import { useState, useEffect, useCallback } from 'react';
import { VehiclesResponse } from '~/types';
import { loadVehicles, deleteVehicleAPI } from '../api';

export function useVehicles(contractorId?: string) {
  const [vehicles, setVehicles] = useState<VehiclesResponse>([]);
  const [loading, setLoading] = useState(false);

  const getVehicles = useCallback(async (contractorId: string) => {
    try {
      setLoading(true);
      const { data } = await loadVehicles(contractorId);
      setVehicles(data);
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (contractorId) {
      getVehicles(contractorId);
    } else {
      setVehicles([]);
    }
  }, [contractorId, getVehicles]);

  const deleteVehicle = useCallback(
    async (vehicleId: string | undefined): Promise<void> => {
      if (!vehicleId) {
        return;
      }

      try {
        await deleteVehicleAPI(vehicleId);

        setVehicles(vehicles.filter(vehicle => vehicle.id !== vehicleId));
      } catch (error) {
        throw new Error(error);
      }
    },
    [vehicles],
  );

  return {
    vehicles,
    loading,
    deleteVehicle,
    getVehicles,
  };
}
