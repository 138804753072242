import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
    },
    '& th, & .MuiToolbar-gutters:not(.MuiTablePagination-toolbar)': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      '& :hover, & .MuiInputBase-root': {
        color: theme.palette.secondary.main,
      },
      '& .MuiInput-underline::before': {
        borderColor: theme.palette.secondary.main,
      },
      '& .MuiSvgIcon-root, & .MuiTableSortLabel-active, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));
