import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { forgotPasswordRequest, clearError } from '~/store/ducks/auth';
import { validEmail } from '~/util/validate';

import Button from '~/components/Button';

import { Input, Link } from './styles';

export default function ForgotPassword({ history }) {
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    if (!username.trim()) {
      return setError({ message: 'E-mail não informado!' });
    }

    if (!validEmail(username)) {
      return setError({ message: 'E-mail inválido' });
    }

    dispatch(forgotPasswordRequest(username));
    history.push('/forgot-confirmation', username);
    return true;
  }

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <Input
        name='username'
        label='E-mail'
        value={username}
        onChange={e => setUsername(e.target.value)}
        autoFocus
        error={error}
      />

      <Button type='submit' filled label='Recuperar senha' />

      <Link to='/'>Voltar</Link>
    </form>
  );
}
