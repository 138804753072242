import produce from 'immer';

export const Types = {
  SET_AUTH_HEADER: '@auth/SET_AUTH_HEADER',
  INIT_CLIENT: '@auth/INIT_CLIENT',
  GET_CLIENT_REQUEST: '@auth/GET_CLIENT_REQUEST',
  GET_CLIENT_SUCCESS: '@auth/GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILURE: '@auth/GET_CLIENT_FAILURE',
  LOGIN_REQUEST: '@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: '@auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: '@auth/LOGIN_FAILURE',
  REFRESH_TOKEN_REQUEST: '@auth/REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: '@auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: '@auth/REFRESH_TOKEN_FAILURE',
  UPDATE_AUTH_DATA: '@auth/UPDATE_AUTH_DATA',
  LOGOUT: '@auth/LOGOUT',
  LOGOUT_SUCCESS: '@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'GET_CLIENT_REQUESTauth/LOGOUT_FAILURE',
  USER_POPULATE_TOKEN: '@auth/USER_POPULATE_TOKEN',
  USER_POPULATE: '@auth/USER_POPULATE',
  FORGOT_PASSWORD: '@auth/FORGOT_PASSWORD',
  SIGN_UP: '@auth/SIGN_UP',
  CONFIRM_EMAIL: '@auth/CONFIRM_EMAIL',
  RESEND_CONFIRMATION_CODE: '@auth/RESEND_CONFIRMATION_CODE',
  CHANGE_PASSWORD: '@auth/CHANGE_PASSWORD',
  RESET_PASSWORD_REQUEST: '@auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: '@auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: '@auth/RESET_PASSWORD_FAILURE',
  CHANGE_PASSWORD_REQUEST: '@auth/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: '@auth/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: '@auth/CHANGE_PASSWORD_FAILURE',
  CLEAR_ERROR: '@auth/CLEAR_ERROR',
};

const INITIAL_STATE = {
  client: {},
  authorization: {},
  user: {},
  loading: false,
  error: false,
};

export function setAuthHeader(accessToken) {
  return { type: Types.SET_AUTH_HEADER, accessToken };
}

export function initClient() {
  return { type: Types.INIT_CLIENT };
}

export function getClientRequest() {
  return { type: Types.GET_CLIENT_REQUEST };
}

export function getClientSuccess(client) {
  return { type: Types.GET_CLIENT_SUCCESS, client };
}

export function getClientFailure(error) {
  return { type: Types.GET_CLIENT_FAILURE, error };
}

export function loginRequest(username, password) {
  return { type: Types.LOGIN_REQUEST, payload: { username, password } };
}

export function loginSuccess(authorization) {
  return { type: Types.LOGIN_SUCCESS, authorization };
}

export function loginFailure(error) {
  return { type: Types.LOGIN_FAILURE, error };
}

export function forgotFailure(error) {
  return { type: Types.LOGIN_FAILURE, error };
}

export function refreshTokenRequest(refresh_token) {
  return { type: Types.REFRESH_TOKEN_REQUEST, payload: { refresh_token } };
}

export function refreshTokenSuccess(authorization) {
  return { type: Types.REFRESH_TOKEN_SUCCESS, authorization };
}

export function refreshTokenFailure(error) {
  return { type: Types.REFRESH_TOKEN_FAILURE, error };
}

export function updateAuthData(authorization) {
  return { type: Types.UPDATE_AUTH_DATA, authorization };
}

export function logout() {
  return { type: Types.LOGOUT };
}

export function logoutSuccess() {
  return { type: Types.LOGOUT_SUCCESS };
}

export function logoutFailure(err) {
  return { type: Types.LOGOUT_FAILURE, err };
}

export function userPopulateToken(tokenDecoded) {
  return { type: Types.USER_POPULATE_TOKEN, tokenDecoded };
}

export function userPopulate(user) {
  return { type: Types.USER_POPULATE, user };
}

export function forgotPasswordRequest(username) {
  return { type: Types.FORGOT_PASSWORD, payload: { username } };
}

export function resetPasswordRequest(username, password, resetCode) {
  return {
    type: Types.RESET_PASSWORD_REQUEST,
    payload: { username, password, resetCode },
  };
}

export function resetPasswordSuccess() {
  return {
    type: Types.RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordFailure(error) {
  return {
    type: Types.RESET_PASSWORD_FAILURE,
    error,
  };
}

export function confirmAccount(confirmationCode, token, type) {
  return {
    type: Types.CONFIRM_EMAIL,
    payload: { confirmationCode, type, token },
  };
}

export function resendConfirmationCode(username) {
  return { type: Types.RESEND_CONFIRMATION_CODE, payload: { username } };
}

export function changePasswordRequest(username, currentPassword, newPassword) {
  return {
    type: Types.CHANGE_PASSWORD_REQUEST,
    payload: { username, currentPassword, newPassword },
  };
}

export function changePasswordSuccess() {
  return {
    type: Types.CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordFailure(error) {
  return {
    type: Types.CHANGE_PASSWORD_FAILURE,
    error,
  };
}

export function clearError() {
  return {
    type: Types.CLEAR_ERROR,
  };
}

export default function authReducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_CLIENT_SUCCESS:
        draft.loading = false;
        draft.client = action.client;
        break;

      case Types.GET_CLIENT_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case Types.LOGIN_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;

      case Types.LOGIN_SUCCESS:
        draft.loading = false;
        draft.authorization = action.authorization;
        break;

      case Types.REFRESH_TOKEN_SUCCESS: {
        const { authorization } = action;
        draft.loading = false;
        draft.authorization = {
          ...state.authorization,
          ...authorization,
        };
        break;
      }

      case Types.LOGIN_FAILURE:
      case Types.REFRESH_TOKEN_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case Types.LOGOUT_SUCCESS:
        draft.authorization = {};
        draft.user = {};
        draft.error = null;
        draft.loading = false;
        break;

      case Types.USER_POPULATE_TOKEN:
        draft.user = action.tokenDecoded;
        break;

      case Types.USER_POPULATE:
        draft.user = action.user;
        break;

      case Types.RESET_PASSWORD_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;

      case Types.RESET_PASSWORD_SUCCESS:
        draft.loading = false;
        break;

      case Types.RESET_PASSWORD_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case Types.CHANGE_PASSWORD_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;

      case Types.CHANGE_PASSWORD_SUCCESS:
        draft.loading = false;
        break;

      case Types.CHANGE_PASSWORD_FAILURE:
        draft.loading = false;
        draft.error = action.error;
        break;

      case Types.CLEAR_ERROR:
        draft.error = null;
        break;

      default:
        break;
    }
  });
}
