import { useState, useEffect, useCallback } from 'react';
import { format, fromUnixTime } from 'date-fns';

import { ContractorsResponse } from '~/types';
import { loadContractors, resetContractor, deleteContractor } from '../api';
import { useToast } from './toast';
import { handleApiErrorResponse } from '../handleErrors';

export function useContractors(status?: ContractorsResponse['status']) {
  const [contractors, setContractors] = useState<ContractorsResponse[]>([]);
  const [loading, setLoading] = useState<boolean>();

  const { addToast } = useToast();

  const getContractors = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = (await loadContractors()) as { data: ContractorsResponse[] };

      const newData = data.map(contractor => ({
        ...contractor,
        _created_at_formatted: contractor.created_at
          ? format(fromUnixTime(contractor.created_at), 'dd/MM/yyyy HH:mm')
          : '',
      }));

      if (status) {
        setContractors(newData.filter(c => c.status === status));
      } else {
        setContractors(newData);
      }
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar prestadores');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, status]);

  useEffect(() => {
    getContractors();
  }, [getContractors]);

  const resetEmail = useCallback(contractorId => {
    if (contractorId) return resetContractor(contractorId, 'EMAIL');
    else return Promise.resolve();
  }, []);

  const resetPhone = useCallback(
    contractorId => {
      if (contractorId)
        return resetContractor(contractorId, 'PHONE_NUMBER').then(() => {
          const newData = [...contractors];
          newData[newData.findIndex(d => d.id === contractorId)]!.phone_number = undefined;
          setContractors(newData);
        });
      else return Promise.resolve();
    },
    [contractors],
  );

  const erase = useCallback(
    contractorId => {
      if (contractorId)
        return deleteContractor(contractorId).then(() => {
          setContractors(contractors.filter(c => c.id !== contractorId));
        });
      else return Promise.resolve();
    },
    [contractors],
  );

  return {
    contractors,
    loading,
    resetEmail,
    resetPhone,
    erase,
    getContractors,
  };
}

export default useContractors;
