import { useState, useEffect } from 'react';
import { Address, OpportunitiesResponse } from '~/types';

import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyA8EQde-ys1im-Gv5EFbY20VeUDR0y_i4c');
Geocode.setLanguage('pt');
Geocode.setRegion('br');

export function useAddresses(opportunity?: OpportunitiesResponse[number]) {
  const [addresses, setAddresses] = useState<Address[]>([]);

  useEffect(() => {
    if (opportunity?.address) setAddresses(opportunity.address);
    else if (opportunity?.coordinates && opportunity.coordinates.length) {
      const promises = opportunity.coordinates.map((coordinate, index) => {
        if (coordinate.address) {
          if (!opportunity.address) {
            opportunity.address = [];
          }

          return new Promise(resolve =>
            resolve(
              (opportunity.address![index] = {
                position: coordinate.position,
                ordination: coordinate.ordination,
                street_number: coordinate.address,
                neighborhood: coordinate.neighborhood,
                city_state: coordinate.city,
              }),
            ),
          );
        }

        return Geocode.fromLatLng(coordinate.latitude + '', coordinate.longitude + '').then(response => {
          const components: { long_name: string; short_name: string; types: string[] }[] =
            response.results[0].address_components;
          const address = components.reduce(
            (A, c) => {
              if (c.types.includes('street_number')) A.number = c.long_name;
              if (c.types.includes('route')) A.street = c.short_name;
              if (c.types.includes('sublocality_level_1')) A.neighborhood = c.short_name;
              if (c.types.includes('administrative_area_level_2')) A.city = c.short_name;
              if (c.types.includes('administrative_area_level_1')) A.state = c.short_name;
              if (c.types.includes('point_of_interest')) A.street_number = c.short_name;
              return A;
            },
            {
              position: coordinate.position,
              ordination: coordinate.ordination,
            } as Address,
          );
          if (!address.street_number) address.street_number = address.street + ' ' + address.number;
          address.city_state = address.city + ' - ' + address.state;
          if (!opportunity.address) opportunity.address = [];
          opportunity.address![index] = address;
        });
      });

      Promise.all(promises).then(() => setAddresses(opportunity.address!));
    }
  }, [opportunity]);

  return addresses;
}

export default useAddresses;
