export enum NoteType {
    ON_SAVE_FROM_MANUAL_ALLOCATION = "@note/ON_SAVE_FROM_MANUAL_ALLOCATION",
    ON_SAVE_FROM_CANCELED = "@note/ON_SAVE_FROM_CANCELED",
    ON_SAVE_FROM_FINISHED = "@note/ON_SAVE_FROM_FINISHED",
    ON_SAVE_FROM_ONGOING = "@note/ON_SAVE_FROM_ONGOING",
    ON_SAVE_FROM_PAYMENT = "@note/ON_SAVE_FROM_PAYMENT",
    ON_SAVE_FROM_ALLOCATING = "@note/ON_SAVE_FROM_ALLOCATING",
    ON_SAVE_FROM_INEXECUTION = "@note/ON_SAVE_FROM_INEXECUTION",
    ON_SUCESS = "@note/ON_SUCESS",
  }

export const onSaveManual = () => {
  return {
    type: NoteType.ON_SAVE_FROM_MANUAL_ALLOCATION,
  };
};

export const onSaveCanceled = () => {
  return {
    type: NoteType.ON_SAVE_FROM_CANCELED,
  };
};
export const onSaveAllocating = () => {
  return {
    type: NoteType.ON_SAVE_FROM_ALLOCATING,
  };
};
export const onSaveInExecution = () => {
  return {
    type: NoteType.ON_SAVE_FROM_INEXECUTION,
  };
};
export const onSavePayment = () => {
  return {
    type: NoteType.ON_SAVE_FROM_PAYMENT,
  };
};

export const onSaveOngoing = () => {
  return {
    type: NoteType.ON_SAVE_FROM_ONGOING,
  };
};

export const onSaveFinished = () => {
  return {
    type: NoteType.ON_SAVE_FROM_FINISHED,
  };
};

export interface NoteState {
    loading: boolean;
    addNoteOnAllocatingOrder: Date | null;
    addNoteOnManualOrder: Date | null;
    addNoteOnFinishedOrder: Date | null;
    addNoteOnOngoingOrder: Date | null;
    addNoteOnPaymentOrder: Date | null;
    addNoteOnInExecutionOrder: Date | null;
    addNoteOnCanceledOrder: Date | null;
  }
  
  const INITIAL_STATE: NoteState = {
    loading: false,
    addNoteOnAllocatingOrder: null,
    addNoteOnManualOrder: null,
    addNoteOnFinishedOrder: null,
    addNoteOnOngoingOrder: null,
    addNoteOnPaymentOrder: null,
    addNoteOnInExecutionOrder: null,
    addNoteOnCanceledOrder: null
  };
  
  export default function orderNote(state = INITIAL_STATE, action: any = {}){
    switch (action.type) {
      case NoteType.ON_SAVE_FROM_MANUAL_ALLOCATION:
        return {
          ...state,
          loading: true,
          addNoteOnManualOrder: new Date(),
        };
      case NoteType.ON_SAVE_FROM_CANCELED:
        return {
          ...state,
          loading: true,
          addNoteOnCanceledOrder: new Date(),
        };
      case NoteType.ON_SAVE_FROM_ALLOCATING:
        return {
          ...state,
          loading: true,
          addNoteOnAllocatingOrder: new Date(),
        };
        case NoteType.ON_SAVE_FROM_PAYMENT:
        return {
          ...state,
          loading: true,
          addNoteOnPaymentOrder: new Date(),
        };
        case NoteType.ON_SAVE_FROM_ONGOING:
        return {
          ...state,
          loading: true,
          addNoteOnOngoingOrder: new Date(),
        };
        case NoteType.ON_SAVE_FROM_FINISHED:
        return {
          ...state,
          loading: true,
          addNoteOnFinishedOrder: new Date(),
        };
        case NoteType.ON_SAVE_FROM_INEXECUTION:
        return {
          ...state,
          loading: true,
          addNoteOnInExecutionOrder: new Date(),
        };
      case NoteType.ON_SUCESS:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };