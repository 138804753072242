import { useCallback, useEffect, useState } from 'react';

import {
  getContractorVehicleTypesApi,
  getFipeManufacturersByCategoryApi,
  getFipeModelsByVehiclesAndManufacturersAndCategoryApi,
  getFipeVehiclesByManufacturersAndCategoryApi,
} from '../api';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

export interface CategoryType {
  id: string;
  description: string;
  subtypes?: {
    id: string;
    description: string;
  }[];
}

export interface ServiceType {
  id: string;
  description: string;
}

export interface ManufacturerType {
  id: string;
  name: string;
}

export interface VehicleType {
  id: string;
  name: string;
}

export interface ModelType {
  id: string;
  name: string;
}

export function useFipeContractor() {
  const [manufacturerTypes, setManufacturerTypes] = useState<ManufacturerType[]>([]);
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [vehiclesTypes, setVehiclesTypes] = useState<VehicleType[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [modelsTypes, setModelsTypes] = useState<ModelType[]>([]);
  const [loadingModels, setLoadingModels] = useState(false);
  const [contractorCategoryTypes, setContractorCategoryTypes] = useState<CategoryType[]>();

  const { addToast } = useToast();

  const getContractorCategoryTypes = useCallback(async () => {
    try {
      const { data } = await getContractorVehicleTypesApi();
      const filteredData = data.filter(d => !d.vehicle_contractor_type_id);
      setContractorCategoryTypes(filteredData);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a categoria do prestador');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [addToast]);

  const getManufacturers = useCallback(
    async (categoryName: string) => {
      try {
        setLoadingManufacturer(true);
        const { data } = await getFipeManufacturersByCategoryApi(categoryName);
        setManufacturerTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a marca');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingManufacturer(false);
      }
    },
    [addToast],
  );

  const getVehicles = useCallback(
    async (categoryName: string, manufactureId: string) => {
      try {
        setLoadingVehicles(true);
        const { data } = await getFipeVehiclesByManufacturersAndCategoryApi(categoryName, manufactureId);
        setVehiclesTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o modelo');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingVehicles(false);
      }
    },
    [addToast],
  );

  const getModels = useCallback(
    async (categoryName: string, manufactureId: string, vehicleId: string) => {
      try {
        setLoadingModels(true);
        const { data } = await getFipeModelsByVehiclesAndManufacturersAndCategoryApi(
          categoryName,
          manufactureId,
          vehicleId,
        );
        setModelsTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o ano');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingModels(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    getContractorCategoryTypes();
  }, [getContractorCategoryTypes]);

  return {
    manufacturerTypes,
    loadingManufacturer,
    vehiclesTypes,
    loadingVehicles,
    modelsTypes,
    loadingModels,
    getModels,
    getManufacturers,
    getVehicles,
    contractorCategoryTypes,
  };
}
