const errorMessagesImportCSV = {
  'message.costumer.batch.username.mandatory': 'Coluna CPF/CNPJ é obrigatória.',
  'message.costumer.batch.username.length': 'Coluna CPF/CNPJ com tamanho inválido.',
  'message.costumer.batch.givenname.mandatory': 'Coluna nome é obrigatória.',
  'message.costumer.batch.givenname.length': 'Coluna nome com tamanho inválido.',
  'message.costumer.batch.familyname.mandatory': 'Coluna sobrenome é obrigatória.',
  'message.costumer.batch.familyname.length': 'Coluna sobrenome com tamanho inválido.',
  'message.vehicle.costumer.licenseplate.mandatory': 'Coluna placa é obrigatória.',
  'message.vehicle.costumer.licenseplate.length': 'Coluna placa com tamanho inválido.',
  'message.vehicle.costumer.model.mandatory': 'Coluna modelo é obrigatória.',
  'message.vehicle.costumer.model.length': 'Coluna modelo com tamanho inválido.',
  'message.vehicle.costumer.manufacturer.mandatory': 'Coluna montadora é obrigatória',
  'message.vehicle.costumer.manufacturer.length': 'Coluna montadora com tamanho inválido.',
  'message.vehicle.costumer.color.mandatory': 'Coluna cor é obrigatória.',
  'message.vehicle.costumer.color.length': 'Coluna cor com tamanho inválido.',
  'message.vehicle.costumer.category.mandatory': 'Coluna categoria é obrigatória.',
  'message.vehicle.costumer.category.length': 'Coluna categoria com tamanho inválido.',
  'message.vehicle.costumer.situation.mandatory': 'Coluna situação é obrigatória.',
  'message.vehicle.costumer.situation.length': 'Coluna situação com tamanho inválido.',
  'message.vehicle.costumer.licenseplate.or.vin.mandatory': 'O registro deve conter ao menos placa ou chassi.',
};

export default errorMessagesImportCSV;
