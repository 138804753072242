import { useState, useEffect, useCallback } from 'react';
import { loadEmployees, resetContractor } from '../api';
import { EmployeesResponse } from '~/types';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

export function useEmployees(organizationId?: string) {
  const [employees, setEmployees] = useState<EmployeesResponse>([]);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const getEmployees = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await loadEmployees(organizationId);
      setEmployees(data);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar funcionários');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, organizationId]);

  useEffect(() => {
    if (organizationId) {
      getEmployees();
    } else {
      setEmployees([]);
    }
  }, [getEmployees, organizationId]);

  const resetEmail = useCallback(contractorId => {
    if (contractorId) return resetContractor(contractorId, 'EMAIL');
    else return Promise.resolve();
  }, []);

  const resetPhone = useCallback(contractorId => {
    if (contractorId) return resetContractor(contractorId, 'PHONE_NUMBER');
    else return Promise.resolve();
  }, []);

  return {
    employees,
    loading,
    resetEmail,
    resetPhone,
    getEmployees,
  };
}

export default useEmployees;
