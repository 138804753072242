import React from 'react';

import { Refresh } from '@material-ui/icons';
import { BaseButton, Icon } from './styles';


export interface ButtonProps {
  label?: string
  loading?: boolean
  [key : string] : any
}

export default function Button({ label, loading, ...rest } : ButtonProps) {
  return (
    <BaseButton {...rest} disabled={loading}>
      {loading ? (
        <Icon>
          <Refresh />
        </Icon>
      ) : (
        label
      )}
    </BaseButton>
  );
}
