import styled from 'styled-components';
import {
  VerticalTimeline as VerticalTimelineBase,
  VerticalTimelineElement as VerticalTimelineElementBase,
} from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';
import Colors from '~/styles/colors';

export const VerticalTimeline = styled(VerticalTimelineBase)`
  &:before {
    background: ${Colors.darkBlue};
  }
`;

export const VerticalTimelineElementFinish = styled(VerticalTimelineElementBase).attrs({
  iconStyle: { background: Colors.done, color: Colors.white },
})`
  .vertical-timeline-element-date {
    color: ${Colors.black};
  }
`;

export const ContentType = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Type = styled.h3`
  display: inline;
  margin: 0;
`;

export const MapButton = styled.p`
  display: inline;
  margin: 0;
`;

export const UpBox = styled.div`
  opacity: 1;
  background-color: ${Colors.darkBlue};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }
`;
