import React, { useState, useCallback } from 'react';
import { CoolDownHover, CopyIconWrapper, CopiedIcon, ToCopyIcon } from './style';
import Colors from '~/styles/colors';

interface Coloring {
  copied?: string;
  hover?: string;
  hoverCopied?: string;
}

interface CopyableProps {
  textGenerator: () => string;
  children: any;
  copiedTime?: number;
  coloring?: Coloring;
}

export default function CopyableToClipboard({
  textGenerator = () => 'Sem dados.',
  children,
  copiedTime = 2000,
  coloring = {},
}: CopyableProps) {
  const defaultColors = {
    copied: Colors.rgba.secondary(0.5),
    hover: Colors.rgba.secondary(0.5),
    hoverCopied: Colors.rgba.secondary(0.1),
  };

  const colors = { ...defaultColors, ...coloring };

  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(
    (textToCopy?: string) => {
      navigator.clipboard.writeText(String(textToCopy)).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), copiedTime);
      });
    },
    [setCopied, copiedTime],
  );

  return (
    <CoolDownHover
      onClick={() => handleCopy(textGenerator && textGenerator())}
      activated={copied}
      activatedColor={colors.copied}
      hoverColor={colors.hover}
      hoverActivatedColor={colors.hoverCopied}
    >
      <div style={{ display: 'flex' }}>
        {children}
        <div style={{ marginLeft: 'auto' }}>
          <CopyIcon copied={copied} />
        </div>
      </div>
    </CoolDownHover>
  );
}

function CopyIcon({ copied }: { copied: boolean }) {
  return <CopyIconWrapper copied={copied}>{copied ? <CopiedIcon /> : <ToCopyIcon />}</CopyIconWrapper>;
}
