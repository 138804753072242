import React, { useState, useEffect, useCallback, useRef } from 'react';
import XLSX from 'xlsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Grid, FormControl, FormHelperText, TextField, Tooltip, Button as ButtonMUI } from '@material-ui/core';

import { FaTrash } from 'react-icons/fa';

import { customerLoad, inactivateAssociationVehiclesApi, loadAssociations } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import errorMessagesImportCSV from '~/config/messagesImportCSV';
import Button from '~/components/Button';
import Inputfile from '~/components/Inputfile';
import Snackbar, { SeverityType } from '~/components/Snackbar';
import { Form, DivAlert } from './styles';
import { AssociationsResponse, CSVImportType, CustomerUploadResponse } from '~/types';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { ModalConfirm } from '~/components/ModalConfirm';
import { useToast } from '~/services/hooks/toast';
import { columnContentToOriginalValue } from '~/util/importFile';
import { Title } from '~/components/Title';
import { SyncHinovaDialog } from './components/SyncHinovaDialog';

const readCsv = XLSX.read;
const sheetToJson = XLSX.utils.sheet_to_json;

const ImportCSVUsers = () => {
  const [associationSelected, setAssociationSelected] = useState<AssociationsResponse | null>(null);
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [contentFile, setContentFile] = useState('');
  const [fileName, setFileName] = useState('Selecione um arquivo');
  const [loading, setLoading] = useState(false);
  const [modaHinovaOpen, setModaHinovaOpen] = useState(false);

  const [errorResponse, setErrorResponse] = useState<CustomerUploadResponse[] | string>();
  const [errorAssociation, setErrorAssociation] = useState('');
  const [errorFile, setErrorFile] = useState('');
  const [modalInactiveVehicle, setModalInactiveVehicle] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');

  const inputRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToast();

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await loadAssociations();
        setAssociations(data || []);
      } catch (err) {
        const error = handleApiErrorResponse(err);
        setSeverity('warning');
        setMessageSnack(error?.applicationErrorMessage || error?.apiError);
        setOpenSnackbar(true);
      }
    }

    loadData();
  }, []);

  const cleanInput = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  const handleFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setErrorFile('');

      const reader = new FileReader();

      reader.onload = async event => {
        const text = event.target?.result?.toString() ?? '';

        setContentFile(text);
        cleanInput();
      };

      if (e.target.files && e.target.files[0]) {
        reader.readAsText(e.target.files[0]);
        setFileName(e.target.files[0].name);
      } else {
        setFileName('Selecione um arquivo');
        setContentFile('');
        cleanInput();
      }
    },
    [cleanInput],
  );

  const translateErrors = useCallback((errorMessage: string) => {
    return errorMessage in errorMessagesImportCSV ? errorMessagesImportCSV[errorMessage] : errorMessage;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setLoading(true);
      setErrorAssociation('');
      setErrorFile('');
      setErrorResponse('');

      if (!associationSelected) {
        setErrorAssociation('Selecione uma empresa');
        setLoading(false);
        return;
      }

      if (!contentFile) {
        setErrorFile('Arquivo não carregado ou vazio');
        setLoading(false);
        return;
      }

      const workbook = columnContentToOriginalValue(readCsv(contentFile, { type: 'binary' }), 'CPF_CNPJ');

      const sheetAsJson = sheetToJson<CSVImportType>(workbook.Sheets.Sheet1).map(
        ({ __rowNum__, CPF_CNPJ, ...rest }) => {
          return {
            __rowNum__,
            CPF_CNPJ,
            ...rest,
          };
        },
      );

      customerLoad(associationSelected.id, sheetAsJson)
        .then(() => {
          setMessageSnack('Importação realizada com sucesso.');
          setSeverity('success');
          setOpenSnackbar(true);
        })
        .catch(error => {
          setErrorResponse(error.response.data);
        })
        .finally(() => {
          setFileName('Selecione um arquivo');
          setAssociationSelected(null);
          setContentFile('');
          setLoading(false);
          cleanInput();
        });
    },
    [associationSelected, cleanInput, contentFile],
  );

  const handleInactiveVehicles = async () => {
    setModalInactiveVehicle(true);
  };

  const handleConfirmInactiveVehicles = async () => {
    try {
      setLoading(true);
      await inactivateAssociationVehiclesApi(associationSelected?.id);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao inativar os veículos');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoading(false);
      setModalInactiveVehicle(false);
    }
  };

  const handleToggleModal = () => {
    setModaHinovaOpen(prev => !prev);
  };

  return (
    <>
      <Title>Importar carga de usuários</Title>

      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={associationSelected?.id ? 5 : 6}>
            <FormControl style={{ width: '100%' }} error={!!errorAssociation}>
              <CustomAutocomplete
                id='combo-box-associations'
                options={associations}
                getOptionLabel={item => item.code}
                renderInput={params => <TextField {...params} label='Selecione uma empresa' />}
                onChange={(_, value) => {
                  setAssociationSelected(value);
                }}
                value={associationSelected ?? null}
              />
              <FormHelperText>{errorAssociation}</FormHelperText>
            </FormControl>
          </Grid>

          {associationSelected && (
            <Grid item xs={1}>
              <Tooltip title='Inativar todos os veículos dessa empresa'>
                <ButtonMUI onClick={handleInactiveVehicles}>
                  <FaTrash />
                </ButtonMUI>
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }} error={!!errorFile}>
              <Inputfile
                accept='.csv'
                id='csvImport'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFile(e)}
                fileName={fileName}
                label='CSV de importação'
                error={!!errorFile}
                clearFile={() => {
                  setFileName('Selecione um arquivo');
                  setContentFile('');
                  cleanInput();
                }}
                ref={inputRef}
              />

              <FormHelperText>{errorFile}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify='flex-end'>
          <Grid item xs={4}>
            <Button type='submit' filled label='Importar' loading={loading} />
          </Grid>
        </Grid>
      </Form>

      <Grid container>
        <Grid item xs={4}>
          <Button type='submit' filled label='Sincronizar Hinova' onClick={handleToggleModal} />
        </Grid>
      </Grid>

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />

      {errorResponse && errorResponse.length > 0 && (
        <DivAlert>
          <Alert severity='error'>
            <AlertTitle>Erro</AlertTitle>

            {Array.isArray(errorResponse)
              ? errorResponse.map(resp => {
                  return (
                    <ul key={resp.index}>
                      <li>
                        <strong>{`Linha: ${resp.index || 'número de linha não encontrada'}`}</strong>
                      </li>
                      <li>{translateErrors(resp.message)}</li>
                    </ul>
                  );
                })
              : errorResponse}
          </Alert>
        </DivAlert>
      )}

      <ModalConfirm
        onConfirm={handleConfirmInactiveVehicles}
        onCancel={() => {
          setModalInactiveVehicle(false);
        }}
        open={modalInactiveVehicle}
        title='Tem certeza de que deseja INATIVAR todos os veículos dessa empresa?'
      />

      <SyncHinovaDialog open={modaHinovaOpen} onClose={handleToggleModal} closeModal={handleToggleModal} fullWidth />
    </>
  );
};

export default ImportCSVUsers;
