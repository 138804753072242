import React, { useState, forwardRef, Ref } from 'react';
import { Snackbar as BaseSnackbar, Slide, SlideProps } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const TransitionLeft = forwardRef((props: SlideProps, ref: Ref<unknown>) => <Slide {...props} ref={ref} direction='left' />);

const TransitionUp = forwardRef((props: SlideProps, ref: Ref<unknown>) => <Slide {...props} ref={ref} direction='up' />);

const TransitionRight = forwardRef((props: SlideProps, ref: Ref<unknown>) => <Slide {...props} ref={ref} direction='right' />);

const TransitionDown = forwardRef((props: SlideProps, ref: Ref<unknown>) => <Slide {...props} ref={ref} direction='down' />);


export type TransactionType = 'up' | 'left' | 'right' | 'down';
export type SeverityType = 'success' | 'info' | 'warning' | 'error';

export interface SnackbarProps {
  open: boolean;
  onClose?: () => void;
  message?: string;
  transition?: TransactionType;
  severity?: SeverityType;
  children?: any;
}

export default function Snackbar({ open, onClose, message, transition = 'up', severity = 'success', children }: SnackbarProps) {
  const transitionOptions = {
    left: TransitionLeft,
    up: TransitionUp,
    right: TransitionRight,
    down: TransitionDown,
  };
  const [directionTransition] = useState(() => transitionOptions[transition]);

  return (
    <BaseSnackbar open={open} onClose={onClose} TransitionComponent={directionTransition}>
      <Alert onClose={onClose} severity={severity} closeText='Fechar'>
        {message ?? children}
      </Alert>
    </BaseSnackbar>
  );
}
