import { CsvBuilder } from 'filefy';
import { OpportunitiesResponse } from '~/types';
import { ColumnCustom } from '~/components/Table';

interface exportCustomCSVParams {
  csvColumns: ColumnCustom<OpportunitiesResponse[number]>[];
  csvContent: OpportunitiesResponse;
  filenameOutput: string;
}

const exportCustomCSV = ({ csvColumns, csvContent, filenameOutput }: exportCustomCSVParams) => {
  const newColumns = csvColumns.filter(columnDef => {
    return (
      (!columnDef.hidden && columnDef.field && columnDef.field !== 'actions') ||
      columnDef.export ||
      columnDef.exportOnlyCSV
    );
  });

  const headers = newColumns.map(newColumn => String(newColumn.title));

  //Execute function to render
  const newData = csvContent.map(rowData => {
    return newColumns.map(columnDef => {
      let exporter;

      if (columnDef.customExport && typeof columnDef.customExport === 'function') {
        exporter = columnDef.customExport;
      } else if (columnDef.render && typeof columnDef.render === 'function') {
        exporter = columnDef.render;
      }

      if (exporter) {
        const returnFunction = exporter(rowData, 'row');
        return returnFunction?.props?.children ?? returnFunction;
      }

      return rowData[columnDef.field!];
    });
  });

  new CsvBuilder(filenameOutput).setDelimeter(';').setColumns(headers).addRows(newData).exportFile();
};

export default exportCustomCSV;
