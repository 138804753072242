import { useEffect, useState } from 'react';
import { OrderDetailsResponse } from '~/types';
import { loadOrderDetails } from '../api';

export function useOrderDetails(orderId?: string, type?: string) {
  const [details, setDetails] = useState<OrderDetailsResponse>([]);

  useEffect(() => {
    if (orderId) {
      loadOrderDetails(orderId, type)
        .then(response => response.data)
        .then(setDetails);
    } else {
      setDetails([]);
    }
  }, [orderId, type]);

  return details;
}

export default useOrderDetails;
