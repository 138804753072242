import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { loadContractorsByLatLng, loadOpportunities } from '~/services/api';
import {
  Divider,
  ContainerModal,
  TitleModal,
  Minitext,
  RefreshButton,
  MapControls,
  SimplePin,
  OrderPin,
  InfoButton,
} from './styles';
import Colors from '~/styles/colors';
import ContractorInfoModalContent from './components/ContractorInfo';
import { handleApiErrorResponse } from '~/services/handleErrors';
import {
  OpportunityStatusRequest as Status,
  OpportunitiesResponse,
  OpportunityResponse,
  CustomRoles,
} from '../../types';
import useInterval from '../../services/hooks/useInterval';
import { appendQueryParamsTo } from '../../services/urlutils';
import Snackbar from '../../components/Snackbar/index';
import { SeverityType } from '../../components/Snackbar/index';
import HelpModal from './components/HelpModal/index';
import OrderInfoModalContent from './components/OrderInfo/index';
import { minutesInMillis, dateDiffFromNow } from './components/utils/date';
import Modal from '~/components/ModalNewVehicle';
import { Title } from '~/components/Title';

interface AllocationInfo {
  id: string;
  service: string;
}

export interface ContractorsLocation {
  id: string;
  vehicle_id: string;
  distance: number;
  latitude: number;
  longitude: number;
  uploaded_at: string;
  has_order: boolean;
  online: boolean;
  vehicle_model: string;
  vehicle_plate: string;
  allocation?: AllocationInfo;
}

type MapRequestStatus = 'IDLE' | 'SUCCESS' | 'ERROR';

const defaultMapCenter = {
  lat: -12.9453842,
  lng: -38.4942182,
};

const defaultRefreshInterval = 50 * 1000;

const defaultZoom = 13;

const getColorForRunningOrder = (opportunity: OpportunityResponse) =>
  opportunity?.status !== 'IN_EXECUTION' ? getColorFromTimingRules(opportunity?.updated_at) : Colors.picktowBlue;

const getColorFromTimingRules = (updatedAt?: number) => {
  if (updatedAt === undefined) return Colors.grey;

  const diff = dateDiffFromNow(updatedAt);

  if (diff > minutesInMillis(50)) return Colors.pureRed;
  if (diff > minutesInMillis(40)) return Colors.pureYellow;
  if (diff > minutesInMillis(30)) return Colors.pureLightBlue;
  if (diff > minutesInMillis(5)) return Colors.pureGreen;
  return Colors.black;
};

const orderStatuses = [Status.ALLOCATING, Status.MANUAL_ALLOCATION, Status.ON_GOING, Status.IN_EXECUTION];

export default function MapOpportunities() {
  const [positionCenter, setPositionCenter] = useState<{ lat: number; lng: number }>();
  const [zoomPosition, setZoomPosition] = useState(defaultZoom);
  const [mapStatus, setMapStatus] = useState<ChangeEventValue>();

  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openModalAllocationInfos, setOpenModalAllocationInfos] = useState(false);
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [openModalOpportunity, setOpenModalOpportunity] = useState(false);

  const [selectedContractor, setSelectedContractor] = useState<ContractorsLocation>();
  const [selectedOpportunity, setSelectedOpportunity] = useState<OpportunityResponse>();

  const [contractorsLocation, setContractorsLocation] = useState<ContractorsLocation[]>([]);
  const [opportunities, setOpportunities] = useState<OpportunitiesResponse>([]);

  const [isLoading, setIsLoading] = useState<{ loading: boolean; status: MapRequestStatus }>({
    loading: false,
    status: 'IDLE',
  });

  const role = useSelector(state => state.auth.user['custom:role']);

  const timeoutId = useRef(0);

  const pinData = useMemo(() => {
    const contractorsById = Object.fromEntries(contractorsLocation.map(contractor => [contractor.id, contractor]));

    const getOpportunityLocation = (opportunity: OpportunityResponse) => {
      return (
        opportunity.coordinates.filter(coordinate => coordinate.position === 'SOURCE')?.[0] ??
        opportunity.coordinates.filter(coordinate => coordinate.position === 'DESTINATION')?.[0]
      );
    };

    const availableContractors = contractorsLocation
      .filter(contractor => !contractor.has_order)
      .map(contractor => {
        return {
          lat: contractor.latitude,
          lng: contractor.longitude,
          color: contractor.online ? Colors.picktowBlue : Colors.grey,
          key: `ContractorPin#${contractor.id}.${contractor.latitude}.${contractor.longitude}`,
          data: contractor,
        };
      });

    const runningOrders = opportunities
      .filter(opportunity => [Status.ON_GOING, Status.IN_EXECUTION].includes(opportunity.status as Status))
      .map(opportunity => {
        return {
          ...opportunity,
          contractorData: contractorsById[opportunity.contractor_id],
        };
      })
      .map(opportunity => {
        return {
          ...opportunity,
          location: getOpportunityLocation(opportunity),
        };
      })
      .map(opportunity => {
        return {
          lat: opportunity?.contractorData?.latitude ?? opportunity.location.latitude,
          lng: opportunity?.contractorData?.longitude ?? opportunity.location.longitude,
          color: getColorForRunningOrder(opportunity),
          key: `ContractorOrderPin#${opportunity.id}.${opportunity.contractor_id}`,
          data: opportunity,
          text: opportunity.number,
        };
      });

    const allocatingOrders = opportunities
      .filter(opportunity => [Status.ALLOCATING, Status.MANUAL_ALLOCATION].includes(opportunity.status as Status))
      .map(opportunity => {
        return {
          ...opportunity,
          location: getOpportunityLocation(opportunity),
        };
      })
      .map(opportunity => {
        return {
          lat: opportunity.location.latitude,
          lng: opportunity.location.longitude,
          color: opportunity.status === Status.ALLOCATING ? Colors.purple : Colors.orange,
          key: `OpportunityPin#${opportunity.id}.${opportunity.contractor_id}`,
          data: opportunity,
          text: opportunity.number,
        };
      });
    return { availableContractors, runningOrders, allocatingOrders };
  }, [contractorsLocation, opportunities]);

  const location = useLocation();

  const getFromQueryParams = useCallback(
    (key: string) => {
      return new URLSearchParams(location.search.slice(0)).get(key) ?? '';
    },
    [location],
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(() => {
      //
    });
  }, []);

  useEffect(() => {
    const lat = Number.parseFloat(getFromQueryParams('lat'));
    const lng = Number.parseFloat(getFromQueryParams('lng'));
    const zoom = Number.parseInt(getFromQueryParams('zoom'));
    setPositionCenter(lat && lng ? { lat, lng } : defaultMapCenter);
    setZoomPosition(zoom ?? defaultZoom);
  }, [setPositionCenter, setZoomPosition, getFromQueryParams]);

  const getData = useCallback(async () => {
    let currentStatus = 'IDLE';

    try {
      setIsLoading({ loading: true, status: 'IDLE' });

      const [contractorsResponse, opportunitiesResponse] = await Promise.all([
        loadContractorsByLatLng(defaultMapCenter.lat, defaultMapCenter.lng),
        loadOpportunities(orderStatuses),
      ]);

      if (contractorsResponse.status === 200)
        setContractorsLocation(contractorsResponse.data.filter(contractor => contractor.id));

      if (opportunitiesResponse.status === 200) setOpportunities(opportunitiesResponse.data);

      setIsLoading({ loading: false, status: 'SUCCESS' });
      currentStatus = 'SUCCESS';
    } catch (err) {
      const error = handleApiErrorResponse(err);

      setSeverity('warning');
      setMessageSnack(
        (error?.applicationErrorMessage || error?.apiError) +
          ': não foi possível carregar prestadores e oportunidades. Tente recarregar a página.',
      );
      setOpenSnackbar(true);

      setIsLoading({ loading: false, status: 'ERROR' });
      currentStatus = 'ERROR';
    } finally {
      timeoutId.current = setTimeout(
        () => setIsLoading({ loading: false, status: 'IDLE' }),
        currentStatus === 'ERROR' ? 2000 : 600,
      );
    }
  }, []);

  const getRefreshRate = useCallback(() => {
    const refreshIntervalToValidValue = (value: number) => Math.max(1000, value);
    const paramsValue = Number.parseInt(getFromQueryParams('refresh_interval_ms'));
    return !Number.isNaN(paramsValue) ? refreshIntervalToValidValue(paramsValue) : defaultRefreshInterval;
  }, [getFromQueryParams]);

  const refresh = () => {
    getData();
  };

  useInterval(refresh, getRefreshRate());

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  return (
    <div>
      <Title>Mapa de Oportunidades</Title>
      <Divider height={40} />

      <>
        <div style={{ height: '75vh', width: '100%' }} id='map'>
          <MapControls>
            <Tooltip arrow placement='top' title='Legenda'>
              <div>
                <InfoButton onClick={() => setOpenModalHelp(true)} />
              </div>
            </Tooltip>

            <Tooltip arrow placement='top' title='Atualizar prestadores'>
              <div>
                <RefreshButton spin={isLoading.loading ? 1 : undefined} status={isLoading.status} onClick={refresh} />
              </div>
            </Tooltip>
          </MapControls>

          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? '' }}
            defaultCenter={defaultMapCenter}
            defaultZoom={13}
            center={positionCenter}
            zoom={zoomPosition}
            onChange={setMapStatus}
            yesIWantToUseGoogleMapApiInternals
          >
            {pinData.availableContractors.map(el => (
              <SimplePin
                key={el.key}
                color={el.color}
                lat={el.lat}
                lng={el.lng}
                onClick={() => {
                  if (role === CustomRoles.B2B) {
                    return;
                  }

                  setSelectedContractor(el.data);
                  setOpenModalAllocationInfos(true);
                }}
              />
            ))}

            {pinData.runningOrders.map(el => (
              <OrderPin
                colorHex={el.color}
                key={el.key}
                lat={el.lat}
                lng={el.lng}
                onClick={() => {
                  if (el.data.contractorData && role !== CustomRoles.B2B) {
                    setSelectedContractor(el.data.contractorData);
                    setOpenModalAllocationInfos(true);
                  } else {
                    setSelectedOpportunity(el.data);
                    setOpenModalOpportunity(true);
                  }
                }}
              >
                {el.text}
              </OrderPin>
            ))}

            {pinData.allocatingOrders.map(el => (
              <OrderPin
                key={el.key}
                colorHex={el.color}
                lat={el.lat}
                lng={el.lng}
                onClick={() => {
                  setSelectedOpportunity(el.data);
                  setOpenModalOpportunity(true);
                }}
              >
                {el.text}
              </OrderPin>
            ))}
          </GoogleMapReact>

          <pre>
            {mapStatus && (
              <Minitext>
                <span>Latitude: {mapStatus.center.lat}&nbsp;</span>
                <span>Longitude: {mapStatus.center.lng}&nbsp;</span>
                <span>Zoom: {mapStatus.zoom}x&nbsp;</span>
                <span>Atualização: {(getRefreshRate() / 1000).toFixed(1)}s&nbsp;</span>
                <a
                  rel='noopener noreferrer'
                  href={appendQueryParamsTo('/map-opportunities', {
                    refresh_interval_ms: getRefreshRate(),
                    lat: mapStatus.center.lat,
                    lng: mapStatus.center.lng,
                    zoom: mapStatus.zoom,
                  })}
                  target='_blank'
                >
                  Criar mapa com as configurações atuais.
                </a>
              </Minitext>
            )}
          </pre>

          <Divider height={15} />

          <Modal
            onConfirm={() => {
              setOpenModalAllocationInfos(false);
            }}
            onCancel={() => {
              setOpenModalAllocationInfos(false);
            }}
            open={openModalAllocationInfos}
          >
            <ContainerModal>
              <TitleModal>Informações da Oportunidade</TitleModal>
              <Divider height={15} />
              {selectedContractor && <ContractorInfoModalContent contractor={selectedContractor} />}
            </ContainerModal>
          </Modal>

          <Modal
            onConfirm={() => {
              setOpenModalOpportunity(false);
            }}
            onCancel={() => {
              setOpenModalOpportunity(false);
            }}
            open={openModalOpportunity}
          >
            <TitleModal>Detalhes da Oportunidade</TitleModal>
            <Divider height={15} />
            {selectedOpportunity && <OrderInfoModalContent order={selectedOpportunity} />}
          </Modal>

          <Modal
            onConfirm={() => {
              setOpenModalHelp(false);
            }}
            onCancel={() => {
              setOpenModalHelp(false);
            }}
            open={openModalHelp}
          >
            <TitleModal>Ajuda</TitleModal>
            <HelpModal />
          </Modal>
        </div>
      </>
      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />
    </div>
  );
}
