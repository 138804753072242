import styled from 'styled-components';

import Colors from '~/styles/colors';
import BaseInputComponent from '~/components/InputComponent';

export const Subtitle = styled.span`
  font-size: 20px;
  color: ${Colors.primary};
  text-align: center;
`;

export const Form = styled.div`
  margin: 20px 0;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const InputComponent = styled(BaseInputComponent)`
  width: 200px;
  margin: 0;
`;
