import React from 'react';
import { Paper, Grid, Typography, Divider, useTheme } from '@material-ui/core';

export interface DataOptions {}

export type Data = [string, string | number, DataOptions?];

export interface Options {}

export interface InfoCardProps {
  title: string;
  data: Data[];
  options?: Options;
}

export function InfoCard(props: InfoCardProps) {
  const theme = useTheme();

  return (
    <Paper style={{ marginBottom: 30, overflow: 'hidden' }}>
      <Grid>
        <Typography
          variant='h5'
          color='secondary'
          style={{ fontSize: '16px', fontWeight: 'bold', padding: 20, backgroundColor: theme.palette.primary.main }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Divider />
      <Grid container style={{ padding: 20 }} spacing={3}>
        {props.data.map((d, index) => (
          <Info key={`${index}`} title={d[0]} value={typeof d[1] === 'boolean' ? (d[1] ? 'SIM' : 'NÃO') : d[1]} />
        ))}
      </Grid>
    </Paper>
  );
}

export default InfoCard;

interface InfoProps {
  title: string;
  value: string | number;
  variant?: 'contained';
  color?: string;
}

function Info(props: InfoProps) {
  return (
    <Grid container item xs={4} direction='column' style={{ whiteSpace: 'nowrap' }}>
      <Grid item>
        <Typography variant='h6'>{props.title}</Typography>
      </Grid>
      {props.variant === 'contained' ? (
        <Grid item alignItems='center' container>
          <Paper style={{ backgroundColor: props.color, alignItems: 'center', padding: 5, flex: -1 }}>
            <Typography style={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '14px' }}>
              {props.value}
            </Typography>
          </Paper>
        </Grid>
      ) : (
        <Grid item>
          <Typography>{props.value}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
