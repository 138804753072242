/* eslint-disable @typescript-eslint/ban-types */
/*
  node_modules/material-table/types/index.d.ts
  RowData extends object
*/
import React, { forwardRef } from 'react';

import MaterialTable, { MaterialTableProps, Column, Options, Components } from 'material-table';

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import { Paper } from '@material-ui/core';

import { useStyles } from './styles';

export interface TableCustomOptions {
  removeShadow?: boolean;
  removeBottomBorder?: boolean;
  startWithDetailPanelOpen?: boolean;
  noLineBreakInHeader?: boolean;
}

export interface ColumnCustom<RowData extends object> extends Column<RowData> {
  exportOnlyCSV?: boolean;
  customExport?: (params: any) => string;
}

export interface TableProps<RowData extends object> extends Omit<MaterialTableProps<RowData>, 'columns'> {
  columns: { [key: string]: string | ColumnCustom<RowData> };

  options?: Options<RowData>;

  customOptions?: TableCustomOptions;
}

export function Table<RowData extends object>({ customOptions, ...props }: TableProps<RowData>) {
  const classes = useStyles();

  const components: Components = {};

  if (customOptions?.removeShadow) components.Container = props => <Paper {...props} elevation={0} />;

  return (
    <div className={classes.tableContainer}>
      <MaterialTable
        {...props}
        columns={parseColumns(props.columns, customOptions)}
        localization={{
          ...props.localization,

          toolbar: {
            ...props.localization?.toolbar,
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar para CSV',
            exportPDFName: 'Exportar para PDF',
          },

          body: {
            deleteTooltip: 'Excluir',
            addTooltip: 'Adicionar',
            editTooltip: 'Editar',
            emptyDataSourceMessage: `Sem dados`,

            ...props.localization?.body,

            editRow: {
              cancelTooltip: 'Cancelar',
              saveTooltip: 'Confirmar',
              deleteText: 'Deseja excluir esta linha?',
              ...props.localization?.body?.editRow,
            },
          },

          pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'linhas por página',
            firstAriaLabel: 'Primeira Página',
            firstTooltip: 'Primeira Página',
            previousAriaLabel: 'Página Anterior',
            previousTooltip: 'Página Anterior',
            nextAriaLabel: 'Próxima Página',
            nextTooltip: 'Próxima Página',
            lastAriaLabel: 'Última Página',
            lastTooltip: 'Última Página',
            ...props.localization?.pagination,
          },

          header: {
            actions: 'Ações',
            ...props.localization?.header,
          },
        }}
        icons={{
          Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
          DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
          Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
          FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
          LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
          NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
          ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
          ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
          ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
          ...props.icons,
        }}
        options={{
          headerStyle: { fontWeight: 'bold', textAlign: 'center' },
          exportAllData: true,
          ...props.options,
        }}
        components={{
          ...components,
          ...props.components,
        }}
      />
    </div>
  );
}

function parseColumns<RowData extends object>(
  columns: { [key: string]: string | Column<RowData> },
  customOptions?: TableCustomOptions,
) {
  const customOptionsColumn: Column<RowData> = {};

  if (customOptions?.removeBottomBorder)
    customOptionsColumn.cellStyle = { ...customOptionsColumn.cellStyle, borderBottomWidth: 0 };
  if (customOptions?.noLineBreakInHeader)
    customOptionsColumn.headerStyle = { ...customOptionsColumn.headerStyle, whiteSpace: 'nowrap' };

  return Object.entries(columns).map(([field, options]) => {
    if (typeof options === 'string') {
      return { field, align: 'center' as const, title: options, ...customOptionsColumn };
    } else {
      return {
        field,
        align: 'center' as const,
        ...customOptionsColumn,
        cellStyle: {
          ...customOptionsColumn.cellStyle,
        },
        ...(options as Column<RowData>),
      };
    }
  });
}

export default Table;
