import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAoOBBjKMR7l89FBFKXw3JGRtPmYSZJZHE',
  authDomain: 'picktow-1e30e.firebaseapp.com',
  databaseURL: 'https://picktow-1e30e.firebaseio.com',
  projectId: 'picktow-1e30e',
  storageBucket: 'picktow-1e30e.appspot.com',
  messagingSenderId: '69293472283',
  appId: '1:69293472283:web:63e479c8df6118b3bdd6be',
  measurementId: 'G-WCJWH9FJ4V',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
