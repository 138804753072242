import { Tooltip } from '@material-ui/core'
import React from 'react'
import { formatCellPhone } from '~/util/format'

const WhatsappLink = ({ phone }: { phone: string }) =>
  <a href={`https://web.whatsapp.com/send?phone=${phone}`} target="_blank" rel="noopener noreferrer">
    {formatCellPhone(phone.replace('+55', ''))}
  </a>

export const WhatsappLinkWithTooltip = ({ phone }: { phone: string }) =>
  <Tooltip title={'Abrir no WhatsApp'}>
    <a href={`https://web.whatsapp.com/send?phone=${phone}`} target="_blank" rel="noopener noreferrer">
      {formatCellPhone(phone.replace('+55', ''))}
    </a>
  </Tooltip>

export default WhatsappLink