import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid).attrs({
  container: true,
  flex: 'column',
});

export const Title = styled(Typography).attrs({})`
  font-size: 12px;
  white-space: nowrap;
`;

export const Data = styled(Avatar).attrs({
  variant: 'rounded',
})`
  width: 100px;
  height: 100px;
`;

export const DataButton = Button;
