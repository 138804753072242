import React, { useState, ReactNode } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { IconButton, SwipeableDrawer, Container, Toolbar, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import LogoPicktow from '~/assets/svg/logo_p_picktow.svg';
import Menu from '~/components/Menu';

import { DivContainer, BoxLogo, AppBar, Main } from './styles';
import { ToastProvider } from '~/services/hooks/toast';

const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',

    '& thead': {
      backgroundColor: theme.palette.primary,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export interface DefaultLayoutProps {
  children: NonNullable<ReactNode>;
}

export function DefaultLayout({ children }: DefaultLayoutProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (status?: boolean) => {
    if (status === undefined) setOpen(!open);
    else setOpen(status);
  };

  return (
    <ToastProvider>
      <DivContainer className={classes.root}>
        <AppBar position='fixed' color='primary' className={classes.appBar}>
          <Toolbar>
            <IconButton edge='start' color='inherit' aria-label='menu' onClick={() => toggleDrawer()}>
              <MenuIcon />
            </IconButton>

            <BoxLogo>
              <NavLink to='/'>
                <img src={LogoPicktow} alt='Picktow' />
              </NavLink>
            </BoxLogo>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          variant='permanent'
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          open={open}
        >
          <Toolbar />
          <Menu menuIsOpen={open} />
        </SwipeableDrawer>

        <Main className={classes.content}>
          <Toolbar />
          <Container maxWidth='lg'>{children}</Container>
        </Main>
      </DivContainer>
    </ToastProvider>
  );
}

export default DefaultLayout;
