import { useState, useEffect, useCallback } from 'react';
import { ServicesResponse } from '~/types';
import { requestBenefit, resetBenefit } from '~/store/ducks/customerBenefit';
import { useDispatch } from 'react-redux';

export function useBenefit(
  services?: ServicesResponse[],
  vehicles?: {
    id: string;
    social_security: string;
    association_id: string;
    association_code: string;
    association_trading_name: string;
    vehicle_customer_type_id: string;
    vehicle_customer_type_description: string;
    license_plate: string;
    model: string;
    model_year?: string;
    color: string;
    manufacture: string;
    vin: string;
    state: string;
    city: string;
    status: string;
    removed: boolean;
    created_at: number;
    updated_at: number;
  }[],
) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const load = useCallback(() => {
    if (vehicles) {
      setLoading(true);
      vehicles.map(v => {
        return services?.map(service => {
          return dispatch(requestBenefit(v.association_id, service.id, v.social_security, v.id, v.vin));
        });
      });
      setLoading(false);
    } else {
      dispatch(resetBenefit());
    }
  }, [vehicles, dispatch, services]);

  useEffect(() => {
    load();
  }, [load, vehicles]);

  return {
    loading,
  };
}
