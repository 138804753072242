import React, { ReactChild } from 'react';

import LogoPicktow from '~/assets/svg/logo_p_picktow.svg';

import { DivContainer, Div, Logo } from './styles';

export default function AuthLayout({ children }: { children: ReactChild }) {
  return (
    <DivContainer>
      <Div>
        <Logo src={LogoPicktow} />
        {children}
      </Div>
    </DivContainer>
  );
}
