import React, { useState, useEffect, useMemo } from 'react';
import { InputLabel, Select, MenuItem, Paper, Typography, IconButton, Tooltip } from '@material-ui/core';
import {
  Info,
  LibraryAddCheck,
  EmojiTransportation,
  SupervisedUserCircle,
  RotateLeft,
  Storage,
  Add,
} from '@material-ui/icons';

import { ContractorsResponse } from '~/types';
import Table from '~/components/Table';

import { Form } from './styles';
import { ContractorDetailsDialog } from './components/ContractorDetailsDialog';
import Onboarding from './pages/Onboarding';
import Vehicles from './pages/Vehicles';
import Employees from './pages/Employees';
import { useContractors } from '~/services/hooks';
import { RouteChildrenProps } from 'react-router-dom';
import { formatCellPhone, formatCPF, removeFormat } from '~/util/format';
import { customFilterAndSearchContractors } from '~/util/customFilter';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import { ContractorRedisModal } from './components/ContractorRedisModal';
import { Title } from '~/components/Title';

export interface ContractorManagerParams {}

export interface ContractorManagerLocationState {}

export type ContractorManagerProps = RouteChildrenProps<ContractorManagerParams, ContractorManagerLocationState>;

export function ContractorManager(props: ContractorManagerProps) {
  const [selectedStatus, setSelectedStatus] = useState<ContractorsResponse['status'] | undefined>(
    props.location.hash.length ? (props.location.hash.slice(1) as ContractorsResponse['status']) : undefined,
  );
  const [selectedContractor, setSelectedContractor] = useState<ContractorsResponse>();
  const [showModal, setShowModal] = useState(false);
  const [isNewContractor, setIsNewContractor] = useState(false);

  const { contractors, loading, erase, getContractors } = useContractors(selectedStatus);

  const handleCloseModal = () => {
    setSelectedContractor(undefined);
    setIsNewContractor(false);
    getContractors();
  };

  const services = useMemo(() => {
    const contractorsServices = contractors
      .filter(contractor => (contractor.services?.length || 0) > 0)
      .map(contractors => contractors.services!);

    const allServices = contractorsServices.flat();

    const distinctServices = allServices
      .filter(
        (service, index, selfServices) =>
          index === selfServices.findIndex(selfService => selfService.id === service.id),
      )
      .reduce((acc, service) => {
        acc[service.description] = service.description;
        return acc;
      }, {});

    return distinctServices;
  }, [contractors]);

  const handleToggleModal = () => {
    setShowModal(prev => !prev);
  };

  const handleNewContractor = () => {
    setIsNewContractor(true);
  };

  useEffect(() => {
    props.history.push({ hash: selectedStatus });
  }, [selectedStatus, props.history]);

  return (
    <>
      <Title>Gestão de Prestadores</Title>

      <Form>
        <div>
          <InputLabel id='status'>Situação</InputLabel>
          <Select
            labelId='status'
            id='status-select'
            value={selectedStatus ? selectedStatus : 0}
            onChange={({ target: { value } }) =>
              setSelectedStatus(value === 0 ? undefined : (value as ContractorsResponse['status']))
            }
          >
            <MenuItem value={0}>Todas</MenuItem>
            <MenuItem value='VALIDATING'>Em Análise</MenuItem>
            <MenuItem value='APPROVED'>Aprovado</MenuItem>
            <MenuItem value='DENIED'>Reprovado</MenuItem>
          </Select>
        </div>

        <div>
          <Tooltip title='Prestadores alocados' arrow>
            <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleToggleModal}>
              <Storage />
            </IconButton>
          </Tooltip>

          <Tooltip title='Adicionar prestador' arrow>
            <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleNewContractor}>
              <Add />
            </IconButton>
          </Tooltip>
        </div>
      </Form>

      <Table
        columns={{
          name: 'Nome',
          id: {
            title: 'CPF',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
            render: rowData => formatCPF(rowData.id),
          },
          role: {
            title: 'Tipo',
            lookup: { ORG_ADMIN: 'Empresa', FREELANCER: 'Autônomo' },
          },
          organization: 'Empresa',
          services: {
            title: 'Serviços',
            lookup: services,
            render: rowData => rowData.services?.map(service => <p key={service.id}>{service.description}</p>),
            customFilterAndSearch: customFilterAndSearchContractors,
            sorting: false,
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          phone_number: {
            title: 'Telefone',
            cellStyle: {
              whiteSpace: 'nowrap',
            },
            render: rowData => formatCellPhone(removeFormat(rowData.phone_number?.replace('+55', ''))),
          },
          city: 'Cidade',
          _created_at_formatted: 'Criação',
          status: {
            title: 'Situação',
            filtering: false,
            align: 'center',
            lookup: { VALIDATING: 'Em Análise', APPROVED: 'Aprovado', DENIED: 'Reprovado' },
            render: contractor => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Paper
                  style={{
                    backgroundColor: {
                      VALIDATING: 'orange',
                      APPROVED: 'green',
                      DENIED: 'red',
                    }[contractor.status],
                    padding: '5px 10px',
                    maxWidth: 120,
                  }}
                >
                  <Typography
                    style={{
                      color: 'white',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    {
                      {
                        VALIDATING: 'Em Análise',
                        APPROVED: 'Aprovado',
                        DENIED: 'Reprovado',
                      }[contractor.status]
                    }
                  </Typography>
                </Paper>
              </div>
            ),
          },
        }}
        data={contractors}
        actions={[
          rowData => ({
            icon: () => <RotateLeft />,
            tooltip: 'apagar conta',
            onClick: () => erase(rowData.id),
            hidden: !!rowData.organization_id,
          }),
          rowData => ({
            icon: () => <Info />,
            tooltip: 'detalhes',
            onClick: (_, data) => setSelectedContractor((data as unknown) as ContractorsResponse),
            hidden: !rowData.organization_id,
          }),
          {
            icon: () => <LibraryAddCheck />,
            tooltip: 'onboarding',
            onClick: (_, data) => {
              const contractor = (data as unknown) as ContractorsResponse;
              props.history.push(`/contractor-manager/onboarding/${contractor.id}`, { contractor });
            },
          },
          {
            icon: () => <EmojiTransportation />,
            tooltip: 'veículos',
            onClick: (_, data) => {
              const contractor = (data as unknown) as ContractorsResponse;
              props.history.push(`/contractor-manager/vehicles/${contractor.id}`, { contractor });
            },
          },
          rowData => ({
            icon: () => <SupervisedUserCircle />,
            tooltip: 'funcionários',
            hidden: rowData.role === 'FREELANCER',
            onClick: (_, data) => {
              const contractor = (data as unknown) as ContractorsResponse;
              props.history.push(`/contractor-manager/employees/${contractor.organization_id}`, { contractor });
            },
          }),
        ]}
        localization={{
          body: {
            editRow: {
              deleteText: 'Deseja excluir o prestador?',
            },
            emptyDataSourceMessage: `Sem prestadores${
              selectedStatus
                ? {
                    VALIDATING: ' em análise',
                    APPROVED: ' aprovados',
                    DENIED: ' reprovados',
                  }[selectedStatus]
                : ''
            }`,
          },
          pagination: {
            labelRowsSelect: 'prestadores',
          },
        }}
        options={{
          showTitle: false,
          filtering: true,
          exportButton: true,
          exportFileName: `prestadores${
            selectedStatus
              ? {
                  VALIDATING: ' em análise',
                  APPROVED: ' aprovados',
                  DENIED: ' reprovados',
                }[selectedStatus]
              : ''
          }`,
        }}
        isLoading={loading}
      />

      <ContractorDetailsDialog
        contractor={selectedContractor}
        onClose={handleCloseModal}
        newContractor={isNewContractor}
        closeModal={handleCloseModal}
      />

      <Modal
        open={showModal}
        buttons={[
          <Button key='cancel' type='button' filled label='Fechar' loading={loading} onClick={handleToggleModal} />,
        ]}
        fullWidth
      >
        <ContractorRedisModal isOpen={showModal} />
      </Modal>
    </>
  );
}

ContractorManager.Onboarding = Onboarding;
ContractorManager.Vehicles = Vehicles;
ContractorManager.Employees = Employees;

export default ContractorManager;
