import React from 'react';
import { ColoredPin, HelpList, ColoredBox } from './styles';
import Colors from '~/styles/colors';
import { Hr } from '../styles';

function HelpModal() {
  return (
    <div>
      <div style={{ width: '40vw' }}>
        <HelpList>
          <li>
            <ColoredPin iconColor={Colors.picktowBlue} />
            <span>
              Prestador online e <strong>sem alocação</strong>.
            </span>
          </li>
          <li>
            <ColoredPin iconColor={Colors.grey} />
            <span>
              Prestador <strong>offline</strong>.
            </span>
          </li>
        </HelpList>

        <Hr />

        <HelpList>
          <li>
            <ColoredBox iconColor={Colors.purple}>2103110N48J</ColoredBox>
            <span>
              Ordem em <strong>Enviadas</strong>.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.orange}>2103110N48J</ColoredBox>
            <span>
              Ordem em <strong>Alocação Manual</strong>.
            </span>
          </li>
        </HelpList>

        <Hr />

        <HelpList>
          <li>
            <ColoredBox iconColor={Colors.black}>2103110N48J</ColoredBox>
            <span>
              Prestador com ordem aceita há menos de <strong>5 minutos</strong>.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.pureGreen}>2103110N48J</ColoredBox>
            <span>
              Prestador com ordem aceita entre <strong>5 e 30 minutos</strong> atrás.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.pureLightBlue}>2103110N48J</ColoredBox>
            <span>
              Prestador com ordem aceita entre <strong>30 e 40 minutos</strong> atrás.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.pureYellow}>2103110N48J</ColoredBox>
            <span>
              Prestador com ordem aceita entre <strong>40 e 50 minutos</strong> atrás.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.pureRed}>2103110N48J</ColoredBox>
            <span>
              Prestador com ordem aceita há mais de <strong>50 minutos</strong>.
            </span>
          </li>
          <li>
            <ColoredBox iconColor={Colors.grey}>Carregando...</ColoredBox>
            <span>
              Prestador alocado, ainda <strong>carregando</strong> número da ordem e tempo de execução.
            </span>
          </li>
        </HelpList>
      </div>
    </div>
  );
}

export default HelpModal;
