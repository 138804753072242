import styled from 'styled-components';
import Colors from '~/styles/colors';
import { Assignment, AssignmentTurnedIn } from '@material-ui/icons';

interface CoolDownHoverProps {
  activated: boolean;
  activatedColor: string;
  hoverColor: string;
  hoverActivatedColor: string;
}

export const CoolDownHover = styled.div.attrs((props: CoolDownHoverProps) => ({
  activated: props.activated,
  activatedColor: props.activatedColor,
  hoverColor: props.hoverColor,
  hoverActivatedColor: props.hoverActivatedColor,
}))<CoolDownHoverProps>`
  cursor: copy;
  transition: background-color 0.2s;
  background-color: ${props => (props.activated ? Colors.rgba.secondary(0.5) : '')};
  &:hover {
    background-color: ${props => (props.activated ? Colors.rgba.secondary(0.5) : Colors.rgba.secondary(0.1))};
  }
`;

export const ToCopyIcon = styled(Assignment)``;

export const CopiedIcon = styled(AssignmentTurnedIn)``;

interface CopyIconWrapperProps {
  copied: boolean;
}

export const CopyIconWrapper = styled.div.attrs((props: CopyIconWrapperProps) => ({ copied: props.copied }))<
  CopyIconWrapperProps
>`
  visibility: ${props => !props.copied && 'hidden'};
  ${CoolDownHover}:hover & {
    visibility: visible;
  }
`;
