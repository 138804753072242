import React from 'react';
import { Album, DirectionsRun, LocalGasStation, RvHookup, VpnKey } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { BatterySvgIcon } from '../../../../assets/svgComponent/BatteryIcon';

interface ServiceIconProps {
  service: string;
  defaultIcon?: any;
  style?: React.CSSProperties;
  iconColor?: string;
}

const ServiceIcon = ({ service, defaultIcon, iconColor, style }: ServiceIconProps) => {
  switch (service) {
    case 'REBOQUE': return <RvHookup style={{...style, color: iconColor}} />;
    case 'CHAVEIRO': return <VpnKey style={{...style, color: iconColor}} />;
    case 'TROCA DE PNEU': return <Album style={{...style, color: iconColor}} />;
    case 'PANE SECA': return <LocalGasStation style={{ ...style, color: iconColor }} />;
    case 'PANE ELÉTRICA - BATERIA': return <SvgIcon style={{...style}}><BatterySvgIcon color={iconColor}/></SvgIcon>;
    default: return defaultIcon ?? <DirectionsRun style={{...style, color: iconColor}} />;
  }
};

export default ServiceIcon;

