import styled from 'styled-components';

export const HeaderQuestions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0 10px;
`;

export const DivQuestion = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-end;

  && input {
    flex: 1;
  }

  && button {
    width: auto;
    height: 30px;
    padding: 0 20px;
    margin-left: 15px;
  }
`;

export const QuestionList = styled.div`
  display: flex;
  flex-direction: column;

  .rst__node {
    display: flex;

    .rst__lineBlock {
      flex: 0 0 auto;
    }

    .rst__nodeContent {
      left: auto !important;
      top: auto !important;
      position: relative !important;
      flex: 0 1 auto;
      width: 100%;
    }
  }

  .rst__rowTitle {
    font-weight: normal;
  }

  .rst__rowLabel {
    flex: 1;
  }

  .MuiInputBase-root {
    width: 100%;
  }
  .MuiButton-sizeSmall {
    height: 25px;
  }

  .MuiTypography-root {
    font-size: 10px;
  }
`;

export const DivActions = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 150px;
  }
`;
