import { useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';

import { CustomerVehicle } from '~/types';
import { getVehicleBySocialSecurityApi } from '../api';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

export function useCustomerVehicles(customerId?: string) {
  const [customerVehicles, setCustomerVehicles] = useState<CustomerVehicle[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);

  const { addToast } = useToast();

  const getVehicleBySocialSecurity = useCallback(
    async (socialSecurity?: string) => {
      if (!socialSecurity) {
        setCustomerVehicles([]);
        return;
      }

      try {
        setLoadingVehicles(true);
        setCustomerVehicles([]);
        const { data } = await getVehicleBySocialSecurityApi(socialSecurity);
        setCustomerVehicles(data);
      } catch (error) {
        const newError = error as AxiosError;
        if (newError.response?.status === 404) {
          return;
        }

        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o veículo');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingVehicles(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    getVehicleBySocialSecurity(customerId);
  }, [customerId, getVehicleBySocialSecurity]);

  return {
    customerVehicles,
    loadingVehicles,
    setCustomerVehicles,
    getVehicleBySocialSecurity,
  };
}
