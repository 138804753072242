import { useCallback, useEffect, useState } from 'react';

import {
  getFipeManufacturersByCategoryApi,
  getFipeModelsByVehiclesAndManufacturersAndCategoryApi,
  getFipeVehiclesByManufacturersAndCategoryApi,
  getVehicleTypesApi,
} from '../api';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

export interface CategoryType {
  id: string;
  description: string;
}

export interface ManufacturerType {
  id: string;
  name: string;
}

export interface VehicleType {
  id: string;
  name: string;
}

export interface ModelType {
  id: string;
  name: string;
}

interface UseFipeProps {
  getInfo?: boolean;
  category?: CategoryType;
  manufacturer?: ManufacturerType;
  vehicle?: VehicleType;
}

export function useFipe({ getInfo, category, manufacturer, vehicle }: UseFipeProps) {
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [manufacturerTypes, setManufacturerTypes] = useState<ManufacturerType[]>([]);
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [vehiclesTypes, setVehiclesTypes] = useState<VehicleType[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [modelsTypes, setModelsTypes] = useState<ModelType[]>([]);
  const [loadingModels, setLoadingModels] = useState(false);

  const { addToast } = useToast();

  const categoryFipe = (categoryId: string) =>
    categoryId.toLowerCase() === 'motocicleta'
      ? 'MOTORCYCLE'
      : categoryId.toLowerCase() === 'caminhao'
      ? 'TRUCK'
      : 'CAR';

  const isSpecialVehicle = (categoryName?: string) => ['ÔNIBUS', 'MÁQUINAS'].includes(categoryName || '');

  const getManufacturers = useCallback(
    async (categoryName: string) => {
      try {
        setLoadingManufacturer(true);
        const { data } = await getFipeManufacturersByCategoryApi(categoryName);
        setManufacturerTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a marca');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingManufacturer(false);
      }
    },
    [addToast],
  );

  const getVehicles = useCallback(
    async (categoryName: string, manufactureId: string) => {
      try {
        setLoadingVehicles(true);
        const { data } = await getFipeVehiclesByManufacturersAndCategoryApi(categoryName, manufactureId);
        setVehiclesTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o modelo');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingVehicles(false);
      }
    },
    [addToast],
  );

  const getModels = useCallback(
    async (categoryName: string, manufactureId: string, vehicleId: string) => {
      try {
        setLoadingModels(true);
        const { data } = await getFipeModelsByVehiclesAndManufacturersAndCategoryApi(
          categoryName,
          manufactureId,
          vehicleId,
        );
        setModelsTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o ano');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingModels(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    setManufacturerTypes([]);
    setVehiclesTypes([]);
    setModelsTypes([]);

    if (!category?.description || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);
    getManufacturers(fipe);
  }, [category, getManufacturers]);

  useEffect(() => {
    setVehiclesTypes([]);
    setModelsTypes([]);

    if (!category?.description || !manufacturer?.id || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);

    getVehicles(fipe, manufacturer.id);
  }, [category, getVehicles, manufacturer]);

  useEffect(() => {
    if (!category?.description || !manufacturer?.id || !vehicle?.id || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);

    getModels(fipe, manufacturer.id, vehicle.id);
  }, [category, getModels, manufacturer, vehicle]);

  useEffect(() => {
    const loadFipeTypes = async () => {
      if (!getInfo) {
        return;
      }

      setLoadingCategory(true);

      try {
        const { data } = await getVehicleTypesApi();
        setCategoryTypes(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a categoria');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingCategory(false);
      }
    };

    loadFipeTypes();
  }, [addToast, getInfo]);

  return {
    categoryTypes,
    loadingCategory,
    manufacturerTypes,
    loadingManufacturer,
    vehiclesTypes,
    loadingVehicles,
    modelsTypes,
    loadingModels,
    isSpecialVehicle,
  };
}
