import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

import Colors from '~/styles/colors';

export const Container = styled.div`
  position: relative;
  margin-top: 20px;

  label {
    color: #999;
  }
`;

export const DivFile = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.primary};
`;

interface LabelProps {
  error: any
}

export const Label = styled.label<LabelProps>`
  ${({ error }) => error && 'color: red;'}
`;

export const File = styled.input`
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  + label {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #000;
  }
`;

export const CloseIcon = styled(IconButton).attrs({
  size: 'small',
})`
  && svg {
    width: 12px;
    height: 12px;
  }
`;
