import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';

import Colors from '~/styles/colors';

export const Pin = styled(FaMapMarkerAlt).attrs({ size: 24, color: Colors.primary })<{ lat: number; lng: number }>`
  position: absolute;
  top: -20px;
  transform-origin: 50% 50%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
`;
