import { put, takeEvery } from "redux-saga/effects";
import { NoteType } from "../ducks/orderNote";

function* addNote() {
  try {
    yield put({
      type: NoteType.ON_SUCESS,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* noteSaga() {
  yield takeEvery(NoteType.ON_SAVE_FROM_ALLOCATING, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_CANCELED, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_FINISHED, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_INEXECUTION, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_MANUAL_ALLOCATION, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_ONGOING, addNote);
  yield takeEvery(NoteType.ON_SAVE_FROM_PAYMENT, addNote);
}
