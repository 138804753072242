import React, { useState, useEffect } from 'react';
import { Switch, Grid, FormControl, TextField } from '@material-ui/core';

import { AddBox, Edit } from '@material-ui/icons';

import { loadAssociations, loadQuizFromAssociation, activeQuiz, inactiveQuiz } from '~/services/api';

import history from '~/services/history';
import Snackbar, { SeverityType } from '~/components/Snackbar';

import { Form } from './styles';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { AssociationsResponse, QuizAssociation } from '~/types';
import { Title } from '~/components/Title';
import { Table } from '~/components';

export default function AdditionalInformationList() {
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [association, setAssociation] = useState<AssociationsResponse | null>(null);
  const [content, setContent] = useState<QuizAssociation[]>([]);
  const [loading, setLoading] = useState(false);

  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await loadAssociations();
        setAssociations(data || []);
      } catch (err) {
        setSeverity('warning');
        setMessageSnack('Ocorreu um erro ao executar a operação');
        setOpenSnackbar(true);
      }
      setLoading(false);
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadQuiz() {
      if (!association) return;
      setLoading(true);
      try {
        const { data } = await loadQuizFromAssociation(association.id);
        setContent(data || []);
      } catch (err) {
        setSeverity('warning');
        setMessageSnack('Ocorreu um erro ao executar a operação');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    }

    loadQuiz();
  }, [association]);

  async function changeStatus(event, rowData) {
    const { id } = rowData;
    const { checked } = event.target;
    try {
      if (checked) {
        await activeQuiz(id);
      } else {
        await inactiveQuiz(id);
      }

      setContent(prev =>
        prev.map(item => {
          const newItem = { ...item };
          if (item.id === id) {
            newItem.active = checked;
          }
          return newItem;
        }),
      );
    } catch (error) {
      setSeverity('warning');
      setMessageSnack('Ocorreu um erro ao executar a operação');
      setOpenSnackbar(true);
    }
  }

  return (
    <>
      <Title>Informações adicionais</Title>

      <Form>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={6} sm={5} md={4} lg={3}>
            <FormControl style={{ width: '100%' }}>
              <CustomAutocomplete
                id='combo-box-associations'
                options={associations}
                getOptionLabel={item => item.code}
                renderInput={params => <TextField {...params} label='Selecione uma empresa' />}
                onChange={(_, value) => {
                  setAssociation(value);
                }}
                value={association ?? null}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Form>

      <Table
        isLoading={loading}
        data={content}
        columns={{
          id: { title: 'ID', hidden: true },
          code: { title: 'Código' },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Selecione uma empresa com perguntas cadastradas',
            editRow: {
              deleteText: 'Deseja excluir o plano?',
            },
          },
        }}
        actions={[
          rowData => ({
            icon: () => (
              <Switch
                checked={rowData.active}
                onChange={event => changeStatus(event, rowData)}
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ),
            tooltip: rowData.active ? 'Ativo' : 'Inativo',
            onClick: () => {
              return undefined;
            },
          }),
          rowData => ({
            icon: Edit,
            tooltip: 'Editar',
            onClick: () => {
              history.push(`/additional-information/${rowData.id}`, {
                edit: true,
              });
            },
          }),
          {
            icon: AddBox,
            onClick: () => {
              history.push(`/additional-information/create`);
            },
            isFreeAction: true,
            tooltip: 'Adicionar Questionário',
          },
        ]}
        options={{
          search: false,
          showTitle: false,
          pageSize: 10,
          paging: false,
          draggable: false,
          actionsColumnIndex: -1,
        }}
      />

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />
    </>
  );
}
