import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';

export interface DatePeriodPickerProps {
  onPick: (fom: Date, to: Date) => void;
}

export function DatePeriodPicker(props: DatePeriodPickerProps) {
  const [fromDate, setFromDate] = useState<Date>(
    (d => {
      d.setDate(d.getDate() - 1);
      return d;
    })(new Date()),
  );

  const [toDate, setToDate] = useState<Date>(new Date());

  useEffect(() => {
    props.onPick(fromDate, toDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div />
      <Grid container justify='flex-end' style={{ margin: 0, marginBottom: 10 }}>
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format='dd/MM/yyyy'
          margin='normal'
          id='from-date-picker'
          label='De'
          value={fromDate}
          onChange={date => date && setFromDate(date)}
          style={{ marginRight: 10 }}
        />
        <KeyboardDatePicker
          disableToolbar
          variant='inline'
          format='dd/MM/yyyy'
          margin='normal'
          id='to-date-picker'
          label='Até'
          value={toDate}
          onChange={date => date && setToDate(date)}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DatePeriodPicker;
