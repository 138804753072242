import React, { ReactNode } from 'react';
import {
  DialogProps,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';

export interface DetailsDialogProps extends DialogProps {
  editable?: boolean;
  editing?: boolean;
  onRequestEdit?: () => void;
  onRequestSave?: () => void;
  loading?: boolean;
  children: ReactNode;
  resetable?: boolean;
  onRequestReset?: () => void;
  onCancelEditing?: () => void;
  saveLabel?: string;
  disabledButtons?: boolean;
}

export function DetailsDialog({
  children,
  editable,
  editing,
  onRequestEdit,
  onRequestSave,
  loading,
  resetable,
  onRequestReset,
  onCancelEditing,
  saveLabel = 'Salvar',
  disabledButtons,
  ...props
}: DetailsDialogProps) {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog maxWidth='lg' fullScreen={fullscreen} disableScrollLock={true} scroll='paper' {...props}>
      {loading ? (
        <CircularProgress style={{ margin: 100 }} />
      ) : (
        <>
          <DialogContent>{children}</DialogContent>
          {editable && (
            <DialogActions>
              {resetable && (
                <Button autoFocus variant='contained' color='primary' onClick={onRequestReset}>
                  Redefinir Conta
                </Button>
              )}
              {editing ? (
                <>
                  <Button
                    autoFocus
                    variant='contained'
                    color='primary'
                    onClick={onRequestSave}
                    disabled={disabledButtons}
                  >
                    {saveLabel}
                  </Button>
                  {onCancelEditing && (
                    <Button
                      autoFocus
                      variant='contained'
                      color='primary'
                      onClick={onCancelEditing}
                      disabled={disabledButtons}
                    >
                      Cancelar
                    </Button>
                  )}
                </>
              ) : (
                <Button autoFocus variant='contained' color='primary' onClick={onRequestEdit}>
                  Editar
                </Button>
              )}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
}

export default DetailsDialog;
