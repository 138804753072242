import { useEffect, useState } from 'react';
import { QuizAnswersResponse } from '~/types';
import { loadQuizAnswers } from '../api';

export function useQuizAnswers(orderId?: string) {
  const [quizAnswers, setQuizAnswers] = useState<QuizAnswersResponse>();

  useEffect(() => {
    if (orderId) {
      loadQuizAnswers(orderId)
        .then(response => response.data)
        .then(data => ({
          ...data,
          value: JSON.parse(data.value),
        }))
        .then(setQuizAnswers);
    } else {
      setQuizAnswers(undefined);
    }
  }, [orderId]);

  return quizAnswers;
}

export default useQuizAnswers;
