import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import { loadAssociationBalance, loadAssociations } from '../api';
import { Association } from '~/types';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';
import { clearBalance, setBalance } from '~/store/ducks/associationBalance';

export interface AssociationBalance {
  account_statement?: {
    available_balance: number;
  };
}

export function useAssociations(associationId?: string) {
  const [associations, setAssociations] = useState<Association[]>([]);
  const [loading, setLoading] = useState(false);
  const [associationBalance, setAssociationBalance] = useState<AssociationBalance>({} as AssociationBalance);
  const [loadingBalance, setLoadingBalance] = useState(false);

  const { addToast } = useToast();
  const dispatch = useDispatch();
  const { isLoading, data: associationBalanceData, isError, error } = useQuery(
    ['associationBalance', associationId],
    () => loadAssociationBalance(associationId),
    {
      enabled: !!associationId,
    },
  );

  useEffect(() => {
    setLoading(true);

    loadAssociations()
      .then(response => setAssociations(response.data as Association[]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!associationId) {
      return;
    }

    setLoadingBalance(isLoading);

    if (isError) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o saldo');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
      return;
    }

    if (isLoading || !associationBalanceData?.data) {
      setAssociationBalance({});
      dispatch(clearBalance());
      return;
    }

    setAssociationBalance(associationBalanceData.data);
    dispatch(setBalance(associationBalanceData.data));
  }, [addToast, associationBalanceData, associationId, dispatch, error, isError, isLoading]);

  return { associations, loading, associationBalance, loadingBalance };
}

export default useAssociations;
