import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'material-table';
import { Refresh } from '@material-ui/icons';

import { useOpportunities } from '~/services/hooks';
import { OpportunityStatusRequest, OpportunitiesResponse, OpportunityResponse, PaymentTypePTBR } from '~/types';
import { Table } from '~/components';
import { formatCNPJ, formatCPF } from '~/util/format';
import Details from '../Details';
import exportCustomCSV from '~/util/exportCustomCSV';
import ActionMenu from '../ActionMenu';
import ConfirmSendToManualAllocation from './components/ConfirmSendToManualAllocation';
import { useOrdersBadge } from '~/services/hooks/useOrdersBadge';

export interface AllocatingTableProps {
  open: boolean;
}

// Enviadas
export function AllocatingTable(props: AllocatingTableProps) {
  const { loading, data, sendToManualAllocation, invalidateQuery } = useOpportunities(
    OpportunityStatusRequest.ALLOCATING,
  );

  const [opportunity, setOpportunity] = useState<OpportunityResponse>();
  const [modalSendToManualAllocation, setModalSendToManualAllocation] = useState(false);
  const [opportunityDetails, setOpportunityDetails] = useState<OpportunitiesResponse[number]>();

  const handleSendToManualAllocation = useCallback((opportunityItem: OpportunityResponse) => {
    setOpportunity(opportunityItem);
    setModalSendToManualAllocation(true);
  }, []);

  const handleConfirmSendToManualAllocation = useCallback(async () => {
    if (!opportunity?.id) {
      return;
    }

    await sendToManualAllocation(opportunity.id);
    invalidateQuery();
    setModalSendToManualAllocation(false);
  }, [opportunity, invalidateQuery, sendToManualAllocation]);

  useEffect(() => {
    if (opportunityDetails) {
      const updatedOpportunity = data.filter(opp => opp.id === opportunityDetails.id);
      setOpportunityDetails(updatedOpportunity[0]);
    }
  }, [data, opportunityDetails]);

  useOrdersBadge('allocating', data.length);

  useEffect(() => {
    if (props.open) {
      invalidateQuery();
    }
  }, [invalidateQuery, props.open]);

  if (!props.open) {
    return null;
  }

  return (
    <>
      <Table
        columns={{
          actions: {
            filtering: false,
            export: false,
            render: rowData => (
              <ActionMenu
                id='teste'
                data={rowData}
                onSendToManualAllocation={handleSendToManualAllocation}
                onCancel={invalidateQuery}
              />
            ),
          },
          issued_by: {
            title: 'Origem',
            hidden: true,
            export: true,
            render: rowData => rowData?.issued_by || 'DESCONHECIDO',
          },
          number: 'ID do pedido',
          service_description: {
            title: 'Tipo de Serviço',
            lookup: (() => data.map(d => d.service_description).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
          },
          payment_type: {
            title: 'Forma de Pagamento',
            lookup: (() =>
              data
                .map(item => item.payment_type)
                .reduce((acc, item) => ({ ...acc, [item]: PaymentTypePTBR[item] }), {}))(),
            render: rowData => PaymentTypePTBR[rowData.payment_type] ?? rowData.payment_type,
            export: false,
            exportOnlyCSV: true,
          },
          customer_id: {
            title: 'CPF/CNPJ',
            render: rowData =>
              rowData.customer_id.length > 11 ? formatCNPJ(rowData.customer_id) : formatCPF(rowData.customer_id),
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          customer_name: {
            title: 'Cliente',
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          customer_phone_number: {
            title: 'Telefone do Cliente',
            exportOnlyCSV: true,
            export: false,
          },
          association_name: {
            title: 'Empresa',
            lookup: (() => data.map(d => d.association_name).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          created_at_formatted: {
            title: 'Data/Hora de inicio',
            defaultSort: 'asc',
          },
          license_plate_or_vin: 'Placa do veículo ou Chassi',
          vehicle_manufacture: {
            title: 'Marca',
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          vehicle_model: {
            title: 'Modelo do veículo',
            hidden: true,
            export: true,
          },
          source_latlng: {
            title: 'LatLng Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          source_address: {
            title: 'Endereço Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_latlng: {
            title: 'LatLng Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_address: {
            title: 'Endereço Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          total: {
            title: 'Total',
            hidden: true,
            export: true,
          },
        }}
        data={data}
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: 'Atualizar',
            isFreeAction: true,
            onClick: invalidateQuery,
          },
        ]}
        onRowClick={(event, rowData) => {
          if (rowData) {
            setOpportunityDetails(rowData);
          }
        }}
        options={{
          showTitle: false,
          exportButton: true,
          exportAllData: true,
          filtering: true,
          exportCsv: (allColumns: Column<OpportunitiesResponse[number]>[], allData: OpportunitiesResponse) => {
            exportCustomCSV({
              csvColumns: allColumns,
              csvContent: allData,
              filenameOutput: 'oportunidades_enviadas.csv',
            });
          },
        }}
        customOptions={{
          noLineBreakInHeader: true,
        }}
        isLoading={loading}
        title='Oportunidades enviadas'
      />

      <Details opportunity={opportunityDetails} onRequestClose={() => setOpportunityDetails(undefined)} />

      <ConfirmSendToManualAllocation
        onConfirm={handleConfirmSendToManualAllocation}
        onCancel={() => {
          setModalSendToManualAllocation(false);
        }}
        open={modalSendToManualAllocation}
      />
    </>
  );
}

export default AllocatingTable;
