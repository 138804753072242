import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Refresh } from '@material-ui/icons';
import { Column } from 'material-table';

import { useOpportunities } from '~/services/hooks';
import {
  OpportunityStatusRequest,
  OpportunitiesResponse,
  AllocationContractorsResponse,
  PaymentTypePTBR,
  CustomRoles,
} from '~/types';
import { Table } from '~/components';
import ActionMenu from '../ActionMenu';
import { formatCNPJ, formatCPF } from '~/util/format';
import { allocateOpportunityForContractor } from '~/services/api';
import ContractorPicker from '../ContractorPicker';
import { ContractorPickConfirm } from '../ContractorPickConfirm';
import Details from '../Details';
import exportCustomCSV from '~/util/exportCustomCSV';
import { useOrdersBadge } from '~/services/hooks/useOrdersBadge';

export interface InExecutionTableProps {
  open: boolean;
}

// Em Execução
export function InExecutionTable(props: InExecutionTableProps) {
  const { customRole } = useSelector(state => ({
    customRole: state.auth.user['custom:role'],
  }));
  const { loading, data, invalidateQuery } = useOpportunities(OpportunityStatusRequest.IN_EXECUTION);

  const [opportunityDetails, setOpportunityDetails] = useState<OpportunitiesResponse[number]>();

  useEffect(() => {
    if (opportunityDetails) {
      const updatedOpportunity = data.filter(opp => opp.id === opportunityDetails.id);
      setOpportunityDetails(updatedOpportunity[0]);
    }
  }, [data, opportunityDetails]);

  const [opportunity, setOpportunity] = useState<OpportunitiesResponse[number]>();

  const [contractor, setContractor] = useState<AllocationContractorsResponse[number]>();

  const cleanManualAllocationState = useCallback(() => {
    setOpportunity(undefined);
    setContractor(undefined);
  }, []);

  const manualAllocate = useCallback(
    (o?: OpportunitiesResponse[number], c?: AllocationContractorsResponse[number]) => {
      if (o && c) {
        allocateOpportunityForContractor(o.id, c.id).finally(() => {
          cleanManualAllocationState();
          invalidateQuery();
        });
      }
    },
    [cleanManualAllocationState, invalidateQuery],
  );

  useOrdersBadge('inExecution', data.length);

  useEffect(() => {
    if (props.open) {
      invalidateQuery();
    }
  }, [invalidateQuery, props.open]);

  if (!props.open) {
    return null;
  }

  return (
    <>
      <Table
        columns={{
          actions: {
            filtering: false,
            export: false,
            render: rowData => (
              <ActionMenu
                id='teste'
                data={rowData}
                onManualRealocation={setOpportunity}
                onCancel={invalidateQuery}
                onCancelAllocation={invalidateQuery}
              />
            ),
          },
          number: 'ID do pedido',
          service_description: {
            title: 'Tipo de Serviço',
            lookup: (() => data.map(d => d.service_description).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
          },
          payment_type: {
            title: 'Forma de Pagamento',
            lookup: (() =>
              data
                .map(item => item.payment_type)
                .reduce((acc, item) => ({ ...acc, [item]: PaymentTypePTBR[item] }), {}))(),
            render: rowData => PaymentTypePTBR[rowData.payment_type] ?? rowData.payment_type,
            export: false,
            exportOnlyCSV: true,
          },
          issued_by: {
            title: 'Origem',
            hidden: true,
            export: true,
            render: rowData => rowData?.issued_by || 'DESCONHECIDO',
          },
          customer_id: {
            title: 'CPF/CNPJ',
            render: rowData =>
              rowData.customer_id.length > 11 ? formatCNPJ(rowData.customer_id) : formatCPF(rowData.customer_id),
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          customer_name: {
            title: 'Cliente',
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          customer_phone_number: {
            title: 'Telefone do Cliente',
            exportOnlyCSV: true,
            export: false,
          },
          organization_name: {
            title: 'Empresa',
            cellStyle: {
              minWidth: 200,
              textAlign: 'left',
            },
          },
          contractor_name: {
            title: 'Nome do prestador',
            cellStyle: {
              minWidth: 200,
              textAlign: 'left',
            },
            render: rowData =>
              customRole === CustomRoles.B2B ? rowData.contractor_name?.split(' ')[0] : rowData.contractor_name,
          },
          association_name: {
            title: 'Empresa',
            lookup: (() => data.map(d => d.association_name).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
            cellStyle: {
              minWidth: 200,
              textAlign: 'left' as const,
            },
          },
          created_at_formatted: {
            title: 'Data/Hora de inicio',
            defaultSort: 'asc',
          },
          license_plate_or_vin: 'Placa do veículo ou Chassi',
          vehicle_manufacture: {
            title: 'Marca',
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          vehicle_model: {
            title: 'Modelo do veículo',
            hidden: true,
            export: true,
          },
          source_latlng: {
            title: 'LatLng Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          source_address: {
            title: 'Endereço Origem',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_latlng: {
            title: 'LatLng Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];

              return `${source.latitude}, ${source.longitude}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          destination_address: {
            title: 'Endereço Destino',
            render: rowData => {
              const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

              if (sourceIndex === -1) {
                return '';
              }

              const source = rowData.coordinates[sourceIndex];
              const address = source.address ?? '';
              const city = source.city ? ` - ${source.city}` : '';

              return `${address}${city}`;
            },
            hidden: true,
            export: false,
            exportOnlyCSV: true,
          },
          total: {
            title: 'Total',
            hidden: true,
            export: true,
          },
        }}
        data={data}
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: 'Atualizar',
            isFreeAction: true,
            onClick: invalidateQuery,
          },
        ]}
        onRowClick={(event, rowData) => {
          if (rowData) {
            setOpportunityDetails(rowData);
          }
        }}
        options={{
          showTitle: false,
          exportButton: true,
          exportAllData: true,
          filtering: true,
          exportCsv: (allColumns: Column<OpportunitiesResponse[number]>[], allData: OpportunitiesResponse) => {
            exportCustomCSV({
              csvColumns: allColumns,
              csvContent: allData,
              filenameOutput: 'oportunidades_em_execucao.csv',
            });
          },
        }}
        customOptions={{
          noLineBreakInHeader: true,
        }}
        isLoading={loading}
        title='Oportunidades em execução'
      />

      <ContractorPicker
        opportunity={opportunity}
        onClose={() => setOpportunity(undefined)}
        onPick={contractor => setContractor(contractor)}
      />

      <ContractorPickConfirm
        opportunity={opportunity}
        contractor={contractor}
        onConfirm={() => manualAllocate(opportunity, contractor)}
        onCancel={() => setContractor(undefined)}
      />

      <Details opportunity={opportunityDetails} onRequestClose={() => setOpportunityDetails(undefined)} />
    </>
  );
}

export default InExecutionTable;
