import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '~/components/Loading';

import { loadAssociations } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import useWindowDimensions from '~/services/hooks/useDimentions';
import { AssociationResponse, CustomRoles } from '~/types';
import { Title } from '~/components/Title';

export const Dashboard = () => {
  const { associationId, role } = useSelector(state => ({
    associationId: state.auth.user['custom:association_id'],
    role: state.auth.user['custom:role'],
  }));

  const [association, setAssociation] = useState<AssociationResponse>();
  const [loading, setLoading] = useState(true);

  const { width, height } = useWindowDimensions();
  const { addToast } = useToast();

  useEffect(() => {
    const getAssociationData = async () => {
      if (!associationId) {
        setLoading(false);
        return;
      }

      try {
        const { data } = (await loadAssociations(associationId)) as { data: AssociationResponse };

        setAssociation(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao buscar dados da empresa');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    getAssociationData();
  }, [addToast, associationId]);

  return (
    <>
      <Title>Dashboard</Title>

      {loading ? (
        <Loading />
      ) : !association?.configuration_value?.association?.power_bi && role === CustomRoles.B2B ? (
        <Typography>Configuração da Dashboard não encontrada</Typography>
      ) : (
        <>
          {role === CustomRoles.BACKOFFICE ? (
            <iframe
              title='Relatorio - Status das Solicitações'
              src='https://app.powerbi.com/view?r=eyJrIjoiMmNhZjUxZmQtZTQ3Yi00YWJmLWJiZWItZGI5ZDVjNTc1NDQzIiwidCI6ImNiYjJkZDI4LTFmMTgtNDIzYS04YjYyLTk5YmU5MDI0NDVjZSJ9'
              width={width * 0.8}
              height={height * 0.8}
              frameBorder='0'
              allowFullScreen={true}
            />
          ) : (
            <iframe
              title='Relatorio - Status das Solicitações'
              src={`https://app.powerbi.com/view?r=${association?.configuration_value?.association?.power_bi}&amp;pageName=ReportSectionf3bb1b65c7e01009c60c%22`}
              width={width * 0.8}
              height={height * 0.8}
              frameBorder='0'
              allowFullScreen={true}
            />
          )}
        </>
      )}
    </>
  );
};
