import { Card, Modal } from '@material-ui/core';
import styled from 'styled-components';
import Button from '~/components/Button';
import Colors from '~/styles/colors';


export const StyledModal = styled(Modal)`
  position: absolute;
  margin: 200px;
`;

export const Content = styled(Card)`
  width: 500px;
  min-height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

export const TextContainer = styled.div`
  min-height: 110px;
  padding-left: 20px;
  padding-top: 10px;
`;

export const StyledButton = styled(Button)`
  color: ${Colors.secondary};
  background-color: ${Colors.primary};
  width: 100px;
  height: 30px;
  margin 5px;
`;