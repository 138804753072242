import { Room } from '@material-ui/icons';
import styled from 'styled-components';
import { opacityColorForBackgroundHex } from '~/styles/colors';

interface ColoredCircleProps {
  iconColor: string;
}

export const ColoredPin = styled(Room)<ColoredCircleProps>`
  display: block;
  min-width: 1em;
  height: 1em;
  color: ${props => props.iconColor};
`;

export const ColoredBox = styled.code<ColoredCircleProps>`
  display: block;
  border-radius: 0.5em;
  border: solid 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  padding: 3px 6px;
  font-size: 1em;
  color: ${props => opacityColorForBackgroundHex({ bgHex: props.iconColor })};
  background-color: ${props => props.iconColor};
`;

export const HelpList = styled.ul`
  & li {
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    display: inline-flex;
    width: 100%;
    margin-top: 4px;
    align-items: center;
  }
  & span {
    margin-left: 10px;
  }
`;
