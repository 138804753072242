import styled from 'styled-components';

import Colors from '~/styles/colors';

interface TitleProps {
  marginBottom?: number;
}

export const Title = styled.h1<TitleProps>`
  font-size: 36px;
  color: ${Colors.primary};
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px;` : '')}
`;
