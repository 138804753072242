import React, { useState } from 'react';
import { Tabs } from '@material-ui/core';

import { Title } from '~/components/Title';
import { NotificationTab } from './components/NotificationTab';
import { ContractorsTable } from './components/ContractorsTable';
import { CustomersTable } from './components/CustomersTable';

enum Tab {
  Contractor,
  Customer,
}

export const Notifications = () => {
  const [tab, setTab] = useState<Tab>(Tab.Contractor);

  return (
    <>
      <Title marginBottom={20}>Envio de notificações</Title>

      <Tabs
        value={tab}
        onChange={(_, newValue) => {
          setTab(newValue);
        }}
        indicatorColor='primary'
        textColor='primary'
        style={{
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        <NotificationTab title='Prestadores' />
        <NotificationTab title='Clientes' />
      </Tabs>

      <ContractorsTable open={tab === Tab.Contractor} />
      <CustomersTable open={tab === Tab.Customer} />
    </>
  );
};
