import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  List,
  Select,
  Typography,
  Button as ButtonMUI,
  Tooltip,
} from '@material-ui/core';
import { HistoryOutlined } from '@material-ui/icons';

import { setOrderQuizAnswers } from '~/store/ducks/order';

import { useQuizAssociation } from '~/services/hooks/useQuizAssociation';

import Title from '../../components/Title';
import Button from '~/components/Button';
import { ModalConfirm } from '~/components/ModalConfirm';
import Modal from '~/components/ModalNewVehicle';
import HighlightedButton from '../../components/HighlightedButton';
import BackButton from '../../components/BackButton';

import { Answers, QuizAssociationResponse, QuizForm, QuizFormatted, QuizResponse } from '~/types';
import InformationOrder from '../../components/InformationOrder';
import { getPeriod, ModalOpportunities } from './components/ModalOpportunities';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OpportunityAdditionalInformation = () => {
  const query = useQuery();
  const quizPicktow = query.get('quizPicktow') ?? false;

  const [modalCancel, setModalCancel] = useState(false);
  const [formattedQuiz, setFormattedQuiz] = useState<QuizFormatted[]>([]);
  const [formResponse, setFormResponse] = useState<QuizForm[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState<JSX.Element>();
  const [quizAssociation, setQuizAssociation] = useState<QuizAssociationResponse[]>();
  const [modalOpportunitiesOpen, setModalOpportunitiesOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { vehicle, service, quizAnswers, benefit } = useSelector(state => state.order);

  const period = getPeriod(benefit.period);

  const { getQuiz, loadQuiz } = useQuizAssociation();

  useEffect(() => {
    setFormResponse(quizAnswers);
  }, [quizAnswers]);

  useEffect(() => {
    const callQuiz = async () => {
      const quiz = await getQuiz(
        vehicle?.status === 'INACTIVE' || quizPicktow ? undefined : vehicle?.association_id,
        service?.id,
      );

      setQuizAssociation(quiz);
    };

    callQuiz();
  }, [getQuiz, quizPicktow, service, vehicle]);

  useEffect(() => {
    if (!service?.id) {
      history.push('/new-opportunity/vehicles');
      return;
    }

    let formattedForm: QuizFormatted[] = [];

    if (quizAssociation && quizAssociation[0]) {
      const form = JSON.parse(quizAssociation[0].form) as QuizResponse[];
      const answers: Answers[] = [];

      form.forEach(fm => {
        let object = {
          question: fm.title,
          answers,
          title: '',
        };

        let yesBlockResponse = false;
        let noBlockResponse = false;
        if (!fm.children && fm.block) {
          yesBlockResponse = fm.blockingResponse === true ? true : false;
          noBlockResponse = fm.blockingResponse === false ? true : false;
        }

        fm.children
          ? fm.children.forEach(
              fmChildren =>
                (object = {
                  ...object,
                  answers: [
                    ...object.answers,
                    {
                      title: fmChildren.title,
                      block: fmChildren.block,
                    },
                  ],
                }),
            )
          : (object = {
              ...object,
              answers: [
                {
                  title: 'Sim',
                  block: yesBlockResponse,
                },
                {
                  title: 'Não',
                  block: noBlockResponse,
                },
              ],
            });

        formattedForm = [...formattedForm, object];
      });
    }

    setFormattedQuiz(formattedForm);
  }, [history, quizAssociation, service]);

  const confirmSeparateService = useCallback(() => {
    if (!!vehicle?.association_code && !quizPicktow) {
      setFormResponse([]);
      setFormattedQuiz([]);
      history.replace('/new-opportunity/additional-information?quizPicktow=true');
    } else if (!vehicle?.association_code || quizPicktow) {
      dispatch(setOrderQuizAnswers(true, formResponse));
      history.push('/new-opportunity/map');
    }
    setOpenModal(false);
  }, [dispatch, formResponse, history, quizPicktow, vehicle]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const responseBlock = formResponse.filter(res => res.block);

      if (responseBlock.length > 0) {
        if (!!vehicle?.association_code && !quizPicktow) {
          const messagesBlock = (
            <>
              <Typography variant='subtitle1' gutterBottom>
                Uma das respostas informadas bloqueia a solicitação para o pacote de serviços.
              </Typography>
              <Typography variant='subtitle1' gutterBottom>
                Deseja solicitar particular?
              </Typography>
            </>
          );

          setMessage(messagesBlock);
          setOpenModal(true);
          return;
        }

        confirmSeparateService();
        return;
      } else {
        dispatch(setOrderQuizAnswers(!!vehicle?.association_code && !quizPicktow ? false : true, formResponse));
        history.push('/new-opportunity/map');
      }
    },
    [confirmSeparateService, dispatch, formResponse, history, quizPicktow, vehicle],
  );

  const handleGoBack = useCallback(() => {
    if (quizPicktow) {
      setFormResponse([]);
      setFormattedQuiz([]);
      history.replace('/new-opportunity/additional-information');
      return;
    }

    history.goBack();
  }, [history, quizPicktow]);

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  const changePicker = useCallback(
    (index, question, answer, block) => {
      const newResponse = [...formResponse];

      newResponse[index] = { question, answer, block };
      setFormResponse(newResponse);
    },
    [formResponse],
  );

  return (
    <>
      <Title>Informações adicionais</Title>

      <InformationOrder />

      <form onSubmit={handleSubmit}>
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <List component='nav' aria-label='main mailbox folders'>
              {Object.keys(formattedQuiz).length > 0 &&
                formattedQuiz.map((qz, index) => {
                  !formResponse[index] && changePicker(index, qz.question, qz.answers[0]?.title, qz.answers[0]?.block);

                  return (
                    <Grid key={index} container spacing={2} alignItems='flex-end'>
                      <Grid item xs={4} md={12}>
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel htmlFor='status'>{qz.question}</InputLabel>
                          <Select
                            native
                            value={formResponse[index]?.answer || qz.answers[0]?.title}
                            onChange={e => {
                              const qzIndex = qz.answers.findIndex(qzItem => qzItem.title === e.target.value);
                              changePicker(index, qz.question, e.target.value, qz.answers[qzIndex].block);
                            }}
                          >
                            <option hidden value=''>
                              {' '}
                            </option>
                            {qz.answers.map((answers, i) => (
                              <option key={`${qz.title}-${i}`} value={answers.title}>
                                {answers.title}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                })}
            </List>
          </CardContent>
        </Card>

        <Grid container spacing={1}>
          <Grid container item xs={3} md={12} spacing={3}>
            <Grid item xs={3} md={10}>
              <BackButton loading={loadQuiz} onClick={handleGoBack} />
              <Button
                type='button'
                label='Cancelar'
                loading={loadQuiz}
                filled
                style={{ width: 200, marginRight: 30 }}
                onClick={handleCancel}
              />
              <HighlightedButton loading={loadQuiz} />
            </Grid>

            <Grid item container md={2} direction='column' alignItems='flex-end' justify='center'>
              <Tooltip title='Histórico de solicitações' arrow>
                <ButtonMUI
                  aria-label='Histórico de solicitações'
                  onClick={() => {
                    setModalOpportunitiesOpen(true);
                  }}
                >
                  <HistoryOutlined style={{ fontSize: 40 }} />
                </ButtonMUI>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <ModalConfirm
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
        title='Tem certeza de que deseja cancelar essa oportunidade?'
      />

      <Modal
        onConfirm={confirmSeparateService}
        onCancel={() => setOpenModal(false)}
        question={!!vehicle?.association_code && !quizPicktow}
        open={openModal}
      >
        {message}
      </Modal>

      <ModalOpportunities
        open={modalOpportunitiesOpen}
        closeModal={() => setModalOpportunitiesOpen(false)}
        vin={vehicle?.vin}
        serviceDescription={service?.description}
        period={period}
      />
    </>
  );
};

export default OpportunityAdditionalInformation;
