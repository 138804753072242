import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import { AssociationsResponse } from '~/types';
import { DetailsDialog, DataTree, DataGroup, DetailsDialogProps } from '~/components';
import { loadAssociations, syncHinovaApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';

interface SyncHinovaDialogProps extends Omit<DetailsDialogProps, 'children'> {
  closeModal?: () => void;
}

type ContractorType = 'ORG_ADMIN' | 'FREELANCER';

export function SyncHinovaDialog({ closeModal, ...props }: SyncHinovaDialogProps) {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [associationsSelected, setAssociationsSelected] = useState<AssociationsResponse[]>();
  const [inactivateVehiclesBeforeImport, setInactivateVehiclesBeforeImport] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      if (!associationsSelected || associationsSelected.length === 0) {
        return;
      }

      setSending(true);

      const data = {
        inactivateVehiclesBeforeImport,
        associationIdsFilter: associationsSelected.map(association => association.id),
      };

      await syncHinovaApi(data);
      addToast({
        title: 'Sincronização',
        type: 'success',
        description: 'Sincronização efetuada com sucesso',
      });

      setSending(false);
      closeModal && closeModal();
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao criar o prestador');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [addToast, associationsSelected, closeModal, inactivateVehiclesBeforeImport]);

  const handleCancelEditing = () => {
    if (closeModal) {
      closeModal();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInactivateVehiclesBeforeImport(event.target.checked);
  };

  useEffect(() => {
    async function loadData() {
      try {
        if (!props.open) {
          return;
        }

        setLoading(true);

        const { data } = await loadAssociations();
        setAssociations(data || []);
      } catch (err) {
        const error = handleApiErrorResponse(err);

        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar empresas');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [addToast, props.open]);

  return (
    <DetailsDialog
      editable
      editing
      onRequestSave={handleSubmit}
      loading={loading}
      onCancelEditing={handleCancelEditing}
      saveLabel='Sincronizar'
      disabledButtons={sending}
      {...props}
    >
      <DataTree>
        <DataGroup title='Sincronizar empresas com a Hinova'>
          <CustomAutocomplete
            multiple
            id='combo-box-associations'
            options={associations}
            getOptionLabel={item => item.code}
            renderInput={params => <TextField {...params} label='Selecione as empresas' />}
            onChange={(_, value) => {
              setAssociationsSelected(value);
            }}
            // value={associationsSelected}
            width='100%'
          />

          <FormControlLabel
            control={<Checkbox checked={inactivateVehiclesBeforeImport} onChange={handleChange} name='checkedA' />}
            label='Inativar veículos antes de sincronizar?'
          />
        </DataGroup>
      </DataTree>
    </DetailsDialog>
  );
}
