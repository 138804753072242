import React from 'react';
import { ListAlt, AttachMoney, PersonPinCircle, MailOutline } from '@material-ui/icons';
import { VerticalTimelineElementProps, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { dateToString } from '~/util/parsers';

import Colors from '~/styles/colors';

import { OrderHistoryItem } from '~/types';

interface VerticalProps extends VerticalTimelineElementProps {
  children: React.ReactNode;
  order: OrderHistoryItem;
}

const VerticalTimelineItem = ({ order, children }: VerticalProps) => {
  let background: string;
  let color: string;
  let icon: React.ReactNode;

  switch (order.type) {
    case 'Ordem':
      background = '#3a75e3';
      color = Colors.white;
      icon = <ListAlt />;
      break;

    case 'Pagamento':
      background = '#00dd34';
      color = Colors.white;
      icon = <AttachMoney />;
      break;

    case 'Alocação':
      background = '#c40202';
      color = Colors.white;
      icon = <PersonPinCircle />;
      break;

    case 'Convite':
      background = '#ffd200';
      color = Colors.black;
      icon = <MailOutline />;
      break;
  }

  return (
    <VerticalTimelineElement
      key={`${order.title}-${order.id}`}
      date={dateToString(new Date(Number(order.updated_at) * 1000))}
      icon={icon}
      iconStyle={{ background, color }}
      contentStyle={{ background: Colors.lightGrey, color: Colors.black }}
      contentArrowStyle={{ borderRight: `7px solid ${Colors.lightGrey}` }}
    >
      {children}
    </VerticalTimelineElement>
  );
};

export default VerticalTimelineItem;
