import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import SetTransform from './SetTransform';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'picktow',
      storage,
      whitelist: ['auth', 'order', 'uploadPhotos', 'orderNote', 'customerBenefits'],
      transforms: [SetTransform],
    },
    reducers,
  );

  return persistedReducer;
};
