import { put, takeEvery } from "redux-saga/effects";
import { UploadPhotoType } from "../ducks/uploadPhoto";

function* uploadPhoto() {
  try {
    yield put({
      type: UploadPhotoType.UPLOAD_SUCESS,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* photoSaga() {
  yield takeEvery(UploadPhotoType.ON_UPLOAD, uploadPhoto);
}
