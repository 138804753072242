import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Add, Edit, Delete } from '@material-ui/icons';
import {
  Grid,
  FormControl,
  Switch,
  ButtonGroup,
  Button,
  FormControlLabel,
  IconButton,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import SortableTree, { removeNodeAtPath, addNodeUnderParent, changeNodeAtPath, TreeItem } from 'react-sortable-tree';

import 'react-sortable-tree/style.css';

import Snackbar, { SeverityType } from '~/components/Snackbar';
import InputComponent from '~/components/InputComponent';
import ButtonComponent from '~/components/Button';
import DialogComponent from '~/components/DialogComponent';

import { loadAssociations, createQuiz, changeQuiz, loadQuiz, deleteQuiz, loadServices } from '~/services/api';
import history from '~/services/history';
import { handleApiErrorResponse } from '~/services/handleErrors';

import { QuestionList, DivQuestion, HeaderQuestions, DivActions } from './styles';
import { AssociationsResponse, ServicesResponse } from '~/types';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { Title } from '~/components/Title';

type CustomProps = {
  needsTitle?: boolean;
  block?: boolean;
  blockingResponse?: boolean;
};

export default function AdditionalInformation() {
  const [status, setStatus] = useState(true);
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [services, setServices] = useState<ServicesResponse[]>([]);
  const [data, setData] = useState<TreeItem<CustomProps>[]>([]);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const [association, setAssociation] = useState<AssociationsResponse | null>(null);
  const [service, setService] = useState<ServicesResponse | null>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const newQuestionRef = useRef<TextFieldProps>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function loadData() {
      try {
        const { data: returnResponse } = await loadAssociations();
        setAssociations(returnResponse || []);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const { data: returnResponse } = await loadServices();
        setServices(returnResponse || []);
      } catch (err) {
        const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
        setSeverity('warning');
        setMessageSnack(applicationErrorMessage || apiError);
        setOpenSnackbar(true);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      if (id) {
        try {
          const { data: returnResponse } = await loadQuiz(id);

          setStatus(returnResponse.active);
          const associationFound = associations.find(item => item.id === returnResponse.association_id) ?? null;
          setAssociation(associationFound);
          const serviceFound = services.find(item => item.id === returnResponse.service_id) ?? null;
          setService(serviceFound);
          setCode(returnResponse.code);
          setData(JSON.parse(returnResponse.form));
        } catch (err) {
          const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
          setSeverity('warning');
          setMessageSnack(applicationErrorMessage || apiError);
          setOpenSnackbar(true);
        }
      }
    }

    loadData();
  }, [associations, id, services]);

  function addQuestionForm() {
    const { value: title } = (newQuestionRef.current || {}) as { value?: React.ReactNode };

    if (!String(title).trim()) {
      // console.log('A pergunta não pode ser vazia.');
      return;
    }

    const newTree = addNodeUnderParent({
      treeData: data,
      expandParent: true,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        title,
        needsTitle: false,
        block: false,
        blockingResponse: false,
      },
    });
    setData(newTree.treeData);
    if (newQuestionRef.current) {
      newQuestionRef.current.value = '';
    }
  }

  function addQuestion(path) {
    const newTree = addNodeUnderParent({
      treeData: data,
      expandParent: true,
      parentKey: path[path.length - 1],
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        title: '',
        needsTitle: true,
        block: false,
        blockingResponse: false,
      },
    });
    setData(newTree.treeData);
  }

  function removeNode(path) {
    setData(
      removeNodeAtPath({
        treeData: data,
        path,
        getNodeKey: ({ treeIndex: number }) => {
          return number;
        },
        ignoreCollapsed: false,
      }),
    );
  }

  function editQuestion(path, treeIndex, node) {
    path.pop();
    path.push(treeIndex);

    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex: number }) => {
        return number;
      },
      newNode: {
        ...node,
        needsTitle: true,
        expanded: true,
      },
    });
    setData(newTree);
  }

  function saveQuestion(event, path, node) {
    event.preventDefault();
    delete node.needsTitle;

    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: node,
    });

    setData(newTree);
  }

  function onChangeInput(node, path, title) {
    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: { ...node, title },
    });

    setData(newTree);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      addQuestionForm();
    }
  }

  function handleBlock(path, treeIndex, node) {
    const block = !node.block;
    path.pop();
    path.push(treeIndex);

    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex: number }) => {
        return number;
      },
      newNode: {
        ...node,
        block,
      },
    });
    setData(newTree);
  }

  function handleBlockingResponse(path, treeIndex, node, value) {
    path.pop();
    path.push(treeIndex);

    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex: number }) => {
        return number;
      },
      newNode: {
        ...node,
        blockingResponse: value,
      },
    });
    setData(newTree);
  }

  async function saveQuizz() {
    setLoading(true);
    if (!association) {
      setSeverity('warning');
      setMessageSnack('Selecione uma empresa.');
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    if (!service) {
      setSeverity('warning');
      setMessageSnack('Selecione um serviço.');
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    if (data.length === 0) {
      setSeverity('warning');
      setMessageSnack('O questionário não pode estar vazio.');
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    if (code.length === 0) {
      setSeverity('warning');
      setMessageSnack('O código não pode ser vazio.');
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    try {
      if (id) {
        await changeQuiz(id, code, association.id, service.id, status, JSON.stringify(data));
        setMessageSnack('Alteração realizada com sucesso!');
      } else {
        await createQuiz(code, association.id, service.id, JSON.stringify(data));
        setMessageSnack('Cadastro realizado com sucesso!');
      }

      setSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => {
        history.push('/additional-information');
      }, 3000);
    } catch (err) {
      const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
      setSeverity('warning');
      setMessageSnack(applicationErrorMessage || apiError);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }

  async function removeQuizz() {
    setLoading(true);
    setOpenDialog(false);
    try {
      await deleteQuiz(id);

      history.push('/additional-information');
    } catch (err) {
      const { applicationErrorMessage, apiError } = handleApiErrorResponse(err) || {};
      setSeverity('warning');
      setMessageSnack(applicationErrorMessage || apiError);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title>Informações adicionais</Title>

      <Grid container spacing={2} alignItems='flex-end'>
        <Grid item xs={4} sm={4} md={3} lg={3}>
          <FormControl style={{ width: '100%' }}>
            <CustomAutocomplete
              id='combo-box-associations'
              options={associations}
              getOptionLabel={item => item.code}
              renderInput={params => <TextField {...params} label='Selecione uma empresa' />}
              onChange={(_, value) => {
                setAssociation(value);
              }}
              value={association ?? null}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={3} lg={3}>
          <FormControl style={{ width: '100%' }}>
            <CustomAutocomplete
              id='combo-box-services'
              options={services}
              getOptionLabel={item => item.description}
              renderInput={params => <TextField {...params} label='Selecione o serviço' />}
              onChange={(_, value) => {
                setService(value);
              }}
              value={service ?? null}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={6} lg={6}>
          <InputComponent
            label='Código da pergunta (Código único)'
            type='text'
            ref={newQuestionRef}
            inputProps={{
              maxLength: 50,
            }}
            onChange={e => setCode(e.target.value)}
            value={code}
          />
        </Grid>
      </Grid>

      <HeaderQuestions>
        <DivQuestion>
          <InputComponent label='Adicionar Pergunta' type='text' ref={newQuestionRef} onKeyDown={handleKeyDown} />
          <ButtonComponent type='button' onClick={addQuestionForm} label='Adicionar' filled />
        </DivQuestion>

        <FormControlLabel
          disabled={!id}
          control={
            <ButtonGroup color='primary' size='small'>
              <Button variant={status ? 'contained' : 'outlined'} onClick={() => setStatus(true)}>
                Sim
              </Button>
              <Button variant={!status ? 'contained' : 'outlined'} onClick={() => setStatus(false)}>
                Não
              </Button>
            </ButtonGroup>
          }
          labelPlacement='top'
          label='Ativo'
        />
      </HeaderQuestions>

      <QuestionList>
        <SortableTree
          style={{ width: '100%', backgroundColor: '#f9f9f9' }}
          treeData={data}
          onChange={treeData => setData(treeData)}
          isVirtualized={false}
          generateNodeProps={({ node, treeIndex, path }) => {
            const { blockingResponse, block } = node;
            return {
              title: !node.needsTitle ? (
                node.title
              ) : (
                <form onSubmit={event => saveQuestion(event, path, node)}>
                  <InputComponent
                    autoFocus
                    value={node.title}
                    onChange={event => {
                      const title = event.target.value;
                      onChangeInput(node, path, title);
                    }}
                  />
                </form>
              ),
              buttons: [
                <div
                  key={Math.random().toString()}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={block}
                        onChange={() => handleBlock(path, treeIndex, node)}
                        name='block'
                        color='primary'
                        size='small'
                      />
                    }
                    labelPlacement='top'
                    label='Bloqueia solicitação'
                  />

                  <FormControlLabel
                    disabled={!block}
                    control={
                      <ButtonGroup color='primary' size='small'>
                        <Button
                          variant={block && blockingResponse ? 'contained' : 'outlined'}
                          onClick={() => handleBlockingResponse(path, treeIndex, node, true)}
                        >
                          Sim
                        </Button>
                        <Button
                          variant={block && !blockingResponse ? 'contained' : 'outlined'}
                          onClick={() => handleBlockingResponse(path, treeIndex, node, false)}
                        >
                          Não
                        </Button>
                      </ButtonGroup>
                    }
                    labelPlacement='top'
                    label='Resposta de Bloqueio'
                  />

                  <IconButton size='small' onClick={() => editQuestion(path, treeIndex, node)}>
                    <Edit color='primary' fontSize='small' />
                  </IconButton>
                  <IconButton size='small' onClick={() => addQuestion(path)}>
                    <Add color='primary' fontSize='small' />
                  </IconButton>
                  <IconButton size='small' onClick={() => removeNode(path)}>
                    <Delete color='primary' fontSize='small' />
                  </IconButton>
                </div>,
              ],
              style: {
                height: 50,
              },
            };
          }}
        />
        <DivActions>
          {id ? <ButtonComponent label='Excluir' onClick={() => setOpenDialog(true)} /> : <div />}
          <ButtonComponent label='Salvar' onClick={saveQuizz} loading={loading} filled />
        </DivActions>
      </QuestionList>

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />

      <DialogComponent
        open={openDialog}
        title='Deseja excluir esse questionário?'
        message='Após a exclusão do questinário, você não poderá acessá-lo novamente.'
        onClose={() => setOpenDialog(false)}
        cancelClick={() => setOpenDialog(false)}
        confirmedClick={removeQuizz}
      />
    </>
  );
}
