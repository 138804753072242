import React, { useEffect, useState } from 'react';

import { AllocationHistory, Invites, OrderHistory, OrderHistoryItem, PaymentHistory } from '~/types';

import { getAllocationsHistoryApi, getInvitesApi, getOrderHistoryApi, getPaymentHistoryApi } from '../api';

import { useToast } from './toast';

import ContractorDetails from '~/pages/OpportunityTimeline/components/ContractorDetails';

import ButtonMaps from '~/pages/OpportunityTimeline/components/ButtonMaps';

const useOrderHistories = (orderId: string) => {
  const [histories, setHistories] = useState<OrderHistoryItem[]>([]);
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();

  useEffect(() => {
    const callApis = async () => {
      const responseHistories = await Promise.all([
        getOrderHistoryApi(orderId),
        getAllocationsHistoryApi(orderId),
        getPaymentHistoryApi(orderId),
        getInvitesApi(orderId),
      ])
        .then(responses => {
          const orderHistories: OrderHistory[] = responses[0].data;
          const allocationHistories: AllocationHistory[] = responses[1].data;
          const paymentHistories: PaymentHistory[] = responses[2].data;
          const invites: Invites[] = responses[3].data;

          return [orderHistories, allocationHistories, paymentHistories, invites];
        })
        .catch(_ => {
          addToast({ title: 'Erro', type: 'error', description: 'Erro ao carregar timeline' });
          return undefined;
        });

      if (!responseHistories) {
        setLoading(false);
        return;
      }

      const allHistories = [
        ...(responseHistories[0] as OrderHistory[]).map(item => {
          const orderHistoryItem: OrderHistoryItem = {
            id: item.id,
            updated_at: item.updated_at,
            type: 'Ordem',
            title: item.status,
            content: (
              <>
                {item.cancel_reason && <p>Motivo do cancelamento: {item.cancel_reason}</p>}
                {item.contractor_id && <ContractorDetails contractorId={item.contractor_id} />}
              </>
            ),
          };

          return orderHistoryItem;
        }),
        ...(responseHistories[1] as AllocationHistory[]).map(item => {
          const orderHistoryItem: OrderHistoryItem = {
            id: item.id,
            updated_at: item.updated_at,
            type: 'Alocação',
            typeContent: (
              <>
                {item.contractor_latitude &&
                  item.contractor_longitude &&
                  ['CHECKIN', 'CHECKOUT'].includes(item.status) && (
                    <ButtonMaps latitude={item.contractor_latitude} longitude={item.contractor_longitude} />
                  )}
              </>
            ),
            title: item.status,
            content: (
              <>
                {item.cancel_reason && <p>Motivo do cancelamento: {item.cancel_reason}</p>}

                <p>
                  Iteração: {item.iteration} <br />
                  Raio: {item.radius ?? 0}
                </p>

                <p>
                  Total de candidados: {item.total_candidates ?? 0} <br />
                  Candidados elegíveis: {item.total_eligible ?? 0} <br />
                  Candidatos inilegíveis: {item.total_ineligible ?? 0}
                </p>
              </>
            ),
          };

          return orderHistoryItem;
        }),
        ...(responseHistories[2] as PaymentHistory[]).map(item => {
          const orderHistoryItem: OrderHistoryItem = {
            id: item.id,
            updated_at: item.updated_at,
            type: 'Pagamento',
            title: item.status,
          };

          return orderHistoryItem;
        }),
        ...(responseHistories[3] as Invites[]).map(item => {
          const orderHistoryItem: OrderHistoryItem = {
            id: item.id,
            updated_at: item.created_at,
            type: 'Convite',
            typeContent: (
              <>
                {item.contractor_latitude && item.contractor_longitude && (
                  <ButtonMaps latitude={item.contractor_latitude} longitude={item.contractor_longitude} />
                )}
              </>
            ),
            title: item.status,
            content: (
              <>
                <p>
                  Iteração: {item.iteration} <br />
                </p>
                {item.contractor_id && <ContractorDetails contractorId={item.contractor_id} />}
                {item.cancel_reason && <p>Motivo do cancelamento: {item.cancel_reason}</p>}
              </>
            ),
          };

          return orderHistoryItem;
        }),
      ];

      const allHistoriesOrdered = allHistories.sort(
        (itemA, itemB) => Number(itemA.updated_at) - Number(itemB.updated_at),
      );

      setHistories(allHistoriesOrdered);
      setLoading(false);
    };

    callApis();
  }, [addToast, orderId]);

  return { histories, loading };
};

export default useOrderHistories;
