import React from 'react';
import { Tab, TabProps, Typography } from '@material-ui/core';

interface Props extends TabProps {
  title: string;
}

export const NotificationTab = ({ title, ...rest }: Props) => {
  return (
    <Tab
      label={
        <>
          <Typography>{title}</Typography>
        </>
      }
      {...rest}
    />
  );
};
