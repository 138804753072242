// import { Theme } from '@material-ui/core';
import { AxiosResponse } from 'axios';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    danger: PaletteColor;
  }

  interface PaletteOptions {
    danger?: PaletteColorOptions;
  }
}

// declare module '@material-ui/core/styles/createMuiTheme' {

//   interface Theme {
//     pa
//   }
//   // permitir configuração usando `createMuiTheme`
//   interface ThemeOptions {
//   }
// }

// declare module 'styled-components' {
//   export type DefaultTheme = Theme;
// }

export type ContractorsResponse = {
  id: string;
  status: 'VALIDATING' | 'APPROVED' | 'DENIED';
  role: 'ORG_ADMIN' | 'FREELANCER';
  name: string;
  phone_number?: string;
  organization: string;
  organization_id: string;
  city?: string;
  created_at?: number;
  services?: ServicesResponse[];
  _created_at_formatted?: string;
};

export interface VehicleContractorRequest {
  organization_id: string;
  vehicle_contractor_type_id: string;
  license_plate: string;
  model: string;
  color: string;
  manufacture: string;
  vin: string;
  state: string;
  city: string;
  services: {
    service_id: string;
  }[];
  status?: string;
  model_year?: string;
  fipe_data?: string;
}

export type VehicleContractorRequestEmpty = RecursivePartial<VehicleContractorRequest>;

export interface ContractorResponse {
  id: string;
  status: 'VALIDATING' | 'APPROVED' | 'DENIED';
  email?: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  rg?: {
    dispatched_date: string;
    number: string;
  };
  cnh?: {
    expiration_date: string;
    number: string;
  };
  organization: {
    id: string;
    social_identity: string;
    email: string;
    company_name: string;
    address: {
      id: string;
      street: string;
      number: string;
      complement?: string;
      neighborhood: string;
      zip_code: string;
      city: string;
      state: string;
      country: string;
      removed: boolean;
      created_at: number;
      updated_at: number;
    };
    removed: boolean;
    created_at: number;
    updated_at: number;
  };
  bank_data?: ContractorBankDataResponse;
}

export type ContractorResponseEmpty = RecursivePartial<ContractorResponse>;

export interface ContractorBankDataResponse {
  recipient: string;
  cnpj?: string;
  cpf?: string;
  cpf_cnpj?: string;
  bank: string;
  branch: string;
  bank_code: string;
  account_type: string;
  document_type: string;
  current_account: string;
  billing_address: {
    city: string;
    neighborhood: string;
    number: string;
    state: string;
    street: string;
    zip_code: string;
  };
  pix_key?: string;
}

export interface Bank {
  code: string;
  bank: string;
}

export type BanksResponse = Array<Bank>;

export type VehiclesResponse = Array<VehiclesResponseItem>;

export interface VehiclesResponseItem {
  id: string;
  organization_id: string;
  vehicle_contractor_type_id: string;
  vehicle_contractor_type_description: string;
  license_plate?: string;
  model: string;
  color: string;
  manufacture: string;
  vin?: string;
  state?: string;
  city?: string;
  status: string; // 'ATIVO'
  removed: boolean;
  created_at: number;
  updated_at: number;
  fipe_data?: {
    model_id: number;
    model_year_id: string;
    manufacture_id: number;
  };
  model_year: string;
}

export type VehiclesResponseItemEmpty = RecursivePartial<VehiclesResponseItem>;

export type EmployeesResponse = Array<{
  username: string;
  email: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  rg: {
    number: string;
    dispatched_date: string;
  };
  cnh: {
    number: string;
    expiration_date: string;
  };
  locale: string;
  zoneinfo: string;
}>;

export type DocumentsResponse = Array<{
  id: string;
  identity_id: string;
  type:
    | 'BANK_DATA'
    | 'CPF'
    | 'CNPJ'
    | 'IMG_CNPJ'
    | 'IMG_SOCIAL_CONTRACT'
    | 'IMG_PROOF_OF_RESIDENCE'
    | 'CNH'
    | 'IMG_CNH_FRONT'
    | 'IMG_CNH_BACK'
    | 'RG'
    | 'IMG_RG_FRONT'
    | 'IMG_RG_BACK'
    | 'IMG_CRLV_FRONT'
    | 'IMG_CRLV_BACK';
  value: string;
  verified: boolean;
  removed: boolean;
  created_at: number;
  updated_at: number;
}>;

export enum OpportunityStatusRequest {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_REFUSED = 'PAYMENT_REFUSED',
  IN_EXECUTION = 'IN_EXECUTION',
  ON_GOING = 'ON_GOING',
  MANUAL_ALLOCATION = 'MANUAL_ALLOCATION',
  ALLOCATING = 'ALLOCATING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export type OpportunityStatusType =
  | 'PAYMENT_PENDING'
  | 'PAYMENT_APPROVED'
  | 'PAYMENT_REFUSED'
  | 'IN_EXECUTION'
  | 'ON_GOING'
  | 'ON_GOING'
  | 'MANUAL_ALLOCATION'
  | 'ALLOCATING'
  | 'FINISHED'
  | 'CANCELED';

export enum OpportunityGenericStatus {
  PAYMENT = 'PAYMENT',
}

export interface Address {
  number?: string;
  street?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  street_number?: string;
  city_state?: string;
  position: string;
  ordination: number;
}

export enum OpportunityResponsePTBR {
  number = 'ID',
  service_id = 'ID do serviço',
  service_description = 'Descrição do serviço',
  association_id = 'ID da empresa',
  association_name = 'Nome da empresa',
  status = 'Situação',
  expended = 'Foi cobrado',
  disable_auto_allocation = 'Desabilitar alocação automática',
  customer_id = 'ID do cliente',
  customer_name = 'Nome do cliente',
  vehicle_id = 'ID do veículo',
  customer_vehicle_type = 'Tipo do veículo',
  state = 'Estado',
  city = 'Cidade',
  vin = 'Chassi',
  license_plate = 'Placa',
  vehicle_status = 'Situação do veículo',
  vehicle_color = 'Cor',
  vehicle_model = 'Marca',
  vehicle_model_year = 'Ano',
  vehicle_manufacture = 'Montadora',
  total = 'Total',
  discount = 'Desconto',
  surcharge = 'A pagar',
  has_benefits = 'Tem benefício',
  benefits_quota_exceeded = 'Cota excedida',
  payment_type = 'Tipo do pagamento',
  contractor_id = 'ID do prestador',
  contractor_name = 'Nome do prestador',
  contractor_phone_number = 'Telefone do prestador',
  cancel_reason = 'Rasão do cancelamento',
  organization_name = 'Nome da organização',
  updated_at_formatted = 'Atualizado em',
  created_at_formatted = 'Criado em',
}

export interface OpportunityResponse {
  id: string;
  number: string; // friendly_id
  time_zone: string;
  service_id: string;
  service_description: string;
  association_id: string;
  association_name: string;
  status: OpportunityStatusType;
  created_at: number;
  updated_at: number;
  from_backoffice: boolean;
  issued_by?: 'APP' | 'BACKOFFICE';
  created_at_formatted: string;
  updated_at_formatted: string;
  expended: boolean;
  disable_auto_allocation: boolean;
  customer_id: string;
  customer_name: string;
  coordinates: {
    order_id: string;
    position: 'SOURCE' | 'DESTINATION';
    ordination: number;
    latitude: number;
    longitude: number;
    address?: string;
    neighborhood?: string;
    city?: string;
  }[];
  vehicle_id: string;
  customer_vehicle_type: string;
  vehicle_type: string;
  state: string;
  city: string;
  vin: string;
  license_plate: string;
  license_plate_or_vin: string;
  vehicle_status: string;
  vehicle_color: string;
  vehicle_model: string;
  vehicle_model_year: string;
  vehicle_manufacture: string;
  total: number;
  discount: number;
  surcharge: number;
  has_benefits: boolean;
  benefits_quota_exceeded: boolean;
  payment_id: string;
  payment_token: string;
  payment_type: string;
  contractor_id: string;
  contractor_name: string;
  contractor_phone_number: string;
  cancel_reason?: string;
  organization_name?: string;
  customer_survey?: {
    feedback?: string;
    rating: number;
  };
  contractor_survey?: {
    feedback?: string;
    rating: number;
  };

  address?: Address[];
  backoffice_note: string;
}

export type OpportunitiesResponse = Array<OpportunityResponse>;

export interface DocumentRequest {
  type:
    | 'BANK_DATA'
    | 'CPF'
    | 'CNPJ'
    | 'IMG_CNPJ'
    | 'IMG_SOCIAL_CONTRACT'
    | 'IMG_PROOF_OF_RESIDENCE'
    | 'CNH'
    | 'IMG_CNH_FRONT'
    | 'IMG_CNH_BACK'
    | 'RG'
    | 'IMG_RG_FRONT'
    | 'IMG_RG_BACK'
    | 'IMG_CRLV_FRONT'
    | 'IMG_CRLV_BACK';
  value: string; // JSON.stringfy({ path: 'http://...' })
  identity_id: string;
  id?: string;
}

export enum AllocationContractorResponsePTBR {
  id = 'CPF',
  name = 'Nome',
  role = 'Função',
  phone_number = 'Telefone',
  company_name = 'Nome da empresa',
}

export interface AllocationContractorResponse {
  id: string;
  name: string;
  role: string;
  phone_number: string;
  company_name: string;
}

export type AllocationContractorsResponse = Array<AllocationContractorResponse>;

export interface CancelableAxiosPromise<T = any> extends Promise<AxiosResponse<T>> {
  cancel: () => void;
}

export interface CustomerVehicle {
  id: string;
  social_security: string;
  association_id: string;
  association_code: string;
  association_trading_name: string;
  vehicle_customer_type_id: string;
  vehicle_customer_type_description: string;
  license_plate: string;
  model: string;
  model_year?: string;
  color: string;
  manufacture: string;
  vin: string;
  state: string;
  city: string;
  status: string;
  removed: boolean;
  created_at: number;
  updated_at: number;
}

export interface CustomerVehicleRequest
  extends Omit<
    CustomerVehicle,
    'created_at' | 'updated_at' | 'removed' | 'social_security' | 'vehicle_customer_type_id' | 'status'
  > {
  status?: string;
}

export interface CustomerResponse {
  id: string;
  name: string;
  given_name: string;
  family_name: string;
  email?: string;
  locale: string;
  zoneinfo: string;
  enabled: boolean;
  cnh: {
    number: string;
    expiration_date: string;
  };
  rg: {
    number: string;
    dispatched_date: string;
  };
  vehicles: CustomerVehicle[];
}

export type CancellationReasonsResponse = Array<{
  id: string;
  reason: string;
  penalty_charge: boolean;
  created_at: number;
  updated_at: number;
  removed: boolean;
}>;

export interface QuizAnswersResponse {
  id: string;
  order_id: string;
  value?: {
    block: boolean;
    answer: string;
    question: string;
  }[];
}

export type CheckinDocumentType =
  | 'CHECKIN_IMG_BOTTOM'
  | 'CHECKIN_IMG_FRONT'
  | 'CHECKIN_IMG_LEFT_SIDE'
  | 'CHECKIN_IMG_RIGHT_SIDE'
  | 'CHECKIN_IMG_WINCHED';

export type CheckoutDocumentType =
  | 'CHECKOUT_IMG_BOTTOM'
  | 'CHECKOUT_IMG_FRONT'
  | 'CHECKOUT_IMG_LEFT_SIDE'
  | 'CHECKOUT_IMG_RIGHT_SIDE'
  | 'CHECKOUT_IMG_WINCHED';

export type OrderDocumentsResponse = Array<{
  id: string;
  order_id: string;
  document_type: CheckinDocumentType | CheckoutDocumentType;
  value: string;
  removed: boolean;
  created_at: number;
  updated_at: number;
}>;

export interface AllocationCancelReasonsResponse {
  back_office_phone_number: string;
  cancel_reasons: {
    id: string;
    cancel_reason: string;
    call_back_office: boolean;
  }[];
}

export enum CustomRoles {
  BACKOFFICE = 'BACKOFFICE',
  B2B = 'B2B',
}

export interface Authorization {
  authenticated?: boolean;
}

export interface AuthState {
  // client: object;
  authorization: Authorization;
  // user: object;
  loading: boolean;
  error: boolean;
}

export interface RootState {
  auth: AuthState;
}

export interface Association {
  id: string;
  company_name: string;
  trading_name: string;
  description: string;
  phone_number: string;
  email: string;
  removed: boolean;
  address_response: Address;
  code: string;
  diff_source_destination: boolean;
  charge_excess: boolean;
}

export type OrderDetailsResponse = Array<{
  type: string;
  value: string;
}>;

export interface OrderDetailsReason {
  reason: string;
}

export type CostType =
  | 'CANCELLATION_FEE'
  | 'SPECIAL_EXIT'
  | 'STOP_TIME'
  | 'TOLL'
  | 'OUTPUT_LIMIT'
  | 'OVER_DISTANCE'
  | 'COMMISSION_PERCENTAGE';

export type PricingAssociationType =
  | CostType
  | 'DIRT_ROAD'
  | 'DAILY'
  | 'GENERAL_COMMISSION_PERCENTAGE'
  | 'TRAVEL_COMMISSION_PERCENTAGE';

export enum PriceAssociationTableEnum {
  'DEFAULT_PRICING' = 'Padrão',
  'INTERMEDIATE_PRICING' = 'Intermediária',
  'LOCATION_BASED_PRICING' = 'Local',
}

export type PriceAssociationTableType = keyof typeof PriceAssociationTableEnum;

export type PayerType = 'ASSOCIATION' | 'CUSTOMER';

export type OrderCostsResponse = Array<{
  type: CostType;
  amount: string;
  value: string;
  payer: string;
  multiplier: string;
}>;

export type OrderHistory = {
  id: string;
  order_id: string;
  status: string;
  contractor_id?: string;
  payment_id?: string;
  cancel_reason?: string;
  expended: boolean;
  updated_at: string;
};

export type AllocationHistory = {
  id: string;
  order_id: string;
  iteration: number;
  status: string;
  radius?: number;
  total_candidates?: number;
  total_eligible?: number;
  total_ineligible?: number;
  updated_at: string;
  cancel_reason?: string;
  contractor_latitude?: string;
  contractor_longitude?: string;
};

export type PaymentHistory = {
  id: string;
  payment_id: string;
  status: string;
  updated_at: string;
};

export type Invites = {
  id: string;
  allocation_id: string;
  iteration: number;
  contractor_id: string;
  status: string;
  updated_at: string;
  created_at: string;
  cancel_reason?: string;
  contractor_latitude?: string;
  contractor_longitude?: string;
};

export enum StatusPTBR {
  NEW = 'Novo',
  PAYMENT_PENDING = 'Pagamento pendente',
  PENDING = 'Pendente',
  PAYMENT_APPROVED = 'Pagamento aprovado',
  ALLOCATING = 'Alocando',
  PERIMETER_VALIDATION = 'Validação do perímetro',
  SERVICE_VALIDATION = 'Validação do serviço',
  SENDING_INVITES = 'Enviando convites',
  CANCELED = 'Cancelado',
  MANUAL_ALLOCATION = 'Alocação manual',
  NOT_FOUND = 'Não encontrado',
  SENT = 'Enviado',
  ACCEPTED = 'Aceito',
  ALLOCATED = 'Alocado',
  ON_GOING = 'Em andamento',
  CHECKIN = 'Checkin',
  IN_EXECUTION = 'Em execução',
  PAYMENT_REFUSED = 'Pagamento recusado',
  FINISHED = 'Finalizado',
  CHECKOUT = 'Checkout',
  TIMEOUT = 'Tempo limite esgotado',
  DISCARDED = 'Descartado',
  APPROVED = 'Aprovado',
}

export type AllOrderHistoryType = 'Ordem' | 'Alocação' | 'Pagamento' | 'Convite';

export type OrderHistoryItem = {
  id: string;
  updated_at: string;
  type: AllOrderHistoryType;
  typeContent?: JSX.Element;
  title: string;
  content?: JSX.Element;
};

export interface CSVImportType {
  __rowNum__: number;
  NOME: string;
  SOBRENOME: string;
  CPF_CNPJ: string;
  SITUACAO: string;
  PLACA: string;
  CHASSI: string;
  MONTADORA: string;
  MODELO: string;
  TIPO_VEICULO: string;
  COR: string;
  ANO: string;
  CIDADE: string;
  ESTADO: string;
  SENHA: string;
  ['BBCPV 015']: string;
  ['BBCPV 005']: string;
  ['BBCPV 006']: string;
  ['BBCPV 007']: string;
  ['BBCPV 008']: string;
  ['BBCPV 009']: string;
  ['BCSPV 001']: string;
  ['BCCPV 001']: string;
  ['BCCPV 002']: string;
  ['BBCPV 013']: string;
}

export interface AssociationsResponse {
  id: string;
  company_name: string;
  trading_name: string;
  description: string;
  phone_number: string;
  email: string;
  removed: boolean;
  address_response: {
    id: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    zip_code: string;
    city: string;
    state: string;
    country: string;
    removed: boolean;
  };
  code: string;
  diff_source_destination: boolean;
  charge_excess: boolean;
}

export interface AssociationResponse {
  id: string;
  company_name: string;
  trading_name: string;
  description: string;
  phone_number: string;
  email: string;
  removed: boolean;
  address: {
    id: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    zip_code: string;
    city: string;
    state: string;
    country: string;
    removed: boolean;
  };
  code: string;
  diff_source_destination: boolean;
  charge_excess: boolean;
  documents: DocumentsResponse;
  configuration_value?: {
    hinova?: {
      active_sync: boolean;
      api_token?: string;
      from_to_vehicle?: Record<string, string[]>;
      from_to_service?: Record<string, string[]>;
    };
    omie?: {
      association_code: string;
      main_account: string;
      checkout_account: string;
    };
    calculation?: {
      travel_outside_the_benefit_city: {
        allowed: boolean;
        limit?: number;
      };
      intermediate_prices?: {
        allowed: boolean;
      };
      output_limit?: number;
    };
    association?: {
      power_bi?: string;
    };
  };
}

export interface CustomerUploadResponse {
  index: number;
  message: string;
}

export enum PaymentTypePTBR {
  CASH = 'Dinheiro',
  VOUCHER = 'Voucher',
  CARD_OFFLINE = 'Cartão (Offline)',
}

export interface ServicesResponse {
  id: string;
  displace: boolean;
  description: string;
}

export interface ContractorSurveyResponseItem {
  id: string;
  order_id: string;
  social_security: string;
  feedback: string;
  rating: number;
}

export type ContractorSurveyResponse = ContractorSurveyResponseItem[];

type ServiceType = 'REBOQUE' | 'CHAVEIRO' | 'TROCA DE PNEU' | 'PANE ELÉTRICA - BATERIA' | 'PANE SECA';

export type VehicleServicesResponse = VehicleServicesResponseItem[];
export interface VehicleServicesResponseItem {
  id: string;
  service_id: string;
  service_description: ServiceType | string;
  vehicle_contractor_id: string;
  removed: boolean;
  created_at: number;
  updated_at: number;
}

export interface QuizAssociationResponse {
  id: string;
  form: string;
}

export type Answers = {
  title: string;
  block: boolean;
};

type QuizType = {
  question: string;
  blockingResponse: boolean;
  answers: Array<Answers>;
} & Answers;

export type QuizResponse = {
  children?: Array<QuizType>;
} & QuizType;

export type QuizFormatted = {
  title: string;
  question: string;
  answers: Array<Answers>;
};

export type QuizForm = {
  question: string;
  answer: string;
  block: boolean;
};

export interface Coords {
  latitude: number;
  longitude: number;
}

export interface TripRequest {
  problem: {
    calculationMode: string;
    points: [
      {
        latitude: number;
        longitude: number;
        siteId: string;
      },
      {
        latitude?: number;
        longitude?: number;
        siteId: string;
      },
    ];
    useRealSpeeds: true;
    startDate: number;
  };
  identity: string;
}

export interface MapCalculatorRequest {
  time_zone_id: string;
  displace?: boolean;
  service_id?: string;
  association_id?: string;
  vehicle_id?: string;
  social_security?: string;
  has_picktow: boolean;
  points: string;
  source?: string;
  total_distance?: number;
  total_speed_profile_penalty?: number;
  destination?: string;
}

export interface MapCalculatorCostParameters {
  type: string;
  amount: number;
  value: number;
  multiplier: number;
  payer: string;
}

export interface MapCalculatorResponse {
  id: string;
  total: number;
  discount: number;
  surcharge: number;
  displace: boolean;
  has_benefits: boolean;
  benefits_quota_exceeded: boolean;
  charge_additional_fee: boolean;
  vehicle_status: string;
  vehicle_active: boolean;
  cost_parameters: Array<MapCalculatorCostParameters>;
  outside_limit_exceeded: boolean;
}

export interface PaymentMethodResponse {
  id: string;
  payment_method: string;
  description: string;
  type: string;
}

export interface AddressComponent {
  number?: string;
  street?: string;
  neighborhood: string;
  city: string;
  point_of_interest?: string;
  fullAddress: string;
}

export interface CoordsOrder {
  position: string;
  ordination: number;
  latitude?: number;
  longitude?: number;
  address?: string;
  neighborhood?: string;
  city?: string;
}

export interface OrderRequest {
  from_backoffice: boolean;
  payment: {
    token: string;
    type: string;
  };
  association_name?: string;
  detached: boolean;
  customer_id?: string;
  association_id?: string;
  time_zone?: string;
  service_type?: string;
  cost?: MapCalculatorResponse;
  vehicle: {
    type?: string;
    model?: string;
    model_year?: string;
    color?: string;
    manufacture?: string;
    status?: string;
    vin?: string;
    license_plate?: string;
    state?: string;
    city?: string;
  };
  coordinates: Array<CoordsOrder>;
  association_code?: string;
  service_id?: string;
  quiz_answers: string;
  disable_auto_allocation: boolean;
}

export interface OrderResponse {
  status: string;
}

export interface PaymentStatus {
  gatewayDetails?: {
    pixText: string;
    secureUrl?: string;
  };
}
export interface BenefitResponse {
  association_id: string;
  code: string;
  coverage_km: number;
  description: string;
  id: string;
  period: string;
  quantity: number;
  service_id: string;
  quota_expended: number;
  created_at: number;
}

export interface BenefitQuotaRequest {
  benefitsDateCreated: string;
  vin: string;
  customerId: string;
  benefitsQuantity: number;
  serviceId: string;
}

export interface BenefitsQuotaResponse {
  total_quota: number;
  consumed_quota: number;
}

export interface ErrorResponse {
  response?: {
    data?: {
      messages: string[];
    };
  };
}

export type UserB2B = {
  username: string;
  given_name: string;
  family_name: string;
  locale: 'pt-BR';
  zoneinfo: 'America/Sao_Paulo';
  password?: string;
  association_id: string;
  enabled: boolean;
};

export interface ServiceCategory {
  id: string;
  description: string;
  service_id: string;
}

export interface QuizAssociation {
  id: string;
  code: string;
  form: string;
  association_id: string;
  service_id: string;
  removed: boolean;
  active: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type WithChildren<T = {}> = T & { children?: React.ReactNode };

export interface PaymentDetails {
  pix?: {
    qrcode_text: string;
  };
  secure_url?: string;
}

export interface ContractorIdentity {
  username: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  role: string;
  locale: string;
  zoneinfo: string;
  picture?: string;
  email_verified: boolean;
  phone_verified: boolean;
  enabled: boolean;
  status: string;
  status_reason?: string;
}

export interface AssociationPrices {
  pricing_association_table: {
    id: string;
    type: PriceAssociationTableType;
    association_id: string;
    service_category_id: string;
    removed: boolean;
    created_at: number;
    updated_at: number;
    location_based_pricing: boolean;
    default_pricing: boolean;
    name?: string;
    data?: {
      kml_file_name: string;
    };
  };
  pricing_associations: {
    id: string;
    pricing_association_table_id: string;
    code: PricingAssociationType;
    value: number;
    removed: boolean;
  }[];
  location_based_pricing: boolean;
  default_pricing: boolean;
}

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P];
};

export interface IBGEState {
  id: number;
  acronym: string;
  name: string;
}

export interface IBGECity {
  id: number;
  name: string;
}
