import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import produce from 'immer';

import {
  loadCustomerApi,
  resetCustomer,
  deleteCustomer,
  deleteCustomerVehicleApi,
  loadCustomerByLicensePlateApi,
} from '../api';
import { useToast } from './toast';

import { CustomerResponse } from '~/types';

export function useCustomer(cpfCNPJ: string) {
  const [id, setId] = useState<string | undefined>(cpfCNPJ);
  const [data, setData] = useState<CustomerResponse>();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const getCustomerApi = useCallback(async (cpfCNPJParam?: string) => {
    if (!cpfCNPJParam || cpfCNPJParam.length < 11) return;

    try {
      setLoading(true);

      const { data } = await loadCustomerApi(cpfCNPJParam);

      setData(data);
    } catch (error) {
      setData(undefined);
    } finally {
      setLoading(false);
    }
  }, []);

  const getCustomerByLicensePlate = useCallback(async (licensePlate?: string) => {
    if (!licensePlate || licensePlate.length < 7) return;

    try {
      setLoading(true);

      const { data } = await loadCustomerByLicensePlateApi(licensePlate);

      return data as CustomerResponse[];
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const debounced = useCallback(debounce(getCustomerApi, 500), []);

  useEffect(() => {
    debounced(cpfCNPJ);
  }, [cpfCNPJ, debounced]);

  const refresh = useCallback(() => {
    getCustomerApi(cpfCNPJ);
  }, [cpfCNPJ, getCustomerApi]);

  const clear = useCallback(() => {
    setId(undefined);
    setData(undefined);
  }, []);

  const resetEmail = useCallback(() => {
    if (id) {
      return resetCustomer(id, 'EMAIL');
    } else {
      return Promise.resolve();
    }
  }, [id]);

  const resetPhone = useCallback(() => {
    if (id) {
      return resetCustomer(id, 'PHONE_NUMBER');
    } else {
      return Promise.resolve();
    }
  }, [id]);

  const erase = useCallback(() => {
    if (id) {
      return deleteCustomer(id).then(() => setData(undefined));
    } else {
      return Promise.resolve();
    }
  }, [id]);

  const deleteCustomerVehicle = useCallback(
    async vehicleId => {
      if (!vehicleId) {
        return;
      }

      try {
        await deleteCustomerVehicleApi(vehicleId);

        setData(prevState => {
          if (!prevState) {
            return;
          }

          return produce(prevState, draft => {
            draft.vehicles = prevState.vehicles.filter(item => item.id !== vehicleId);
          });
        });
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Erro ao excluir o veículo!',
        });
      }
    },
    [addToast],
  );

  return {
    data,
    loading,
    refresh,
    clear,
    resetEmail,
    resetPhone,
    erase,
    deleteCustomerVehicle,
    getCustomerByLicensePlate,
  };
}
