import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { useContractor } from '~/services/hooks';

import { CustomRoles } from '~/types';
import Loading from '~/components/Loading';

interface ButtonContractorNameProps {
  contractorId: string;
}

const ContractorDetails = ({ contractorId }: ButtonContractorNameProps) => {
  const [contractorToLoad, setContractorToLoad] = useState('');

  const { contractor, loading } = useContractor(contractorToLoad);
  const customRole = useSelector(state => state.auth.user['custom:role']);

  const handleLoadContractor = useCallback(() => {
    setContractorToLoad(contractorId);
  }, [contractorId]);

  return (
    <p>
      Prestador:{' '}
      {customRole === CustomRoles.B2B
        ? contractorId.split('').map((character, index) => (index < 3 ? character : '*'))
        : contractorId}
      <Tooltip title='Mostrar detalhes do prestador' arrow>
        <Button>
          <Search fontSize='default' onClick={handleLoadContractor} />
        </Button>
      </Tooltip>
      {loading ? (
        <Loading />
      ) : (
        contractor && (
          <p>Nome: {`${contractor.given_name} ${customRole === CustomRoles.B2B ? '' : contractor.family_name}`}</p>
        )
      )}
    </p>
  );
};

export default ContractorDetails;
