import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, FormLabel, TextField, Tooltip, Button as ButtonMUI } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

import Button from '~/components/Button';

import { formatPrice, maskAndFormatPrice } from '~/util/format';
import {
  loadAssociations,
  createAssociationPricingApi,
  loadServices,
  loadCategoriesByServices,
  loadPricingByAssociationAndServiceCategory,
  updateAssociationPricingApi,
  deleteAssociationPricingApi,
  uploadFile,
} from '~/services/api';

import { Form, Subtitle, InputComponent } from './styles';
import Snackbar, { SeverityType } from '~/components/Snackbar';
import { handleApiErrorResponse } from '~/services/handleErrors';
import {
  AssociationsResponse,
  ServiceCategory,
  ServicesResponse,
  AssociationPrices,
  PricingAssociationType,
  PriceAssociationTableType,
  PriceAssociationTableEnum,
} from '~/types';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { Title } from '~/components/Title';
import Modal from '~/components/Modal';
import Loading from '~/components/Loading';

type TableIdType = { id: string; type: PriceAssociationTableType; label?: string };

type TablePrice = {
  type?: PriceAssociationTableType;
  name?: string;
  kmlFile?: string;
  prices?: {
    code: PricingAssociationType;
    value: number;
  }[];
};

export default function TablePrices() {
  const [tablePrices, setTablePrices] = useState<TablePrice>();
  const [newTablePrices, setNewTablePrices] = useState<TablePrice>();
  const [loading, setLoading] = useState(false);
  const [associations, setAssociations] = useState<AssociationsResponse[]>([]);
  const [associationSelected, setAssociationSelected] = useState<AssociationsResponse | null>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [modalNewTablePrices, setModalNewTablePrices] = useState(false);

  const [services, setServices] = useState<ServicesResponse[]>([]);
  const [service, setService] = useState<ServicesResponse | null>(null);

  const [categories, setCategories] = useState<ServiceCategory[]>([]);
  const [category, setCategory] = useState<ServiceCategory | null>(null);

  const [tableId, setTableId] = useState<TableIdType | null>(null);
  const [tableType, setTableType] = useState<string[] | null>(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [fileKML, setFileKML] = useState<File>();

  const [associationPrices, setAssociationPrices] = useState<AssociationPrices[]>();

  const handleChangeService = async (service: ServicesResponse | null) => {
    try {
      setLoadingData(true);
      const { data } = await loadCategoriesByServices(service?.id);
      setCategories(data);
      setCategory(null);
      setService(service);
      setTablePrices(undefined);
      setTableId(null);
      setNewTablePrices(undefined);
      setFileKML(undefined);
      setTableType(null);
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChangeCategory = async (serviceCategory: ServiceCategory | null) => {
    setCategory(serviceCategory);
    setTablePrices(undefined);
    setTableId(null);
    setNewTablePrices(undefined);
    setFileKML(undefined);
    setTableType(null);
  };

  const handleSubmit = (table?: TablePrice) => async () => {
    if (!associationSelected || !table || !category) {
      setLoading(false);
      return;
    }

    let fileUrl = table.kmlFile;

    if (fileKML) {
      const fileData = new FormData();
      fileData.append('file', fileKML);
      const { data } = await uploadFile(fileData);
      fileUrl = data.url;
    }

    const payload = {
      name: table.name,
      association_id: associationSelected.id,
      service_category_id: category?.id,
      prices: table.prices,
      type: tableType
        ? tableType[0]
        : Object.keys(PriceAssociationTableEnum)[
            Object.values(PriceAssociationTableEnum).indexOf('LOCATION_BASED_PRICING' as PriceAssociationTableEnum)
          ],
      ...(fileUrl ? { data: { kml_file_name: fileUrl } } : {}),
    };

    try {
      tableId ? await updateAssociationPricingApi(tableId.id, payload) : await createAssociationPricingApi(payload);

      setSeverity('success');
      setMessageSnack('Cadastrado com sucesso!');
      setOpenSnackbar(true);
      loadPricingByAssociationAndServiceCategoryData();
      setTablePrices(undefined);
      setTableId(null);
      setNewTablePrices(undefined);
      setFileKML(undefined);
      setTableType(null);
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
    }
  };

  const changeTable = (tableId: TableIdType | null) => {
    setTableId(tableId);
  };

  const changeTableType = (type: string[] | null) => {
    setTableType(type);
  };

  const PriceTables: TableIdType[] | undefined = useMemo(
    () =>
      associationPrices?.map(item => {
        switch (item.pricing_association_table.type) {
          case 'DEFAULT_PRICING':
            return {
              label: 'Padrão',
              id: item.pricing_association_table.id,
              type: item.pricing_association_table.type,
            };

          case 'INTERMEDIATE_PRICING':
            return {
              label: 'Intermediária',
              id: item.pricing_association_table.id,
              type: item.pricing_association_table.type,
            };

          default:
            return {
              label: item.pricing_association_table.name,
              id: item.pricing_association_table.id,
              type: item.pricing_association_table.type,
            };
        }
      }),
    [associationPrices],
  );

  const handleChangePrice = (
    code: PricingAssociationType,
    value: number,
    stateParam: React.Dispatch<React.SetStateAction<TablePrice | undefined>> = setTablePrices,
  ) => {
    stateParam(prev => {
      if (!prev || !prev.prices) {
        return {
          prices: [
            {
              code,
              value,
            },
          ],
        };
      }

      const newTablePrices = prev.prices.filter(item => item.code !== code);
      newTablePrices.push({
        code,
        value,
      });

      return {
        ...prev,
        prices: newTablePrices,
      };
    });
  };

  const handleChangeName = (
    name?: string,
    stateParam: React.Dispatch<React.SetStateAction<TablePrice | undefined>> = setTablePrices,
  ) => {
    stateParam(prev => {
      if (!prev) {
        return {
          name,
        };
      }

      return {
        ...prev,
        name,
        prices: prev.prices,
      };
    });
  };

  const handleKmlFile = (kmlFile?: string) => {
    setTablePrices(prev => {
      if (!prev) {
        return {
          kmlFile,
        };
      }

      return {
        ...prev,
        kmlFile,
        prices: prev.prices,
      };
    });
  };

  const handleToggleModalNewTablePrices = () => {
    setTableId(null);
    setModalNewTablePrices(prev => !prev);
  };

  const handleCreateNewTable = () => {
    handleToggleModalNewTablePrices();
    handleSubmit(newTablePrices)();
  };

  const loadPricingByAssociationAndServiceCategoryData = useCallback(async () => {
    if (!associationSelected || !category) {
      setTablePrices(undefined);

      return;
    }

    try {
      setLoadingData(true);
      const { data: pricesData } = await loadPricingByAssociationAndServiceCategory(
        associationSelected.id,
        category?.id,
      );

      setAssociationPrices(pricesData);
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
    } finally {
      setLoadingData(false);
    }
  }, [associationSelected, category]);

  const handleDeleteTable = async () => {
    if (!tableId) {
      return;
    }

    try {
      setLoadingData(true);
      await deleteAssociationPricingApi(tableId.id);
      loadPricingByAssociationAndServiceCategoryData();
      setTablePrices(undefined);
      setTableId(null);
      setNewTablePrices(undefined);
      setFileKML(undefined);
      setTableType(null);
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data } = await loadAssociations();
        setAssociations(data || []);

        const { data: servicesData } = await loadServices();
        setServices(servicesData || []);
      } catch (err) {
        const error = handleApiErrorResponse(err);
        setSeverity('warning');
        setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
        setOpenSnackbar(true);
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    loadPricingByAssociationAndServiceCategoryData();
  }, [loadPricingByAssociationAndServiceCategoryData]);

  useEffect(() => {
    setTablePrices(undefined);
    if (!tableId) {
      return;
    }

    const table = associationPrices?.find(item => item.pricing_association_table.id === tableId.id);

    if (!table) {
      return;
    }

    table.pricing_associations.forEach(item => {
      handleChangePrice(item.code, item.value);
    });
    handleChangeName(table.pricing_association_table.name);
    handleKmlFile(table.pricing_association_table.data?.kml_file_name);
  }, [associationPrices, tableId]);

  return (
    <>
      <Title>Tabela de preços</Title>

      <Grid container spacing={2} alignItems='flex-end'>
        <Grid item xs={12} md={6} lg={4}>
          <CustomAutocomplete
            id='combo-box-associations'
            options={associations}
            getOptionLabel={item => item.code}
            renderInput={params => <TextField {...params} label='Selecione uma empresa' />}
            onChange={(_, value) => {
              setAssociationSelected(value);

              // TODO ade rever
              // if (associations.find(a => a.id === value?.id)?.code === 'PICKTOW') {
              //   changeExceeded(EXCEEDED[0]);
              // }
            }}
            value={associationSelected ?? null}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomAutocomplete
            id='combo-box-services'
            options={services}
            getOptionLabel={item => item.description}
            renderInput={params => <TextField {...params} label='Selecione o serviço' />}
            onChange={(_, value) => {
              handleChangeService(value);
            }}
            value={service ?? null}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomAutocomplete
            id='combo-box-categories'
            options={categories}
            getOptionLabel={item => item.description}
            renderInput={params => <TextField {...params} label='Selecione a categoria do serviço' />}
            onChange={(_, value) => {
              handleChangeCategory(value);
            }}
          />
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={6}>
          <CustomAutocomplete
            id='combo-box-exceeded'
            options={PriceTables || []}
            getOptionLabel={item => item.label || ''}
            renderInput={params => <TextField {...params} label='Selecione a tabela' />}
            onChange={(_, value) => {
              changeTable(value);
            }}
            value={tableId}
            width={200}
            disabled={associations.find(a => a.id === associationSelected?.id)?.code === 'PICKTOW'}
          />
        </Grid>

        <Grid item container xs={6} justify='flex-end' style={{ display: 'flex' }}>
          <Tooltip title='Nova tabela de preços' arrow>
            <ButtonMUI onClick={handleToggleModalNewTablePrices} disabled={!category}>
              <Add />
            </ButtonMUI>
          </Tooltip>
        </Grid>
      </Grid>

      <Form>
        <Grid container xs={12}>
          <Grid container item spacing={2} sm={12} md={6}>
            <Grid item xs={6}>
              <Subtitle>Serviço</Subtitle>
            </Grid>

            <Grid item xs={6}>
              <Subtitle>Valor</Subtitle>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Saida (Até 40km)</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('OUTPUT_LIMIT', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'OUTPUT_LIMIT')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>km Excedente</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('OVER_DISTANCE', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'OVER_DISTANCE')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Hora Parada</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('STOP_TIME', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'STOP_TIME')?.value, false)}
              />
            </Grid>

            {/* <Grid item xs={6} >
              <FormLabel>km Estrada de terra</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  maskAndFormatPrice(e.target);
                  setDirtRoad(e.target.value);
                }}
                value={dirtRoad}
              />
            </Grid> */}

            <Grid item xs={6}>
              <FormLabel>Diária na base</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('DAILY', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'DAILY')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Taxa de cancelamento</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('CANCELLATION_FEE', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'CANCELLATION_FEE')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Saida (Noite / Sab/ Dom)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('SPECIAL_EXIT', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(tablePrices?.prices?.find(item => item.code === 'SPECIAL_EXIT')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Comissão Geral (%)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('GENERAL_COMMISSION_PERCENTAGE', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(
                  tablePrices?.prices?.find(item => item.code === 'GENERAL_COMMISSION_PERCENTAGE')?.value,
                  false,
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Comissão Viagem (%)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('TRAVEL_COMMISSION_PERCENTAGE', maskAndFormatPrice(e.target.value));
                }}
                value={formatPrice(
                  tablePrices?.prices?.find(item => item.code === 'TRAVEL_COMMISSION_PERCENTAGE')?.value,
                  false,
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                label='Salvar'
                type='button'
                loading={loading}
                filled
                style={{ width: 200 }}
                onClick={handleSubmit(tablePrices)}
                disabled={!category}
              />
            </Grid>

            <Grid item xs={6} container justify='center'>
              <Button label='Excluir' onClick={handleDeleteTable} />
            </Grid>
          </Grid>

          {tableId?.type === 'LOCATION_BASED_PRICING' && (
            <Grid container item sm={12} md={6}>
              <Grid item>
                <Subtitle>Nome da tabela</Subtitle>
                <br />
                <InputComponent
                  onChange={e => {
                    handleChangeName(e.target.value);
                  }}
                  value={tablePrices?.name}
                />
                <br />
                <br />
                <br />
                <br />

                <Subtitle>Arquivo</Subtitle>
                <br />
                {fileKML?.name && (
                  <>
                    <br />
                    {fileKML?.name}
                    <br />
                  </>
                )}
                <br />
                <MyDropzoneKML setFile={setFileKML} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Form>

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />

      <Modal open={loadingData}>
        <Loading />
      </Modal>

      <Modal
        open={modalNewTablePrices}
        buttons={[
          <Button key='cancel' type='button' filled label='Cancelar' onClick={handleToggleModalNewTablePrices} />,
          <Button key='save' type='button' filled label='Salvar' onClick={handleCreateNewTable} />,
        ]}
        fullWidth
      >
        <Grid container item xs={12}>
          <Grid container item spacing={2} sm={12} md={6}>
            <Grid item xs={6}>
              <Subtitle>Serviço</Subtitle>
            </Grid>

            <Grid item xs={6}>
              <Subtitle>Valor</Subtitle>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Saida (Até 40km)</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('OUTPUT_LIMIT', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(newTablePrices?.prices?.find(item => item.code === 'OUTPUT_LIMIT')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>km Excedente</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('OVER_DISTANCE', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(newTablePrices?.prices?.find(item => item.code === 'OVER_DISTANCE')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Hora Parada</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('STOP_TIME', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(newTablePrices?.prices?.find(item => item.code === 'STOP_TIME')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Diária na base</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('DAILY', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(newTablePrices?.prices?.find(item => item.code === 'DAILY')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Taxa de cancelamento</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('CANCELLATION_FEE', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(
                  newTablePrices?.prices?.find(item => item.code === 'CANCELLATION_FEE')?.value,
                  false,
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Saida (Noite / Sab/ Dom)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice('SPECIAL_EXIT', maskAndFormatPrice(e.target.value), setNewTablePrices);
                }}
                value={formatPrice(newTablePrices?.prices?.find(item => item.code === 'SPECIAL_EXIT')?.value, false)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Comissão Geral (%)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice(
                    'GENERAL_COMMISSION_PERCENTAGE',
                    maskAndFormatPrice(e.target.value),
                    setNewTablePrices,
                  );
                }}
                value={formatPrice(
                  newTablePrices?.prices?.find(item => item.code === 'GENERAL_COMMISSION_PERCENTAGE')?.value,
                  false,
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Comissão Viagem (%)</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                onChange={e => {
                  handleChangePrice(
                    'TRAVEL_COMMISSION_PERCENTAGE',
                    maskAndFormatPrice(e.target.value),
                    setNewTablePrices,
                  );
                }}
                value={formatPrice(
                  newTablePrices?.prices?.find(item => item.code === 'TRAVEL_COMMISSION_PERCENTAGE')?.value,
                  false,
                )}
              />
            </Grid>
          </Grid>

          <Grid container item sm={12} md={6}>
            <Grid item sm={12}>
              <CustomAutocomplete
                id='combo-box-table-types'
                options={Object.entries(PriceAssociationTableEnum)}
                getOptionLabel={item => item[1] || ''}
                renderInput={params => <TextField {...params} label='Selecione o tipo da tabela' />}
                onChange={(_, value) => {
                  changeTableType(value);
                }}
                value={tableType}
              />
            </Grid>

            {tableType && tableType[1] === PriceAssociationTableEnum['LOCATION_BASED_PRICING'] && (
              <Grid item>
                <Subtitle>Nome da tabela</Subtitle>
                <br />
                <InputComponent
                  onChange={e => {
                    handleChangeName(e.target.value, setNewTablePrices);
                  }}
                  value={newTablePrices?.name}
                />
                <br />
                <br />
                <br />
                <br />

                <Subtitle>Arquivo</Subtitle>
                <br />
                {fileKML?.name && (
                  <>
                    <br />
                    {fileKML?.name}
                    <br />
                  </>
                )}
                <br />
                <MyDropzoneKML setFile={setFileKML} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

interface MyDropzoneKMLProps {
  setFile: (value?: File) => void;
}

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const MyDropzoneKML = ({ setFile }: MyDropzoneKMLProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const acceptedFilesValid = acceptedFiles.filter(file => {
        const m = file.name.match(/\.[0-9a-z]+$/i);
        if (!m) {
          return false;
        }

        return String(m[0]).toLowerCase() === '.kml';
      });

      setFile(acceptedFilesValid.length > 0 ? acceptedFilesValid[0] : undefined);
    },
    [setFile],
  );

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject, isFocused],
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Solte o arquivo aqui ...</p>
      ) : (
        <p>Arraste o arquivo .KML aqui, ou clique para selecionar o arquivo</p>
      )}
    </div>
  );
};
