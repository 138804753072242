export const oneSecondInMillis = 1000
export const oneMinuteInMillis = 60 * oneSecondInMillis

export const daysAwayFromDate = (date: Date, days: number) => {
  const dateCopy = new Date(date)
  dateCopy.setDate(dateCopy.getDate() + Math.round(days))
  return dateCopy
}

export const minutesInMillis = (minutes: number) => minutes * oneMinuteInMillis

export const dateDiffFromNow = (updatedAt: number, multiplier = 1000) => Date.now() - (updatedAt * multiplier)