import { useState, useEffect, useCallback } from 'react';

import { DocumentsResponse, DocumentRequest } from '~/types';
import {
  loadDocumentsApi,
  updateDocument,
  createDocument,
  uploadFile,
  sendReviewDocumentsEmail,
  uploadPublicFilesApi,
  changePictureContractorAdminApi,
} from '../api';

export function useDocuments(contractorId?: string) {
  const [documents, setDocuments] = useState<DocumentsResponse>([]);
  const [loading, setLoading] = useState(false);

  const load = useCallback(() => {
    if (contractorId) {
      setLoading(true);
      loadDocumentsApi(contractorId)
        .then(response => response.data as DocumentsResponse)
        .then(data => data.map(item => ({ ...item, value: JSON.parse(item.value).path })))
        .then(setDocuments)
        .finally(() => setLoading(false));
    } else {
      setDocuments([]);
    }
  }, [contractorId]);

  const updateSelf = useCallback(async (contractorId: string, image: File) => {
    const fileData = new FormData();
    fileData.append('file', image);

    const { data } = await uploadPublicFilesApi(fileData);
    const photoURL = data?.url;

    if (photoURL) {
      await changePictureContractorAdminApi(contractorId, {
        picture: photoURL,
      });
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const update = useCallback(
    (id: string, type: DocumentRequest['type'], image: File) => {
      if (contractorId) {
        return updateDoc(contractorId, id, type, image).then(() => load());
      } else return Promise.resolve();
    },
    [contractorId, load],
  );

  const create = useCallback(
    (type: DocumentRequest['type'], image: File) => {
      if (contractorId) {
        return createDoc(contractorId, type, image).then(() => load());
      } else return Promise.resolve();
    },
    [contractorId, load],
  );

  const sendReviewEmail = useCallback(
    (email: string) => {
      if (contractorId) {
        return sendReviewDocumentsEmail(contractorId, { email });
      } else return Promise.resolve();
    },
    [contractorId],
  );

  return {
    documents,
    loading,
    refresh: load,
    update,
    create,
    sendReviewEmail,
    updateSelf,
  };
}

export default useDocuments;

function updateDoc(identity_id: string, id: string, type: DocumentRequest['type'], image: File) {
  const fileData = new FormData();
  fileData.append('file', image);
  return uploadFile(fileData)
    .then(response => response.data.url as string)
    .then(url =>
      updateDocument(id, {
        type,
        identity_id,
        id,
        value: JSON.stringify({ path: url }),
      } as DocumentRequest),
    );
}

function createDoc(identity_id: string, type: DocumentRequest['type'], image: File) {
  const fileData = new FormData();
  fileData.append('file', image);
  return uploadFile(fileData)
    .then(response => response.data.url as string)
    .then(url =>
      createDocument(identity_id, {
        type,
        identity_id,
        value: JSON.stringify({ path: url }),
      } as DocumentRequest),
    );
}
