import { useEffect, useRef } from 'react';

export function useInterval(
  callback: () => void,
  intervalMs: number,
  runOnCreation: boolean = true,
  status: 'PLAY' | 'PAUSE' = 'PLAY')
{
  const savedCallback = useRef<() => void | null>()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (runOnCreation && typeof savedCallback?.current !== 'undefined') {
      savedCallback?.current()
    }
  }, [runOnCreation])

  useEffect(() => {

    function tick() {
      if (typeof savedCallback?.current !== 'undefined') {
        savedCallback?.current()
      }
    }

    if (status === 'PLAY') {
      const id = setInterval(tick, intervalMs)
      return () => clearInterval(id)
    }
  }, [intervalMs, status])

}

export default useInterval;
