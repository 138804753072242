export function formatPrice(value, withCurrency = true) {
  if (!value) {
    if (withCurrency) {
      return 'R$ 0,00';
    }

    return '0,00';
  }

  const localValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  return localValue.replace(/^([\D]{3})(.*)/, withCurrency ? 'R$ $2' : '$2');
}

export function maskAndFormatPrice(value) {
  let v = value.replace(/\D/g, '');
  v = `${(v / 100).toFixed(2)}`;
  return Number(v);
}

export function formatPhone(phone) {
  if (!phone) return '';
  return phone.length > 10
    ? phone.replace(/(^\+\d{2})(\d{2})(\d{5})(\d{0,4}$)/, '$1 ($2) $3-$4').slice(0)
    : phone.length > 5
    ? phone.replace(/(^\+\d{2})(\d{2})(\d{0,5}$)/, '$1 ($2) $3').slice(0)
    : phone.length > 3
    ? phone.replace(/(^\+\d{2})(\d{0,2}$)/, '$1 ($2').slice(0)
    : phone.slice(0);
}

export function formatZipCode(zipCode) {
  if (!zipCode) {
    return '';
  }

  return zipCode.length > 5 ? zipCode.replace(/(^\d{1,5})(\d{0,3}$)/, '$1-$2') : zipCode;
}

export function formatDate(date, format) {
  if (typeof date !== 'string') {
    return '';
  }

  const split = date.split('-');

  const month = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ];

  switch (format) {
    case 'dd de mm de yyyy':
      return `${split[2]} de ${month[parseInt(split[1], 10) - 1]} de ${split[0]}`;
    case 'dd/mm/yyyy':
      return split.reverse().join('/');

    default:
      return date;
  }
}

export function hideEmail(email) {
  return email.replace(/(.{2})(.*)(?=@)/, (gp1, gp2, gp3) => {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
}

export const removeFormat = (text, length = 14) => {
  if (!text) {
    return '';
  }

  return text.replace(/\D/g, '').substring(0, length);
};

export const formatCPF = valor => {
  if (!valor) return '';
  return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const formatCNPJ = value => {
  if (!value) return '';

  return value.length > 12
    ? value.replace(/(^\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2}$)/g, '$1.$2.$3/$4-$5')
    : value.length > 8
    ? value.replace(/(^\d{2})(\d{3})(\d{3})(\d{0,4}$)/g, '$1.$2.$3/$4')
    : value.length > 5
    ? value.replace(/(^\d{2})(\d{3})(\d{0,3}$)/g, '$1.$2.$3')
    : value.length > 2
    ? value.replace(/(^\d{2})(\d{0,3}$)/g, '$1.$2')
    : value;
};

export const formatUsername = (text, onlyCNPJ = false, onlyCPF = false) => {
  if (!text) {
    return '';
  }

  let textFormated = removeFormat(text);

  if (onlyCPF) {
    textFormated = textFormated.substr(0, 11);
  }

  if (textFormated.length <= 11 && !onlyCNPJ) {
    textFormated = formatCPF(textFormated);
  } else if (!onlyCPF) {
    textFormated = formatCNPJ(textFormated);
  }
  return textFormated;
};

export const replaceSpecialChars = str => {
  if (!str) {
    return;
  }

  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    .replace(/--+/g, '-') // Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/, '')
    .toLowerCase(); // Remove hífens extras do final ou do inicio da string
};

export function formatCellPhone(phone) {
  if (!phone) {
    return '';
  }

  return phone
    .replace(/^0/, '')
    .replace(/(\d{2})(\d{4,5})(\d{4})/, '($1)$2-$3')
    .trim();
}

export const onlyNumbers = text => text.replace(/[^0-9]+/g, '');

export const formatLicensePlate = licensePlate => {
  return licensePlate
    ?.toUpperCase()
    .replace(/-/, '')
    .replace(/(\w{3})(\w{4})/, '$1-$2');
};

export const formatCPF_CNPJ = str => {
  if (str.length <= 11) {
    const partial = str.split(/(\d{1,3})/).filter(s => s.length);
    return partial.slice(0, 3).join('.') + (partial.length === 4 ? `-${partial[3]}` : '');
  } else {
    return formatCNPJ(str);
  }
};

export const formatValue = str => {
  if (!str) return '';

  return String(str).replace('.', ',');
};
