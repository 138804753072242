import { Tooltip, Typography, CircularProgress, Paper } from '@material-ui/core';
import { Star, StarBorder, StarHalf } from '@material-ui/icons';
import React from 'react'

import { ContractorSurveyResponse } from '../../../../types';
import { avg } from '../utils/statistics';
import { RatingWrapper } from '../styles';

interface RatingStarProps {
  rating: number
  starValue: number
}

const RatingStar = ({ rating, starValue }: RatingStarProps) => {
  if (rating >= starValue) {
    return <Star fontSize='large' />
  }
  else if (rating > starValue - 1) {
    return <StarHalf fontSize='large' />
  }
  else {
    return <StarBorder fontSize='large' />
  }
}

interface FiveStarRatingBar {
  rating: number
  messages: { [score: number]: string[] }
}

const FiveStarRatingBar = ({ rating, messages }) => {

  return (
    <RatingWrapper>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {
          [1, 2, 3, 4, 5].map(starNumber =>
            <Tooltip title={messages?.[starNumber.toString()] || 'Sem mensagem.'} key={`star#${starNumber}`}>
              <div><RatingStar rating={rating || 0} starValue={starNumber} /></div>
            </Tooltip>
          )
        }
      </div>
      <div style={{ textAlign: 'center', padding: '1em 0 1em 0' }}>
        {
          !Number.isNaN(rating) &&
          <Typography style={{ fontSize: '1.3em' }}>
            <strong>
              {
                `${rating.toFixed(1).replace('.', ',')} de 5`
              }
            </strong>
          </Typography>
        }
        {
          Number.isNaN(rating) && <p style={{ fontSize: '1.3em', fontWeight: 'bold' }}>Não há avaliações</p>
        }
      </div>
    </RatingWrapper>
  )
}

interface RatingBarProps {
  ratings: ContractorSurveyResponse
  periodDays: number
  loading: boolean
}

const RatingBar = ({ ratings, loading, periodDays }: RatingBarProps) => {

  const averageRating = avg(ratings.map(rating => rating.rating))
  const messages = Object.fromEntries(ratings.map(rating => [rating.rating, `Mensagem do cliente: "${rating.feedback}"`]))

  return (
    <Paper style={{padding: '10px'}}>
      <div>
        <Typography><strong>Avaliação: </strong></Typography>

        {
          !loading && <>
            <FiveStarRatingBar rating={averageRating} messages={messages} />
          </>
        }
        {
          !loading && ratings.length > 0 && <p>
            <em>* Com base nas avaliações de {ratings.length} oportunidades nos últimos {periodDays} dias.</em>
          </p>
        }
        {
          !loading && ratings.length === 0 && <p>
            <em>* Buscando pelas notas de oportunidades nos últimos {periodDays} dias.</em>
          </p>
        }
        {
          loading && <CircularProgress size='2em' />
        }
      </div>
    </Paper>
  )
}

export default RatingBar;