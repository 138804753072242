import React, { ReactNode } from 'react';
import {
  DialogProps,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';

export interface DetailsDialogProps extends DialogProps {
  children: ReactNode;
  buttons?: JSX.Element[];
  loading?: boolean;
}

export function Modal({ children, buttons: Buttons, loading, ...props }: DetailsDialogProps) {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog maxWidth='lg' fullScreen={fullscreen} disableScrollLock={true} scroll='paper' {...props}>
      {loading ? (
        <CircularProgress style={{ margin: 100 }} />
      ) : (
        <>
          <DialogContent>{children}</DialogContent>
          {Buttons && <DialogActions>{Buttons}</DialogActions>}
        </>
      )}
    </Dialog>
  );
}

export default Modal;
