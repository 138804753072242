import React from 'react';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { AddPhoto } from './styles';

interface AddPhotoButtonProps {
  onClick: () => void;
}

export const AddPhotoButton: React.FC<AddPhotoButtonProps> = ({
  onClick
}) => {
  return (
    <AddPhoto aria-label='upload photo' color='primary' onClick={() => onClick()}>
      <AddToPhotosIcon style={{ fontSize: 30 }} />
    </AddPhoto>
  );
};
