import React, { useMemo, useRef } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { toast } from 'react-toastify';

import { useContractors } from '~/services/hooks';
import { Table } from '~/components';
import { formatCPF } from '~/util/format';
import { customFilterAndSearchContractors } from '~/util/customFilter';
import Button from '~/components/Button';
import { ContractorsResponse } from '~/types';
import InputComponent from '~/components/InputComponent';
import { sendPushApi } from '~/services/api';

export interface ContractorsTableProps {
  open: boolean;
}

export const ContractorsTable = ({ open }: ContractorsTableProps) => {
  const { loading, contractors } = useContractors('APPROVED');

  const contractorsSelectedRef = useRef<ContractorsResponse[]>();
  const titleRef = useRef<TextFieldProps>(null);
  const messageRef = useRef<TextFieldProps>(null);

  const handleSubmit = async () => {
    let error = false;

    const title = titleRef.current?.value ? String(titleRef.current?.value) : undefined;
    const message = messageRef.current?.value ? String(messageRef.current?.value) : undefined;

    if (!contractorsSelectedRef.current?.length) {
      error = true;
      toast.warn('Selecione os prestadores');
    }

    if (!title?.trim()) {
      error = true;
      toast.warn('Informe o título');
    }

    if (!message?.trim()) {
      error = true;
      toast.warn('Informe a mensagem');
    }

    if (error) {
      return;
    }

    toast.promise(
      sendPushApi({
        destinations: contractorsSelectedRef.current?.map(contractor => contractor.id),
        type: 'CONTRACTOR',
        body: message,
        title,
      }),
      {
        pending: 'Enviando notificação',
        error: 'Erro ao enviar notificação',
        success: 'Notificação enviada com sucesso!',
      },
    );
  };

  const services = useMemo(() => {
    const contractorsServices = contractors
      .filter(contractor => (contractor.services?.length || 0) > 0)
      .map(contractors => contractors.services!);

    const allServices = contractorsServices.flat();

    const distinctServices = allServices
      .filter(
        (service, index, selfServices) =>
          index === selfServices.findIndex(selfService => selfService.id === service.id),
      )
      .reduce((acc, service) => {
        acc[service.description] = service.description;
        return acc;
      }, {});

    return distinctServices;
  }, [contractors]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Table
        columns={{
          id: {
            title: 'CPF',
            render: rowData => formatCPF(rowData.id),
          },
          name: 'Nome',
          organization: 'Empresa',
          services: {
            title: 'Serviços',
            lookup: services,
            render: rowData => rowData.services?.map(service => <p key={service.id}>{service.description}</p>),
            customFilterAndSearch: customFilterAndSearchContractors,
            sorting: false,
            cellStyle: {
              whiteSpace: 'nowrap',
            },
          },
          city: 'Cidade',
        }}
        data={contractors}
        options={{
          showTitle: false,
          filtering: true,
          selection: true,
        }}
        customOptions={{
          noLineBreakInHeader: true,
        }}
        isLoading={loading}
        localization={{
          toolbar: {
            nRowsSelected: '{0} linha(s) selecionada(s)',
          },
        }}
        onSelectionChange={rows => {
          contractorsSelectedRef.current = rows;
        }}
      />

      <InputComponent type='text' autoFocus ref={titleRef} label='Título' />

      <InputComponent type='text' autoFocus ref={messageRef} label='Mensagem' />

      <Button filled label='Enviar' onClick={handleSubmit} />
    </>
  );
};

export default ContractorsTable;
