import React from 'react';

import Button from '~/components/Button';

interface ChangeAddressButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading: boolean;
}

const ChangeAddressButton = ({ loading, ...rest }: ChangeAddressButtonProps) => {
  return (
    <Button
      {...rest}
      type='button'
      label='Alterar endereço'
      loading={loading}
      filled
      style={{ width: 200, marginRight: 30 }}
    />
  );
};

export default ChangeAddressButton;
