import React from 'react';
import { IconText, IconWithBottomText, IconContentGrid, TimelineIcon, ChipButton, Hr } from '../styles';
import { CircularProgress } from '@material-ui/core';

import NameValueItem from './NameValueItem';
import { CopyableNameValueItem } from './NameValueItem';
import ServiceIcon from './ServiceIcon';
import { OpportunityResponse } from '../../../../types';
import { chooseIconWithBottomTextColor } from '../utils/colorChoice';
import { opacityColorForBackgroundHex } from '../../../../styles/colors';

interface OrderInfoSectionProps {
  orderData: {
    orders: OpportunityResponse[];
    loading: boolean;
  };
  iconStyle: {
    width: string;
    height: string;
    color: string;
  };
  iconActive: boolean;
}

const OrderInfoSection = ({ orderData, iconStyle, iconActive = false }: OrderInfoSectionProps) => {
  const color = chooseIconWithBottomTextColor(orderData.orders.length > 0, iconActive);

  return (
    <IconContentGrid>
      <IconWithBottomText color={color}>
        <ServiceIcon
          iconColor={opacityColorForBackgroundHex({ bgHex: color })}
          service={orderData.orders.length > 0 ? orderData.orders[0].service_description : ''}
          style={{ ...iconStyle }}
        />
        <IconText bgColorHex={color}>
          {orderData.orders.length
            ? `${orderData.orders[0].number} ${orderData.orders[0].service_description}`
            : 'ORDER'}
        </IconText>
      </IconWithBottomText>

      <section>
        {orderData.loading && <CircularProgress size='2em' />}

        {orderData.orders.map(order => (
          <div key={`OrderInfoSection#${order.id}`}>
            <NameValueItem title='Nome' loading={orderData.loading}>
              {order.customer_name}
            </NameValueItem>

            <CopyableNameValueItem textGenerator={() => order.number} title='Ordem' loading={orderData.loading}>
              {order.number}
            </CopyableNameValueItem>

            <NameValueItem title='Serviço' loading={orderData.loading}>
              {order.service_description}
            </NameValueItem>

            <NameValueItem title='Empresa' loading={orderData.loading}>
              {order.association_name}
            </NameValueItem>

            <NameValueItem title='Situação' loading={orderData.loading}>
              {order.status}
            </NameValueItem>

            <NameValueItem title='Origem' loading={orderData.loading}>
              {order.from_backoffice ? 'BACKOFFICE' : 'APP'}
            </NameValueItem>

            <NameValueItem title='Veículo do cliente' loading={orderData.loading}>
              {`${order.vehicle_type} - ${order.vehicle_manufacture} ${order.vehicle_model}`}
            </NameValueItem>

            <NameValueItem title='Placa' loading={orderData.loading}>
              {order.license_plate}
            </NameValueItem>
          </div>
        ))}
        <Hr />

        <a href={`/opportunity-timeline/${orderData.orders[0].id}`} target='_blank' rel='noopener noreferrer'>
          <ChipButton label='Ver na timeline' icon={<TimelineIcon />} />
        </a>
      </section>
    </IconContentGrid>
  );
};

export default OrderInfoSection;
