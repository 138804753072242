import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import CopyableToClipboard from '~/components/Copyable';

export interface NameValueItemProps {
  title: string;
  loading?: boolean;
  emptyText?: string;
  children: any;
}

const NameValueItem: React.FC<NameValueItemProps> = ({ title, children, loading = false }: NameValueItemProps) => (
  <Typography>
    <strong>
      {title}
      {': '}
    </strong>
    <>
      {loading ? (
        <span>
          <CircularProgress size={15} />
        </span>
      ) : (
        children
      )}
    </>
  </Typography>
);

export interface CopyableNameValueItemProps extends NameValueItemProps {
  textGenerator: () => string;
}

export const CopyableNameValueItem = ({
  title,
  children,
  loading = false,
  textGenerator,
}: CopyableNameValueItemProps) => (
  <CopyableToClipboard textGenerator={textGenerator}>
    <NameValueItem title={title} loading={loading}>
      {children}
    </NameValueItem>
  </CopyableToClipboard>
);

export default NameValueItem;
