import React from 'react';
import { OpportunityStatusRequest, OpportunityResponse } from '../../../../../../types';
import { Hoverable, TextOverflow } from './styles';
import { Tooltip } from '@material-ui/core';

const mapCancelReasonToReadableHeaderText = (reason: string, status: string) => {
  if (status === OpportunityStatusRequest.PAYMENT_APPROVED) {
    return 'Pagamento aprovado';
  }

  if (status === OpportunityStatusRequest.PAYMENT_PENDING) {
    return 'Aguardando o processamento';
  }

  if (status === OpportunityStatusRequest.PAYMENT_REFUSED) {
    if (reason === 'message.payment.association.balance.insufficient') return `O saldo da empresa é insuficiente`;

    if (
      reason === 'Read timed out' ||
      reason.startsWith('500 Internal Server Error') ||
      reason.startsWith('502 Bad Gateway')
    ) {
      return 'Falha comunicação com serviço externo';
    }
  }
};

const MessageText = ({ header, message = 'Sem motivo' }: { header?: string; message?: string }) => {
  return (
    <>
      {header && (
        <p>
          {header}
          {message && ':'}
        </p>
      )}
      {message && (
        <em>
          <TextOverflow>{message}</TextOverflow>
        </em>
      )}
    </>
  );
};

const CancelReasonItem = ({ data }: { data: OpportunityResponse }) => {
  return (
    <Hoverable>
      <Tooltip title={data.cancel_reason || 'Sem motivo.'} arrow>
        <div>
          <MessageText
            message={data.cancel_reason}
            header={mapCancelReasonToReadableHeaderText(data.cancel_reason || '', data.status)}
          />
        </div>
      </Tooltip>
    </Hoverable>
  );
};

export default CancelReasonItem;
