import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'material-table';
import { Refresh } from '@material-ui/icons';

import { useOpportunities } from '~/services/hooks';
import { OpportunityStatusRequest, OpportunitiesResponse, PaymentTypePTBR, CustomRoles } from '~/types';
import { Table } from '~/components';
import { formatPhone, formatCNPJ, formatCPF } from '~/util/format';
import ActionMenu from '../ActionMenu';
import Details from '../Details';
import exportCustomCSV from '~/util/exportCustomCSV';
import { useOrdersBadge } from '~/services/hooks/useOrdersBadge';
import Colors from '~/styles/colors';

export interface OnGoingTableProps {
  open: boolean;
}

// Aceitas
export function OnGoingTable(props: OnGoingTableProps) {
  const { customRole } = useSelector(state => ({
    customRole: state.auth.user['custom:role'],
  }));
  const { loading, data, invalidateQuery } = useOpportunities(OpportunityStatusRequest.ON_GOING);

  const [opportunityDetails, setOpportunityDetails] = useState<OpportunitiesResponse[number]>();

  useEffect(() => {
    if (opportunityDetails) {
      const updatedOpportunity = data.filter(opp => opp.id === opportunityDetails.id);
      setOpportunityDetails(updatedOpportunity[0]);
    }
  }, [data, opportunityDetails]);

  useOrdersBadge('onGoing', data.length);

  useEffect(() => {
    if (props.open) {
      invalidateQuery();
    }
  }, [invalidateQuery, props.open]);

  if (!props.open) return null;
  else
    return (
      <>
        <Table
          columns={{
            actions: {
              filtering: false,
              export: false,
              render: rowData => (
                <ActionMenu id='teste' data={rowData} onCancel={invalidateQuery} onCancelAllocation={invalidateQuery} />
              ),
            },
            number: {
              title: 'ID do pedido',
              cellStyle: (_, rowData) => {
                const diff = Date.now() - rowData.updated_at;
                return {
                  background:
                    diff > 1000 * 60 * 50
                      ? Colors.pureRed
                      : diff > 1000 * 60 * 40
                      ? Colors.pureYellow
                      : diff > 1000 * 60 * 30
                      ? Colors.pureLightBlue
                      : diff > 1000 * 60 * 6
                      ? Colors.pureGreen
                      : Colors.black,
                  color:
                    diff > 1000 * 60 * 50
                      ? Colors.white
                      : diff > 1000 * 60 * 40
                      ? Colors.black
                      : diff > 1000 * 60 * 30
                      ? Colors.white
                      : diff > 1000 * 60 * 6
                      ? Colors.white
                      : Colors.white,
                };
              },
            },
            service_description: {
              title: 'Tipo de Serviço',
              lookup: (() => data.map(d => d.service_description).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
            },
            issued_by: {
              title: 'Origem',
              hidden: true,
              export: true,
              render: rowData => rowData?.issued_by || 'DESCONHECIDO',
            },
            payment_type: {
              title: 'Forma de Pagamento',
              lookup: (() =>
                data
                  .map(item => item.payment_type)
                  .reduce((acc, item) => ({ ...acc, [item]: PaymentTypePTBR[item] }), {}))(),
              render: rowData => PaymentTypePTBR[rowData.payment_type] ?? rowData.payment_type,
              export: false,
              exportOnlyCSV: true,
            },
            customer_id: {
              title: 'CPF/CNPJ',
              render: rowData =>
                rowData.customer_id.length > 11 ? formatCNPJ(rowData.customer_id) : formatCPF(rowData.customer_id),
              cellStyle: {
                whiteSpace: 'nowrap',
              },
            },
            customer_name: {
              title: 'Cliente',
              cellStyle: {
                minWidth: 200,
                textAlign: 'left' as const,
              },
            },
            customer_phone_number: {
              title: 'Telefone do Cliente',
              exportOnlyCSV: true,
              export: false,
            },
            organization_name: {
              title: 'Empresa',
              cellStyle: {
                minWidth: 200,
                textAlign: 'left',
              },
            },
            contractor_name: {
              title: 'Nome do prestador',
              cellStyle: {
                minWidth: 200,
                textAlign: 'left',
              },
              render: rowData =>
                customRole === CustomRoles.B2B ? rowData.contractor_name?.split(' ')[0] : rowData.contractor_name,
            },
            contractor_phone_number: {
              title: 'Telefone do prestador',
              render: rowData => (rowData.contractor_phone_number ? formatPhone(rowData.contractor_phone_number) : ''),
              hidden: customRole === CustomRoles.B2B,
            },
            association_name: {
              title: 'Empresa',
              lookup: (() => data.map(d => d.association_name).reduce((O, s) => ({ ...O, [s]: s }), {}))(),
              cellStyle: {
                minWidth: 200,
                textAlign: 'left' as const,
              },
            },
            created_at_formatted: 'Data/Hora de inicio',
            updated_at_formatted: {
              title: 'Data/Hora do aceite do pedido',
              defaultSort: 'asc',
            },
            license_plate_or_vin: 'Placa do veículo ou Chassi',
            vehicle_manufacture: {
              title: 'Marca',
              hidden: true,
              export: false,
              exportOnlyCSV: true,
            },
            vehicle_model: {
              title: 'Modelo do veículo',
              hidden: true,
              export: true,
            },
            source_latlng: {
              title: 'LatLng Origem',
              render: rowData => {
                const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

                if (sourceIndex === -1) {
                  return '';
                }

                const source = rowData.coordinates[sourceIndex];

                return `${source.latitude}, ${source.longitude}`;
              },
              hidden: true,
              export: false,
              exportOnlyCSV: true,
            },
            source_address: {
              title: 'Endereço Origem',
              render: rowData => {
                const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'SOURCE');

                if (sourceIndex === -1) {
                  return '';
                }

                const source = rowData.coordinates[sourceIndex];
                const address = source.address ?? '';
                const city = source.city ? ` - ${source.city}` : '';

                return `${address}${city}`;
              },
              hidden: true,
              export: false,
              exportOnlyCSV: true,
            },
            destination_latlng: {
              title: 'LatLng Destino',
              render: rowData => {
                const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

                if (sourceIndex === -1) {
                  return '';
                }

                const source = rowData.coordinates[sourceIndex];

                return `${source.latitude}, ${source.longitude}`;
              },
              hidden: true,
              export: false,
              exportOnlyCSV: true,
            },
            destination_address: {
              title: 'Endereço Destino',
              render: rowData => {
                const sourceIndex = rowData.coordinates.findIndex(item => item.position === 'DESTINATION');

                if (sourceIndex === -1) {
                  return '';
                }

                const source = rowData.coordinates[sourceIndex];
                const address = source.address ?? '';
                const city = source.city ? ` - ${source.city}` : '';

                return `${address}${city}`;
              },
              hidden: true,
              export: false,
              exportOnlyCSV: true,
            },
            total: {
              title: 'Total',
              hidden: true,
              export: true,
            },
          }}
          data={data}
          actions={[
            {
              icon: () => <Refresh />,
              tooltip: 'Atualizar',
              isFreeAction: true,
              onClick: invalidateQuery,
            },
          ]}
          onRowClick={(event, rowData) => {
            if (rowData) {
              setOpportunityDetails(rowData);
            }
          }}
          options={{
            showTitle: false,
            exportButton: true,
            exportAllData: true,
            filtering: true,
            exportCsv: (allColumns: Column<OpportunitiesResponse[number]>[], allData: OpportunitiesResponse) => {
              exportCustomCSV({
                csvColumns: allColumns,
                csvContent: allData,
                filenameOutput: 'oportunidades_aceitas.csv',
              });
            },
          }}
          customOptions={{
            noLineBreakInHeader: true,
          }}
          isLoading={loading}
          title='Oportunidades aceitas'
        />

        <Details opportunity={opportunityDetails} onRequestClose={() => setOpportunityDetails(undefined)} />
      </>
    );
}

export default OnGoingTable;
