import React, { useState, useEffect } from 'react';

import { Switch } from '@material-ui/core';

import Snackbar, { SeverityType } from '~/components/Snackbar';
import InputComponent from '~/components/InputComponent';
import {
  loadAssociations,
  createUserB2BApi,
  changeUserB2BApi,
  loadUsersB2BApi,
  deleteUserB2BApi,
  enabledUserB2BApi,
} from '~/services/api';

import { handleApiErrorResponse } from '~/services/handleErrors';
import { AssociationsResponse, UserB2B } from '~/types';
import { AssociationAutocomplete } from './components/AssociationAutocomplete';
import { Title } from '~/components/Title';
import { Table } from '~/components';

export function B2BManager() {
  const [errorsField, setErrorsField] = useState({
    username: false,
    given_name: false,
    family_name: false,
    association_id: false,
  });
  const [usersB2B, setUsersB2B] = useState<UserB2B[]>([]);
  const [associations, setAssociations] = useState<Record<string, string>>({});
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [messageSnack, setMessageSnack] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUsersB2B = async () => {
    setLoading(true);

    try {
      const { data } = await loadUsersB2BApi();
      setUsersB2B(data || []);
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const validateFields = (user: UserB2B) => {
    let error = false;

    setErrorsField({
      username: false,
      given_name: false,
      family_name: false,
      association_id: false,
    });

    if (!user.username.trim()) {
      setSeverity('warning');
      setMessageSnack('Informe o usuário.');
      setOpenSnackbar(true);
      setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        username: true,
      }));
      error = true;
    }

    if (!user.given_name.trim()) {
      setSeverity('warning');
      setMessageSnack('Informe o nome.');
      setOpenSnackbar(true);
      setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        given_name: true,
      }));
      error = true;
    }

    if (!user.family_name.trim()) {
      setSeverity('warning');
      setMessageSnack('Informe o sobrenome.');
      setOpenSnackbar(true);
      setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        family_name: true,
      }));
      error = true;
    }

    if (!user.association_id.trim()) {
      setSeverity('warning');
      setMessageSnack('Selecione uma empresa.');
      setOpenSnackbar(true);
      setLoading(false);

      setErrorsField(prevState => ({
        ...prevState,
        association_id: true,
      }));
      error = true;
    }

    return !error;
  };

  const saveNewUser = async (user: UserB2B) => {
    setLoading(true);

    try {
      await createUserB2BApi({
        email: user.username,
        given_name: user.given_name,
        family_name: user.family_name,
        locale: 'pt-BR',
        zoneinfo: 'America/Sao_Paulo',
        association_id: user.association_id,
      });

      await getUsersB2B();

      return true;
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const changeUser = async (username: string, user: UserB2B) => {
    setLoading(true);
    try {
      await changeUserB2BApi({
        email: username,
        given_name: user.given_name,
        family_name: user.family_name,
        locale: 'pt-BR',
        zoneinfo: 'America/Sao_Paulo',
        association_id: user.association_id,
      });

      await getUsersB2B();

      return true;
    } catch (err) {
      const error = handleApiErrorResponse(err);
      setSeverity('warning');
      setMessageSnack(error?.handledMessage || error?.applicationErrorMessage || error?.apiError);
      setOpenSnackbar(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const changeStatus = async (event, rowData: UserB2B) => {
    const { username } = rowData;
    const { checked } = event.target;
    try {
      if (checked) {
        await enabledUserB2BApi(username);
      } else {
        await deleteUserB2BApi(username);
      }

      await getUsersB2B();
    } catch (error) {
      setSeverity('warning');
      setMessageSnack('Ocorreu um erro ao executar a operação');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data } = await loadAssociations();
        const associationsReduce: Record<string, string> = (data as AssociationsResponse[]).reduce((acc, item) => {
          acc[item.id] = item.code;
          return acc;
        }, {});
        setAssociations(associationsReduce || {});
      } catch (err) {
        const error = handleApiErrorResponse(err);
        setSeverity('warning');
        setMessageSnack(error?.applicationErrorMessage || error?.apiError);
        setOpenSnackbar(true);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    getUsersB2B();
  }, []);

  return (
    <>
      <Title>Gestão B2B</Title>

      <Table
        isLoading={loading}
        data={usersB2B}
        columns={{
          username: { title: 'Usuário', editable: 'onAdd' },
          given_name: {
            title: 'Nome',
          },
          family_name: {
            title: 'Sobrenome',
          },
          association_id: {
            title: 'Empresa',
            lookup: associations,
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'Nenhum usuário B2B encontrado',
            editRow: {
              deleteText: 'Deseja excluir o usuário B2B?',
            },
          },
        }}
        options={{
          addRowPosition: 'first',
          showTitle: false,
          pageSize: 10,
          paging: false,
          draggable: false,
        }}
        components={{
          EditField: props => {
            const {
              value,
              columnDef: { field },
            } = props;

            return field === 'association_id' ? (
              <AssociationAutocomplete props={props} />
            ) : (
              <InputComponent
                type='text'
                value={value}
                onChange={e => props.onChange(e.target.value)}
                error={errorsField[field]}
                autoFocus={errorsField[field]}
              />
            );
          },
        }}
        editable={{
          onRowAdd: async newData => {
            if (!validateFields(newData)) {
              return Promise.reject();
            }

            await saveNewUser(newData);
            return true;
          },
          onRowUpdate: async (newData, oldData) => {
            if (!validateFields(newData) || !oldData) {
              return Promise.reject();
            }

            await changeUser(oldData.username, newData);
            return true;
          },
        }}
        actions={[
          rowData => ({
            icon: () => (
              <Switch
                checked={rowData.enabled}
                onChange={event => changeStatus(event, rowData)}
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ),
            tooltip: rowData.enabled ? 'Ativo' : 'Inativo',
            onClick: () => {
              return undefined;
            },
          }),
        ]}
      />

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />
    </>
  );
}
