import { Card, Select } from '@material-ui/core';
import styled from 'styled-components';
import Button from '~/components/Button';
import Colors from '~/styles/colors';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const Wrapper = styled(Card)`
  align-items: center;
  text-align: center;
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.section`
  height: 150px;
  margin: 10px;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #808080;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const FilesWrapper = styled.div`
  align-items: center;
  min-height: max-content;
  margin: 10px;
  flexdirection: 'row';
  flexwrap: 'wrap';
  margintop: 16;
`;

export const ButtonContainer = styled.div`
  min-height: max-content
  margin: 20px;
`;

export const DropzoneButton = styled(Button)`
  color: ${Colors.secondary};
  background-color: ${Colors.primary};
`;

export const Dropzone = styled.div``;

export const FilesContainer = styled.div`
  margin: 20px;
  font-weight: bold;
`;

export const StyledSelect = styled(Select)`
  margin-top: 10px;
  width: 200px;
`;
