import React, { useState, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ManualAllocationTable from './components/ManualAllocationTable';
import InExecutionTable from './components/InExecutionTable';
import CanceledTable from './components/CanceledTable';
import FinishedTable from './components/FinishedTable';
import OnGoingTable from './components/OnGoingTable';
import AllocatingTable from './components/AllocatingTable';
import { OpportunityStatusRequest as Opportunity } from '~/types';
import PaymentTable from './components/PaymentTable/index';
import { OpportunityGenericStatus } from '../../types';
import { clearOrder } from '~/store/ducks/order';
import { clearAllOrderBadge } from '~/store/ducks/ordersBadge';
import { OpportunityTab } from './components/OppotunityTab';
import { Title } from '~/components/Title';

export interface OpportunityManagerParams {}

export interface OpportunityManagerLocationState {}

export type OpportunityManagerProps = RouteChildrenProps<OpportunityManagerParams, OpportunityManagerLocationState>;

export function OpportunityManager(props: OpportunityManagerProps) {
  const [tab, setTab] = useState(
    {
      [Opportunity.MANUAL_ALLOCATION]: 0, // Rejeitadas
      [Opportunity.ALLOCATING]: 1, // Enviadas
      [Opportunity.ON_GOING]: 2, // Aceitas
      [Opportunity.IN_EXECUTION]: 3, // Em Execução
      [Opportunity.FINISHED]: 4, // Finalizadas
      [Opportunity.CANCELED]: 5, // Canceladas
      [OpportunityGenericStatus.PAYMENT]: 6, // Pagamento
    }[props.location.hash.length ? (props.location.hash.slice(1) as Opportunity) : Opportunity.MANUAL_ALLOCATION],
  );
  const { needClear, ordersBadge } = useSelector(state => ({
    needClear: state.order.needClear,
    ordersBadge: state.ordersBadge,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (needClear) {
      dispatch(clearOrder());
    }
  }, [dispatch, needClear]);

  useEffect(() => {
    props.history.push({
      hash: [
        Opportunity.MANUAL_ALLOCATION,
        Opportunity.ALLOCATING,
        Opportunity.ON_GOING,
        Opportunity.IN_EXECUTION,
        Opportunity.FINISHED,
        Opportunity.CANCELED,
        OpportunityGenericStatus.PAYMENT,
      ][tab],
    });
  }, [tab, props.history]);

  useEffect(() => {
    return () => {
      dispatch(clearAllOrderBadge());
    };
  }, [dispatch]);

  return (
    <>
      <Title marginBottom={20}>Gestão de Oportunidades</Title>

      <Tabs
        value={tab}
        onChange={(_, newValue) => {
          setTab(newValue);
        }}
        indicatorColor='primary'
        textColor='primary'
        style={{
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        <OpportunityTab title='Alocação Manual' badgeContent={ordersBadge.manualAllocation} />
        <OpportunityTab title='Enviadas' badgeContent={ordersBadge.allocating} />
        <OpportunityTab title='Aceitas' badgeContent={ordersBadge.onGoing} />
        <OpportunityTab title='Em execução' badgeContent={ordersBadge.inExecution} />
        <OpportunityTab title='Finalizadas' badgeContent={ordersBadge.finished} />
        <OpportunityTab title='Canceladas' badgeContent={ordersBadge.canceled} />
        <OpportunityTab title='Pagamento' badgeContent={ordersBadge.payment} />
      </Tabs>

      <ManualAllocationTable open={tab === 0} />
      <AllocatingTable open={tab === 1} />
      <OnGoingTable open={tab === 2} />
      <InExecutionTable open={tab === 3} />
      <FinishedTable open={tab === 4} />
      <CanceledTable open={tab === 5} />
      <PaymentTable open={tab === 6} />
    </>
  );
}

export default OpportunityManager;
