import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

interface DataProps {
  hasWhiteSpace?: boolean;
}

export const Container = styled(Grid).attrs({
  container: true,
  flex: 'column',
})``;

export const Title = styled(Typography).attrs({
  variant: 'h6',
})`
  margin-right: 20px;
  white-space: nowrap;
  font-weight: bold;
`;

export const Data = styled(Typography)<DataProps>`
  ${({ hasWhiteSpace = true }) => (hasWhiteSpace ? `white-space: nowrap;` : '')}
  font-size: 20px;
`;

export const Code = styled.span`
  letter-spacing: 0.05em;
`;
