import React from 'react';

import OrderInfoSection from '../MapModalSections/OrderInfoSection';
import { OpportunityResponse } from '../../../../types';

interface OrderInfoModalContentProps {
  order: OpportunityResponse;
}

const OrderInfoModalContent = ({ order }: OrderInfoModalContentProps) => {
  return (
    <div style={{ maxWidth: '50vh' }}>
      <OrderInfoSection
        orderData={{
          orders: [order],
          loading: false,
        }}
        iconStyle={{ width: '100%', height: '50%', color: 'rgba(0,0,0, .6)' }}
        iconActive
      />
    </div>
  );
};

export default OrderInfoModalContent;
