import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button as ButtonMUI,
  Tooltip,
} from '@material-ui/core';

import { getIconVehicle } from '~/util/getIcon';

import { useCustomerVehicles } from '~/services/hooks/useCustomerVehicles';
import { useToast } from '~/services/hooks/toast';
import { useQuizAssociation } from '~/services/hooks/useQuizAssociation';

import { setOrderQuizAnswers, setOrderVehicle } from '~/store/ducks/order';

import Title from '../../components/Title';
import Button from '~/components/Button';
import { ModalConfirm } from '~/components/ModalConfirm';
import HighlightedButton from '../../components/HighlightedButton';
import BackButton from '../../components/BackButton';

import { BenefitResponse, CustomerVehicle, CustomerVehicleRequest } from '~/types';
import InformationOrder from '../../components/InformationOrder';
import { AddCircleOutline, Edit, List as ListIcon } from '@material-ui/icons';
import {
  FipeModel,
  VehicleRegistration,
  SelectedBenefits,
  VehicleInitialData,
  VehicleStatus,
} from './components/VehicleRegistration';
import { BenefitsRegistration } from './components/BenefitsRegistration';
import { changeVehicleApi, createCustomerVehicleApi, createCustomerVehicleBenefitsApi } from '~/services/api';
import Modal from '~/components/ModalNewVehicle';
import { VehicleBenefit } from '~/pages/CustomerManager';
import { useBenefit } from '~/services/hooks/useBenefit';
import useServices from '~/services/hooks/useServices';
import { clearBenefit } from '~/store/ducks/customerBenefit';
import { getKeyByValue } from '~/util/objectUtil';
import { handleApiErrorResponse } from '~/services/handleErrors';

const OpportunityVehicles = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<CustomerVehicle>();
  const [modalCancel, setModalCancel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalVehicleIsOpen, setModalVehicleIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedBenefits, setSelectedBenefits] = useState<SelectedBenefits>({});
  const [fipeModel, setFipeModel] = useState<FipeModel>({} as FipeModel);
  const [vehicleBenefits, setVehicleBenefits] = useState<VehicleBenefit>();
  const [vehicleInitialData, setVehicleInitialData] = useState<VehicleInitialData>();
  const [benefitsInitialData, setBenefitsInitialData] = useState<BenefitResponse[]>();

  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();

  const { customerId, service, vehicle } = useSelector(state => state.order);

  const { customerVehicles, loadingVehicles, getVehicleBySocialSecurity } = useCustomerVehicles(customerId);
  const { getQuiz, loadQuiz } = useQuizAssociation();

  const { services } = useServices();
  useBenefit(services, customerVehicles);
  const benefits = useSelector(state => state.customerBenefit);

  useEffect(() => {
    setSelectedVehicle(vehicle);
  }, [vehicle]);

  useEffect(() => {
    if (!customerId || !service?.id) {
      history.push('/new-opportunity/services');
    }
  }, [customerId, history, service]);

  const handleSubmit = useCallback(
    async (event?: React.FormEvent<HTMLFormElement>, vehicle?: CustomerVehicleRequest) => {
      event?.preventDefault();

      if (!vehicle?.id) {
        addToast({ title: 'Validação', type: 'error', description: 'Veículo obrigatório!' });
        return;
      }

      dispatch(setOrderVehicle(vehicle));

      const quiz = await getQuiz(vehicle.association_id, service?.id);

      if (quiz && quiz.length > 0) {
        history.push('/new-opportunity/additional-information');
      } else {
        if (vehicle.association_code) {
          dispatch(setOrderQuizAnswers(false, []));
        } else {
          dispatch(setOrderQuizAnswers(true, []));
        }
        history.push('/new-opportunity/map');
      }
    },
    [addToast, dispatch, getQuiz, history, service],
  );

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  const selectInactiveVehicle = useCallback(async () => {
    if (!selectedVehicle) {
      addToast({ title: 'Validação', type: 'error', description: 'Veículo obrigatório!' });
      return;
    }

    dispatch(setOrderVehicle(selectedVehicle));

    const quiz = await getQuiz(undefined, service?.id);

    if (quiz && quiz.length > 0) {
      history.push('/new-opportunity/additional-information');
    } else {
      dispatch(setOrderQuizAnswers(false, {}));
      history.push('/new-opportunity/map');
    }
  }, [addToast, dispatch, getQuiz, history, selectedVehicle, service]);

  const handleListItemClick = useCallback((item: CustomerVehicle) => {
    setSelectedVehicle(item);

    if (item.status === 'INACTIVE') {
      setOpenModal(true);
      return;
    }
  }, []);

  const handleCancelInactive = useCallback(() => {
    setSelectedVehicle(undefined);
    setOpenModal(false);
  }, []);

  const handleRegisterVehicle = async () => {
    const errors: FipeModel = {};

    if (!fipeModel.association?.code?.trim()) {
      errors.association_error = true;
    }

    if (!fipeModel.category?.description.trim()) {
      errors.category_error = true;
    }

    if (!fipeModel.manufacturer?.name.trim()) {
      errors.manufacturer_error = true;
    }

    if (!fipeModel.vehicle?.name.trim()) {
      errors.vehicle_error = true;
    }

    if (!fipeModel.model?.name.trim()) {
      errors.model_error = true;
    }

    if ((fipeModel.license_plate?.length || 0) < 7) {
      errors.license_plate_error = true;
    }

    if (!fipeModel.color?.trim()) {
      errors.color_error = true;
    }

    if (!fipeModel.city?.trim()) {
      errors.city_error = true;
    }

    if (!fipeModel.state?.trim()) {
      errors.state_error = true;
    }

    setFipeModel({ ...fipeModel, ...errors });

    if (Object.keys(errors).length !== 0) {
      return;
    }

    if (step === 0 && !vehicleInitialData) {
      setStep(1);
      return;
    }

    const fipe_data = {
      model_id: fipeModel.vehicle?.id,
      manufacture_id: fipeModel.manufacturer?.id,
      model_year_id: fipeModel.model?.id,
    };

    const data = {
      association_id: fipeModel.association?.id,
      social_security: customerId,
      vehicle_customer_type_id: fipeModel.category?.id,
      license_plate: fipeModel.license_plate,
      manufacture: fipeModel.manufacturer?.name.toUpperCase(),
      model: fipeModel.vehicle?.name.toUpperCase(),
      model_year: fipeModel.model?.name,
      color: fipeModel.color,
      vin: fipeModel.vin || `${customerId}${fipeModel.license_plate?.replace(/-/g, '')}`,
      state: fipeModel.state,
      city: fipeModel.city,
      status: getKeyByValue(VehicleStatus, fipeModel.status || VehicleStatus.ACTIVE),
      fipe_data: JSON.stringify(fipe_data),
    };

    try {
      const { headers } = !vehicleInitialData
        ? await createCustomerVehicleApi(data)
        : await changeVehicleApi(vehicleInitialData.vehicleId, data);
      const location = headers?.location;

      if (location && !vehicleInitialData) {
        const vehicleId = location.split('/').pop();
        await createCustomerVehicleBenefitsApi(vehicleId, Object.values(selectedBenefits));
        const vehicle: CustomerVehicleRequest = {
          id: String(vehicleId),
          city: data.city!,
          model: data.model!,
          model_year: data.model_year,
          manufacture: data.manufacture!,
          state: data.state!,
          association_id: fipeModel.association!.id,
          license_plate: data.license_plate!,
          status: getKeyByValue(VehicleStatus, fipeModel.status || VehicleStatus.ACTIVE),
          vehicle_customer_type_description: fipeModel.category!.description,
          association_code: fipeModel.association!.code,
          color: data.color!,
          vin: data.vin!,
          association_trading_name: fipeModel.association!.trading_name,
        };
        handleSubmit(undefined, vehicle);
      }

      setModalVehicleIsOpen(false);
      await getVehicleBySocialSecurity(customerId);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao cadastrar o veículo');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  const handleCancelModalVehicle = () => {
    if (step === 0) {
      setSelectedBenefits({});
      setFipeModel({} as FipeModel);
      setModalVehicleIsOpen(false);
      return;
    }

    setSelectedBenefits({});
    if (benefitsInitialData) {
      setBenefitsInitialData(undefined);
      setModalVehicleIsOpen(false);
    } else {
      setStep(0);
    }
  };

  const handleChangeVehicle = (vehicle: CustomerVehicle) => () => {
    setStep(0);
    setVehicleInitialData({
      vehicleId: vehicle.id,
      associationId: vehicle.association_id,
      categoryId: vehicle.vehicle_customer_type_id,
      manufacturerDescription: vehicle.manufacture,
      vehicleDescription: vehicle.model,
      modelDescription: vehicle.model_year,
      licensePlate: vehicle.license_plate,
      color: vehicle.color,
      vin: vehicle.vin,
      state: vehicle.state,
      city: vehicle.city,
      status: vehicle.status,
    });
    setModalVehicleIsOpen(true);
  };

  const handleNewVehicle = () => {
    setStep(0);
    setVehicleInitialData(undefined);
    setBenefitsInitialData(undefined);
    setFipeModel({} as FipeModel);
    setModalVehicleIsOpen(true);
  };

  const filterBenefitsByVehicleId = (id: string) => {
    return benefits.vehicleBenefits.filter(b => b.id === id).map(obj => obj.benefits);
  };

  const handleChangeBenefits = (vehicle: CustomerVehicle) => () => {
    setStep(1);
    setSelectedBenefits({} as SelectedBenefits);
    const vehicleBenefits = filterBenefitsByVehicleId(vehicle.id);
    setBenefitsInitialData(vehicleBenefits);
    setVehicleBenefits({ associationId: vehicle.association_id, vehicleId: vehicle.id });
    setModalVehicleIsOpen(true);
  };

  const handleCreateBenefits = async () => {
    try {
      if (!vehicleBenefits?.vehicleId) {
        return;
      }

      await createCustomerVehicleBenefitsApi(vehicleBenefits?.vehicleId, Object.values(selectedBenefits));
      await getVehicleBySocialSecurity(customerId);
      dispatch(clearBenefit());
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao cadastrar o benefício');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setVehicleBenefits(undefined);
      setSelectedBenefits({} as SelectedBenefits);
      setBenefitsInitialData(undefined);
      setSelectedBenefits({});
      setModalVehicleIsOpen(false);
    }
  };

  return (
    <>
      <Title>Veículo</Title>

      <InformationOrder type='VEHICLE' />

      <IconButton aria-label='novo veículo' onClick={handleNewVehicle}>
        <AddCircleOutline />
        <Typography style={{ marginLeft: 8 }}>Novo veículo</Typography>
      </IconButton>

      <form onSubmit={event => handleSubmit(event, selectedVehicle)}>
        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <List component='nav' aria-label='main mailbox folders'>
              {customerVehicles.map(item => (
                <ListItem
                  key={item.id}
                  button
                  selected={selectedVehicle?.id === item.id}
                  onClick={() => handleListItemClick(item)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item.vehicle_customer_type_description}
                      src={getIconVehicle(item.vehicle_customer_type_description)}
                      style={{ width: 52, height: 52 }}
                    />
                  </ListItemAvatar>

                  <ListItemText
                    primary={`${item.license_plate}${item.association_code ? ` (${item.association_code})` : ''}`}
                    secondary={item.model}
                  />

                  <Tooltip title='Alterar veículo' arrow>
                    <ButtonMUI onClick={handleChangeVehicle(item)}>
                      <Edit />
                    </ButtonMUI>
                  </Tooltip>

                  <Tooltip title='Alterar benefícios' arrow>
                    <ButtonMUI onClick={handleChangeBenefits(item)}>
                      <ListIcon />
                    </ButtonMUI>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>

        <Grid item xs={6} sm={8} md={9} lg={10}>
          <BackButton loading={loadingVehicles || loadQuiz} onClick={handleGoBack} />

          <Button
            type='button'
            label='Cancelar'
            loading={loadingVehicles || loadQuiz}
            filled
            style={{ width: 200, marginRight: 30 }}
            onClick={handleCancel}
          />

          <HighlightedButton loading={loadingVehicles || loadQuiz} />
        </Grid>
      </form>

      <ModalConfirm
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
        title='Tem certeza de que deseja cancelar essa oportunidade?'
      />

      <Modal onConfirm={() => selectInactiveVehicle()} onCancel={handleCancelInactive} question open={openModal}>
        <Typography>O Veículo selecionado está inativo, por isso o pedido será avulso.</Typography>
      </Modal>

      <Modal
        onConfirm={benefitsInitialData ? handleCreateBenefits : handleRegisterVehicle}
        open={modalVehicleIsOpen}
        buttonPositive={
          vehicleInitialData || benefitsInitialData ? 'Salvar' : step === 0 ? 'Avançar' : 'Cadastrar e avançar'
        }
        haveCustomButton
        buttonCustom={step === 0 || vehicleInitialData || benefitsInitialData ? 'Cancelar' : 'Voltar'}
        onCustom={handleCancelModalVehicle}
      >
        {step === 0 ? (
          <VehicleRegistration
            modalVehicleIsOpen={modalVehicleIsOpen}
            fipeModel={fipeModel}
            setFipeModel={setFipeModel}
            initialData={vehicleInitialData}
            title={vehicleInitialData ? 'Editar Veículo' : undefined}
            showStatus={vehicleInitialData ? true : false}
          />
        ) : (
          <BenefitsRegistration
            associationId={fipeModel.association?.id || vehicleBenefits?.associationId || ''}
            selectedBenefits={selectedBenefits}
            setSelectedBenefits={setSelectedBenefits}
            initialData={benefitsInitialData}
          />
        )}
      </Modal>
    </>
  );
};

export default OpportunityVehicles;
