import { put, call, takeEvery } from 'redux-saga/effects';
import { loadVehicleBenefitQuota, loadVehicleBenefits } from '~/services/api';
import { ReduxAction } from '../@types/Action';
import { BenefitState, CustomerBenefitType as ActionTypes } from '../ducks/customerBenefit';

function* loadBenefitSaga(action: ReduxAction<ActionTypes, BenefitState>) {
  try {
    const associationId = action.payload?.associationId ?? '';
    const serviceId = action.payload?.serviceId ?? '';
    const socialSecurity = action.payload?.socialSecurity ?? '';
    const vehicleId = action.payload?.vehicleId ?? '';

    const benefit = yield call(loadVehicleBenefits, associationId, serviceId, socialSecurity, vehicleId);
    const benefitQuota = yield call(loadVehicleBenefitQuota, vehicleId, benefit.data.id);

    yield put({
      type: ActionTypes.ON_SUCESS,
      payload: {
        vehicleBenefits: {
          benefits: { ...benefit.data, quota_expended: benefitQuota.data.consumed_quota },
          id: vehicleId,
        },
        associationId: associationId,
        serviceId: serviceId,
        socialSecurity: socialSecurity,
        vehicleId: vehicleId,
        loading: false,
      },
    });
  } catch {
    yield put({
      type: ActionTypes.ON_FAILURE,
      payload: {
        loading: false,
      },
    });
  }
}

export default function* benefitsSaga() {
  yield takeEvery(ActionTypes.ON_REQUEST, loadBenefitSaga);
}
