import React from 'react';

import Colors from '~/styles/colors';

import Button from '~/components/Button';

interface BackButtonProps extends React.HTMLProps<HTMLButtonElement> {
  loading: boolean;
}

const BackButton = ({ loading, ...rest }: BackButtonProps) => {
  return (
    <Button
      {...rest}
      type='button'
      label='Voltar'
      loading={loading}
      backgroundColor={Colors.backButton}
      color={Colors.white}
      style={{ width: 200, marginRight: 30 }}
    />
  );
};

export default BackButton;
