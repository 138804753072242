import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputAdornment } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';

import { changePasswordRequest, clearError } from '~/store/ducks/auth';

import Button from '~/components/Button';
import Input from '~/components/InputComponent';
import { Title } from '~/components/Title';

import Colors from '~/styles/colors';
import { validPassword } from '~/util/validate';

import { Form } from './styles';

export default function RegisterAssociation() {
  const { email, loading, error } = useSelector(state => ({
    email: state.auth.user.email,
    loading: state.auth.loading,
    error: state.auth.error,
  }));

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [oldSecureText, setOldSecureText] = useState(true);
  const [newSecureText, setNewSecureText] = useState(true);
  const [confirmSecureText, setConfirmSecureText] = useState(true);

  const [oldPassError, setOldPassError] = useState(null);
  const [newPassError, setNewPassError] = useState(null);
  const [confirmPassError, setConfirmPassError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearError());
  }, [dispatch]);

  useEffect(() => {
    if (error?.apiResponseStatus === 401) {
      return setOldPassError({ message: 'Senha inválida!' });
    }

    if (typeof error?.apiError === 'object') {
      return setOldPassError({ message: error.apiError.message });
    }
  }, [error]);

  async function handleSubmit(event) {
    event.preventDefault();
    setOldPassError(null);
    setNewPassError(null);
    setConfirmPassError(null);

    if (!oldPassword.trim()) {
      return setOldPassError({ message: 'Senha Antiga não informada!' });
    }

    if (!newPassword.trim()) {
      return setNewPassError({ message: 'Nova senha não informada!' });
    }

    if (newPassword !== confirmPassword) {
      setNewPassError({ message: 'Senhas não conferem!' });
      setConfirmPassError({ message: 'Senhas não conferem!' });
      return false;
    }

    if (!validPassword(newPassword)) {
      return setNewPassError({
        message: 'Deve conter 8 caracteres. Caracter especial. Letras e números.',
      });
    }

    dispatch(changePasswordRequest(email, oldPassword, newPassword));
    return true;
  }

  return (
    <>
      <Title>Alterar Senha</Title>
      <Form onSubmit={handleSubmit} autoComplete='off'>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={12}>
            <Input
              name='password'
              label='Senha Antiga'
              type={oldSecureText ? 'password' : 'text'}
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              error={oldPassError}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() => setOldSecureText(secure => !secure)}
                    style={{ cursor: 'pointer', color: Colors.primary }}
                  >
                    {oldSecureText ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='password'
              label='Nova Senha'
              type={newSecureText ? 'password' : 'text'}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              error={newPassError}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() => setNewSecureText(secure => !secure)}
                    style={{ cursor: 'pointer', color: Colors.primary }}
                  >
                    {newSecureText ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name='password'
              label='Confirmar Nova Senha'
              type={confirmSecureText ? 'password' : 'text'}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              error={confirmPassError}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() => setConfirmSecureText(secure => !secure)}
                    style={{ cursor: 'pointer', color: Colors.primary }}
                  >
                    {confirmSecureText ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify='flex-end'>
          <Grid item xs={4}>
            <Button type='submit' filled label='Alterar Senha' loading={loading} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}
