import React, { useEffect, useState } from 'react';
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { format, getYear, startOfMonth, startOfYear, sub, endOfYear, endOfMonth, fromUnixTime } from 'date-fns';

import { Container } from './styles';
import { loadOpportunities } from '~/services/api';
import {
  OpportunitiesResponse,
  OpportunityResponse,
  OpportunityStatusRequest,
  PaymentTypePTBR,
  StatusPTBR,
} from '~/types';
import { Table } from '~/components';
import Details from '~/pages/OpportunityManager/components/Details';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';

type ModalOpportunitiesProps = {
  open: boolean;
  closeModal: () => void;
  vin?: string;
  serviceDescription?: string;
  period?: PeriodType;
};

type PeriodType = {
  start: string;
  end: string;
};

enum Period {
  Mensal = 0,
  Bimestral = 1,
  Trimestral = 2,
  Quadrimestral = 3,
  Semestral = 5,
}

export const getPeriod = (period: string): PeriodType => {
  const date = new Date();

  if (period === 'Anual') {
    return {
      start: format(startOfYear(date), 'yyyy-MM-dd'),
      end: format(endOfYear(date), 'yyyy-MM-dd'),
    };
  }

  const monthsSub = Period[period] || Period.Mensal;
  const dateSub = sub(startOfMonth(date), { months: monthsSub });

  if (getYear(date) > getYear(dateSub)) {
    return {
      start: format(startOfYear(date), 'yyyy-MM-dd'),
      end: format(endOfMonth(date), 'yyyy-MM-dd'),
    };
  }

  return {
    start: format(dateSub, 'yyyy-MM-dd'),
    end: format(endOfMonth(date), 'yyyy-MM-dd'),
  };
};

export function ModalOpportunities({ open, closeModal, vin, serviceDescription, period }: ModalOpportunitiesProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OpportunitiesResponse>([]);
  const [opportunityDetails, setOpportunityDetails] = useState<OpportunityResponse>();

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { addToast } = useToast();

  useEffect(() => {
    const getOpportunities = async () => {
      if (!open) return;

      setLoading(true);
      try {
        const { data } = (await loadOpportunities(
          [OpportunityStatusRequest.FINISHED, OpportunityStatusRequest.CANCELED],
          period?.start,
          period?.end,
          vin,
        )) as { data: OpportunitiesResponse };
        const opportunitiesExpended = data
          .filter(
            opportunity =>
              opportunity.expended &&
              (serviceDescription ? opportunity.service_description === serviceDescription : true),
          )
          .map(opportunity => {
            opportunity.created_at = fromUnixTime(opportunity.created_at).getTime();
            opportunity.created_at_formatted = format(new Date(opportunity.created_at), 'dd/MM/yyyy HH:mm') ?? '';
            opportunity.issued_by = opportunity.from_backoffice ? 'BACKOFFICE' : 'APP';
            return opportunity;
          });
        setData(opportunitiesExpended);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar as oportunidades');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    getOpportunities();
  }, [addToast, open, period, serviceDescription, vin]);

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} open={open}>
      <DialogContent>
        <Container>
          {loading ? (
            <CircularProgress size={26} />
          ) : (
            <>
              <Table
                columns={{
                  number: 'ID do pedido',
                  payment_type: {
                    title: 'Forma de Pagamento',
                    lookup: (() =>
                      data
                        .map(item => item.payment_type)
                        .reduce((acc, item) => ({ ...acc, [item]: PaymentTypePTBR[item] }), {}))(),
                    render: rowData => PaymentTypePTBR[rowData.payment_type] ?? rowData.payment_type,
                  },
                  customer_name: {
                    title: 'Cliente',
                    cellStyle: {
                      minWidth: 200,
                      textAlign: 'left' as const,
                    },
                  },
                  created_at_formatted: 'Data/Hora de inicio',
                  status: {
                    title: 'Situação',
                    render: rowData => StatusPTBR[rowData.status] ?? rowData.status,
                    customFilterAndSearch: (filter: string, rowData) =>
                      (StatusPTBR[rowData.status] ?? rowData.status).includes(filter),
                  },
                }}
                data={data}
                onRowClick={(_, rowData) => {
                  if (rowData) {
                    setOpportunityDetails(rowData);
                  }
                }}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  filtering: true,
                }}
                customOptions={{
                  noLineBreakInHeader: true,
                }}
                isLoading={loading}
                title={`Oportunidades - chassi - ${vin}`}
              />

              <Details opportunity={opportunityDetails} onRequestClose={() => setOpportunityDetails(undefined)} />
            </>
          )}
        </Container>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button autoFocus variant='contained' color='secondary' style={{ textTransform: 'none' }} onClick={closeModal}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
