import { useState, useEffect, useCallback } from 'react';
import Geocode from 'react-geocode';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { OpportunitiesResponse, OpportunityStatusRequest } from '~/types';
import { loadOpportunities, sendToManualAllocationAPI } from '../api';
import { dateToString } from '~/util/parsers';
import { useToast } from './toast';
import { OpportunityResponse } from '../../types';

Geocode.setApiKey('AIzaSyA8EQde-ys1im-Gv5EFbY20VeUDR0y_i4c');
Geocode.setLanguage('pt');
Geocode.setRegion('br');

export function useOpportunities(
  status: OpportunityStatusRequest | OpportunityStatusRequest[] = OpportunityStatusRequest.MANUAL_ALLOCATION,
  fromDate: Date | null = null,
  toDate: Date | null = null,
  loadOnMount = false,
) {
  const [data, setData] = useState<OpportunitiesResponse>([]);

  const [selectedStatus, setSelectedStatus] = useState<OpportunityStatusRequest | OpportunityStatusRequest[]>(status);
  const [loading, setLoading] = useState(loadOnMount);

  const [initialDate, setInitialDate] = useState<Date | null>(fromDate);
  const [endDate, setEndDate] = useState<Date | null>(toDate);

  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mapData = (data?: OpportunitiesResponse) => {
    if (!data) return;

    const addIssuedBy = (item: OpportunityResponse) => {
      return { ...item, issued_by: item.from_backoffice ? 'BACKOFFICE' : 'APP' } as OpportunityResponse;
    };
    return data.map(addIssuedBy);
  };

  const fromDateString = dateToString(initialDate, '0000-00-00');
  const toDateString = dateToString(endDate, '0000-00-00');
  const { isLoading, data: orderData } = useQuery(
    ['orders', selectedStatus, fromDateString, toDateString],
    () => loadOpportunities(selectedStatus, fromDateString, toDateString),
    {
      // Refetch the data 30 seconds
      refetchInterval: 30 * 1000,
    },
  );

  useEffect(() => {
    setLoading(isLoading);
    if (isLoading) {
      return;
    }

    const orderMap = mapData(
      orderData?.data.map(d => {
        d.created_at = d.created_at * 1000;
        d.updated_at = d.updated_at * 1000;
        d.created_at_formatted = dateToString(new Date(d.created_at)) ?? '';
        d.updated_at_formatted = dateToString(new Date(d.updated_at)) ?? '';
        d.license_plate_or_vin = d.license_plate || d.vin;
        return d;
      }),
    );

    if (!orderMap) {
      return;
    }

    setData(orderMap);
    setLoading(false);
  }, [isLoading, orderData]);

  const selectParams = useCallback(
    (s: OpportunityStatusRequest | null = null, id: Date | null = null, ed: Date | null = null) => {
      if (s) {
        setLoading(false);
        setData([]);
      }
      setInitialDate(id);
      setEndDate(ed);
      if (s) setSelectedStatus(s);
      setLoading(true);
    },
    [],
  );

  const sendToManualAllocation = useCallback(
    async (orderId: string) => {
      try {
        await sendToManualAllocationAPI(orderId, {
          reallocate: false,
          cancellation_reason: 'BACKOFFICE - Enviado para alocação manual',
        });
      } catch (error) {
        addToast({ title: 'Erro', type: 'error', description: 'Erro ao atualizar a ordem para alocação manual' });
      }
    },
    [addToast],
  );

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(['orders', selectedStatus, fromDateString, toDateString]);
  }, [fromDateString, queryClient, selectedStatus, toDateString]);

  return {
    data,
    loading,
    selectStatus: setSelectedStatus,
    selectInitialDate: setInitialDate,
    selectEndDate: setEndDate,
    selectParams,
    sendToManualAllocation,
    invalidateQuery,
  };
}

export default useOpportunities;
