import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography, Paper, Grid, Divider, useTheme, TextField } from '@material-ui/core';
import { ArrowBack, Image } from '@material-ui/icons';
import { RouteChildrenProps } from 'react-router-dom';

import { Header, ActionContainer, ActionButton } from './styles';
import { ContractorIdentity, ContractorsResponse, DocumentsResponse } from '~/types';
import { Table, ImagePicker, ImageViewer } from '~/components';
import { useDocuments, useContractor } from '~/services/hooks';
import { formatCPF, formatCNPJ, formatPhone } from '~/util/format';
import Modal from '~/components/Modal';
import ButtonComp from '~/components/Button';
import Loading from '~/components/Loading';
import { Title } from '~/components/Title';

export interface OnboardingParams {
  contractorId: string;
}

export interface OnboardingLocationState {
  contractor?: ContractorsResponse;
}

export type OnboardingProps = RouteChildrenProps<OnboardingParams, OnboardingLocationState | undefined>;

export function Onboarding(props: OnboardingProps) {
  const theme = useTheme();

  const { contractor: details, approve, refuse, getContractor } = useContractor(props.match?.params.contractorId);

  const contractor = props.location.state?.contractor;

  const { documents, loading, update, create, sendReviewEmail, updateSelf } = useDocuments(
    props.match?.params.contractorId || contractor?.id,
  );

  const [imageUrl, setImageUrl] = useState<string>();
  const [contractorData, setContractorData] = useState<ContractorIdentity>();
  const [loadingContractor, setLoadingContractor] = useState(true);
  const [reasonRefuse, setReasonRefuse] = useState<string>();
  const [openModal, setOpenModal] = useState(false);

  function handleImage(
    rowData: Partial<DocumentsResponse[number]> & { type: string; identity_id: string },
    image: File,
  ) {
    if (rowData.id) {
      update(rowData.id, rowData.type, image);
    } else {
      create(rowData.type, image);
    }
  }

  const getContractorData = useCallback(async () => {
    const data = await getContractor(props.match?.params.contractorId);
    setContractorData(data);
    setLoadingContractor(false);
  }, [getContractor, props.match]);

  const sendUpdateSelf = async (file: File) => {
    const idContractor = details?.id || contractor?.id;

    if (!idContractor) {
      return;
    }

    setLoadingContractor(true);

    await updateSelf(idContractor, file);
    await getContractorData();
  };

  const handleRefuse = async () => {
    if (!reasonRefuse) {
      return setOpenModal(true);
    }

    await refuse(reasonRefuse);
    await getContractorData();
    setOpenModal(false);
    setReasonRefuse(undefined);
  };

  useEffect(() => {
    getContractorData();
  }, [getContractorData]);

  return (
    <>
      <Header>
        <Button style={{ marginRight: 20 }}>
          <ArrowBack fontSize='large' onClick={() => props.history.push('/contractor-manager')} />
        </Button>
        <Title>Onboarding</Title>
      </Header>

      <Paper style={{ marginBottom: 30, overflow: 'hidden' }}>
        <Grid>
          <Typography
            variant='h5'
            color='secondary'
            style={{ fontSize: '16px', fontWeight: 'bold', padding: 20, backgroundColor: theme.palette.primary.main }}
          >
            Informações do Prestador
          </Typography>
        </Grid>
        <Divider />
        <Grid container style={{ padding: 20 }} spacing={3}>
          {contractor?.role && (
            <DetailCardInfo title='Tipo' value={{ ORG_ADMIN: 'Empresa', FREELANCER: 'Autônomo' }[contractor.role]} />
          )}
          {(contractor?.name || (details?.given_name && details.family_name)) && (
            <DetailCardInfo
              title='Nome'
              value={(details && details.given_name + ' ' + details.family_name) || contractor?.name || ''}
            />
          )}
          {(contractor?.id || details?.id) && (
            <DetailCardInfo title='CPF' value={formatCPF(details?.id || contractor?.id)} />
          )}
          {details?.rg && <DetailCardInfo title='RG' value={details.rg.number} />}
          {details?.cnh && <DetailCardInfo title='CNH' value={details.cnh.number} />}
          {(contractor?.organization || details?.organization.company_name) && (
            <DetailCardInfo
              title='Empresa'
              value={details?.organization.company_name || contractor?.organization || ''}
            />
          )}
          {details && <DetailCardInfo title='CNPJ' value={formatCNPJ(details.organization.social_identity)} />}
          {(contractor?.phone_number || details?.phone_number) && (
            <DetailCardInfo title='Telefone' value={formatPhone(details?.phone_number || contractor?.phone_number)} />
          )}
          {details && <DetailCardInfo title='Rua' value={details.organization.address.street} />}
          {details && <DetailCardInfo title='Número' value={details.organization.address.number} />}
          {details && <DetailCardInfo title='Bairro' value={details.organization.address.neighborhood} />}
          {details && <DetailCardInfo title='CEP' value={details.organization.address.zip_code} />}
          {details && <DetailCardInfo title='Cidade' value={details.organization.address.city} />}
          {details && <DetailCardInfo title='Estado' value={details.organization.address.state} />}
          {details && <DetailCardInfo title='País' value={details.organization.address.country} />}
          {contractor?.status && !details?.status && (
            <DetailCardInfo
              title='Situação'
              value={{ APPROVED: 'Aprovado', VALIDATING: 'Em Análise', DENIED: 'Reprovado' }[contractor.status]}
              variant='contained'
              color={({ APPROVED: 'green', VALIDATING: 'orange', DENIED: 'red' } as any)[contractor.status]}
            />
          )}
          {details?.status && (
            <DetailCardInfo
              title='Situação'
              value={{ APPROVED: 'Aprovado', VALIDATING: 'Em Análise', DENIED: 'Reprovado' }[details.status]}
              variant='contained'
              color={({ APPROVED: 'green', VALIDATING: 'orange', DENIED: 'red' } as any)[details.status]}
            />
          )}
          <Grid container item xs>
            {details?.status === 'DENIED' && contractorData?.status_reason && (
              <DetailCardInfo title='Motivo' value={contractorData?.status_reason} />
            )}
          </Grid>

          <Grid container item xs direction='column' style={{ whiteSpace: 'nowrap' }}>
            <Grid item>
              <Typography variant='h6'>Foto</Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => setImageUrl(contractorData?.picture)} disabled={!contractorData?.picture}>
                <Image color={!contractorData?.picture ? 'disabled' : undefined} />
              </Button>
              <ImagePicker onFile={sendUpdateSelf} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Table
        columns={{
          type: {
            title: 'Tipo de Documento',
            lookup: {
              IMG_PROOF_OF_RESIDENCE: 'Comprovante de Residência',
              IMG_CNPJ: 'Cartão CNPJ',
              IMG_CNH_BACK: 'CNH (Verso)',
              IMG_CNH_FRONT: 'CNH (Frente)',
              IMG_RG_BACK: 'RG (Verso)',
              IMG_RG_FRONT: 'RG (Frente)',
              IMG_CRLV_BACK: 'CRLV (Verso)',
              IMG_CRLV_FRONT: 'CRLV (Frente)',
            },
          },
          value: {
            title: 'Visualizar',
            render: data => (
              <Button onClick={() => setImageUrl(data.value)} disabled={!data.value}>
                <Image color={!data.value ? 'disabled' : undefined} />
              </Button>
            ),
          },
          send: {
            title: 'Enviar',
            render: rowData => <ImagePicker onFile={file => handleImage(rowData, file)} />,
          },
        }}
        data={(() => {
          const docs = parseDocuments(
            documents,
            details?.id || props.match?.params.contractorId || contractor?.id,
            contractor?.role,
          );
          return docs;
        })()}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'Sem documentos',
          },
          pagination: {
            labelRowsSelect: 'documentos',
          },
        }}
        options={{
          paging: false,
          search: false,
          showTitle: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
          },
        }}
        style={{
          overflow: 'hidden',
        }}
        isLoading={loading}
      />

      <ActionContainer>
        <ActionButton
          onClick={details?.email ? () => sendReviewEmail(details.email!) : undefined}
          disabled={!details?.email}
        >
          Solicitar Reenvio de Documentos
        </ActionButton>
        <ActionButton $success onClick={approve}>
          Aprovar
        </ActionButton>
        <ActionButton $danger onClick={handleRefuse}>
          Recusar
        </ActionButton>
      </ActionContainer>

      <ImageViewer imageUrl={imageUrl?.replace('https', 'http')} onClose={() => setImageUrl(undefined)} />

      <Modal open={loadingContractor}>
        <Loading />
      </Modal>

      <Modal
        open={openModal}
        fullWidth
        buttons={[
          <ButtonComp key='cancel' type='button' filled label='Cancelar' onClick={() => setOpenModal(false)} />,
          <ButtonComp key='save' type='button' filled label='Salvar' onClick={handleRefuse} />,
        ]}
      >
        <Typography style={{ fontWeight: 'bold' }}>Informe o motivo da recusa</Typography>
        <TextField
          id='reason-field'
          value={reasonRefuse}
          onChange={({ target: { value } }) => setReasonRefuse(value)}
          style={{ width: '100%' }}
        />
      </Modal>
    </>
  );
}

export default Onboarding;

const types = [
  'IMG_RG_FRONT',
  'IMG_RG_BACK',
  'IMG_CNH_FRONT',
  'IMG_CNH_BACK',
  'IMG_CRLV_FRONT',
  'IMG_CRLV_BACK',
  'IMG_PROOF_OF_RESIDENCE',
  'IMG_CNPJ',
] as const;
const typesOrder = types.reduce((O, t, i) => ({ ...O, [t]: i }), {}) as {
  [key in DocumentsResponse[number]['type']]: number;
};

function parseDocuments(documents: DocumentsResponse, identity_id = '', role?: ContractorsResponse['role']) {
  let docs: (Partial<DocumentsResponse[number]> & { type: string; identity_id: string })[] = [...documents];
  const docsTypes = docs.map(d => d.type);
  types.forEach(type => {
    if (!docsTypes.includes(type)) {
      docs.push({
        identity_id,
        type,
      });
    }
  });
  if (role === 'FREELANCER') docs = docs.filter(d => d.type !== 'IMG_CNPJ');
  docs.sort((d1, d2) => typesOrder[d1.type] - typesOrder[d2.type]);
  return docs;
}

interface DetailCardInfoProps {
  title: string;
  value: string | number;
  variant?: 'contained';
  color?: string;
}

function DetailCardInfo(props: DetailCardInfoProps) {
  return (
    <Grid container item xs direction='column' style={{ whiteSpace: 'nowrap' }}>
      <Grid item>
        <Typography variant='h6'>{props.title}</Typography>
      </Grid>
      {props.variant === 'contained' ? (
        <Grid item alignItems='center' container>
          <Paper style={{ backgroundColor: props.color, alignItems: 'center', padding: 5, flex: -1 }}>
            <Typography style={{ color: 'white', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '14px' }}>
              {props.value}
            </Typography>
          </Paper>
        </Grid>
      ) : (
        <Grid item>
          <Typography>{props.value}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
