import React from 'react';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import { OpportunityResponse } from '~/types';
import { SurveyModalContent } from '../SurveyModalContent';

interface Props {
  opportunity?: OpportunityResponse;
  loading?: boolean;
  open: boolean;
  handleCloseModal: () => void;
}

export const ModalSurvey = ({ opportunity, loading, open, handleCloseModal }: Props) => {
  return (
    <Modal
      open={open}
      buttons={[
        <Button key='cancel' type='button' filled label='Fechar' loading={loading} onClick={handleCloseModal} />,
      ]}
      fullWidth
    >
      <SurveyModalContent opportunity={opportunity} closeModal={handleCloseModal} />
    </Modal>
  );
};
