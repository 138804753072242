import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './types';

import '~/config/ReactotronConfig';

import { store, persistor } from './store';

import Routes from '~/routes';
import history from '~/services/history';

import GlobalStyled from '~/styles/global';
import Colors from './styles/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
      light: Colors.primary,
      dark: Colors.primary,
      contrastText: Colors.secondary,
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.secondary,
      dark: Colors.secondary,
      contrastText: Colors.primary,
    },
    danger: {
      main: Colors.red,
      light: '#F88',
      dark: Colors.red,
      contrastText: Colors.red,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          '&:hover': {
            backgroundColor: Colors.secondary,
          },
          backgroundColor: Colors.secondary,
        },
      },
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <StyledComponentsThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Routes />
                <GlobalStyled />
                <ToastContainer />
              </QueryClientProvider>
            </StyledComponentsThemeProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
