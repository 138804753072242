import React, { useState, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { Grid, Button, Typography, Divider, TextField, TextFieldProps } from '@material-ui/core';
import { Delete, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToString } from '~/util/parsers';
import { useEffect } from 'react';

export const DataTree = styled(Grid).attrs({
  container: true,
  direction: 'column',
})`
  max-width: 750px;
`;

export const DataGroupItemsContainer = styled(Grid).attrs({
  container: true,
  item: true,
  alignItems: 'center',
})<{ $open: boolean }>`
  padding-left: 40px;
  overflow: hidden;
  height: ${props => (props.$open ? undefined : 0)};
`;

export interface DataGroupProps {
  variant?: boolean;
  title: string;
  open?: boolean;
  children: ReactElement<DataItemProps, typeof DataItem> | ReactElement<DataItemProps, typeof DataItem>[];
  deletedFunction?: () => void;
  rightButton?: JSX.Element;
}

export function DataGroup(props: DataGroupProps) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (props.open !== undefined) setOpen(props.open);
  }, [props.open]);

  return (
    <Grid container direction='column' style={{ marginTop: 20 }}>
      <Grid container direction='row' alignItems='center' justify='space-between'>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => setOpen(!open)} style={{ minWidth: 0 }}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </Button>
          <Typography variant={props.variant ? 'body1' : 'h5'}>{props.title}</Typography>
        </div>

        <div>
          {props.rightButton}

          {props.deletedFunction && (
            <Button onClick={props.deletedFunction}>
              <Delete />
            </Button>
          )}
        </div>
      </Grid>
      <Divider />
      <DataGroupItemsContainer $open={open} wrap='wrap'>
        {props.children}
      </DataGroupItemsContainer>
    </Grid>
  );
}

export interface DataItemProps {
  variant?: boolean;
  title: string;
  children: ReactNode;
}

export function DataItem(props: DataItemProps) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography variant={props.variant ? 'body1' : 'h6'} style={{ whiteSpace: 'normal' }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {props.children}
      </Grid>
    </>
  );
}

export interface TextDataItemProps {
  variant?: boolean;
  fontSize?: number;
  title: string;
  value?: string;
  editing?: boolean;
  onChange?: (text: string) => void;
  required?: boolean;
  error?: boolean;
  otherProps?: TextFieldProps;
}

export function TextDataItem(props: TextDataItemProps) {
  return (
    <DataItem title={props.title} variant={props.variant}>
      {props.editing ? (
        <TextField
          value={props.value}
          onChange={props.onChange ? ({ target: { value } }) => props.onChange!(value) : undefined}
          required={props.required}
          error={props.error}
          style={{ display: 'flex' }}
          {...props.otherProps}
        />
      ) : (
        <Typography style={{ fontSize: props.fontSize }}>{props.value}</Typography>
      )}
    </DataItem>
  );
}

export interface DateDataItemProps {
  title: string;
  value?: string | number;
  editing?: boolean;
  onChange?: (dateString: string) => void;
  required?: boolean;
  error?: boolean;
}

export function DateDataItem(props: DateDataItemProps) {
  return (
    <DataItem title={props.title}>
      {props.editing ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            id={`date-picker-${props.title}`}
            label=''
            value={(() => {
              if (!props.value) return '';

              if (typeof props.value === 'number') return props.value;

              let newDate: string[];

              if (Array.isArray(props.value)) {
                newDate = props.value
                  .map(item => String(item).padStart(2, '0'))
                  .join('-')
                  .split('-');
              } else {
                newDate = props.value.split('-');
              }

              newDate[2] = `${('0' + (parseInt(newDate[2]) + 1)).slice(-2)}`;

              return newDate?.join('-');
            })()}
            onChange={date =>
              props.onChange &&
              date &&
              props.onChange(
                `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`,
              )
            }
            error={props.error}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <Typography>
          {typeof props.value === 'string'
            ? props.value?.split('-').reverse().join('/')
            : typeof props.value === 'number'
            ? dateToString(new Date(props.value))
            : ''}
        </Typography>
      )}
    </DataItem>
  );
}

export default DataTree;
