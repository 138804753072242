import { all, put, call, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

import { loginApi, setAuthorizationHeader, forgotApi, resetPasswordApi, changePasswordApi } from '~/services/api';

import { handleApiErrorResponse } from '~/services/handleErrors';
import {
  Types as AuthTypes,
  // loginRequest,
  // getClientRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  refreshTokenSuccess,
  refreshTokenFailure,
  userPopulateToken,
  forgotFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateAuthData,
  changePasswordSuccess,
  changePasswordFailure,
  logout,
} from '~/store/ducks/auth';
import history from '~/services/history';
import { clearOrder } from '../ducks/order';
import { clearBalance } from '../ducks/associationBalance';

export function* forgotSaga(action) {
  try {
    const { username } = action.payload;
    yield call(forgotApi, username);
  } catch (err) {
    yield put(forgotFailure(handleApiErrorResponse(err)));
  }
}

export function* setAuthorizationHeaderSaga(token) {
  yield call(setAuthorizationHeader, token);
}

export function* updateAuthDataSaga(action) {
  const { authorization: authData } = action;
  // Set api X-Authorization header
  yield call(setAuthorizationHeaderSaga, authData.id_token);

  // Persist authorization data
  authData.expires_in *= 1000;
  authData.expiration_date = new Date().getTime() + authData.expires_in;

  // dispatch modified auth data to store
}

export function* refreshTokenSaga() {
  try {
    // const { refresh_token } = action.payload;
    const { data: authData } = yield call();
    yield call(updateAuthDataSaga, { authorization: authData });
    yield put(refreshTokenSuccess(authData));
  } catch (err) {
    yield put(refreshTokenFailure(handleApiErrorResponse(err)));
  }
}

export function* logoutSaga() {
  try {
    yield put(updateAuthData({}));
    yield put(clearOrder());
    yield put(clearBalance());
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure(err));
  }
}

export function* userPopulateSaga(token) {
  if (!token) {
    return;
  }

  const tokenDecoded = jwtDecode(token);
  yield put(userPopulateToken(tokenDecoded));
}

export function* loadAuthorizationFromStorageSaga() {
  // const storageAtuhData = yield call(AsyncStorage.getItem, 'authorization');
  // let lastAuthData = null;
  // if (storageAtuhData) {
  //   lastAuthData = JSON.parse(storageAtuhData);
  // }
  // return lastAuthData;
}

export function* loadUserFromStorageSaga() {
  // const storageUserData = yield call(AsyncStorage.getItem, 'user');
  // let lastUserData = null;
  // if (storageUserData) {
  //   lastUserData = JSON.parse(storageUserData);
  //   delete lastUserData?.password;
  // }
  // return lastUserData;
}

export function* resetPasswordSaga(action) {
  try {
    const { username, password, resetCode } = action.payload;
    yield call(resetPasswordApi, username, password, resetCode);
    yield put(resetPasswordSuccess());
    history.push('/');
  } catch (err) {
    yield put(resetPasswordFailure(handleApiErrorResponse(err)));
  }
}

export function* resendConfirmationCodeSaga(action) {
  try {
    const { username } = action.payload;
    yield call(forgotApi, username);
    // yield put(ToastActionsCreators.displayInfo('Novo Código Enviado!'));
  } catch (err) {
    // const errorMessages = handleApiErrorResponse(err);
    // yield put(
    //   ToastActionsCreators.displayError(
    //     errorMessages.applicationErrorMessage + '\n' + errorMessages.apiError,
    //   ),
    // );
  }
}

export function* changePasswordSaga(action) {
  try {
    const { username, currentPassword, newPassword } = action.payload;
    yield call(changePasswordApi, username, currentPassword, newPassword);
    yield put(changePasswordSuccess());
    yield put(logout());
  } catch (err) {
    yield put(changePasswordFailure(handleApiErrorResponse(err)));
  }
}

export function* loginSaga(action) {
  try {
    const { username, password } = action.payload;
    const { data: authData } = yield call(loginApi, username, password);

    yield call(userPopulateSaga, authData.id_token);
    yield call(updateAuthDataSaga, { authorization: authData });
    yield put(loginSuccess(authData));
  } catch (err) {
    yield put(loginFailure(handleApiErrorResponse(err)));
  }
}

export default all([
  takeLatest(AuthTypes.LOGIN_REQUEST, loginSaga),
  takeLatest(AuthTypes.FORGOT_PASSWORD, forgotSaga),
  takeLatest(AuthTypes.LOGOUT, logoutSaga),
  takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga),
  takeLatest(AuthTypes.RESEND_CONFIRMATION_CODE, resendConfirmationCodeSaga),
  takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePasswordSaga),
  takeLatest(AuthTypes.UPDATE_AUTH_DATA, updateAuthDataSaga),
]);
