import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import Colors from '~/styles/colors';

import InputComponent from '~/components/InputComponent';

export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.primary};

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 360px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 40px 30px; */
  /* border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 5px; */
`;

export const Logo = styled.img`
  width: 300px;
  margin-bottom: 10px;
`;

export const Input = styled(InputComponent)`
  input {
    color: #fff;
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.white)};

    &:hover {
      border-bottom-color: ${Colors.white};
    }
  }
  & label {
    color: #fff;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.blue};
  }

  && .MuiInput-underline:before {
    border-bottom-color: ${Colors.white};
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid ${Colors.white};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-bottom-color: ${Colors.blue};
    }
    &.Mui-focused fieldset {
      border-bottom-color: ${Colors.blue};
    }
  }
`;

export const Resend = styled.a`
  color: ${Colors.white};
  margin-top: 15px;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Link = styled(BaseLink)`
  color: ${Colors.white};
  margin-top: 15px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
