import React from 'react';
import { Switch } from 'react-router-dom';

import RouterWrapper from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ForgotPasswordCode from '~/pages/ForgotPasswordCode';
import ImportCSVUsers from '~/pages/ImportCSVUsers';
import RegisterAssociation from '~/pages/RegisterAssociation';
import ChangePassword from '~/pages/ChangePassword';
import CoveragePlans from '~/pages/CoveragePlans';
import TablePrices from '~/pages/TablePrices';
import AdditionalInformation from '~/pages/AdditionalInformation';
import AdditionalInformationList from '~/pages/AdditionalInformationList';
import NotFound from '~/pages/NotFound';
import ContractorManager from '~/pages/ContractorManager';
import OpportunityManager from '~/pages/OpportunityManager';
import AssociationManager from '~/pages/AssociationManager';
import CustomerManager from '~/pages/CustomerManager';
import OpportunityTimeline from '~/pages/OpportunityTimeline';
import NewOpportunity from '~/pages/NewOpportunity';
import OpportunityServices from '~/pages/NewOpportunity/pages/OpportunityServices';
import OpportunityVehicles from '~/pages/NewOpportunity/pages/OpportunityVehicles';
import OpportunityAdditionalInformation from '~/pages/NewOpportunity/pages/OpportunityAdditionalInformation';
import OpportunityMap from '~/pages/NewOpportunity/pages/OpportunityMap';
import MapOpportunities from '~/pages/MapOpportunities';
import { CustomRoles } from '~/types';
import { B2BManager } from '~/pages/B2BManager';
import { Dashboard } from '~/pages/Dashboard';
import { Notifications } from '~/pages/Notifications';

export default function Routes() {
  return (
    <Switch>
      <RouterWrapper exact path='/' component={Login} />

      <RouterWrapper path='/forgot-password' component={ForgotPassword} />

      <RouterWrapper path='/forgot-confirmation' component={ForgotPasswordCode} />

      <RouterWrapper path='/import' component={ImportCSVUsers} isPrivate />

      <RouterWrapper path='/association' component={RegisterAssociation} exact isPrivate />

      <RouterWrapper path='/association/:associationId' component={RegisterAssociation} exact isPrivate />

      <RouterWrapper
        path='/change-password'
        component={ChangePassword}
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper path='/coverage-plans' component={CoveragePlans} isPrivate />

      <RouterWrapper path='/table-prices' component={TablePrices} isPrivate />

      <RouterWrapper path='/additional-information/create' component={AdditionalInformation} exact isPrivate />

      <RouterWrapper path='/additional-information/:id' component={AdditionalInformation} isPrivate />

      <RouterWrapper path='/additional-information' component={AdditionalInformationList} exact isPrivate />

      <RouterWrapper path='/contractor-manager' component={ContractorManager} exact isPrivate />

      <RouterWrapper
        path='/contractor-manager/onboarding/:contractorId'
        component={ContractorManager.Onboarding}
        exact
        isPrivate
      />

      <RouterWrapper
        path='/contractor-manager/vehicles/:contractorId'
        component={ContractorManager.Vehicles}
        exact
        isPrivate
      />

      <RouterWrapper
        path='/contractor-manager/employees/:organizationId'
        component={ContractorManager.Employees}
        exact
        isPrivate
      />

      <RouterWrapper
        path='/opportunity-manager'
        component={OpportunityManager}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper path='/association-manager' component={AssociationManager} exact isPrivate />

      <RouterWrapper
        path='/customer-manager'
        component={CustomerManager}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/opportunity-timeline/:orderId'
        component={OpportunityTimeline}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/new-opportunity'
        component={NewOpportunity}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/new-opportunity/services'
        component={OpportunityServices}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/new-opportunity/vehicles'
        component={OpportunityVehicles}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/new-opportunity/additional-information'
        component={OpportunityAdditionalInformation}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/new-opportunity/map'
        component={OpportunityMap}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper
        path='/map-opportunities'
        component={MapOpportunities}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper path='/b2b-manager' component={B2BManager} exact isPrivate />

      <RouterWrapper
        path='/dashboard'
        component={Dashboard}
        exact
        isPrivate
        roles={[CustomRoles.BACKOFFICE, CustomRoles.B2B]}
      />

      <RouterWrapper path='/notifications' component={Notifications} exact isPrivate />

      <RouterWrapper path='*' component={NotFound} />
    </Switch>
  );
}
