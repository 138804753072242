import React from 'react';
import { Badge, createStyles, makeStyles, Tab, Typography } from '@material-ui/core';
import Colors from '~/styles/colors';

interface Props {
  title: string;
  badgeContent?: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      position: 'absolute',
      top: 10,
      right: 18,
      '& span': {
        color: Colors.white,
        fontWeight: 'bold',
      },
    },
  }),
);

export const OpportunityTab = ({ title, badgeContent, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Tab
      label={
        <>
          <Badge badgeContent={badgeContent} color='primary' className={classes.badge} />
          <Typography>{title}</Typography>
        </>
      }
      {...rest}
    />
  );
};
