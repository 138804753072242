import React from 'react';
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';

import InfoCard from '../InfoCard';

import {
  AllocationContractorResponse,
  AllocationContractorResponsePTBR,
  OpportunityResponse,
  OpportunityResponsePTBR,
} from '~/types';

export interface ContractorPickConfirmProps extends Omit<DialogProps, 'open'> {
  opportunity?: OpportunityResponse;
  contractor?: AllocationContractorResponse;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ContractorPickConfirm({
  opportunity,
  contractor,
  onConfirm,
  onCancel,
  ...props
}: ContractorPickConfirmProps) {
  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  const open = !!opportunity && !!contractor;

  return (
    <Dialog
      maxWidth='xl'
      fullScreen={fullscreen}
      disableScrollLock={true}
      onClose={_ => onCancel()}
      {...props}
      open={open}
    >
      <DialogTitle>Tem certeza de que deseja realocar esta oportunidade para o prestador selecionado?</DialogTitle>
      <DialogContent>
        {opportunity && contractor && (
          <>
            <InfoCard
              title='Oportunidade'
              data={Object.entries(opportunity)
                .filter(e => ['string', 'number', 'boolean'].includes(typeof e[1]) && !!OpportunityResponsePTBR[e[0]])
                .map(item => [OpportunityResponsePTBR[item[0]] ?? item[0], item[1]] as [string, any])}
            />
            <InfoCard
              title='Prestador'
              data={Object.entries(contractor)
                .filter(e => ['string', 'number'].includes(typeof e[1]) && !!AllocationContractorResponsePTBR[e[0]])
                .map(item => [AllocationContractorResponsePTBR[item[0]] ?? item[0], item[1]] as [string, any])}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onCancel()}
        >
          Cancelar
        </Button>
        <Button
          autoFocus
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none' }}
          onClick={_ => onConfirm()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
