import React, { useState, useMemo } from 'react';
import { ContractorsLocation } from '../../index';
import { Container } from './styles';
import { useVehicles } from '../../../../services/hooks/useVehicles';
import { useContractor } from '../../../../services/hooks/useContractor';
import { useOrdersFromContractor } from '../../../../services/hooks/useOrdersFromContractor';
import { OpportunityStatusRequest } from '../../../../types';
import InfoPanel from '../MapModalSections/InfoPanel';
import useVehiclesServices from '../../../../services/hooks/useVehiclesServices';
import useContractorRating from '../../../../services/hooks/useContractorRating';
import { daysAwayFromDate } from '../utils/date';
import RatingBar from '../MapModalSections/RatingBar';
import ContractorInfoSection from '../MapModalSections/ContractorInfoSection';
import OrderInfoSection from '../MapModalSections/OrderInfoSection';
import VehicleInfoSection from '../MapModalSections/VehicleInfoSection';
interface ContractorInfoModalContentProps {
  contractor: ContractorsLocation;
}

const ContractorInfoModalContent: React.FC<ContractorInfoModalContentProps> = ({ contractor }) => {
  const contractorId = contractor.id
  const [opportunityStatus] = useState([OpportunityStatusRequest.ON_GOING, OpportunityStatusRequest.IN_EXECUTION])
  const vehicleData = useVehicles(contractorId)
  const contractorData = useContractor(contractorId)
  const orderData = useOrdersFromContractor(contractor.id, opportunityStatus)
  const servicesData = useVehiclesServices(vehicleData.vehicles)
  const [ratingSearchLenghtInDays] = useState(10)
  const [ratingBegin] = useState<Date>(daysAwayFromDate(new Date(), -ratingSearchLenghtInDays))
  const [ratingEnd] = useState<Date>(new Date())
  const ratingData = useContractorRating(contractor.id, ratingBegin, ratingEnd)

  const iconStyle = { width: '100%', height: '50%', color: 'rgba(0,0,0, .6)' }

  const shouldDisplayOrderSection = useMemo(() => {
    return !orderData.loading && orderData.orders.length > 0
  }, [orderData.loading, orderData.orders.length])

  return (
    <div>
      <Container>
        
        <InfoPanel title='Prestador'>
          <ContractorInfoSection contractor={contractor} contractorData={contractorData} />
          <RatingBar ratings={ratingData.contractorRating} loading={ratingData.loadingContractorRating} periodDays={ratingSearchLenghtInDays} />
        </InfoPanel>

        {
          shouldDisplayOrderSection && <InfoPanel title='Atendendo'>
            <OrderInfoSection orderData={orderData} iconStyle={iconStyle} iconActive={false}/>
          </InfoPanel>
        }

        <InfoPanel title='Veículos' style={{ flexGrow: 2, minWidth: '50%' }}>
          <VehicleInfoSection vehicleData={vehicleData} iconStyle={iconStyle} contractor={contractor} servicesData={servicesData} />
        </InfoPanel>

      </Container>

    </div>
  )
};

export default ContractorInfoModalContent;



