import styled from 'styled-components';
import { Card, CardContent, CardHeader } from '@material-ui/core';

export const Container = styled(Card).attrs({
  variant: 'elevation',
})`
  padding: 10px;
  margin-bottom: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & + div {
    margin-left: 16px;
  }
`;

export const Header = CardHeader;

export const Content = CardContent;
