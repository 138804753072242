import { combineReducers } from 'redux';

import auth from './auth';
import order from './order';
import orderNote from './orderNote';
import uploadPhotos from './uploadPhoto';
import customerBenefit from './customerBenefit';
import { associationBalance } from './associationBalance';
import { ordersBadge } from './ordersBadge';

const reducers = combineReducers({
  auth,
  order,
  orderNote,
  uploadPhotos,
  customerBenefit,
  associationBalance,
  ordersBadge,
});

export default reducers;
