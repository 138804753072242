import { useState, useEffect, useCallback } from 'react';
import {
  loadContractor,
  editContractorApi,
  approveContractor,
  resetContractor,
  getContractorApi,
  createContractorApi,
} from '../api';
import { ContractorBankDataResponse, ContractorIdentity, ContractorResponse } from '~/types';
import { removeFormat } from '~/util/format';

const BANK_DATA: ContractorBankDataResponse = {
  bank: '',
  branch: '',
  bank_code: '',
  recipient: '',
  account_type: '',
  document_type: '',
  current_account: '',
  billing_address: {
    city: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    zip_code: '',
  },
  pix_key: '',
};

export function useContractor(contractorId?: string) {
  const [contractor, setContractor] = useState<ContractorResponse>();
  const [loading, setLoading] = useState(false);

  const getContractor = useCallback(async (username?: string) => {
    if (!username) {
      return;
    }

    const { data } = await getContractorApi(username);
    return data as ContractorIdentity;
  }, []);

  const load = useCallback(() => {
    if (contractorId) {
      setLoading(true);
      loadContractor(contractorId)
        .then(response => response.data as ContractorResponse)
        .then(data => {
          const cpf_cnpj = data.bank_data?.cnpj || data.bank_data?.cpf;

          const newData = { ...data, bank_data: { ...data.bank_data, cpf_cnpj } };

          return newData as ContractorResponse;
        })
        .then(setContractor)
        .finally(() => setLoading(false));
    } else {
      setContractor(undefined);
    }
  }, [contractorId]);

  useEffect(() => {
    load();
  }, [load]);

  const approve = useCallback(() => {
    if (contractorId) return approveContractor(contractorId, true).then(() => load());
    else return Promise.resolve();
  }, [contractorId, load]);

  const refuse = useCallback(
    async (reason: string) => {
      if (contractorId) return approveContractor(contractorId, false, reason).then(() => load());
      else return Promise.resolve();
    },
    [contractorId, load],
  );

  const resetEmail = useCallback(() => {
    if (contractorId) return resetContractor(contractorId, 'EMAIL');
    else return Promise.resolve();
  }, [contractorId]);

  const resetPhone = useCallback(() => {
    if (contractorId) return resetContractor(contractorId, 'PHONE_NUMBER');
    else return Promise.resolve();
  }, [contractorId]);

  const submitContractor = useCallback((data: ContractorResponse, newContractor?: boolean) => {
    let userName = removeFormat(data.bank_data?.cpf_cnpj);
    let document_type = '';

    if (userName.length <= 11) {
      document_type = 'CPF';
      userName = userName.padStart(11, '0');
    } else {
      document_type = 'CNPJ';
      userName = userName.padStart(14, '0');
    }

    let newData: ContractorResponse;

    if (document_type === 'CPF') {
      newData = {
        ...data,
        bank_data: { ...BANK_DATA, ...data.bank_data, cpf: userName, document_type, cnpj: '' },
      };
    } else {
      newData = {
        ...data,
        bank_data: { ...BANK_DATA, ...data.bank_data, cnpj: userName, document_type, cpf: '' },
      };
    }

    delete newData.bank_data?.cpf_cnpj;

    return newContractor
      ? createContractorApi({ cpf: newData.id, ...newData })
      : editContractorApi(newData.id, newData);
  }, []);

  return {
    contractor,
    loading,
    submitContractor,
    approve,
    refuse,
    refresh: () => load(),
    resetEmail,
    resetPhone,
    getContractor,
  };
}
