import produce from 'immer';

export type OrderBadgeState = {
  manualAllocation: number;
  allocating: number;
  onGoing: number;
  inExecution: number;
  finished: number;
  canceled: number;
  payment: number;
};

export type OrderBadgeType = keyof OrderBadgeState;

const OrderBadgeTypes = {
  ORDER_BADGE_VALUE: '@ordersBadge/ORDER_BADGE_VALUE',
  CLEAR_ALL_ORDER_BADGE: '@ordersBadge/CLEAR_ALL_ORDER_BADGE',
} as const;

type SetOrderBadgeValue = ReturnType<typeof setOrderBadgeValue>;
type ClearAllOrderBadge = ReturnType<typeof clearAllOrderBadge>;

type OrderBadgeActions = SetOrderBadgeValue | ClearAllOrderBadge;

const INITIAL_STATE: OrderBadgeState = {
  manualAllocation: 0,
  allocating: 0,
  onGoing: 0,
  inExecution: 0,
  finished: 0,
  canceled: 0,
  payment: 0,
};

export const setOrderBadgeValue = (badge: OrderBadgeType, value: number) => {
  return {
    type: OrderBadgeTypes.ORDER_BADGE_VALUE,
    payload: { badge, value },
  };
};

export const clearAllOrderBadge = () => {
  return {
    type: OrderBadgeTypes.CLEAR_ALL_ORDER_BADGE,
  };
};

export function ordersBadge(state = INITIAL_STATE, action: OrderBadgeActions) {
  switch (action.type) {
    case OrderBadgeTypes.ORDER_BADGE_VALUE:
      return produce(state, draft => {
        const { badge, value } = action.payload;
        draft[badge] = value;
        return draft;
      });

    case OrderBadgeTypes.CLEAR_ALL_ORDER_BADGE:
      return Object.entries(INITIAL_STATE).reduce((acc, [key, value]) => {
        const keyCast = key as OrderBadgeType;
        acc[keyCast] = value;
        return acc;
      }, {} as OrderBadgeState);

    default:
      return state;
  }
}
