import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Add, ArrowBack, Delete, Info } from '@material-ui/icons';

import { ContractorsResponse, EmployeesResponse } from '~/types';
import Table from '~/components/Table';
import { Title } from '~/components/Title';

import { DivButtons, Header } from './styles';
import { useEmployees } from '~/services/hooks';
import EmployeeDetailsDialog from './components/EmployeeDetailsDialog';
import { formatCellPhone, formatCPF, removeFormat } from '~/util/format';
import { ModalConfirm } from '~/components/ModalConfirm';
import { deleteEmployeeApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';

export interface EmployeesParams {
  organizationId: string;
}

export interface EmployeesLocationState {
  contractor?: ContractorsResponse;
}

export type EmployeesProps = RouteChildrenProps<EmployeesParams, EmployeesLocationState>;

export function Employees(props: EmployeesProps) {
  const organizationId = props.match?.params.organizationId || props.location.state.contractor?.organization_id;
  const { employees, loading, getEmployees } = useEmployees(organizationId);

  const [selectedEmployee, setSelectedEmployee] = useState<EmployeesResponse[number]>();
  const [isNewEmployee, setIsNewEmployee] = useState(false);
  const [employeeId, setEmployeeId] = useState<string>();

  const { addToast } = useToast();

  const handleCloseModal = async () => {
    setSelectedEmployee(undefined);
    setIsNewEmployee(false);
    await getEmployees();
  };

  const handleNewEmployee = () => {
    setIsNewEmployee(true);
  };

  const handleDeleteEmployee = async () => {
    try {
      if (!employeeId) {
        return;
      }

      await deleteEmployeeApi(employeeId);
      await getEmployees();
      setEmployeeId(undefined);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao excluir o funcionário');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  return (
    <>
      <Header>
        <Button style={{ marginRight: 20 }}>
          <ArrowBack fontSize='large' onClick={() => props.history.goBack()} />
        </Button>
        <Title>Funcionários</Title>
      </Header>

      <DivButtons>
        <Tooltip title='Adicionar funcionário' arrow>
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleNewEmployee}>
            <Add />
          </IconButton>
        </Tooltip>
      </DivButtons>

      <Table
        title={props.location.state?.contractor?.name || props.match?.params.organizationId}
        columns={{
          given_name: 'Nome',
          family_name: 'Sobrenome',
          username: {
            title: 'CPF',
            render: data => formatCPF(data.username),
          },
          'rg.number': 'RG',
          'cnh.number': 'CNH',
          phone_number: {
            title: 'Telefone',
            render: data => formatCellPhone(removeFormat(data.phone_number?.replace('+55', ''))),
          },
        }}
        data={employees}
        actions={[
          () => ({
            icon: () => <Info />,
            tooltip: 'detalhes',
            onClick: (_, data) => setSelectedEmployee((data as unknown) as EmployeesResponse[number]),
          }),
          () => ({
            icon: () => <Delete />,
            tooltip: 'excluir',
            onClick: (_, data) => {
              const employeeData = (data as unknown) as EmployeesResponse[number];
              setEmployeeId(employeeData.username);
            },
          }),
        ]}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'Sem funcionários',
          },
          pagination: {
            labelRowsSelect: 'funcionários',
          },
        }}
        options={{
          exportButton: true,
          exportFileName: `Funcionários-${props.match?.params.organizationId}`,
        }}
        customOptions={{
          noLineBreakInHeader: true,
        }}
        isLoading={loading}
      />

      <EmployeeDetailsDialog
        employee={selectedEmployee}
        onClose={handleCloseModal}
        newEmployee={isNewEmployee}
        closeModal={handleCloseModal}
        organizationId={organizationId}
      />

      <ModalConfirm
        onConfirm={handleDeleteEmployee}
        onCancel={() => {
          setEmployeeId(undefined);
        }}
        open={!!employeeId}
        title='Tem certeza de que deseja EXCLUIR este funcionário?'
      />
    </>
  );
}

export default Employees;
