import styled, { css } from 'styled-components';
import { Typography, Chip, Paper } from '@material-ui/core';
import { Home, LocalShipping, QueryBuilder, Timeline } from '@material-ui/icons';
import Colors, { opacityColorForBackgroundHex } from '~/styles/colors';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

export interface WithColorProps {
  color: string
}

export const Panel = styled.div`
  flex: 1 1 50%;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 10px;
`;

export const PanelTitle = styled.h2`
  font-size: 18px;
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: solid 3px ${Colors.darkBlue};
`;

interface IconWithBottomTextProps extends WithColorProps {

}

export const IconWithBottomText = styled.div<IconWithBottomTextProps>`
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80px;
  height: 80px;
  padding: .5em;
  border-radius: 5%;
  margin-right: 20px;
  margin-bottom: 45px;
  background-color: ${props => props.color};
`;

interface WorkingIconWithTextProps extends WithColorProps {
}

export const WorkingIconWithText = styled(IconWithBottomText).attrs({}) <WorkingIconWithTextProps>`
  background-color: ${props => props.color};
`;

interface IconTextProps {
  bgColorHex: string
}

export const IconText = styled.span<IconTextProps>`
  font-size: 0.75em;
  line-height: 90%;
  display: block;
  text-align: center;
  font-weight: bold;
  color: ${props => opacityColorForBackgroundHex({ bgHex: props.bgColorHex })};
`;

interface CustomBlockProps {
  disabled?: boolean;
}

export const Description = styled(Typography) <CustomBlockProps>`
  color: ${props => (props.disabled ? '#949494' : '#000')};
`;

const iconStyle = css`
  width: 100%;
  height: 50%;
  color: rgba(0,0,0, .6);
`

export const WorkingIcon = styled(LocalShipping)`
  ${iconStyle}
`;

export const OnlineIcon = styled(QueryBuilder)`
  ${iconStyle}
`;

export const OfflineIcon = styled(Home)`
  ${iconStyle}
`;

export const VehicleIcon = styled(DriveEtaIcon)`
  ${iconStyle}
`;

export const Chips = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > * {
      margin: 5px;
  }
`;

export const RatingWrapper = styled.div`
  padding: 5px;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  color: ${Colors.darkBlue};
  border-radius: 5px;
`;

export const SelectedVehicleText = styled(Typography)`
  margin-bottom: 5px;
  color: ${Colors.rgba.darkBlue(.9)};
  padding: 5px;
  background-color: ${Colors.blue};
  border-radius: 1em;
  font-size: 1.3em;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.5);
`;

interface ServiceChipProps {
  active: boolean
}

export const ServiceChip = styled(Chip) <ServiceChipProps>`
  background-color: ${props => (props.active ? Colors.blue : Colors.lightBlue)};
  color: rgba(0,0,0,0.6);
  font-weight: bolder;
`;

export const IconContentGrid = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
`;

const detailsButtonStyle = css`
  color: rgba(0,0,0, .6);
  border-radius: 100%;
  padding: 1px;
  background-color: rgba(0,0,0, .1);
  color: ${opacityColorForBackgroundHex({ bgHex: Colors.backButton })};
`

export const TimelineIcon = styled(Timeline)`
  ${detailsButtonStyle}
`

export const ChipButton = styled(Chip)`
  padding: 5px;
  cursor: pointer;
  font-weight: bold;
  color: rgba(0,0,0, .6);
  color: ${opacityColorForBackgroundHex({ bgHex: Colors.backButton })};
  background-color: ${Colors.rgba.picktowBlue(.9)};
  &:hover {
    background-color: ${Colors.picktowBlue};
  };
`;


interface VehiclePaperProps {
  selected: boolean
}

export const VehiclePaper = styled(Paper) <VehiclePaperProps>`
  margin-bottom: 10px;
  height: 100%;
  padding: 20px 15px 15px 15px;
  background-color: ${props => props.selected ? '' : Colors.lightGrey};
  color: ${props => props.selected ? '' : Colors.grey};
`

export const VehicleListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

export const VehicleLi = styled.li`
  flex: 1;
  min-width: 300px;
  margin: 0 10px 10px 0;
`

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: ${Colors.lightGrey};
  margin: 10px 0px 10px 0px; 
`
