import React from 'react';
import { Button, Tooltip } from '@material-ui/core';

import MapIcon from '~/assets/svg/pin.svg';

interface ButtonMapsParam {
  latitude: string;
  longitude: string;
}

const ButtonMaps = ({ latitude, longitude }: ButtonMapsParam) => {
  return (
    <Tooltip title='Abrir no Google Maps' arrow>
      <Button>
        <a href={`https://www.google.com/maps?q=${latitude},${longitude}`} target='_blank' rel='noopener noreferrer'>
          <img src={MapIcon} alt='Mapa' width={24} height={24} />
        </a>
      </Button>
    </Tooltip>
  );
};

export default ButtonMaps;
