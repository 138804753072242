import produce from 'immer';

import { AssociationBalance } from '~/services/hooks';

const AssociationBalanceType = {
  SET_BALANCE: '@associationBalance/SET_BALANCE',
  CLEAR_BALANCE: '@associationBalance/CLEAR_BALANCE',
} as const;

type SetBalance = ReturnType<typeof setBalance>;
type ClearBalance = ReturnType<typeof clearBalance>;

type BalanceActions = SetBalance | ClearBalance;

const INITIAL_STATE: AssociationBalance = {
  account_statement: {
    available_balance: 0,
  },
};

export const setBalance = (data: AssociationBalance) => {
  return {
    type: AssociationBalanceType.SET_BALANCE,
    payload: { data },
  };
};

export const clearBalance = () => {
  return {
    type: AssociationBalanceType.CLEAR_BALANCE,
  };
};

export function associationBalance(state = INITIAL_STATE, action: BalanceActions) {
  switch (action.type) {
    case AssociationBalanceType.SET_BALANCE:
      return produce(state, draft => {
        draft = action.payload.data;
        return draft;
      });

    case AssociationBalanceType.CLEAR_BALANCE:
      return produce(state, draft => {
        draft.account_statement = {
          available_balance: 0,
        };
      });
    default:
      return state;
  }
}
