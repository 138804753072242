import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '~/components/Loading';

import { useAssociations } from '~/services/hooks';

import { formatCNPJ, formatCPF, formatPrice } from '~/util/format';
import { validCPF } from '~/util/validate';
import Card, { CardItemProps } from './components/Card';
import { ModalPhone } from './components/ModalPhone';
import { useInformationOrder } from './hooks/useInformationOrder';

import { Container } from './styles';

export interface InformationOrderProps {
  type?: 'SERVICE' | 'VEHICLE';
}

const InformationOrder = ({ type }: InformationOrderProps) => {
  const [openModalPhone, setOpenModalPhone] = useState(false);

  const order = useSelector(state => state.order);
  const { associationBalance, loadingBalance } = useAssociations(order.vehicle?.association_id);
  const { benefit, quota, loadingBenefit, loadingQuota } = useInformationOrder();

  const handleOpenModal = () => {
    setOpenModalPhone(true);
  };

  const cards = useMemo(() => {
    const arrCards: CardItemProps[] = [
      { title: 'Cliente', subtitle: order.customerName, clipboard: order.customerName },
      {
        title: 'CPF | CNPJ',
        subtitle: validCPF(order.customerId) ? formatCPF(order.customerId) : formatCNPJ(order.customerId),
        clipboard: order.customerId,
      },
      {
        title: 'Telefone',
        subtitle: order.customerPhone || '',
        clipboard: order.customerPhone || '',
        edit: handleOpenModal,
      },
    ];

    if (order.service?.description) {
      arrCards.push({
        title: 'Serviço',
        subtitle: order.service?.description,
        typeCard: 'SERVICE',
      });
    }

    if (order.vehicle?.license_plate) {
      arrCards.push({
        title: 'Veículo',
        subtitle: (
          <>
            <p>
              {order.vehicle?.license_plate}{' '}
              {!!order.vehicle?.association_code && `(${order.vehicle?.association_code})`}
            </p>
            <p>
              {order.vehicle?.city} - {order.vehicle?.state}
            </p>
          </>
        ),
        clipboard: order.vehicle?.license_plate,
        typeCard: 'VEHICLE',
      });
    }

    if (benefit) {
      arrCards.push({
        title: 'Benefício',
        subtitle: (
          <>
            <p>Cota: {benefit.quantity}</p>
            <p>Período: {benefit.period}</p>
            <p>{benefit.coverage_km} km</p>
            <p>Consumida: {quota?.consumed_quota || 0}</p>
          </>
        ),
      });
    }

    if (associationBalance.account_statement?.available_balance) {
      arrCards.push({
        title: `Saldo ${order.vehicle?.association_code}`,
        subtitle: formatPrice(associationBalance.account_statement.available_balance),
      });
    }

    return arrCards;
  }, [
    associationBalance.account_statement,
    benefit,
    order.customerId,
    order.customerName,
    order.customerPhone,
    order.service,
    order.vehicle,
    quota,
  ]);

  useEffect(() => {
    setOpenModalPhone(!order.customerPhone && type === 'SERVICE');
  }, [order.customerPhone, type]);

  if (loadingBalance || loadingBenefit || loadingQuota) {
    return <Loading />;
  }

  return (
    <Container>
      {cards.map((item, index) => (
        <Card {...item} key={index} last={index === cards.length - 1} currentTypeCard={type} typeCard={item.typeCard} />
      ))}

      <ModalPhone openModalPhone={openModalPhone} setOpenModalPhone={setOpenModalPhone} />
    </Container>
  );
};

export default InformationOrder;
