import { useCallback, useEffect, useState } from 'react';

import { loadBenefits, loadServices } from '../api';

import { BenefitResponse, ServicesResponse } from '~/types';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';

export function useServices() {
  const [services, setServices] = useState<ServicesResponse[]>();
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingBenefits, setLoadingBenefits] = useState(false);

  const { addToast } = useToast();

  const getBenefits = useCallback(
    async (associationId?: string, serviceId?: string): Promise<BenefitResponse[] | undefined> => {
      try {
        if (!associationId || !serviceId) return;

        setLoadingBenefits(true);

        const { data } = await loadBenefits(associationId, serviceId);

        return data;
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o benefício');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingBenefits(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    setLoadingServices(true);
    loadServices()
      .then(response => response.data)
      .then(setServices)
      .finally(() => {
        setLoadingServices(false);
      });
  }, []);

  return { services, loadingServices, getBenefits, loadingBenefits };
}

export default useServices;
