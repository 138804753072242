import styled from 'styled-components';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { RoomOutlined } from '@material-ui/icons';
import Colors from '~/styles/colors';

export const Container = styled(Card).attrs({
  variant: 'elevation',
})`
  padding: 10px;
  margin-bottom: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & + div {
    margin-left: 16px;
  }
`;

export const Header = styled(CardHeader)`
  flex: 1;
`;

export const Content = CardContent;

export const OptionalChildrenWrapper = styled.div`
  margin-left: 16px;
`
export const FullWidthFlex = styled.div`
  width: 100%;
  display: flex;
`

export const IconWrapper = styled.div`
  height: auto;
  text-align: center;
`

export const OpenOnMapsIcon = styled(RoomOutlined)`
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  transition: background-color .3s;
  color: ${Colors.rgba.primary(.9)};
  &:hover{
    background-color: ${Colors.rgba.secondary(.5)}
  }
` 