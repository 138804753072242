import { Grid } from '@material-ui/core';
import React from 'react';

import { WithChildren } from '~/types';

interface GridContainerProps extends WithChildren {}

export function GridContainer({ children }: GridContainerProps) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}
