import React, { useState, useCallback } from 'react';
import { MoreHoriz } from '@material-ui/icons';
import { Popover, Typography, Button, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Table } from '~/components';

import { OpportunitiesResponse, CancellationReasonsResponse, AllocationCancelReasonsResponse } from '~/types';

import { useAllocationCancelReasons, useCancellationReasons } from '~/services/hooks';
import { forceCancelAllocation, forceCancelOrder } from '~/services/api';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';
import firebase from '~/config/firebase';

const db = firebase.firestore();

export interface ActionMenuProps {
  data: OpportunitiesResponse[number];
  id: string;
  allowCancel?: boolean;
  onManualRealocation?: (opportunity: OpportunitiesResponse[number]) => void;
  onCancel: () => void;
  onCancelAllocation?: () => void;
  onSendToManualAllocation?: (opportunity: OpportunitiesResponse[number]) => void;
  onSurvey?: (opportunity: OpportunitiesResponse[number]) => void;
}

export function ActionMenu({
  id: paramId,
  data,
  allowCancel = true,
  onCancel,
  onCancelAllocation,
  onManualRealocation,
  onSendToManualAllocation,
  onSurvey,
}: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openCancelReasons, setOpenCancelReasons] = useState(false);
  const [openAllocationCancelReasons, setOpenAllocationCancelReasons] = useState(false);

  const history = useHistory();
  const cancellationReasons = useCancellationReasons();
  const allocationCancelReasons = useAllocationCancelReasons();
  const { addToast } = useToast();

  const open = Boolean(anchorEl);
  const id = open ? paramId : undefined;

  const handleCancelOrder = useCallback(
    async (orderId: string, cancellationReason: CancellationReasonsResponse[number]) => {
      try {
        await forceCancelOrder(orderId, cancellationReason.reason, cancellationReason.penalty_charge);
        await db.collection('orders').doc(orderId).delete();
        setOpenCancelReasons(false);
        setAnchorEl(null);
        onCancel();
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao cancelar oportunidade');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    },
    [addToast, onCancel],
  );

  const handleCancelAllocation = useCallback(
    async (contractorId: string, cancellationReason: AllocationCancelReasonsResponse['cancel_reasons'][number]) => {
      try {
        await forceCancelAllocation(contractorId, cancellationReason.id);
        setOpenAllocationCancelReasons(false);
        setAnchorEl(null);
        if (onCancelAllocation) {
          onCancelAllocation();
        }
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao cancelar alocação');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    },
    [addToast, onCancelAllocation],
  );

  return (
    <div onClick={event => event.stopPropagation()}>
      <Button
        aria-describedby={id}
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreHoriz />
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          },
        }}
      >
        {onManualRealocation && (
          <Button
            style={{ textTransform: 'none' }}
            onClick={event => {
              onManualRealocation!(data);
              event.stopPropagation();
            }}
          >
            <Typography>Realocação Manual</Typography>
          </Button>
        )}

        {onCancelAllocation && (
          <Button
            style={{ textTransform: 'none' }}
            onClick={event => {
              event.stopPropagation();
              allocationCancelReasons.load();
              setOpenAllocationCancelReasons(true);
            }}
          >
            <Typography>Cancelar Alocação</Typography>
          </Button>
        )}

        {onSendToManualAllocation ? (
          <Button
            style={{ textTransform: 'none' }}
            onClick={event => {
              onSendToManualAllocation!(data);
              event.stopPropagation();
            }}
          >
            <Typography>Enviar para Alocação Manual</Typography>
          </Button>
        ) : (
          allowCancel && (
            <Button
              style={{ textTransform: 'none' }}
              onClick={event => {
                event.stopPropagation();
                cancellationReasons.load();
                setOpenCancelReasons(true);
              }}
            >
              <Typography>Cancelar Pedido</Typography>
            </Button>
          )
        )}

        {onSurvey && (!data.contractor_survey || !data.customer_survey) && (
          <Button
            style={{ textTransform: 'none' }}
            onClick={event => {
              event.stopPropagation();
              onSurvey(data);
            }}
          >
            <Typography>Avaliar</Typography>
          </Button>
        )}
        <Button
          style={{ textTransform: 'none' }}
          onClick={event => {
            event.stopPropagation();
            history.push(`/opportunity-timeline/${data.id}`);
          }}
        >
          <Typography>Timeline</Typography>
        </Button>
      </Popover>

      <Dialog
        open={openCancelReasons}
        onClose={() => {
          setOpenCancelReasons(false);
        }}
      >
        <Table
          data={cancellationReasons.data}
          columns={{
            reason: 'Tipo de Cancelamento',
            penalty_charge: {
              title: 'Multa',
              render: (data: CancellationReasonsResponse[number]) => {
                if (data.penalty_charge === undefined) return 'À Definir';
                else if (data.penalty_charge) return 'Sim';
                else return 'Não';
              },
            },
          }}
          onRowClick={(event, rowData) => {
            event?.stopPropagation();
            handleCancelOrder(data.id, rowData as CancellationReasonsResponse[number]);
          }}
          options={{
            toolbar: false,
            paging: false,
          }}
          isLoading={cancellationReasons.loading}
        />
      </Dialog>

      <Dialog
        open={openAllocationCancelReasons}
        onClose={() => {
          setOpenAllocationCancelReasons(false);
        }}
      >
        <Table
          data={allocationCancelReasons.data}
          columns={{
            cancel_reason: 'Tipo de Cancelamento',
          }}
          onRowClick={(event, rowData) => {
            event?.stopPropagation();
            handleCancelAllocation(
              data.contractor_id,
              rowData as AllocationCancelReasonsResponse['cancel_reasons'][number],
            );
          }}
          options={{
            toolbar: false,
            paging: false,
          }}
          isLoading={allocationCancelReasons.loading}
        />
      </Dialog>
    </div>
  );
}

export default ActionMenu;
