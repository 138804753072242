import { useEffect, useState } from 'react';

import { loadContractorRating } from '../api';

import { ContractorSurveyResponse } from '../../types';
import { dateToString } from '../../util/parsers';

export function useContractorRating(contractorId: string, startDate: Date, endDate: Date) {
  const [contractorRating, setContratorRating] = useState<ContractorSurveyResponse>([]);
  const [loadingContractorRating, setLoadingContractorRating] = useState(false);

  useEffect(() => {
    setLoadingContractorRating(true);
    loadContractorRating(contractorId, dateToString(startDate, '0000-00-00'), dateToString(endDate, '0000-00-00'))
      .then(response => response.data as ContractorSurveyResponse)
      .then(setContratorRating)
      .finally(() => {
        setLoadingContractorRating(false);
      });
  }, [contractorId, startDate, endDate]);

  return { contractorRating, loadingContractorRating };
}

export default useContractorRating;
