import axios from 'axios';
import { store } from '~/store';
import { refreshTokenSuccess, logout } from '~/store/ducks/auth';
import { appendQueryParamsTo } from './urlutils';

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error?.response?.data?.exp === 'token expired') {
      const { refresh_token } = store.getState().auth.authorization;

      return new Promise((resolve, reject) => {
        api
          .post('/identity-providers/BACKOFFICE/authentications:refresh', {
            refresh_token,
          })
          .then(({ data }) => {
            store.dispatch(refreshTokenSuccess(data));
            originalRequest.headers.Authorization = `Bearer ${data.id_token}`;
            resolve(axios(originalRequest));
          })
          .catch(err => {
            reject(err);
            store.dispatch(logout());
          });
      });
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use(config => {
  const token = store.getState().auth.authorization.id_token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export function loginApi(username, password) {
  return api.post('/identity-providers/BACKOFFICE/authentications', {
    username,
    password,
  });
}

export function setAuthorizationHeader(token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const forgotApi = username => {
  return api.post('/identity-providers/BACKOFFICE/authentications:forgottenPassword', { username });
};

export const resetPasswordApi = (username, password, resetCode) => {
  return api.post('/identity-providers/BACKOFFICE/authentications:resetPassword', {
    username,
    new_password: password,
    reset_code: resetCode,
  });
};

export const changePasswordApi = (username, currentPassword, newPassword) => {
  return api.post(`/identity-providers/BACKOFFICE/authentications/${username}:changePassword`, {
    old_password: currentPassword,
    new_password: newPassword,
  });
};

export const uploadFiles = bodyFormData => {
  return api.post('/files:upload', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const documentsAssociation = (associationId, data) => {
  // types
  //   BANK_DATA,  => url deverá ser passada via value
  //   CPF,  => url deverá ser passada via value
  //   CNPJ,  => url deverá ser passada via value
  //   STATE_SUBSCRIPTION,  => url deverá ser passada via value
  //   PROOF_OF_CNPJ => url deverá ser passada no parâmetro path
  //   SOCIAL_CONTRACT => url deverá ser passada no parâmetro path
  //   PROOF_OF_RESIDENCE => url deverá ser passada no parâmetro path
  return api.post(`/documents/${associationId}`, data);
};

export const createAssociation = data => {
  return api.post('/associations', data);
};

export const loadAssociations = (associationId = '') => {
  return api.get(`/associations/${associationId}`);
};

export const customerLoad = (association_id, data) => {
  return api.post('/spreadsheets/customer:upload', {
    association_id,
    data,
  });
};

export const deleteQuiz = quizId => {
  return api.delete(`quizzes/${quizId}`);
};

export const restoreQuiz = quizId => {
  return api.post(`quizzes/${quizId}:undelete`);
};

export const inactiveQuiz = quizId => {
  return api.post(`quizzes/${quizId}:inactive`);
};

export const activeQuiz = quizId => {
  return api.post(`quizzes/${quizId}:active`);
};

export const createQuiz = (code, association_id, service_id, form) => {
  return api.post('quizzes', {
    code,
    association_id,
    service_id,
    form,
  });
};

export const changeQuiz = (quizId, code, association_id, service_id, active, form) => {
  return api.put(`quizzes/${quizId}`, {
    code,
    association_id,
    service_id,
    active,
    form,
  });
};

export const loadQuiz = quizId => {
  return api.get(`quizzes/${quizId}`);
};

export const loadQuizFromAssociation = associationId => {
  return api.get(`quizzes/associations/${associationId}`);
};

export const loadBenefits = (associationId, serviceId) => {
  return api.get(`benefits/associations/${associationId}/services/${serviceId}`);
};

export const createBenefits = data => {
  return api.post('benefits', data);
};

export const changeBenefits = (id, data) => {
  return api.put(`benefits/${id}`, data);
};

export const deleteBenefits = id => {
  return api.delete(`benefits/${id}`);
};

export const loadPeriods = () => {
  return api.get(`periods`);
};

export const loadServices = () => {
  return api.get('services');
};

export const loadContractorRating = (contractorId, from, to) => {
  return api.get(appendQueryParamsTo(`contractors/survey/${contractorId}`, { from, to }));
};

export const loadVehicleServicesApi = vehicleId => {
  return api.get(`associated/services/contractors/${vehicleId}`);
};

export const loadCategoriesByServices = serviceId => {
  return api.get(`services-categories/services/${serviceId}`);
};

export const loadPricingByAssociationAndServiceCategory = (associationId, serviceCategoryId) => {
  return api.get('pricing-association-tables/pricing-associations', {
    params: {
      associationId,
      serviceCategoryId,
    },
  });
};

export const createAssociationPricingApi = data => {
  return api.post('pricing-association-tables', data);
};

export const updateAssociationPricingApi = (tableId, data) => {
  return api.put(`pricing-association-tables/${tableId}`, data);
};

export const deleteAssociationPricingApi = tableId => {
  return api.delete(`pricing-association-tables/${tableId}`);
};

export function loadContractors() {
  return api.get('contractors');
}

export function loadContractor(contractorId) {
  return api.get(`contractors/${contractorId}:fetchDetails`);
}

export function editContractorApi(contractorId, contractor) {
  return api.put(`contractors/${contractorId}/management`, contractor);
}

export function createContractorApi(data) {
  return api.post('contractors', data);
}

export function deleteContractorApi(contractorId) {
  return api.delete(`contractors/${contractorId}`);
}

export const updateEmployeeApi = (employeeId, data) => {
  return api.put(`employees/${employeeId}`, data);
};

export const createEmployeeApi = data => {
  return api.post('employees', data);
};

export const deleteEmployeeApi = employeeId => {
  return api.delete(`employees/${employeeId}`);
};

export const changeContractorApi = data => {
  return api.put('identities/CONTRACTOR:admin', data);
};

export const signUpContractorApi = data => {
  return api.post('identities/CONTRACTOR:admin', data);
};

export function loadVehicles(contractorId) {
  return api.get(`contractor/vehicle/organization/contractors/${contractorId}:getAll`);
}

export function loadEmployees(organizationId) {
  return api.get(`employees/organizations/${organizationId}`);
}

export function loadDocumentsApi(contractorId) {
  return api.get(`documents/${contractorId}:findImages`);
}

export function approveContractor(identityId, approved = true, status_reason = '') {
  return api.patch(`documents/${identityId}/verified`, { approved, status_reason });
}

export function loadOrdersFromContractor(contractorId, statuses) {
  return api.get(appendQueryParamsTo(`orders/contractor/${contractorId}`, { status: statuses }));
}

export function loadOpportunities(status, from, to, vin) {
  if (Array.isArray(status)) {
    return loadOpportunitiesInSeveralStatuses(status, from, to, vin);
  }
  const source = axios.CancelToken.source();
  const promise = api.get(
    `orders?status=${status}${from && to ? `&from=${from}&to=${to}` : ''}${vin ? `&vin=${vin}` : ''}`,
    {
      cancelToken: source.token,
    },
  );
  promise.cancel = () => source.cancel();
  return promise;
}

function loadOpportunitiesInSeveralStatuses(statuses = [], from, to, vin) {
  const source = axios.CancelToken.source();

  const promise = api.get(appendQueryParamsTo('orders', { status: statuses, from, to, vin }), {
    cancelToken: source.token,
  });
  promise.cancel = () => source.cancel();
  return promise;
}

export function uploadFile(bodyFormData) {
  return api.post('/files:upload', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const uploadPublicFilesApi = bodyFormData => {
  return api.post('/assets/files:upload', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const changePictureContractorAdminApi = (username, data) => {
  return api.patch(`/identities/CONTRACTOR/${username}:changePictureAdmin`, data);
};

export function updateDocument(documentId, document) {
  return api.put(`documents/${documentId}`, document);
}

export function createDocument(identityId, document) {
  return api.post(`documents/${identityId}`, document);
}

export function sendReviewDocumentsEmail(identityId, data) {
  return api.post(`documents/${identityId}/review`, data);
}

export function loadAllocationContractors(service_id, customer_vehicle_type) {
  const source = axios.CancelToken.source();
  const promise = api.get(
    `allocations/contractors:listContractorsAbleTo?serviceId=${service_id}&customerVehicleType=${customer_vehicle_type}`,
    {
      cancelToken: source.token,
    },
  );
  promise.cancel = () => source.cancel();
  return promise;
}

export function allocateOpportunityForContractor(orderId, contractorId) {
  return api.post(`orders/${orderId}:manualAllocation`, { contractor_id: contractorId });
}

export function loadCustomerApi(customerId) {
  return api.get(`customers/${customerId}:fetchDetails`);
}

export function loadCancelReasons() {
  return api.get('cancellation-reasons');
}

export function forceCancelOrder(orderId, reason, penalty_charge) {
  return api.post(`orders/${orderId}:forceCancel`, { reason, penalty_charge });
}

export function resetCustomer(username, confirmation_type) {
  return api.post(`identities/CUSTOMER/${username}:resetAlias`, { confirmation_type });
}

export function resetContractor(username, confirmation_type) {
  return api.post(`identities/CONTRACTOR/${username}:resetAlias`, { confirmation_type });
}

export function deleteCustomer(username) {
  return api.post(`identities/CUSTOMER/${username}:delete`);
}

export function deleteContractor(username) {
  return api.delete(`identities/CONTRACTOR/${username}`);
}

export function loadQuizAnswers(orderId) {
  return api.get(`quiz-answers/orders/${orderId}`);
}

export function loadOrderDocuments(orderId) {
  return api.get(`documents/orders/${orderId}`);
}

export function loadAllocationCancelReasons() {
  return api.get('cancel_reasons');
}

export function forceCancelAllocation(contractorId, cancelReasonId) {
  return api.post(`invites/contractors/${contractorId}/cancel_reasons/${cancelReasonId}`);
}

export function loadOrderDetails(orderId, type) {
  return api.get(`order-details/orders/${orderId}`, { params: { type } });
}

export const deleteVehicleAPI = vehicleId => {
  return api.delete(`contractor/vehicle/${vehicleId}`);
};

export const getAllBanksApi = () => {
  return api.get('/integrations/bank-list');
};

export function loadOrderCosts(orderId) {
  return api.get(`cost-parameters/orders/${orderId}`);
}

export const sendToManualAllocationAPI = (orderId, data) => {
  return api.post(`cancellations/orders/${orderId}`, data);
};

export const getOrderHistoryApi = orderId => {
  return api.get(`orders/history/orders/${orderId}`);
};

export const getAllocationsHistoryApi = orderId => {
  return api.get(`allocations/history/orders/${orderId}`);
};

export const getPaymentHistoryApi = orderId => {
  return api.get(`payments/history/orders/${orderId}`);
};

export const getInvitesApi = orderId => {
  return api.get(`invites/orders/${orderId}`);
};

export const deleteCustomerVehicleApi = vehicleId => {
  return api.delete(`customers/vehicles/${vehicleId}`);
};

export const getVehicleBySocialSecurityApi = socialSecurity => {
  return api.get(`customers/vehicles/social-security/${socialSecurity}`);
};

export const getQuizByAssociationServiceApi = (associationId, serviceId) => {
  return api.get(`/quizzes/associations/${associationId}/services/${serviceId}`);
};

export const getQuizByServiceApi = serviceId => {
  return api.get(`/quizzes/services/${serviceId}`);
};

export const validateRegionsServiceApi = data => {
  return api.post('service-regions/within', data);
};

export const getTimeZoneByGoogleApi = (API_KEY, latitude, longitude) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=1458000000&key=${API_KEY}`,
  );
};

export const maplinkTripApi = data => {
  return api.post('trip', data);
};

export const mapCalculatorApi = data => {
  return api.post('customers/calculation', data);
};

export const getAllPaymentMethodsApi = () => {
  return api.get('payment-methods');
};

export const createOrderApi = data => {
  return api.post('orders', data);
};

export const loadContractorsByLatLng = (latitude, longitude) => {
  return api.get(`coordinates/contractors?lat=${latitude}&lng=${longitude}&radiusKm=2500`);
};

export const loadContractorsInfoByIdAndStatus = (contractorId, { status }) => {
  return api.get(`orders/contractor/${contractorId}?status=${status}`);
};

export function addNoteByOrderId(orderId, note) {
  return api.put(`orders/${orderId}:addAnnotation`, { note });
}
export const uploadFileToOrderAPI = data => {
  return api.put('documents/orders/photo-file:upload', data);
};

export const loadVehicleBenefits = (associationId, serviceId, socialSecurity, vehicleId) => {
  return api.get(
    `benefits/associations/${associationId}/services/${serviceId}/social-security/${socialSecurity}/vehicle/${vehicleId}`,
  );
};

export const loadVehicleBenefitQuota = (vehicleId, benefitId) => {
  return api.get(`customers/vehicles/${vehicleId}/benefits/${benefitId}:benefitsQuotaUsage`);
};

export const getVehicleTypesApi = () => {
  return api.get('/customers/vehicles/types');
};

export const getFipeManufacturersByCategoryApi = categoryName => {
  return api.get(`/integrations/FIPE/categories/${categoryName}/manufacturers`);
};

export const getFipeVehiclesByManufacturersAndCategoryApi = (categoryName, manufacturerId) => {
  return api.get(`/integrations/FIPE/categories/${categoryName}/manufacturers/${manufacturerId}/vehicles`);
};

export const getFipeModelsByVehiclesAndManufacturersAndCategoryApi = (categoryName, manufacturerId, vehicleId) => {
  return api.get(
    `/integrations/FIPE/categories/${categoryName}/manufacturers/${manufacturerId}/vehicles/${vehicleId}/models`,
  );
};

export const loadAssociationBalance = associationId => {
  return api.get(`associations/${associationId}:getAccountStatement`);
};

export const createNewUserApi = data => {
  return api.post('identities/CUSTOMER:admin', data);
};

export const createCustomerVehicleApi = data => {
  return api.post('customers/vehicles', data);
};

export const createCustomerVehicleBenefitsApi = (vehicleId, data) => {
  return api.post(`customers/vehicles/${vehicleId}/benefits`, data);
};

export const changeVehicleApi = (vehicleId, data) => {
  return api.put(`customers/vehicles/${vehicleId}`, data);
};

export const loadUsersB2BApi = () => {
  return api.get('identities/BACKOFFICE?role=B2B');
};

export const createUserB2BApi = data => {
  return api.post('identities/BACKOFFICE:admin', data);
};

export const changeUserB2BApi = data => {
  return api.put('identities/BACKOFFICE:admin', data);
};

export const enabledUserB2BApi = username => {
  return api.post(`identities/BACKOFFICE/${username}:enable`);
};

export const deleteUserB2BApi = username => {
  return api.delete(`identities/BACKOFFICE/${username}`);
};

export const inactivateAssociationVehiclesApi = associationId => {
  return api.post(`associations/${associationId}/vehicles:inactivate`);
};

export const changeCustomerApi = data => {
  return api.put('identities/CUSTOMER:admin', data);
};

export const disableCustomerApi = customerId => {
  return api.delete(`identities/CUSTOMER/${customerId}`);
};

export const enableCustomerApi = customerId => {
  return api.post(`identities/CUSTOMER/${customerId}:enable`);
};

export const getPaymentDetailsApi = orderId => {
  return api.get(`payments/details/orders/${orderId}`);
};

export const updateAssociation = (associationId, data) => {
  return api.put(`/associations/${associationId}`, data);
};

export function loadCustomerByLicensePlateApi(licensePlate) {
  return api.get(`customers/vehicles/license-plate/${licensePlate}`);
}

export const getContractorApi = username => {
  return api.get(`identities/CONTRACTOR/${username}`);
};

export const getImageFileApi = file => {
  return api.get(`files:download`, { responseType: 'blob', params: { url: file } });
};

export const getContractorsAllocatedApi = () => {
  return api.get('allocations/contractors');
};

export const createContractorAllocatedApi = contractorId => {
  return api.post(`allocations/contractors/${contractorId}`);
};

export const deleteContractorAllocatedApi = contractorId => {
  return api.delete(`allocations/contractors/${contractorId}`);
};

export const sendPushApi = data => {
  return api.post('notifications/push', data);
};

export const getContractorVehicleTypesApi = () => {
  return api.get('/contractor/vehicle/types');
};

export const getVehicleServicesByTypeIDApi = typeId => {
  return api.get(`/contractor/vehicle/services/${typeId}`);
};

export const createVehicleApi = data => {
  return api.post('/contractor/vehicle', data);
};

export const updateVehicleApi = (vehicleId, data) => {
  return api.put(`/contractor/vehicle/${vehicleId}`, data);
};

export const syncHinovaApi = data => {
  return api.post('/webhooks/hinova', data);
};

export const createSurveyCustomerApi = data => {
  return api.post('customers/survey', data);
};

export const createSurveyContractorApi = data => {
  return api.post('contractors/survey', data);
};

export const getOrderByIdApi = orderId => {
  return api.get(`orders/${orderId}`);
};

export const getStatesApi = () => {
  return api.get('/integrations/IBGE/states');
};

export const getCitiesApi = stateId => {
  return api.get(`/integrations/IBGE/states/${stateId}/cities`);
};

export default api;
